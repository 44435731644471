import { gsap } from "gsap";
import {Link, useNavigate, Navigate} from 'react-router-dom'
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import React, {useState, useRef, useEffect, useLayoutEffect} from "react";
import Countdown from 'react-countdown';
import useWebSocket from 'react-use-websocket';
import {useSwipeable} from 'react-swipeable';

const socketUrl = process.env.REACT_APP_WSS;

const colors1 = ['#1A0C64', '#2C3FA5', '#624237', '#0D6E77', '#BDBDBD', '#F5B9C1', '#AA210E', '#00F8EA', '#DA044E', '#FB323C'];
const colors2 = ['#530084', '#F57708', '#3A52BC', '#6594FE', '#42A296', '#FF4400', '#4EC2FB', '#6D6212', '#F7937B', '#6CD1DD'];

function Home() {
    const [wsStatus, setWsStatus] = useState('loading');
    const [mainStatus, setMainStatus] = useState('intro');
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentSlide2, setCurrentSlide2] = useState(0);
    const [slLoaded, setSlLoaded] = useState(false);
    const [slLoaded2, setSlLoaded2] = useState(false);
    const [drawFinished, setDrawFinished] = useState(false);
    const [countdownDate, setCountDownDate] = useState(null);
    const [error, setError] = useState(null);
    const [objects, setObjects] = useState({
        0: {
            active: null,
            elements: ['none', 'none', 'none']
        },
        1: {
            active: null,
            elements: ['none', 'none']
        },
        2: {
            active: null,
            elements: ['none', 'none']
        },
        3: {
            active: null,
            elements: ['none', 'none']
        },
        4: {
            active: null,
            elements: ['none', 'none', 'none']
        }
    });

    const handlers = useSwipeable({
        onSwipedUp: (eventData) => {
            let mess =  {
                "action": "finished",
                "data":
                    {
                        "ObjectID": currentSlide,
                        "Elements":objects[currentSlide].elements
                    }

            };
            sendMessage(JSON.stringify(mess));
        },
    });

    const {
        sendMessage,
        sendJsonMessage,
        lastMessage,
        lastJsonMessage,
        readyState,
        getWebSocket,
    } = useWebSocket(socketUrl, {
        onOpen: () => {
            let mess =  {"action": "hello"};
            sendMessage(JSON.stringify(mess));
        },
        onMessage: (response) => {
            let mess = JSON.parse(response.data);
            console.log("mess");
            console.log(mess);
        },
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => true,
    });

    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        slides: {
            perView: 1,
            spacing: 0,
        },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
        created() {
            setSlLoaded(true)
        },
    });

    const [sliderRef2, instanceRef2] = useKeenSlider({
        initial: 0,
        slides: {
            perView: 1,
            spacing: 0,
        },
        slideChanged(slider) {
            setCurrentSlide2(slider.track.details.rel);
        },
        created() {
            setSlLoaded2(true)
        },
    });

    useEffect(()=>{
        if(!objects[currentSlide].elements.includes('none')){
            setDrawFinished(true);
        }
    }, [objects]);

    useEffect(()=>{
        console.log(readyState);
    }, [readyState]);

    useEffect(()=>{
        if(lastMessage && lastMessage.data){
            let data = JSON.parse(lastMessage.data);
            let action = data.action;
            switch(action){
                case 'ready':
                    break;
                case 'service':
                    break;
                case 'busy':
                    break;
                case 'ping':
                    break;
                case 'fail':
                    if(data.error){
                        setError(data.error);
                    }
                    break;
                case 'success':
                    break;
            }
            setWsStatus(action);
        }


    }, [lastMessage]);

    const renderer = ({ minutes, seconds, completed }) => {
        return <span>{("0" + minutes).slice(-2)}:{("0" + seconds).slice(-2)}</span>;
    };

    return(
        <>
            {['busy', 'service', 'loading', 'finish', 'ping', 'fail'].includes(wsStatus) && (
                <div className="sc__loader h-full fadeIn animated">
                    {['busy', 'service', 'loading', 'ping', 'fail'].includes(wsStatus) && (
                        <>
                            <div className="star star--1"/>
                            <div className="star star--2"/>
                            <div className="star star--3"/>
                        </>
                    )}
                    {wsStatus === 'finish' && (
                        <>
                            <div className="star-v star-v--1"/>
                            <div className="star-v star-v--2"/>
                            <div className="star-v star-v--3"/>
                            <div className="star-v star-v--4"/>
                            <div className="star-v star-v--5"/>
                        </>
                    )}
                    <div className="container h-100 d-flex align-items-center justify-content-center">
                        {['busy', 'service', 'loading', 'ping', 'fail'].includes(wsStatus) && (
                            <div className="loader__ct">
                                <div className="loader__img">
                                    <img src={require("../assets/img/aircraft.png")}/>
                                </div>
                                <div className="loading"/>
                                {wsStatus == 'busy' && (
                                    <div className="status__txt text-center">
                                        <p>
                                            В данный момент <br/>
                                            кто-то уже работает в системе.
                                        </p>
                                        <p>
                                            Ожидай своей очереди
                                        </p>
                                    </div>
                                )}

                                {wsStatus == 'service' && (
                                    <div className="status__txt text-center fadeIn animated">
                                        <p>
                                            В данный момент <br/>
                                            кто-то уже работает в системе.
                                        </p>
                                        <p>
                                            Ожидай своей очереди
                                        </p>
                                    </div>
                                )}

                                {wsStatus == 'ping' && (
                                    <div className="status__txt text-center fadeIn animated">
                                        <p>
                                            К сожалению, твоё время истекло.
                                        </p>
                                        <p>
                                            Просканируй QR-код заново <br/>
                                            и возвращайся в игру.
                                        </p>
                                    </div>
                                )}

                                {wsStatus == 'fail' && error && (
                                    <div className="status__txt status__txt--error text-center fadeIn animated">
                                        {error}
                                    </div>
                                )}
                            </div>
                        )}

                        {wsStatus === 'finish' && (
                            <div {...handlers} className="finish__over"/>
                        )}

                        {wsStatus === 'finish' && (
                            <div className="finish__box">
                                <div className="finish__img-box d-flex align-items-center justify-content-center">
                                    <div className="finish__bg">
                                        <svg width="280" height="266" viewBox="0 0 280 266" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                            <path d="M246.133 266C256.074 266 264.133 257.941 264.133 248L264.133 209.677C264.133 206.232 265.122 202.858 266.983 199.958L277.15 184.109C279.011 181.208 280 177.835 280 174.389L280 32.9333C280 22.9922 271.941 14.9333 262 14.9333L222.917 14.9333C219.363 14.9333 215.889 13.8814 212.932 11.9102L199.601 3.02307C196.644 1.05185 193.17 -3.4313e-05 189.617 -3.44684e-05L31.0667 -4.13988e-05C21.1256 -4.18333e-05 13.0667 8.05883 13.0667 18L13.0667 78.4743C13.0667 81.9294 12.0723 85.3115 10.2022 88.2167L2.86445 99.6165C0.994392 102.522 6.99802e-06 105.904 6.847e-06 109.359L7.86806e-07 248C3.52266e-07 257.941 8.05887 266 18 266L246.133 266Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <div className="finish__img-bl">
                                        {currentSlide === 0 && (
                                            <div className="obj__it-img finish--0">
                                                <img src={require("../assets/img/objects/ssj100.svg").default}/>
                                                <div className="obj__el">
                                                    <svg viewBox="0 0 1931 1086">
                                                        <g
                                                            id="E1"
                                                            style={{color: objects[0].elements[0]}}
                                                            className="action-group">
                                                            <g>
                                                                <path d="M113.3,625.5c0.3,0.2,0.5,0.3,0.7,0.2c-0.8,1.4-1.2,2.2,0.3,3.3c3.8,2.7,8,4.5,12.5,5.5
			c2.2,0.5,4.9,0.6,7.9,0.2c14-1.8,27.9-3.6,41.9-5.4c3.1-0.4,5.3-1.7,8.1-3c0.3-0.1,0.5-0.4,0.7-0.6c4.2-6.4,8.7-12.6,13.5-18.6
			c7.5-9.3,16.7-17.7,26-25.7c1-0.9,1.1-2.4,0.2-3.4c-0.4-0.4-0.9-0.7-1.5-0.8c-3.1-0.4-6.2-1.4-9.5-1.4
			c-13.1,0.2-25.4,1.4-37.1,3.9c-3,0.6-6.1,1.8-9.4,3.7c-0.2,0-0.3-0.2-0.3-0.5c7-6,13.8-13.6,21.9-18.4c13.6-8.1,23-13.4,28.1-16.1
			c60.8-31.4,126.5-52.8,194.6-61.1c65-7.9,130-15.5,195.1-22.9c0.3,0,0.6,0.1,0.8,0.3l0.7,0.7c0.2,0.2,0.6,0.2,0.8,0
			c7.1-6.6,14.3-13.2,21.5-19.7c2.6-2.4,5.4-2.5,8.8-2.3c0.2,0,0.3,0.2,0.3,0.3c0,0.1,0,0.1,0,0.1c-3.1,6.2-6.1,12.3-9.1,18.4
			c-0.2,0.4-0.3,0.7-0.3,0.9c0,0.4,0.3,0.8,0.7,0.9c0.4,0,0.7-0.2,0.8-0.6c0.3-1.2,1.1-1.8,2.4-1.9c133.9-15.1,267.8-30,401.8-44.7
			c2.8-0.3,5.1-0.3,7,0c0.4,0.1,0.6-0.1,0.9-0.4l8.6-11.6c0.2-0.2,0.4-0.4,0.7-0.4l3-0.1c0.4,0,0.5,0.2,0.5,0.5l-2.1,11
			c-0.1,0.4,0.2,0.7,0.6,0.8c0.3,0,0.5-0.1,0.7-0.3l1.2-1.6c0.1-0.2,0.4-0.3,0.6-0.4c45.4-5,90.7-10,136.1-15
			c70.8-7.9,117.3-12.4,176.3-14.8c2.1-0.1,4.2,0,6.3,0.1c7.8,0.7,16.5,7.1,21.5,12.7c8.1,8.8,10.1,27.3,9.3,38.5
			c-0.3,4.4-1.3,9-3,13.8c-5.1,14.3-12.1,27.6-21.2,39.9c-4.7,6.3-10.9,11.3-17,16.5c-2.9,2.4-5.9,4.7-9.2,6.7
			c-18.3,11.4-39.5,24.7-59.9,34c-16.8,7.6-33.7,15.2-51.2,21.4c-23.2,8.3-47,15.6-69.5,25.4c-11.3,4.9-22.4,9.8-33.4,14.6
			c-1.1,0.5-2.2,0.6-3.4,0.3c-15.3-3.3-30.6-6.7-46-10c-19.3-4.2-38.5-6.7-58.2-7.9c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0.1,0,0.1
			c0.1,0.5,0.4,0.7,0.9,0.8c2.3,0.3,4.6,0.5,6.9,0.8c0.3,0,0.4,0.1,0.6,0.2c0.3,0.3,0.2,0.4-0.2,0.5c-25,1.9-49.9,4.2-74.8,7
			c-22.9,2.5-46.5,6.1-68.6,12.4c-21,6-41.5,13.3-61.6,21.9c-9,3.8-27.2,11.7-23.3,24.6c0.8,2.5,4.6,5.9,6.5,7.8
			c0.2,0.3,0.5,0.4,0.9,0.4c11.2-0.2,22.6,0.1,33.4-0.4c27-1.2,53.4-2.1,79.3-2.9c4.5-0.1,4.5,0.2,0.1,1
			c-17.2,3.1-35.1,6.4-53.8,9.9c-27.3,5.1-55.2,8.2-82.7,11.7c-19.2,2.5-38.3,2-57.5,3.6c-30,2.5-60,5.6-89.9,9.4
			c-6.4,0.8-15,1-20.1,0.2c-2.8-0.5-4.2,0.3-6.5,0.5c-12.7,1.3-25.4,2.5-38.1,4.4c-74.7,11.4-148.7,26.3-223.6,36.4
			c-15.1,2-30.5,5.4-45.3,6.7c-10,0.9-20.1,1.6-30.1,2.3c-0.4,0-0.6,0.2-0.6,0.6v0.7c0,0.3,0.2,0.5,0.5,0.5l34.9-2.7
			c0.5,0,0.7,0.2,0.7,0.7l0.2,41.7c0,0.4-0.2,0.7-0.6,0.7l-76.7,3.5c-0.3,0-0.6-0.2-0.6-0.6c0,0,0,0,0,0l-0.1-38.7
			c0-0.5,0.4-1,0.9-1l38.2-3.2c0.6,0,1-0.6,1-1.2c0,0,0,0,0-0.1c-0.1-0.8-0.7-1.1-1.7-0.9c-0.6,0.1-1,0.2-1,0.2
			c-16.6,1.5-33.2,3.2-49.9,3.5c-34.5,0.6-69.1,0.3-103.6-0.7c-6.8-0.2-13.5-0.7-19.9-1.6c-22.8-3.1-41.9-6.6-57.3-10.4
			c-9.2-2.3-17.8-8-26-13.5c-3.3-2.2-4-4.3-2.3-7.8c6.7-14.2,13.7-24.4,24.8-36.3c19.8-21.2,40.9-41.3,63.3-60.1
			c0.1-0.1,0.3-0.1,0.4,0c0,0.1,0.1,0.1,0.1,0.2l-0.1,0.6c0,0.2,0,0.5,0.2,0.6C112,625.6,112.5,625.7,113.3,625.5z M1355.5,487.1
			c3.6-0.4,5.8-6.3,4.9-13s-4.4-11.9-8-11.4c0,0,0,0,0,0c-3.6,0.4-5.8,6.3-4.9,13S1352,487.6,1355.5,487.1
			C1355.5,487.1,1355.5,487.1,1355.5,487.1z M1334.2,489.9c3.6-0.5,5.8-6.3,4.9-13.1c0,0,0,0,0,0c-0.9-6.8-4.5-11.8-8.2-11.4
			c-3.6,0.5-5.8,6.3-4.9,13.1c0,0,0,0,0,0C1326.9,485.3,1330.5,490.4,1334.2,489.9z M1306.8,489.6c2.6,5.2,7.9,3.8,9.7-1
			c2.4-6.6,1.5-12.6-2.9-18.1c-2.5-3.1-6.6-3.3-8.3,0.8C1302.9,476.7,1304.3,484.5,1306.8,489.6z M1290.3,495.8
			c3.7-0.5,6-6.4,5.1-13.3l0,0c-0.9-6.9-4.6-12-8.4-11.5l0,0c-3.7,0.5-6,6.4-5.1,13.3S1286.6,496.3,1290.3,495.8z M1268,498.8
			c3.8-0.5,6-6.5,5.1-13.5c0,0,0,0,0,0c-0.9-6.9-4.7-12.2-8.5-11.7c0,0,0,0,0,0c-3.7,0.5-6,6.5-5.1,13.5
			C1260.4,494.1,1264.2,499.3,1268,498.8z M1245.2,501.9c3.8-0.5,6.1-6.5,5.2-13.5c0,0,0,0,0,0c-0.9-7-4.7-12.2-8.5-11.7h0
			c-3.8,0.5-6.1,6.5-5.2,13.5C1237.6,497.1,1241.4,502.4,1245.2,501.9z M1222.1,505c3.8-0.5,6.2-6.6,5.3-13.7
			c-0.9-7-4.8-12.3-8.6-11.8h0c-3.8,0.5-6.2,6.6-5.3,13.7C1214.4,500.3,1218.3,505.5,1222.1,505L1222.1,505z M1198.8,508.1
			c3.9-0.5,6.2-6.7,5.3-13.8c0,0,0,0,0,0c-1-7.1-5-12.3-8.8-11.8c-3.9,0.5-6.2,6.7-5.3,13.8c0,0,0,0,0,0
			C1190.9,503.4,1194.9,508.7,1198.8,508.1z M1174.9,511.4c4-0.5,6.4-6.7,5.4-13.8c0,0,0,0,0,0c-0.9-7.1-4.9-12.4-8.9-11.9
			c0,0,0,0,0,0c-4,0.5-6.4,6.7-5.4,13.8c0,0,0,0,0,0C1166.9,506.6,1170.9,511.9,1174.9,511.4L1174.9,511.4z M1150.7,514.6
			c4-0.6,6.5-6.8,5.5-14c0,0,0,0,0,0c-1-7.2-5-12.5-9.1-12c-4,0.6-6.5,6.8-5.5,14l0,0C1142.7,509.8,1146.7,515.2,1150.7,514.6z
			 M1126.2,518c4.1-0.6,6.5-6.9,5.5-14.1s-5.1-12.6-9.2-12c-4.1,0.6-6.5,6.9-5.5,14.1C1118,513.1,1122.2,518.5,1126.2,518z
			 M1101.3,521.3c4.1-0.6,6.6-7,5.6-14.3c-1-7.3-5.3-12.7-9.4-12.1c-4.1,0.6-6.6,7-5.6,14.3C1092.9,516.5,1097.1,521.9,1101.3,521.3
			z M1075.9,524.8c4.2-0.6,6.7-7,5.7-14.4c0,0,0,0,0,0c-1-7.3-5.3-12.8-9.4-12.2v0c-4.2,0.6-6.7,7-5.7,14.4
			C1067.5,519.9,1071.7,525.4,1075.9,524.8z M1050.1,528.3c4.2-0.6,6.8-7.1,5.8-14.5v0c-1.1-7.4-5.4-12.9-9.6-12.3l0,0
			c-4.2,0.6-6.8,7.1-5.8,14.5c0,0,0,0,0,0C1041.6,523.4,1045.9,528.9,1050.1,528.3L1050.1,528.3z M1023.9,531.8
			c4.3-0.6,6.9-7.2,5.8-14.7c0,0,0,0,0,0c-1.1-7.5-5.5-13-9.8-12.4l0,0c-4.3,0.6-6.9,7.2-5.8,14.7v0
			C1015.3,526.9,1019.6,532.4,1023.9,531.8L1023.9,531.8z M997.2,535.4c4.4-0.6,7-7.3,5.9-14.8c-1.1-7.5-5.5-13.1-9.9-12.5
			c-4.4,0.6-7,7.3-5.9,14.8c0,0,0,0,0,0C988.5,530.5,992.9,536,997.2,535.4L997.2,535.4z M970.2,539.1c4.4-0.7,7.1-7.3,6-14.9
			c0,0,0,0,0,0c-1.1-7.6-5.6-13.2-10-12.6c-4.4,0.7-7.1,7.3-6,14.9c0,0,0,0,0,0C961.2,534.1,965.7,539.8,970.2,539.1z M942.6,542.8
			c4.5-0.7,7.2-7.4,6.1-15.1v0c-1.1-7.7-5.7-13.3-10.2-12.7c-4.5,0.7-7.2,7.4-6.1,15.1c0,0,0,0,0,0
			C933.6,537.8,938.1,543.5,942.6,542.8z M914.6,546.6c4.6-0.7,7.4-7.5,6.2-15.2c-1.1-7.7-5.8-13.4-10.3-12.7c0,0,0,0,0,0
			c-4.6,0.7-7.4,7.5-6.2,15.2S910,547.3,914.6,546.6z M886.1,550.4c4.6-0.7,7.4-7.6,6.2-15.3v0c-1.2-7.8-5.9-13.5-10.5-12.8
			c0,0,0,0,0,0c-4.6,0.7-7.4,7.6-6.2,15.3v0C876.8,545.4,881.5,551.1,886.1,550.4L886.1,550.4z M857,554.4c4.7-0.7,7.6-7.6,6.4-15.5
			c-1.2-7.8-5.9-13.6-10.6-12.9c0,0,0,0,0,0c-4.7,0.7-7.6,7.6-6.4,15.5C847.6,549.3,852.3,555.1,857,554.4
			C857,554.4,857,554.4,857,554.4z M827.5,558.4c4.8-0.7,7.7-7.7,6.5-15.6c-1.2-7.9-6-13.7-10.8-13h0c-4.8,0.7-7.7,7.7-6.5,15.6
			C817.8,553.3,822.7,559.1,827.5,558.4C827.5,558.4,827.5,558.4,827.5,558.4z M797.5,562.4c4.9-0.7,7.8-7.8,6.6-15.8
			c-1.2-8-6.1-13.8-11-13.1s-7.8,7.8-6.6,15.8C787.7,557.3,792.6,563.1,797.5,562.4z M766.9,566.5c4.9-0.8,7.9-7.9,6.7-15.9v0
			c-1.2-8.1-6.2-14-11.1-13.2c-4.9,0.8-7.9,7.9-6.7,15.9c0,0,0,0,0,0C756.9,561.4,761.9,567.3,766.9,566.5z M735.7,570.7
			c5-0.8,8.1-8,6.9-16.1c-1.2-8.1-6.3-14.1-11.4-13.3c-5,0.8-8.1,8-6.9,16.1C725.6,565.5,730.7,571.5,735.7,570.7z M704,575
			c5.1-0.8,8.2-8.1,7-16.3c0,0,0,0,0,0c-1.3-8.2-6.4-14.2-11.5-13.4c-5.1,0.8-8.2,8.1-7,16.3S698.9,575.8,704,575z M671.7,579.3
			c5.2-0.8,8.3-8.2,7.1-16.4l0,0c-1.3-8.2-6.5-14.3-11.7-13.5c-5.2,0.8-8.3,8.2-7.1,16.4c0,0,0,0,0,0
			C661.2,574.1,666.5,580.2,671.7,579.3z M638.8,583.8c5.3-0.8,8.5-8.3,7.1-16.6s-6.7-14.4-11.9-13.6c0,0,0,0,0,0
			c-5.3,0.8-8.5,8.3-7.1,16.6C628.2,578.5,633.5,584.6,638.8,583.8C638.8,583.8,638.8,583.8,638.8,583.8z M605.2,588.3
			c5.4-0.9,8.6-8.4,7.3-16.8c0,0,0,0,0,0c-1.3-8.4-6.8-14.5-12.2-13.7h0c-5.4,0.9-8.6,8.4-7.3,16.8v0
			C594.4,583,599.9,589.1,605.2,588.3L605.2,588.3z M571,592.9c5.4-0.9,8.8-8.5,7.4-17c0,0,0,0,0,0c-1.4-8.5-6.9-14.7-12.3-13.8l0,0
			c-5.4,0.9-8.8,8.5-7.4,17l0,0C560.1,587.5,565.6,593.7,571,592.9C571,592.9,571,592.9,571,592.9z M536.2,597.5
			c5.6-0.9,8.9-8.6,7.5-17.1c-1.4-8.6-7-14.8-12.6-13.9c-5.6,0.9-8.9,8.6-7.5,17.1S530.6,598.4,536.2,597.5z M195.8,626.1
			c16,3.5,32.9,3.5,49.7,4.7c3.2,0.2,5-1.1,5.4-3.9c1.4-10.3,2.4-23.2,3-38.6c0.2-4.3-0.6-6.5-5-7.4c-5.3-1-15.5-4.5-19.8-0.1
			c-13.2,13.5-26,25.2-34.6,42.6c-0.4,0.9-0.1,2,0.8,2.4C195.5,626,195.7,626.1,195.8,626.1z M261.9,631.1c0.3,0.2,0.6,0.2,0.9,0
			c10.1-4.9,20.3-9.8,30.7-14.6c5.8-2.7,6.1-2.5,5.1-9c-0.9-5.8-2.1-11.5-3.4-17.2c-0.8-3.5-0.9-5.8-5.3-6.3
			c-8.2-0.9-16.3-1.9-24.2-3.1c-1.5-0.2-2.9,0.8-3.1,2.3c0,0.1,0,0.2,0,0.4c-0.3,14.3-1.3,28.8-3,43.6
			C259.4,629.4,260.2,630.1,261.9,631.1z"/>
                                                                <path d="M242.6,756.5l0.4,8.8c0,0.4-0.3,0.7-0.6,0.7l-15.1,1.1c-0.4,0-0.7-0.3-0.7-0.7c0,0,0-0.1,0-0.1l0.7-9.6
			c0-0.4,0.3-0.6,0.7-0.6l14-0.2C242.3,755.8,242.6,756.1,242.6,756.5z"/>
                                                            </g>
                                                        </g>
                                                        <g
                                                            style={{color: objects[0].elements[1]}}
                                                            className="action-group" id="E2">
                                                            <g>
                                                                <path d="M815.2,400c-0.9-0.2-1.3,0.2-1.1,1.4c0,0.1,0,0.2,0.1,0.2l18.4,33.6c0,0,0,0.1,0.1,0.1l1.1,1.1
			c0.2,0.2,0.2,0.5,0,0.7c-0.1,0.1-0.2,0.1-0.3,0.2l-62.2,6.9c-0.5,0.1-0.7-0.2-0.7-0.7c1.4-10.7,3.1-21.1,5.2-31.2
			c1.4-6.7,3.6-8.6,10.1-11c11.9-4.3,22.2-7.4,33.4-6.6c0.7,0,1.3,0.5,1.5,1.2l2,5.5c0.1,0.2,0,0.5-0.3,0.6c-0.1,0-0.2,0-0.3,0
			C820,401.2,817.7,400.6,815.2,400z"/>
                                                                <path d="M835.6,435.3c-0.8-0.2-1.4-0.7-1.8-1.4l-16.7-31c-0.1-0.2,0-0.4,0.1-0.5c0.1,0,0.1,0,0.2,0
			c5.1,0.3,7.4,2.4,10.5,6.6c6.4,8.6,12.9,17.3,19.4,26c0.4,0.5,0.2,0.7-0.4,0.8C843.1,436.3,839.4,436.1,835.6,435.3z"/>
                                                                <path d="M1024.7,657.1c0.3-0.4,0.3-0.7,0-1c-0.1-0.1-0.3-0.2-0.6-0.3c-5.1-0.8-8.4-1-10-0.5
			c-5.4,1.7-10.8,3.5-16.1,5.3c-6,2-14,1.1-20.2,0.6c-0.3,0-0.6-0.2-0.7-0.5c-1.4-3.5,3.2-7.3,5.8-8.7c0.4-0.2,0.5-0.7,0.3-1.1
			c-0.1-0.1-0.2-0.3-0.3-0.3c-0.1-0.1-0.3-0.1-0.5-0.3c-0.2-0.1-0.4-0.1-0.6-0.2c-51.6-2.5-103.1-5-154.5-7.5
			c-0.2,0-0.4-0.2-0.4-0.5c0-0.1,0.1-0.3,0.2-0.4c9.7-5.3,18-8.5,30.1-13.2c36.5-14.1,69.2-21.7,108.8-26.3
			c24.4-2.8,52.9-5.5,85.5-7.8c11.6-0.8,25.1,1.9,35.8,4.1c23.5,5,62.3,13.5,116.5,25.5c0.1,0,2.1,0.5,6,1.5c3.4,0.9,8,1.7,13.5,2.4
			c85,10.6,170,21.2,255,31.8c14.4,1.8,32.2,4.3,50.2,6.2c23.3,2.5,55.2,7.1,86.9,11c44.5,5.4,89.1,10.7,133.5,16.6
			c46,6.2,92.3,12,138.8,14.5c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2l-6.9,2.2c-0.3,0.1-0.5,0.5-0.4,0.8c0,0,0,0,0,0.1
			c0.3,0.6,0.7,0.8,1.3,0.6c3.1-1.2,6.2-2.1,9.4-2.8c0.2-0.1,0.6-0.3,1-0.8c0.2-0.2,0.5-0.4,0.8-0.3l5.8,0.4c0.2,0,0.4,0.2,0.4,0.4
			c0,0.2-0.1,0.3-0.3,0.4c-9.9,2-19.8,5.7-29.7,6.9c-25.7,3.1-45.7,5.1-60,5.9c-5.3,0.3-16.2,0.3-32.8,0c-0.4,0-0.5-0.2-0.3-0.6
			c0.3-0.5,0.7-0.9,1.3-1.1c0.6-0.2,0.8-0.9,0.6-1.4c-0.2-0.4-0.5-0.7-1-0.7c-22.8-1.3-45.6-3.2-68.3-5.2
			c-206.3-18.5-412.5-37.9-618.8-57.2c-4.2-0.4-8.5-0.8-12.7-1.1c-5.4-0.4-8.4,2.8-9.3,7.9c0,0.3-0.3,0.5-0.6,0.5l-32.4-0.9
			c-0.4,0-0.8,0.3-1,0.7c-0.2,0.5,0,0.8,0.5,0.8c16.5,0.8,33.2,0.7,49.4,2.1c28.5,2.4,57,4.8,85.5,7.3c0.3,0,0.6,0.3,0.6,0.7
			c0,0.1,0,0.2-0.1,0.3c-2.4,3.3-4.9,6.6-7.5,9.7c-0.4,0.5-1,0.9-1.6,1c-19.9,5.3-40.4,4.8-60.7,3.6c-22.9-1.3-45-7.2-68.1-7.6
			c-15.8-0.3-31.5,0.4-47.2,1.9c-5,0.5-14.1,1.3-18.5-1.5c-0.3-0.2-0.4-0.7-0.2-1c0-0.1,0.1-0.1,0.1-0.2c7.9-6.9,16.8-12.2,26.8-16
			c10.2-3.8,19.4-9,31.2-7.5C1024.2,657.4,1024.5,657.3,1024.7,657.1z M1032.2,663.7c0.3-0.5-0.1-1.2-0.9-1.6c0,0,0,0,0,0
			c-0.7-0.4-1.6-0.4-1.8,0.1c-0.3,0.5,0.1,1.2,0.9,1.6l0,0C1031.1,664.2,1031.9,664.2,1032.2,663.7z"/>
                                                                <path d="M822,665.6c-2.6-2.1-5.6-4.7-6.1-8c-0.8-5,4-13.1,9.7-12.8c51,2.4,102,4.8,153.1,7.2c0.3,0,0.5,0.2,0.5,0.5
			c0,0.2-0.1,0.3-0.2,0.4c-2.8,2.1-4,4.9-3.7,8.4c0,0.4-0.1,0.6-0.5,0.6l-152.3,4C822.2,665.8,822.1,665.7,822,665.6z"/>
                                                                <path d="M1070.3,663.5c-0.3,0-0.5-0.3-0.4-0.5c0,0,0,0,0,0c0.5-4,3.3-6.3,7.5-5.9c141.8,13.2,283.6,26.4,425.4,39.6
			c90.3,8.4,180.7,17,271.2,23.9c0.4,0,0.6,0.2,0.6,0.6c0,1.2-1.2,1.7-2.3,1.6c-23.7-1.7-47.3-3.2-71-5.1
			c-195.2-16.1-390.3-33.2-585.5-49.9C1101.2,666.4,1086.1,665,1070.3,663.5z"/>
                                                            </g>
                                                        </g>
                                                        <g
                                                            style={{color: objects[0].elements[2]}}
                                                            className="action-group" id="E3">
                                                            <g>
                                                                <path d="M888.5,694.3c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0.1-0.1,0.2-0.2c24.7-5.7,49.6-10.4,74.7-14.2
			c0.2,0,0.4,0.1,0.5,0.3c0,0.1,0,0.1,0,0.2l-0.4,1.3c-0.1,0.4,0,0.7,0.3,0.9c7.4,5.7,20.4,2.7,29.4,2.1c19.7-1.5,39.8-2,59.4,0.4
			c12.1,1.5,24.2,3.2,36.2,5.1c5.1,0.8,11,0.8,16.4,1.4c0.3,0,0.6,0.2,0.9,0.4c13.4,13.8,20.1,41.9,10.3,59.3
			c-1.4,2.4-3.5,4.5-6.3,6.3c-14,8.8-28.1,17.4-42.3,25.9c-23.9,14.4-49.8,25.4-76.8,32c-24.4,6-49.1,11.2-74.1,15.7
			c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.3c5.7-5.7,9.5-12.6,11.3-20.8c7.1-32.3-1.8-77.9-23.7-104.1
			C899.9,700.5,894.7,696.8,888.5,694.3z"/>
                                                                <path d="M845.8,826.2c-18.7-17.7-26.1-45.8-27.5-70.9c-0.8-14.5,0.5-31.7,11.9-41.8c4.7-4.1,14.9-9.4,20.3-10.3
			c17.3-3.1,31.5,27,35.9,39.8c8.2,24.3,14.5,64.3-0.3,87.2c-6.7,10.4-20.5,7.8-30.1,3.6C851.8,831.8,849.6,829.8,845.8,826.2z
			 M835,724.8c11.6,0.7,19.1,10.9,23.5,21.3c1.9,4.4,1.8,7.6,2.2,12.5c0.8,10.7,3.3,21.3,7.6,32c3.3,8.3,1.1,21.9-4.1,29.1
			c-0.6,0.8-1.5,1.2-2.7,1c-0.4,0-0.7,0.2-0.8,0.6l-0.3,1.3c-0.1,0.7,0.3,1.3,0.9,1.4c0.4,0.1,0.8,0,1.1-0.3c4.3-3.9,7-8.8,8.2-14.6
			c0.4-2,0.7-5.6,0.7-10.8c0.2-15.6-3.2-31.7-8.5-46.3c-4.8-13.1-12-27.1-26.9-29c-0.3,0-0.6,0-0.8,0.1c-0.4,0.2-0.6,0.3-0.7,0.4
			c-0.2,0.3-0.2,0.8,0.2,1C834.7,724.7,834.8,724.8,835,724.8z"/>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        )}

                                        {currentSlide === 1 && (
                                            <div className="obj__it-img finish--1">
                                                <img src={require("../assets/img/objects/suitcase.svg").default}/>
                                                <div className="obj__el">
                                                    <svg viewBox="0 0 1931 1086">
                                                        <g
                                                            style={{color: objects[1].elements[0]}}
                                                            className="action-group"
                                                            id="E1">
                                                            <g>
                                                                <path d="M624.6,792.5c-0.6-2.7-1-4.7-1.1-6c-3-37.1-5-65.4-6.1-84.8c-0.1-1.7,0.4-3.9,1.3-6.5
			c0.2-0.1,0.4-0.2,0.5-0.4c0.2-0.3,0.5-0.3,0.8-0.2c0.2,0.1,0.3,0.3,0.2,0.5l-0.1,2.6c0,0.1,0.1,0.3,0.3,0.3c0,0,0.1,0,0.1,0
			c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.5c-0.1-3.2-0.3-6.4-0.8-9.5c-0.1-0.3,0.2-0.6,0.5-0.7c0.2,0,0.4,0,0.5,0.2
			c5.5,5.8,11,10.3,18.2,14.9c73.1,46.7,146.3,93.5,219.4,140.2c12.8,8.2,27.7,15.1,44.9,20.6c7.3,2.4,16.9,3.8,23.5,4
			c17.2,0.4,32.6-6.7,48.7-13.1c0.4-0.1,0.5-0.6,0.4-0.9c-0.1-0.2-0.2-0.3-0.4-0.4c-0.6-0.3-1.4-0.2-2.2,0.1
			c-15.2,6.3-28.6,12-45.5,12.3c-6.8,0.1-15.7-1.1-22.1-3.1c-21.9-7.1-40.2-17.1-59.5-29.4c-65.3-41.4-130.6-82.9-196-124.4
			c-9.7-6.1-32.9-19.8-34.3-32.2c-0.5-4.3-0.8-8.6-1-12.8c-0.3-5-2.1-13.5,0.5-18.8c0.1-0.3,0.5-0.5,0.8-0.3
			c0.3,0.1,0.4,0.4,0.4,0.7l-0.3,1.6c0,0.1,0.1,0.3,0.2,0.3c0,0,0,0,0.1,0c0.1,0,0.2,0,0.3,0c0.3,0,0.5-0.2,0.5-0.5c0,0,0,0,0-0.1
			l-1-10.2c0-0.4,0.2-0.7,0.6-0.7c0.2,0,0.4,0,0.5,0.2c6,5.8,12,10.3,19.7,14.9c73.1,44,146.2,88.1,219.3,132.2
			c14.2,8.5,30.4,15.5,48.8,21c8,2.4,18.8,3.2,26.8,2.8c16-1,29.3-6.9,43.4-12.4c0.5-0.2,0.6-0.5,0.4-1l-0.1-0.2
			c-0.2-0.4-0.7-0.6-1.1-0.5c-15.2,5.6-28,11.1-44.3,12.2c-8,0.5-18.8-1-26.3-3.3c-18.4-5.7-35.2-13.3-51.8-23.3
			c-68-40.6-136-81.3-204-122c-7-4.2-12.9-7.8-17.6-10.9c-5.3-3.5-10.4-7.9-15.2-13.2c-2.5-2.1-3.9-4.5-4-7.1
			c-0.5-8.7-1.2-17.5-1.9-26.2c-0.2-2.1,0.4-4.6,1.8-7.4c0.3-0.3,0.6-0.4,1.1-0.3c0.3,0.1,0.4,0.3,0.4,0.6c-0.1,0.7-0.1,1.5,0,2.3
			c0,0.2,0.2,0.4,0.4,0.3c0.2-0.1,0.4-0.3,0.4-0.6l-0.7-11.3c0-0.3,0.2-0.5,0.5-0.5c0.1,0,0.3,0,0.4,0.1c6.3,6,12.4,10.1,20.7,14.8
			c71.2,40.2,142.3,80.5,213.5,120.7c18.5,10.5,37.2,18.7,57.8,24.3c4.7,1.3,9.9,1.5,14.8,2c20,2,35.5-4.5,54.5-11.1
			c0.1,0,0.3-0.1,0.8-0.2c0.5-0.1,0.7-0.4,0.5-0.8c-0.4-0.8-1.4-1.2-2.2-0.9c-15.7,5.9-30.2,11.5-47.9,11.3
			c-26.6-0.2-54.8-13.6-77.5-26.4C779.4,676.3,709.7,637.1,640,598c-3.5-1.9-6.9-3.9-10.3-5.9c-6.7-3.8-12.7-8.8-18.1-14.8
			c-4.2-2.9-3.8-9-3.9-14.9c1-8.2-1.6-16.2,0.6-24.3c0.1-0.3,0.3-0.5,0.6-0.5c0.1,0,0.2,0,0.3,0c0.2,0.1,0.2,0.2,0.2,0.3
			c-0.1,0.6-0.1,1.2,0.1,1.8c0,0.2,0.3,0.4,0.5,0.4c0.2,0,0.4-0.3,0.4-0.5l-0.7-12c0-0.3,0.2-0.6,0.5-0.6c0.1,0,0.3,0,0.4,0.1
			c5,4.4,10.5,8.2,16.4,11.3c73.2,38.7,146.5,77.4,219.8,116c26.7,14,58.5,27.8,88.6,25c14.6-1.4,27.2-6.5,41-10.6
			c0.5-0.1,0.7-0.6,0.6-1.1c-0.1-0.3-0.3-0.5-0.6-0.6l-0.7-0.2c-0.3-0.1-0.7-0.1-1,0c-21,7.2-36.4,12.1-57.2,10.2
			c-5.7-0.5-12.2-1.8-19.6-3.9c-15.7-4.4-29.9-10-42.7-16.6c-75-39.1-150-78.3-224.9-117.5c-7.5-3.9-24.2-13.2-25-22.6
			c-1.2-13.5-1.9-23-2.1-28.4c-0.1-1.4,0.5-2.9,1.6-4.6c0.1-0.2,0.3-0.2,0.5-0.1c0.1,0.1,0.2,0.2,0.2,0.3l0.1,1.3
			c0,0.6,0.2,0.7,0.6,0.2l0.3-0.3c0.1-0.1,0.1-0.2,0.1-0.3l-1-12.8c0-0.3,0.2-0.6,0.5-0.6c0.1,0,0.3,0,0.4,0.1
			c5.6,4.4,11.1,8,17.8,11.3c76.2,37.2,152.4,74.3,228.7,111.4c14.6,7.1,30.8,12.8,48.6,17.1c8.4,2,18.5,3.1,27.2,2.9
			c17-0.5,31.3-5.3,46.8-10.3c0.5-0.2,0.8-0.7,0.6-1.2c-0.2-0.4-0.6-0.7-1.1-0.6c-0.8,0.2-1.7,0.4-2.7,0.8
			c-23.2,7.9-46.2,12.3-70.5,6.4c-17.7-4.3-33.9-10-48.6-17.1c-75.4-36.4-150.8-72.8-226.2-109.2c-7.2-3.5-24.8-12.5-25.6-21.3
			c-1.1-13.3-1.8-23-2.1-28.9c-0.1-1.6,0.5-3.3,1.7-4.9c0.3-0.4,0.5-0.4,0.5,0.2v1.1c0,0.4,0.2,0.6,0.7,0.6c0.4,0,0.6-0.2,0.6-0.7
			c-0.4-4.7-0.8-9.3-1.1-13.8c0-0.3,0.2-0.5,0.5-0.5c0.1,0,0.2,0,0.3,0.1c6.3,4.4,12.4,7.9,19.9,11.3
			c68.1,30.4,136.3,60.9,204.4,91.3c14.3,6.4,25,11,31.9,13.8c14.2,5.7,28.8,10.2,43.7,13.5c5.5,1.2,11.3,1.5,17,2
			c19,1.7,38.5-3.5,56.4-9.4c0.4-0.1,0.6-0.5,0.5-0.9c0-0.1-0.1-0.2-0.1-0.3c-0.6-0.7-1.5-0.8-2.7-0.4c-14.5,4.8-29.1,8.5-44.4,9
			c-27,1-57-9.4-81.6-20.3c-43.9-19.5-119.1-52.8-225.7-99.9c-9.5-4.2-17.6-8.9-23.8-16.6c-0.2-0.3-0.3-0.6-0.4-0.9
			c-0.7-9.9-1.4-19.9-2-29.8c-0.2-3.3,0.5-5.4,2.8-7.6c0.2-0.2,0.3-0.5,0.3-0.8l-0.9-11.5c0-0.3,0.2-0.6,0.5-0.7
			c0.1,0,0.3,0,0.4,0.1c6.1,4.1,11.8,7,19.1,10c75.7,30.7,151.5,61.3,227.2,92c19.2,7.8,38.6,13.5,58.2,17.3c3,0.6,8.9,1,17.7,1.4
			c19.5,0.8,35.7-3.2,54.8-8.4c0.6-0.2,0.7-0.5,0.5-1l-0.4-0.7c-0.1-0.2-0.4-0.4-0.7-0.3c-19.5,4.7-36.9,9.9-58,8.1
			c-4.8-0.4-9.6-0.5-14.2-1.4c-20.5-4-40.4-9.9-59.8-17.8c-76.4-31.2-153.3-61.5-229.9-92.2c-3.1-1.2-7.2-3.5-12.5-6.9
			c-2.6-1.7-4.6-3.9-6.7-6.2c-0.2-0.2-0.3-0.5-0.4-0.8c-1.3-15.6-2.1-26.4-2.4-32.6c0-1,0.4-2.2,1.2-3.5c0.2-0.4,0.4-0.4,0.7,0
			l0.2,0.3c0.2,0.3,0.6,0.4,1,0.2c0.2-0.1,0.4-0.4,0.3-0.7l-1.1-15.6c0-0.3,0.2-0.6,0.5-0.6c0.1,0,0.2,0,0.3,0.1
			c5.5,3.2,11.1,5.9,17,8c78.4,28.5,156.8,56.8,235.2,85c19.1,6.9,38.6,11.9,58.6,15.2c0.1,0,0.2,0,0.2-0.1c0.7-1.2,0.4-1.9-0.7-2.1
			c-19.9-3.6-39.8-8.6-58.8-15.4c-77.7-27.7-155.4-55.5-233.1-83.4c-8.2-2.9-17.5-6.9-22.9-13.3c-0.2-0.2-0.3-0.6-0.3-0.9
			c-0.7-12.3-1.5-22.7-2.2-31.1c-0.1-1.6,0-3.1,0.6-4.5c0.1-0.4,0.4-0.6,0.8-0.5l0.9,0c0.6,0,1-0.4,1.1-1c0,0,0-0.1,0-0.1l-1.2-16.4
			c0-0.5,0.2-0.7,0.6-0.4c7.3,3.8,14.8,6.9,22.6,9.4c80.1,25.3,160.2,50.6,240.2,75.9c2.5,0.8,6.4,1.8,11.7,3c0.3,0.1,0.5,0,0.7-0.3
			c0.5-1,0.3-1.6-0.6-1.9c-6.2-1.8-12.6-3.5-18.5-5.3c-79.7-25-159.4-49.9-239.1-74.9c-7-2.2-15.5-5.6-21.3-11.4
			c-0.2-0.2-0.4-0.5-0.4-0.8c-2.3-30.8-4.4-61.6-6.5-92.4c-0.6-9.2-0.9-15.8-0.7-20c0.4-13,2.8-25.8,7.2-38.6
			c6.7-19.8,26.2-30.6,45.7-33.4c13.9-2,25.8-3.4,37-1.6c76,12.4,152,24.7,228,37.1c5.8,0.9,11.5,1.9,17.2,3c5.6,1,9.9,2.8,14.9,5
			c5.4,2.4,10.5,6.4,15.5,9.7c6.5,4.4,12.4,12.9,17.5,18.9c1.3,1.6,2.4,3.3,3.1,5.2c3.8,9.8,8.5,19.9,10.6,30.3
			c3.4,16.8,6.3,32,6.2,49.4c-0.6,254.2-0.6,508.5-1.9,762.7c-0.1,13.6-1.8,24.5-6.3,36.3c-0.7,1.7-1.8,3.3-3.4,4.7
			c-0.4,0.3-0.5,0.3-0.4-0.2c1-4.3,2.7-7.4,2.4-12c-0.1-1.1-1-2-2.2-1.9c-0.1,0-0.3,0-0.4,0.1c-5.6,1.5-11.8,4.7-15.7,6.2
			c-10.1,3.8-20.2,5-30.9,3.4c-4.7-0.7-9.3-1.3-13.9-2.8c-16-5.4-31.1-12.5-45.3-21.5c-2.3-1.4-4.3-1.8-6.9-2.3
			c-1.1-0.2-1.8,0.1-2.1,0.9c-0.8,2.3-0.6,6.9-0.3,9.3c0.6,4.7,3.4,10.3,4.9,15.4c0.2,0.6,0,0.8-0.5,0.4
			c-62.1-45.6-124.3-91.3-186.4-136.9c-1.7-1.2-4-3.2-6.9-6c-3.1-3-4.4-7.7-6-11.7c-1.9-4.6-3.3-10.3-5.3-15.4c-1.5-3.7-5-7.5-8-10
			c-9-7.2-18.8-17.8-20.4-29.7c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.2,0L624.6,792.5z M942.1,405.8c11.6-1.7,22.8-4.2,33.8-7.2
			c0.5-0.1,0.8-0.7,0.6-1.2c-0.1-0.4-0.5-0.7-0.9-0.7c-0.9,0-1.8,0.1-2.9,0.4c-11.1,2.8-22.2,4.9-33.3,6.5c-0.6,0.1-1,0.6-0.9,1.1
			c0.1,0.4,0.4,0.8,0.8,0.9C940.2,405.9,941.1,405.9,942.1,405.8z"/>
                                                                <path 
                                                                      d="M984.3,52.2c-1.7,0.2-3.3,0-4.7-0.7C981.3,51.1,982.9,51.4,984.3,52.2z"/>
                                                                <path d="M1040.2,196.2c0.2-16.8-1.7-32.2-5.2-48.5c-2.5-11.6-7.1-22.5-11.6-33.6c-0.7-1.8-1.8-3.5-3.1-5
			c-4.2-4.6-8.3-9.3-12.4-14c-4.2-4.8-11.2-8.3-16.5-11.9c-0.2-0.1-0.2-0.3,0-0.4c0.1-0.1,0.3-0.1,0.5,0c6.1,3.7,14.6,8.3,19.7,14.1
			c4.4,5,12,12,14.5,18.1c3.9,9.8,8.7,19.9,10.7,30.4c2.5,12.9,5.6,25.8,5.6,39c0,7-0.1,19.5-0.3,37.4c-3,220.1-5.7,440.2-9.1,660.2
			c-0.4,23.9,0,52-1,67.8c-0.4,7.2-2.1,14.8-5.1,22.9c-2.2,6.1-6,10.7-10.1,15.7c-0.2,0.2-0.3,0.3-0.5,0.3c-0.2,0-0.5-0.2-0.5-0.4
			c0-0.1,0-0.2,0.1-0.3c3-3.3,5.7-6.2,7.4-10.5c4.5-11.5,6.3-21.4,6.5-34.5C1033.7,694,1036.7,445.1,1040.2,196.2z"/>
                                                            </g>
                                                        </g>
                                                        <g
                                                            style={{color: objects[1].elements[1]}}
                                                            className="action-group"
                                                            id="E2">
                                                            <g>
                                                                <path d="M833.2,29.8c0.6-0.1,1.2,0,1.8,0.3c-0.4,0.6-1.1,0.9-2,1c-4.5,0.5-8.3,1.4-12.9,0.8c-7-1-13.3,0.4-19.3,0.5
			c-2.1,0-4,0.5-5.7,1.3c-0.3,0.2-0.6,0.5-0.5,0.9v4.6c0,0.4-0.3,0.7-0.7,0.7c0,0-0.1,0-0.1,0L760,35c-0.2,0-0.4-0.2-0.4-0.4
			s0.2-0.4,0.4-0.4c17-1,34-2.5,51.2-4.4C815.5,29.3,822.9,29.3,833.2,29.8z"/>
                                                                <path d="M903.9,46.3c11.2,3.9,22,8.5,32.6,13.7c0.1,0,0.2,0.2,0.1,0.3c0,0.1-0.1,0.1-0.2,0.1L818.5,43.3
			c-0.4-0.1-0.5-0.3-0.2-0.6l0.2-0.2c0.1-0.1,0.3-0.2,0.5-0.2c3.3,0.2,7.1-0.4,11.5-1.6c9.5-2.7,21.3-3.9,32.2-3.5
			c0.7,0,1.4,0.4,1.9,1c0.2,0.2,0.5,0.4,0.8,0.4C878.6,40.3,891.5,41.9,903.9,46.3z"/>
                                                                <path d="M1104.6,940c-3,3.8-6.4,9-10.9,11.1c-15,7.3-30.1,14.6-45.2,21.9c-0.2,0.1-0.4,0-0.5-0.2
			c-0.1-0.1,0-0.3,0-0.4c1.9-2.3,3.8-4.2,4.9-7.1c2.1-5.8,4.4-11.7,5.3-17.7c0.9-6.4,1.5-13.5,1.7-21.2
			c4.2-207.5,8.5-414.9,12.7-622.3c0-0.3,0.2-0.5,0.5-0.5c0,0,0.1,0,0.1,0c3.2,1,5.8,2,9.3,1.3c6.2-1.4,10.8-2.3,13.7-2.9
			c1.9-0.4,3.1-1,3.3-3.1c0.6-6,0.8-12.3,0.6-19c-0.1-1.9,0.7-3.5,0.5-5.4c-0.4-3.3-0.4-6.6-0.1-9.8c0.4-3.6-0.4-7.5,1.1-11.4
			c2.5-6.8,1.8-13.9,2.3-22.1c0.2-3.8-2.2-4-5.5-4.7c-2.1-0.5-3.5-0.9-5.7-0.5c-6,1.1-12.1,2.2-18.1,3.2c-0.3,0.1-0.5-0.1-0.5-0.4
			c0.2-14.4,0.4-28.9,0.7-43.3c0.4-16.6-2.6-31.2-5.8-47.2c-2-10-6.8-19.9-10.6-29.4c-0.7-1.9-1.8-3.6-3.1-5.1
			c-5.3-6-11.4-14.4-18.1-18.7c-9.2-5.9-18.6-12.2-29.6-14.1c-12.3-2.1-24.7-4-37.1-5.6c-0.3,0-0.4-0.2-0.3-0.5
			c0.1-0.2,0.2-0.3,0.5-0.3c2.6-0.1,5-0.5,7-1.2c2.8-1,1.9-5.5,1.9-7.9c0-0.3,0.3-0.6,0.6-0.6c0,0,0.1,0,0.1,0
			c6,0.9,12,1.7,18.1,2.5c4.8,0.6,10.6,0.5,16,1.3c1.4,0.2,2.3,1.2,3.8,0c0.3-0.2,0.6-0.3,1-0.4c6.3-0.6,14-0.4,23,0.7
			c22.8,2.9,40.9,5.4,54.2,7.7c12.4,2.1,24.5,8.7,34.8,16.5c6,4.5,11.2,10.8,16.5,16.8c1.5,1.6,2.6,3.5,3.5,5.5
			c3.5,8,7.1,15.8,9.2,24.3c3.3,13.2,5.6,25.5,5.1,39.4c-1.3,36.4-2.6,72.9-4,109.3c-0.2,6.4-7.7,8.8-12.8,10
			c-0.7,0.2-0.8,0.6-0.4,1.2c0.3,0.4,0.8,0.6,1.3,0.4l6.8-2.2c0.4-0.1,0.5,0,0.5,0.4c0,5.5-0.2,10.9-0.6,16.3
			c-0.1,1.1,0,2.1,0.3,3.1c0.1,0.4,0.3,0.5,0.7,0.5l1.1-0.1c0.4,0,0.6,0.1,0.8,0.4c0.9,1.4,1.2,3.2,1.2,5.1
			c-0.3,6.1-0.7,17.2-1.2,33.5c-0.2,7.9-24.6,11.7-30.8,13.2c-1.5,0.4-2.6,1.7-2.7,3.2c-0.4,7-0.5,14.2-0.5,21.7
			c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.3,0.5,0.4,0.7c0.2,0.3,0.6,0.4,1,0.2c0.2-0.1,0.3-0.4,0.3-0.6l0.5-22.7c0-0.7,0.5-1.3,1.2-1.5
			c8.3-2,17.1-3.6,25.1-6.9c0.3-0.1,0.6,0,0.7,0.3c0,0.1,0,0.1,0,0.2l-0.6,17.8c0,0.5,0.4,0.9,0.8,0.9c0.1,0,0.1,0,0.2,0l1.3-0.2
			c0.2,0,0.4,0.1,0.5,0.2c0.8,1.3,1.1,2.4,1.1,3.6c-0.3,11.1-0.7,22.3-1.2,33.4c-0.2,4.3-4.3,7.6-7.9,8.8c-6.9,2.3-13.9,4.3-21,6
			c-1.6,0.4-2.8,1.1-3.8,2.3c-0.3,0.3-0.4,0.7-0.4,1.1l-0.6,21.7c0,0.6,0.3,1.2,0.9,1.4l0.6,0.3c0.4,0.2,0.6,0.1,0.6-0.3l0.3-21.9
			c0-1.1,0.7-2,1.8-2.2c7.8-2.1,17-3.9,24.2-7.5c0.3-0.1,0.7,0,0.8,0.3c0,0.1,0.1,0.2,0.1,0.3l-0.7,16.4c0,0.6,0.5,1.1,1.1,1.1
			c0.1,0,0.3,0,0.4-0.1l0.7-0.3c0.4-0.1,0.7,0,0.8,0.4c0.8,1.8,1.2,3.8,1,6.1c-0.5,7.6-0.9,17.3-1.2,29.2c-0.1,5.8-5.5,9.3-10.6,11
			c-8.3,2.8-14.4,4.7-18.3,5.7c-2.5,0.6-3.8,2.2-3.9,4.8c-0.2,5.9-1.2,14.2-0.1,19.9c0.1,0.4,0.5,0.7,0.9,0.6
			c0.4-0.1,0.6-0.4,0.6-0.8l0.4-20.5c0-1.1,0.8-2,1.8-2.3c8.2-2,16.3-4.7,24.1-7.9c0.3-0.1,0.5,0,0.5,0.3l-0.3,15.8
			c0,1.5,0.5,1.7,1.5,0.7l0.4-0.4c0.3-0.3,0.5-0.2,0.7,0.1c0.9,1.7,1.4,3.2,1.3,4.6c-0.5,10.1-0.9,20.2-1.2,30.4
			c-0.3,10.8-19.8,15.3-28,17.4c-3.1,0.8-4.7,2.8-4.6,6.2c0.1,7.3-0.4,13.7-0.3,18.1c0,0.8,0.5,1.1,1.3,1c0.2,0,0.3-0.2,0.3-0.4
			l0.5-20.2c0-1,0.7-1.9,1.7-2.2c8.1-2.7,16.7-4.6,23.8-8.8c0.2-0.1,0.5-0.1,0.7,0.2c0,0.1,0.1,0.2,0.1,0.3l-0.4,14.7
			c0,0.5,0.4,0.9,0.9,0.9c0.3,0,0.6-0.1,0.8-0.4c0.2-0.3,0.3-0.6,0.2-1c0-0.1,0-0.2,0.1-0.3s0.2,0,0.3,0.1c1.1,2.2,1.6,3.6,1.6,4.4
			c-0.3,10.3-0.6,20.6-1.1,30.8c-0.3,6.1-5.5,10.4-10.9,12.4c-6.5,2.4-12.8,4.6-18.7,6.8c-1.6,0.5-2.6,2-2.6,3.7l-0.2,18.8
			c0,0.4,0.1,0.8,0.4,1.2c0.3,0.4,0.5,0.4,0.7,0.1c0.3-0.4,0.4-0.7,0.4-0.8l0.5-19c0-1.2,0.8-2.2,1.9-2.6c7.6-2.7,16.2-4.9,22.8-9
			c0.9-0.6,1.3-0.3,1.3,0.8l-0.4,13.6c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.7c0.1-0.4,0.2-0.8,0.4-1.2c0.2-0.4,0.3-0.4,0.5,0
			c0.6,1.8,1.2,3.2,1.2,5.1c-0.1,9.8-0.4,19.6-1,29.3c-0.3,5.3-4.4,10.1-8.9,12.1c-7.5,3.2-13.9,5.7-19.2,7.5
			c-2.2,0.7-3.3,2.1-3.4,4.3c-0.5,8.8-0.8,15-0.7,18.4c0,0.8,0.5,1.1,1.4,0.9c0.3-0.1,0.4-0.3,0.4-0.5c-0.2-7.1,0-13.4,0.5-18.9
			c0.2-1.9,1.4-2.3,3-2.9c7.3-2.8,15.3-5.1,21.9-9.4c0.3-0.2,0.6-0.1,0.8,0.2c0.1,0.1,0.1,0.2,0.1,0.3l-0.6,13c0,0.5,0.4,1,0.9,1.1
			c0.5,0.1,0.9-0.3,1-0.8l0-1c0-0.2,0.2-0.3,0.3-0.3c0.1,0,0.2,0.1,0.2,0.1c0.8,1.5,1.2,3,1.2,4.4c0.1,8.7-0.3,18.6-1,29.6
			c-0.4,5.8-4.4,10.4-9.4,12.7c-3.8,1.8-10.3,4.8-17.6,7.1c-3.2,1.1-4.3,2.7-4.3,6.2c-0.1,4.7-0.3,9.4-0.5,14.1
			c-0.1,1.3-0.3,2.5,0.9,3.3c0.2,0.1,0.4,0.1,0.5-0.1c0-0.1,0.1-0.1,0.1-0.2l0.7-19c0-0.9,0.6-1.6,1.4-2c7.6-3.2,16.8-5.9,23.4-10.8
			c0.2-0.2,0.5-0.1,0.7,0.1c0.1,0.1,0.1,0.2,0.1,0.3c-0.4,4.2-0.5,8.3-0.4,12.4c0,0.4,0.3,0.8,0.7,0.9c0.4,0.1,0.7-0.2,0.7-0.6l0,0
			c0-0.7,0-1.4-0.1-2c0-0.1,0.1-0.2,0.2-0.2l0.3,0c0.1,0,0.2,0,0.3,0.2c0.7,2.2,1.4,4.4,1.4,6.8c-0.2,8.6-0.5,17.3-0.9,25.9
			c-0.1,2.7-0.6,4.9-1.5,6.6c-4.7,8.8-18.4,12.7-27,16.3c-1.5,0.7-2.5,2.1-2.5,3.8l-0.4,17.7c0,0.5,0.3,0.8,0.8,0.9
			c0.7,0.1,1-0.3,0.8-1c-0.1-0.4-0.2-0.7-0.1-0.9c0.2-5.8,0.4-11.4,0.5-16.8c0-1,0.6-2,1.6-2.4c5.6-2.4,11.2-4.8,17-7.3
			c2.1-0.9,3.9-2.5,6-3.8c0.2-0.1,0.5-0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.3l-0.4,11.8c0,0.4,0.3,0.7,0.7,0.7c0.2,0,0.5-0.1,0.6-0.3
			c0.3-0.4,0.4-0.9,0.2-1.5c0-0.1,0-0.3,0.2-0.3l0.3-0.1c0.2-0.1,0.3,0,0.4,0.2c1,2.6,1.4,5.3,1.3,8.2c-1,28.8-2.1,57.5-3.2,86.2
			c-0.2,5.9-2.2,18.9-6.9,23c-4.1,3.5-8.7,6.3-13.8,8.4c-8.2,3.4-9.5,9.4-12.4,16.6C1106,937.9,1105.4,939,1104.6,940z
			 M1128.6,319.7c0.4,0.2,0.9,0.1,1.1-0.3c0.1-0.2,0.1-0.5,0-0.7c-0.4-0.9-0.6-2-0.5-3.2c0.2-6.5,0.4-13,0.7-19.5
			c0-0.8,0.5-1.4,1.3-1.7c1.2-0.4,2.5-0.8,4-1.1c0.4-0.1,0.7-0.3,0.8-0.5c0.2-0.3,0.2-0.6,0.1-1c-0.1-0.3-0.4-0.5-0.7-0.4
			c-2.8,0.2-7.3,1-7.5,4.2c-0.2,3.3-0.4,10.6-0.6,22C1127.2,318.5,1127.7,319.2,1128.6,319.7z M1066.3,705.5c0,4.2,2.4,5.5,5.7,7.2
			c6.3,3.2,17-2.8,20.2-8.5c0.7-1.4,1.2-3.2,1.3-5.5c0.3-5.5,0.5-10.9,0.6-16.4c0-0.3-0.2-0.8-0.6-1.4c-0.2-0.3-0.2-0.6-0.2-0.9
			c5.6-26.9,9.3-52.4,10.9-76.5c2.9-41.3-0.6-84.6-5.5-126.8c-0.1-0.6,0.1-1.2,0.4-1.8c0.2-0.3,0.3-0.6,0.3-0.9
			c0.2-5.5,0.3-11,0.5-16.5c0.2-4.3-0.6-8.3-4.8-9.8c-7-2.6-16.6-1-21.5,5.3c-4.7,6-1.2,16.2-3.1,25.8c-0.1,0.3,0,0.6,0.3,0.9
			c0.6,0.6,1,1.1,1.1,2c5.8,45,9,87.8,6.1,129.8c-1.6,23.1-5,47.8-10.1,73.9c-0.3,1.7-1.3,2.2-1.3,4
			C1066.4,694.8,1066.3,700.2,1066.3,705.5z"/>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        )}

                                        {currentSlide === 2 && (
                                            <div className="obj__it-img finish--2">
                                                <img src={require("../assets/img/objects/stairs.svg").default}/>
                                                <div className="obj__el">
                                                    <svg viewBox="0 0 1931 1086">
                                                        <g id="E2" style={{color: objects[2].elements[1]}} className="action-group">
                                                            <g>
                                                                <path d="M1233.7,74.9c2.7-4.7,5.1-7,10.6-5.4c25.8,7.6,71.8,21.1,138,40.5c8.6,2.5,10.5,10.8,10.4,18.5
		c-0.2,45.9-1.2,91.6-1.5,137.6c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0l-0.6,0c-0.4,0-0.6-0.2-0.5-0.6l1.5-115c0-0.4-0.3-0.7-0.7-0.7
		c0,0,0,0,0,0h-1c-0.5,0-0.7-0.2-0.7-0.7c0-7.5,2.6-32.8-6.6-35.4c-47.1-13.6-94.1-27.2-141.1-40.8c-0.7-0.2-1.5,0.1-1.9,0.8
		c-8.8,16.2-17.7,32.5-26.7,49c-1.2,2.3-1.6,5.9-1.6,9c-0.6,40.7-0.9,74.4-0.9,101.3c0,2.3-0.1,4.2-0.3,5.5
		c-0.1,0.5-0.3,0.6-0.8,0.6l-3.2-0.5c-0.4-0.1-0.6-0.3-0.6-0.8c0.1-43.2,0.4-79,0.8-107.5c0-3.5,1-6.9,2.8-10.2
		C1216.7,105.4,1225.2,90.1,1233.7,74.9z"/>
                                                                <path d="M1030.9,100.4c1.8-2.6,4.4-3.5,8-2.7c45.2,10.4,90.5,20.9,135.8,31.3c0.3,0.1,0.5,0.4,0.5,0.7
		c0,0.1-0.1,0.2-0.1,0.2l-1.5,1.9c-0.3,0.4-0.7,0.5-1.2,0.4l-135-30.8c-1.2-0.3-2.5,0.2-3.1,1.3l-11.3,18.2c-0.2,0.4-0.6,0.5-1,0.4
		l-3.1-0.6c-0.2-0.1-0.4-0.3-0.3-0.5c0,0,0-0.1,0-0.1C1022.7,113.1,1026.8,106.5,1030.9,100.4z"/>
                                                                <path d="M617,813.5c193.4-236.1,387-472.1,580.5-708.1c5.5-6.8,11.4-11,17.9-1.9c0.2,0.3,0.2,0.6,0,0.9l-1.9,3.3
		c-0.1,0.2-0.4,0.3-0.7,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c-1.3-3.1-5-7.4-8.4-3.3c-10.6,13-21.3,26-31.9,39.1
		c-74.1,90.8-148.1,181.7-222.2,272.5c-93.5,114.6-186.9,229.3-280.4,343.9c-2.5,3-8.8,10-12.8,15.3c-11,14.6-23.1,28.7-34.6,43
		c-4.2,5.2-6.8,8.5-7.4,15.4c-1.2,13.4-2.2,26.8-3.1,40.1c-0.5,8.2-1.1,11.8,4.1,18.9c20.1,27.5,40.2,55,60.3,82.4
		c0.2,0.3,0.3,0.6,0.2,1c-0.6,1.8-4.3,5.3-6.2,2.7c-20.3-27.6-40.6-55.1-61-82.6c-4.3-5.8-5.6-11.5-5-18.4c1-12.2,2-24.4,2.9-36.5
		C608.1,828.8,608.7,823.6,617,813.5z"/>
                                                                <path d="M970.7,177.4c-76.5,83.9-153.2,167.7-229.8,251.5c-95.3,104.2-190.7,208.3-286.1,312.3
		c-1.2,1.3-2.1,2.8-2.8,3.5c-11.6,12.8-23.3,25.5-34.9,38.2c-2.1,2.3-3.4,5.8-3.8,10.3c-1.2,13.9-2.2,27.7-3.2,41.6
		c-0.4,6,3.3,9.3,7,14c19.4,24.2,38.8,48.5,58.3,72.7c0.2,0.3,0.3,0.6,0.1,1c-0.9,1.8-2.4,2.8-4.4,3.1c-0.3,0-0.6,0-0.8-0.3
		c-20.4-25.2-40.7-50.5-60.9-75.8c-4.6-5.8-6.6-11.9-5.9-18.6c1.3-12.1,2.2-24.1,2.9-36.2c0.5-9.3,4.6-14.9,11.1-21.9
		c198-215.5,396.1-430.9,594.2-646.4c2.7-2.9,5.7-4.1,8.9-3.5c9.2,1.6,12.5,15,12.5,23c0.1,31.4,0.1,62.8-0.1,94.2
		c-0.1,10.4-6.6,15.9-12.4,23.2c-0.4,0.4-1,0.5-1.5,0.2c0,0-0.1-0.1-0.1-0.1c-0.9-1-1-2.1,0-3.1c6.3-7,11.3-11.6,11.4-20.3
		c0.3-31.6,0.3-63.2,0.2-94.9c0-4.8-0.9-9-2.5-12.5c-0.1-0.3-0.4-0.6-0.7-0.7c-3.5-2.1-7.6-8.6-12-3.8
		C999.5,146.2,984.5,162.2,970.7,177.4z"/>
                                                                <path d="M1015,131.3c3.3-3.7,3.6-3.5,1,0.8c-2.8,4.5-6,9.8-9.8,15.8c-2.8,4.4-0.6,7.8-5.7,11.7
		c-0.2,0.2-0.4,0.1-0.4-0.2C998.3,146.5,1007.5,139.8,1015,131.3z"/>
                                                                <path d="M1182.9,133.2c0.3-0.6,0.7-1.1,1.2-1.4c0.3-0.2,0.6-0.3,1-0.2l7.3,1.6c0.3,0.1,0.5,0.4,0.4,0.7
		c0,0.1-0.1,0.2-0.1,0.3l-1.9,2c-0.3,0.3-0.6,0.4-1,0.3l-6.7-1.4c-0.3-0.1-0.5-0.3-0.6-0.6C1182.4,133.8,1182.5,133.5,1182.9,133.2z
		"/>
                                                                <path d="M1166,153.3c0.9-1.1,1.6-1,2.2,0.3l31.7,83.6c0.2,0.5-0.1,1-0.5,1.2c-0.1,0-0.2,0.1-0.3,0.1h-2.2
		c-0.4,0-0.6-0.2-0.7-0.5c-10.3-27-20.7-54-31.2-81C1164.5,156,1164.8,154.7,1166,153.3z"/>
                                                                <path d="M1167,166.8c0.1,0.3,0,0.6-0.3,0.6l-0.1,0.1c-1.1,0.4-2.3-0.1-2.6-1.1c0,0,0,0,0,0l-2.2-6.3
		c-0.4-1,0.2-2.1,1.3-2.5c0,0,0,0,0,0l0.1-0.1c0.3-0.1,0.6,0,0.6,0.3c0,0,0,0,0,0L1167,166.8z"/>
                                                                <path d="M977.1,173.6l43.8,98.6c0.1,0.2,0,0.4-0.1,0.5l-2.2,2.6c-0.2,0.2-0.5,0.2-0.7,0.1c-0.1,0-0.1-0.1-0.1-0.2
		l-44.1-98.8c-0.1-0.2,0-0.4,0.1-0.5l2.4-2.5c0.2-0.2,0.5-0.2,0.7,0C977.1,173.5,977.1,173.6,977.1,173.6z"/>
                                                                <path d="M971.8,178.5L971.8,178.5c0.6-0.2,1.2,0,1.4,0.5l3.1,7.1c0.2,0.5,0,1.1-0.5,1.3l-0.1,0c-0.5,0.2-1.1,0-1.3-0.5
		l-3.1-7.1C971.1,179.3,971.3,178.7,971.8,178.5z"/>
                                                                <path d="M1189.5,241.3c0.1-0.2,0.3-0.3,0.5-0.3l8.8-0.7c0.2,0,0.4,0.2,0.5,0.4c0,0.1,0,0.1,0,0.2l-2.2,4.7
		c0,0.1,0,0.2-0.1,0.2l-0.3,17.1c0,0.3-0.2,0.5-0.5,0.5l-9,0.4c-0.4,0-0.6-0.2-0.6-0.6c0.1-4.7,0.1-9.4,0.1-14
		C1186.5,246.1,1188.2,244,1189.5,241.3z"/>
                                                                <path d="M1199.6,263.5c-0.8-0.1-1.2-0.6-1.3-1.4c-0.1-4.6-0.1-9.2,0.2-13.9c0.1-2.7,1.3-4.8,2.7-7
		c0.4-0.6,1-0.9,1.7-0.8l192,28.9c1.3,0.2,2.1,1.4,1.9,2.6c0,0,0,0.1,0,0.1c-0.5,2.9-0.8,5.7-0.9,8.3c-0.1,2.3,0.7,5.3,0.4,8
		c-0.1,0.5-0.5,0.8-1,0.8c0,0,0,0,0,0L1199.6,263.5z"/>
                                                                <path d="M1038.1,260.8c0.3,0,0.6,0.1,0.8,0.3c0.2,0.1,0.2,0.3,0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2l-14.2,0.9
		c-0.5,0-0.6-0.2-0.4-0.6c0.7-1.4,1.8-2.5,3.6-2.3C1031.2,260,1034.6,260.4,1038.1,260.8z"/>
                                                                <path d="M1079.1,260.3l11-0.9c0.3,0,0.5,0.2,0.5,0.4l0,0.5c0,0.3-0.2,0.5-0.4,0.5l-11,0.9c-0.3,0-0.5-0.2-0.5-0.4
		l0-0.5C1078.6,260.5,1078.8,260.3,1079.1,260.3z"/>
                                                                <path d="M1024.2,264.1l42.1-2.9c0.3,0,0.6,0.2,0.7,0.6l0,0.2c0,0.3-0.2,0.6-0.6,0.7l-42.1,2.9c-0.3,0-0.6-0.2-0.7-0.6
		l0-0.2C1023.6,264.4,1023.8,264.1,1024.2,264.1z"/>
                                                                <path d="M1071.1,270.1l5.4-6.4c0.1-0.1,0.2-0.2,0.4-0.2l10.6-0.9c0.3,0,0.6,0.2,0.7,0.6c0,0.2,0,0.3-0.1,0.4l-6.6,8.2
		c-0.1,0.2-0.3,0.3-0.5,0.2l-9.4-0.9c-0.3,0-0.6-0.3-0.6-0.7C1071,270.4,1071,270.2,1071.1,270.1z"/>
                                                                <path d="M1034.8,266.2l29.4-1.9c0.2,0,0.4,0.1,0.4,0.4c0,0.1,0,0.2-0.1,0.3l-3.9,4.7c-0.1,0.1-0.2,0.2-0.3,0.1
		l-25.5-2.8c-0.2,0-0.4-0.2-0.4-0.4C1034.4,266.4,1034.6,266.2,1034.8,266.2L1034.8,266.2z"/>
                                                                <path d="M1021.6,269.2c-0.9-0.9-1.3-2-0.9-3.3c0.1-0.4,0.3-0.5,0.7-0.4c0.4,0.1,0.6,0.5,0.5,1.1c0,0.4,0.1,0.6,0.5,0.6
		l36,4c0.3,0,0.5,0.3,0.4,0.5c0,0.1-0.1,0.2-0.1,0.3l-8.5,10.3c-0.2,0.3-0.5,0.4-0.9,0.4l-17.2-1.6c-0.4,0-0.7-0.3-0.9-0.6l-5-11.1
		c-0.1-0.3-0.4-0.5-0.8-0.4l-3,0.5C1022.2,269.6,1021.8,269.5,1021.6,269.2z"/>
                                                                <path d="M1197,290c-1.2-2.5-2.4-5-3.6-7.6c-2-4.4-1.2-10.8-1.1-15.5c0-0.3,0.3-0.6,0.6-0.6l3.2-0.2
		c0.3,0,0.6,0.2,0.6,0.6c0,0,0,0,0,0c0.1,4.5-0.9,11.4,1,15.4c1.3,2.7,2.6,5.5,3.8,8.2c0.1,0.3,0,0.6-0.3,0.7c-0.1,0-0.2,0.1-0.3,0
		l-3.1-0.4C1197.5,290.6,1197.2,290.4,1197,290z"/>
                                                                <path d="M1204.8,290.5c-0.8-0.1-1.5-0.6-1.9-1.3c-1.5-3.3-4.7-7.8-4.7-11.5c0-3.3,0-6.7,0-10.1c0-1,0.5-1.4,1.5-1.3
		l22.8,2.9c0.6,0.1,1,0.5,1,1.1c0,3.5,0,7-0.1,10.6c-0.1,3.5-3.1,7.5-4.8,10.5c-0.1,0.2-0.4,0.4-0.7,0.4L1204.8,290.5z
		 M1212.6,284.8c2.7,0,4.9-2.7,4.8-5.9c0-3.2-2.3-5.8-5-5.8c-2.7,0-4.9,2.7-4.8,5.9C1207.7,282.2,1209.9,284.8,1212.6,284.8z"/>
                                                                <path d="M1225.9,268.6l12.7,1.6c0.1,0,0.3,0.2,0.2,0.3l0,0.3c0,0.1-0.2,0.3-0.3,0.2l-12.7-1.6c-0.1,0-0.3-0.2-0.2-0.3
		l0-0.3C1225.6,268.7,1225.8,268.6,1225.9,268.6z"/>
                                                                <path d="M1398.5,270.7c0.2-0.2,0.5-0.3,0.8-0.1c0.2,0.1,0.3,0.4,0.2,0.6l-0.5,2c-0.1,0.2-0.3,0.3-0.5,0.3
		c-0.1,0-0.2-0.1-0.3-0.3C1397.8,272.3,1397.9,271.5,1398.5,270.7z"/>
                                                                <path d="M1225.3,271.3c0-0.2,0.2-0.4,0.4-0.4h0.1c7.7,0.2,14,1.3,13.9,2.4c0,0,0,0,0,0l0,0.2c0,1.1-6.3,1.8-14.1,1.6
		l-0.1,0c-0.2,0-0.4-0.2-0.4-0.4c0,0,0,0,0,0L1225.3,271.3z"/>
                                                                <path d="M1001.2,301.3c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.6,0.3-0.8c7.8-9.3,15.7-18.7,23.5-28.1
		c0.2-0.2,0.5-0.3,0.7-0.1c0.1,0.1,0.1,0.1,0.1,0.2l3.9,8.6c0.2,0.3,0.1,0.6-0.1,0.9l-6.3,7.7c-0.1,0.2-0.4,0.2-0.6,0.1
		c-0.1-0.1-0.1-0.1-0.1-0.2c-0.4-1.6-1.3-3-2.5-4c-0.3-0.2-0.7-0.4-1.1-0.4c-6.4,0-6.6,8.6-1.7,10.7c0.3,0.1,0.4,0.3,0.2,0.6
		c-2.5,5.2-12.3,0.4-13.6,4.5c-0.1,0.5-0.4,0.7-0.9,0.6L1001.2,301.3z"/>
                                                                <path d="M1249.8,296.2c-1.1,0.3-3.9,0.3-4.4-1.1c-1.4-3.4-4.2-7.6-4.1-11.3c0-3.5,0-7,0-10.5c0-0.5,0.3-0.8,0.8-0.8
		l2.9-0.1c0.4,0,0.6,0.2,0.6,0.6c0,3.4,0,6.8,0,10.2c0,4.1,2.9,8.6,4.6,12.2C1250.3,295.8,1250.2,296.1,1249.8,296.2z"/>
                                                                <path d="M1251.7,295.1c-1.1-2-2.1-4.1-3-6.2c-2.2-4.8-1.5-10.9-1.3-16c0-0.2,0.2-0.4,0.4-0.4c0,0,0,0,0,0l22.2,2.8
		c0.3,0,0.5,0.3,0.5,0.6c0,3.3,0,6.7-0.2,10.1c-0.2,3.7-2.3,6.5-4,9.6c-0.5,1-1.4,1.4-2.5,1.3l-11.3-1.2
		C1252.2,295.6,1251.9,295.4,1251.7,295.1z M1260.3,290.4c2.6,0,4.7-2.5,4.7-5.7c0,0,0,0,0,0c0-3.2-2.1-5.7-4.7-5.7
		c-2.6,0-4.7,2.5-4.7,5.7c0,0,0,0,0,0C1255.6,287.8,1257.7,290.4,1260.3,290.4z"/>
                                                                <path d="M1060.4,284c-0.2-0.1-0.3-0.3-0.2-0.4c0,0,0-0.1,0.1-0.1l8.6-10.5c0.3-0.3,0.6-0.4,1-0.4l9.3,0.9
		c0.3,0,0.5,0.3,0.5,0.6c0,0.1-0.1,0.2-0.2,0.3l-0.3,0.3c-0.3,0.3-0.6,0.4-1,0.4l-7.4,0.3c-0.3,0-0.6,0.1-0.8,0.4
		c-1.9,2.3-3.8,4.6-5.6,6.9C1063.1,284.4,1062.3,284.7,1060.4,284z"/>

                                                                <ellipse transform="matrix(0.9998 -1.745241e-02 1.745241e-02 0.9998 -4.6721 24.5299)" cx="1403.1" cy="280" rx="2.7" ry="6.9"/>
                                                                <path d="M1211.6,282.5c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0,0,0h-0.1c-1,0-1.8-1.1-1.8-2.5l-0.1-2.2
		c-0.1-1.4,0.7-2.6,1.6-2.6c0,0,0,0,0,0h0.1c0.1,0,0.2,0.1,0.2,0.1c0,0,0,0,0,0L1211.6,282.5z"/>
                                                                <path d="M1069.3,279.4c1.5-1.8,2.1-3.2,5-2.3c0.2,0.1,0.3,0.2,0.2,0.4c0,0,0,0.1-0.1,0.1c-4.8,5.8-9.5,11.5-14.1,17.3
		c-1.1,1.4-2.6,1.8-4.2,1.5c-0.5-0.1-0.5-0.3-0.2-0.7C1060.4,290.2,1064.8,284.8,1069.3,279.4z"/>
                                                                <path d="M1225.2,282.5v-4.3c0-0.3,0.2-0.5,0.5-0.6l13.2-0.8c0.4,0,0.6,0.2,0.6,0.6l0,5.5c0,0.4-0.3,0.7-0.7,0.8
		l-13.5,1c-0.3,0-0.5-0.1-0.5-0.4c-0.1-0.4,0-0.7,0.3-1.1C1225.2,283,1225.2,282.8,1225.2,282.5z"/>
                                                                <path d="M1272,279c0-0.6,0.1-1.2,0.4-1.7c0.1-0.3,0.4-0.4,0.7-0.4l23.6,3c0.3,0,0.4,0.2,0.4,0.5c0,0.1-0.1,0.2-0.2,0.3
		c-0.1,0.1-0.2,0.1-0.3,0.1c-7.9,0.3-15.8,0.7-23.7,1.2c-0.4,0-0.7-0.2-0.8-0.5c-0.1-0.4-0.1-0.9-0.1-1.4
		C1272,280,1272,279.7,1272,279z"/>
                                                                <path d="M1214.1,278.4c-0.1-0.5,0.2-0.9,0.7-1c0.5-0.1,0.9,0.2,1,0.7c0.2,0.8,0,1.5-0.4,2.1c-0.2,0.2-0.5,0.3-0.7,0.1
		c-0.1-0.1-0.2-0.2-0.2-0.3L1214.1,278.4z"/>
                                                                <path d="M1316.6,291.7c-3.4-0.1-3.1-9-3.1-10.7c0-0.3,0.3-0.6,0.6-0.6c0,0,0,0,0,0l2.9,0.1c0.2,0,0.3,0.2,0.3,0.4
		c0,0.1-0.1,0.3-0.2,0.3c-4,1.3-4,7.9,0.4,8.6c0.4,0.1,0.6,0.4,0.6,0.8c0,0.1-0.1,0.2-0.1,0.3
		C1317.5,291.5,1317.1,291.7,1316.6,291.7z"/>
                                                                <path d="M1259.5,288.1c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0,0,0h-0.1c-0.9,0-1.8-1.1-1.8-2.5c0,0,0,0,0,0l-0.1-1.8
		c-0.1-1.4,0.7-2.6,1.6-2.7l0.1,0c0.1,0,0.2,0.1,0.2,0.1c0,0,0,0,0,0L1259.5,288.1z"/>
                                                                <path d="M1270.3,291.6c2-2.4,1.7-4.1,2-7.3c0-0.4,0.3-0.7,0.7-0.7l32.1-1.8c0.3,0,0.6,0.2,0.6,0.5c0,0,0,0,0,0l0.5,7.7
		c0,0.4-0.3,0.7-0.6,0.7c0,0,0,0,0,0l-34.8,1.8c-0.3,0-0.5-0.2-0.5-0.5C1270.2,291.8,1270.2,291.7,1270.3,291.6z"/>
                                                                <path d="M1308.2,282.2c0.1-0.2,0.2-0.2,0.4-0.2c0,0,0.1,0,0.1,0.1l0.3,0.3c0.2,0.2,0.2,0.4,0.2,0.6l-0.5,4.5
		c0,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.3-0.1-0.3-0.3C1307.5,285.7,1307.6,283.9,1308.2,282.2z"/>
                                                                <path d="M1262.1,283.5c0,0,0.3-0.1,0.5-0.2c0.2-0.1,0.3,0,0.4,0.2c0.5,0.8,0.5,1.6,0,2.2c-0.2,0.2-0.5,0.3-0.8,0.1
		c-0.1-0.1-0.2-0.3-0.2-0.4V283.5z"/>
                                                                <path d="M1354.1,287.4c0.3,0,0.6,0.2,0.8,0.4c0.1,0.1,0.1,0.3,0,0.4c0,0-0.1,0.1-0.1,0.1l-33.8,1.6
		c-0.3,0-0.6-0.2-0.6-0.6c0,0,0,0,0-0.1l0.3-5c0-0.5,0.5-1,1-0.9c0,0,0,0,0.1,0C1332.4,284.5,1343.2,285.9,1354.1,287.4z"/>
                                                                <path d="M1310.7,283.7c0-0.2,0.2-0.3,0.4-0.3c0.1,0,0.2,0.1,0.3,0.3c0.6,3.9,1.3,7.3,0.8,11.4
		c-7.1,51.5-14.3,103-21.4,154.5c-0.1,0.4-0.3,0.6-0.7,0.6h-1.7c-0.4,0-0.6-0.2-0.6-0.7L1310.7,283.7z"/>

                                                                <ellipse transform="matrix(0.9999 -1.570734e-02 1.570734e-02 0.9999 -4.3217 20.7328)" cx="1317.7" cy="285.5" rx="0.8" ry="2.1"/>
                                                                <path d="M1191.5,283.7l0.3,0.3c0.1,0.1,0.2,0.3,0.1,0.4L1011,527.9c-0.1,0.1-0.3,0.2-0.4,0.1l-0.3-0.3
		c-0.1-0.1-0.2-0.3-0.1-0.4L1191,283.8C1191.1,283.7,1191.3,283.6,1191.5,283.7z"/>
                                                                <path d="M1047.4,285.4c-0.4,1-1.7,2.8-2.7,3.4c-0.3,0.2-0.5,0.1-0.5-0.3v-3c0-0.5,0.2-0.8,0.8-0.8h2
		C1047.4,284.8,1047.6,285,1047.4,285.4z"/>
                                                                <path d="M1050.3,296.8c-0.2,0-0.4-0.2-0.4-0.5c0-0.1,0-0.2,0.1-0.2c2.6-3.3,5.2-6.6,8.1-9.8c0.8-0.9,1.8-1,3-0.3
		c0.3,0.2,0.3,0.4,0.1,0.7c-2.2,2.7-4.4,5.4-6.6,8.1C1053.3,296.5,1052.3,297,1050.3,296.8z"/>
                                                                <path d="M1223,287c0.2-0.4,0.6-0.6,1-0.6l13-0.7c0.5,0,0.6,0.2,0.3,0.6l-5.7,7.8c-0.2,0.3-0.5,0.4-0.8,0.5l-10.1,0.4
		c-0.6,0-1.1-0.4-1.2-1.1c0-0.2,0-0.4,0.1-0.6L1223,287z"/>
                                                                <path d="M1398.4,289.7c-0.6-1-0.7-2.1-0.2-3.4c0.2-0.4,0.4-0.4,0.5,0l1.2,2.6c0.2,0.4,0,1-0.5,1.2
		C1399.1,290.2,1398.6,290.1,1398.4,289.7z"/>
                                                                <path d="M1199.7,293.1L696.5,977.5c0,0.1-0.1,0.1-0.2,0.1l-21.5,6.7c-0.2,0.1-0.4-0.1-0.5-0.3c0-0.1,0-0.2,0.1-0.3
		L1192.8,286c0.1-0.2,0.4-0.2,0.5-0.1c0,0,0.1,0.1,0.1,0.1l2.7,5.8c0.1,0.1,0.2,0.2,0.3,0.2l3.1,0.4c0.2,0,0.3,0.2,0.3,0.4
		C1199.8,293,1199.8,293.1,1199.7,293.1z M1192.1,299.3c1.2,0,2.2-1.3,2.2-3c0-1.7-0.9-3.1-2.1-3.1c-1.2,0-2.2,1.3-2.2,3
		C1189.9,297.9,1190.9,299.3,1192.1,299.3z M1165.9,334.8c1.2,0,2.2-1.4,2.2-3.1c0,0,0,0,0,0c0-1.7-1-3.1-2.2-3.1c0,0,0,0,0,0
		c-1.2,0-2.2,1.4-2.2,3.1v0C1163.7,333.5,1164.7,334.8,1165.9,334.8C1165.9,334.8,1165.9,334.8,1165.9,334.8z M1138.6,371.7
		c1.2,0,2.3-1.4,2.3-3.1c0-1.7-1-3.1-2.2-3.1c0,0,0,0,0,0c-1.2,0-2.3,1.4-2.3,3.1C1136.4,370.3,1137.4,371.7,1138.6,371.7
		L1138.6,371.7z M1110.3,410.1c1.2,0,2.3-1.4,2.3-3.1s-0.9-3.2-2.2-3.2l0,0c-1.2,0-2.3,1.4-2.3,3.1
		C1108.1,408.6,1109.1,410.1,1110.3,410.1L1110.3,410.1z M1080.9,450c1.3,0,2.4-1.4,2.4-3.1c0-1.8-1-3.2-2.3-3.3h0
		c-1.3,0-2.4,1.4-2.4,3.1c0,0,0,0,0,0C1078.6,448.5,1079.6,450,1080.9,450L1080.9,450z M1050.2,491.5c1.3,0.1,2.4-1.4,2.5-3.2
		c0,0,0,0,0,0c0.1-1.8-0.9-3.3-2.2-3.3c0,0,0,0,0,0c-1.3-0.1-2.4,1.4-2.5,3.2c0,0,0,0,0,0C1047.9,490,1048.9,491.5,1050.2,491.5
		L1050.2,491.5z M1018.2,534.8c1.3,0.1,2.5-1.3,2.6-3.1s-0.9-3.3-2.2-3.4c-1.3-0.1-2.5,1.3-2.6,3.1
		C1015.8,533.2,1016.8,534.7,1018.2,534.8z M984.8,579.8c1.4,0.1,2.6-1.2,2.8-3v0c0.2-1.8-0.8-3.4-2.2-3.5c0,0,0,0,0,0
		c-1.4-0.1-2.6,1.2-2.8,3c0,0,0,0,0,0C982.4,578.1,983.4,579.7,984.8,579.8C984.8,579.8,984.8,579.8,984.8,579.8z M950.1,627
		c1.4,0.1,2.6-1.3,2.8-3.2c0.2-1.9-0.8-3.5-2.2-3.6s-2.6,1.3-2.8,3.2C947.7,625.3,948.7,626.9,950.1,627z M913.6,676.2
		c1.4,0.2,2.8-1.2,3-3c0,0,0,0,0,0c0.3-1.9-0.7-3.5-2.1-3.7c-1.4-0.2-2.8,1.2-3,3v0C911.2,674.3,912.2,676,913.6,676.2z
		 M875.6,727.6c1.4,0.2,2.8-1.2,3.1-3s-0.7-3.6-2.1-3.8c-1.4-0.2-2.8,1.2-3.1,3c0,0,0,0,0,0C873.2,725.6,874.2,727.3,875.6,727.6z
		 M835.9,781.4c1.5,0.2,3-1.2,3.3-3.1c0.3-1.9-0.7-3.7-2.2-3.9l0,0c-1.5-0.2-3,1.2-3.3,3.1S834.4,781.2,835.9,781.4
		C835.9,781.4,835.9,781.4,835.9,781.4z M794,837.8c1.5,0.3,3.1-1,3.5-3l0,0c0.4-2-0.5-3.8-2-4.1l0,0c-1.5-0.3-3.1,1-3.5,3
		S792.5,837.5,794,837.8C794,837.8,794,837.8,794,837.8z M750.1,896.9c1.5,0.5,3.3-0.8,3.8-2.7v0c0.6-2-0.2-3.9-1.8-4.3
		c-1.5-0.5-3.3,0.8-3.8,2.7C747.8,894.5,748.6,896.5,750.1,896.9z M704,958.9c1.5,0.6,3.4-0.6,4.1-2.5c0.7-2,0-4-1.5-4.6l0,0
		c-1.5-0.6-3.4,0.6-4.1,2.5C701.8,956.3,702.5,958.4,704,958.9L704,958.9z"/>
                                                                <path d="M1239.2,286.6c0.1-0.1,0.3-0.2,0.4-0.1c0,0,0.1,0.1,0.1,0.1l1.7,3.2c0.1,0.2,0.1,0.5-0.1,0.8
		c-145,203-290,406.1-435,609.1c-1,1.4-1.6,2.7-3.5,2.5c-0.7-0.1-1.3,0.5-1.3,1.1c0,0.1,0,0.3,0,0.4c0.4,1.6,0,2.8-1,4.1
		c-11.4,16-22.8,32-34.3,47.9c-0.2,0.3-0.5,0.5-0.9,0.6l-8,2.5c-0.2,0.1-0.4-0.1-0.4-0.2c0-0.1,0-0.2,0-0.3L1239.2,286.6z"/>
                                                                <path d="M1020.5,291.4c-0.2,0.4-0.6,0.6-1,0.4c-0.3-0.1-0.5-0.4-0.5-0.7v-1.7c0-0.4,0.3-0.7,0.6-0.7
		c0.2,0,0.4,0.1,0.5,0.3C1020.7,289.8,1020.8,290.6,1020.5,291.4z"/>
                                                                <path d="M1376.8,290.3c0.2,0,0.3,0.2,0.3,0.3c0,0.2-0.2,0.3-0.3,0.3l-59.1,3.1c-0.4,0-0.5-0.1-0.2-0.4
		c0.8-1,1.5-1.5,2.3-1.5c13.2-0.7,26.4-1.4,39.7-2.3C1364.6,289.4,1370.4,289.6,1376.8,290.3z"/>
                                                                <path d="M1023.2,291.7c0.1-0.1,0.2-0.1,0.3,0l0.1,0.1c1.1,0.8,0.8,3-0.6,4.8c0,0,0,0,0,0l-0.1,0.2
		c-1.4,1.8-3.4,2.6-4.5,1.8c0,0,0,0,0,0l-0.1-0.1c-0.1-0.1-0.1-0.2,0-0.3L1023.2,291.7z"/>
                                                                <path d="M1245.5,297.5l2.7,0.3c0.3,0,0.5,0.3,0.5,0.6c0,0.1,0,0.2-0.1,0.3c-140.9,199.2-281.8,398.4-422.5,597.6
		c-0.5,0.7-0.9,1-1.5,1.1c-0.4,0.1-0.8,0-1.2-0.3c-0.3-0.2-0.6-0.2-1-0.2l-14.2,4c-0.2,0-0.4-0.1-0.4-0.2c0-0.1,0-0.2,0-0.3
		l433.9-607.6c0.2-0.2,0.5-0.3,0.7-0.1c0.1,0.1,0.1,0.1,0.2,0.2l2,4C1244.9,297.2,1245.1,297.4,1245.5,297.5z M1241.5,304.5
		c1.1,0,2.1-1.3,2.1-3c0,0,0,0,0,0c0-1.7-0.9-3-2-3h0c-1.1,0-2.1,1.3-2.1,3c0,0,0,0,0,0C1239.4,303.2,1240.3,304.5,1241.5,304.5
		L1241.5,304.5z M1217.2,338.6c1.2,0,2.1-1.3,2.1-2.9c0,0,0,0,0,0c0-1.6-0.9-3-2.1-3c-1.2,0-2.1,1.3-2.1,2.9c0,0,0,0,0,0
		C1215.1,337.3,1216,338.6,1217.2,338.6z M1191.9,374.2c1.2,0,2.2-1.3,2.2-2.9c0-1.7-0.9-3-2.1-3.1c0,0,0,0,0,0
		c-1.2,0-2.2,1.3-2.2,2.9c0,0,0,0,0,0C1189.8,372.8,1190.8,374.1,1191.9,374.2L1191.9,374.2z M1165.9,411c1.2,0,2.2-1.4,2.2-3
		c0,0,0,0,0,0c0-1.7-1-3-2.3-3c-1.2,0-2.2,1.4-2.2,3c0,0,0,0,0,0C1163.7,409.7,1164.7,411,1165.9,411z M1138.7,449.3
		c1.2,0,2.3-1.3,2.3-3.1c0-1.7-0.9-3.1-2.2-3.2c-1.2,0-2.3,1.3-2.3,3.1v0C1136.5,447.8,1137.5,449.3,1138.7,449.3L1138.7,449.3z
		 M1110.5,489.1c1.2,0,2.3-1.4,2.3-3.2c0,0,0,0,0,0c0-1.8-1-3.2-2.2-3.3l0,0c-1.2,0-2.3,1.4-2.3,3.2c0,0,0,0,0,0
		C1108.3,487.6,1109.3,489.1,1110.5,489.1L1110.5,489.1z M1081,530.5c1.3,0.1,2.4-1.3,2.6-3c0.1-1.8-0.8-3.3-2.1-3.4h0
		c-1.3-0.1-2.4,1.3-2.6,3C1078.7,528.9,1079.7,530.4,1081,530.5C1081,530.5,1081,530.5,1081,530.5z M1050.4,573.5
		c1.3,0.1,2.5-1.2,2.6-3c0,0,0,0,0,0c0.1-1.8-0.8-3.3-2.1-3.4s-2.5,1.2-2.6,3v0C1048.1,571.8,1049.1,573.4,1050.4,573.5z
		 M1018.6,618.4c1.3,0.1,2.5-1.3,2.7-3.1s-0.8-3.4-2.2-3.5c-1.3-0.1-2.5,1.3-2.7,3.1C1016.3,616.7,1017.3,618.3,1018.6,618.4z
		 M985.3,665c1.4,0.2,2.6-1.1,2.9-2.9c0,0,0,0,0,0c0.2-1.8-0.7-3.4-2-3.6h0c-1.4-0.2-2.6,1.1-2.9,2.9
		C983,663.2,983.9,664.9,985.3,665C985.3,665,985.3,665,985.3,665z M950.7,713.9c1.4,0.2,2.7-1.2,2.9-3.1c0,0,0,0,0,0
		c0.2-1.9-0.7-3.5-2.1-3.7c-1.4-0.2-2.7,1.2-2.9,3.1c0,0,0,0,0,0C948.3,712.1,949.3,713.7,950.7,713.9z M914.6,764.8
		c1.4,0.2,2.7-1.2,2.9-3.1l0,0c0.2-1.9-0.8-3.5-2.2-3.7c-1.4-0.2-2.7,1.2-2.9,3.1S913.2,764.7,914.6,764.8
		C914.6,764.8,914.6,764.8,914.6,764.8z M876.6,818.1c1.4,0.2,2.9-1.1,3.2-3s-0.6-3.6-2.1-3.9c-1.4-0.2-2.9,1.1-3.2,3v0
		C874.2,816.1,875.1,817.9,876.6,818.1L876.6,818.1z M836.8,873.8c1.5,0.3,3-1,3.4-2.9c0.4-1.9-0.5-3.7-1.9-4c0,0,0,0,0,0
		c-1.5-0.3-3,1-3.4,2.9C834.5,871.6,835.3,873.5,836.8,873.8C836.8,873.8,836.8,873.8,836.8,873.8z"/>
                                                                <path d="M1268.9,294.7l37.9-1.9c0.3,0,0.6,0.2,0.7,0.6l0,0.5c0,0.3-0.2,0.6-0.6,0.7l-37.9,1.9c-0.3,0-0.6-0.2-0.7-0.6
		l0-0.5C1268.3,295,1268.6,294.7,1268.9,294.7z"/>
                                                                <path d="M1202.9,293.1l14.6,1.4c0.1,0,0.3,0.1,0.2,0.3l0,0.3c0,0.1-0.1,0.3-0.3,0.2l-14.6-1.4c-0.1,0-0.3-0.1-0.2-0.3
		l0-0.3C1202.6,293.2,1202.8,293.1,1202.9,293.1z"/>
                                                                <path d="M1314.3,293.7l0.3,0c0.2,0,0.4,0.3,0.4,0.5l-21.6,155.6c0,0.2-0.3,0.4-0.5,0.4l-0.3,0c-0.2,0-0.4-0.3-0.4-0.5
		l21.6-155.6C1313.9,293.8,1314.1,293.7,1314.3,293.7z"/>
                                                                <path d="M1196.2,300.6l4.1-5.3c0.1-0.1,0.3-0.2,0.4-0.2l27.3,2.9c0.3,0,0.4,0.3,0.4,0.5c0,0.1,0,0.2-0.1,0.2l-3.8,5.1
		c-0.1,0.1-0.3,0.2-0.4,0.2l-27.7-2.7c-0.3,0-0.4-0.2-0.4-0.5C1196.1,300.7,1196.2,300.6,1196.2,300.6z"/>
                                                                <path d="M1047.9,299.8c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.2,0.1-0.3c2-1.5,8.4-1.1,11-1.2c0.3,0,0.5,0.2,0.5,0.5
		c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3c0,0.2-0.1,0.3-0.2,0.3L1047.9,299.8z"/>
                                                                <path d="M1006.7,301l28.5-1.8c0.2,0,0.4,0.1,0.5,0.2c0.2,0.3,0.1,0.6-0.3,0.9c-0.1,0.1-0.3,0.1-0.5,0.1
		c-9.3,0.6-18.6,1-27.9,1.4c-0.2,0-0.3-0.1-0.4-0.3c0-0.1-0.1-0.2,0-0.4S1006.6,301,1006.7,301z"/>
                                                                <path d="M1045.8,301.4l11.4-0.7c0.2,0,0.4,0.1,0.4,0.3c0,0.1,0,0.2-0.1,0.3l-3.1,3.9c-0.1,0.1-0.2,0.1-0.3,0.1
		l-11.6,0.7c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.3l3.2-4C1045.6,301.5,1045.7,301.4,1045.8,301.4z"/>
                                                                <path d="M946.4,302.2c0-0.3,0.2-0.5,0.5-0.7c0.3-0.1,0.6,0,0.7,0.3l4.6,9.8c0.1,0.3,0.1,0.6-0.1,0.9l-8.8,10.3
		c-0.2,0.2-0.4,0.2-0.6,0c0,0-0.1-0.1-0.1-0.1l-4.7-9.6c-0.2-0.5-0.1-1,0.2-1.4C940.9,308.5,943.7,305.3,946.4,302.2z"/>
                                                                <path d="M1009.4,307.4v-3.6c0-0.2,0.2-0.4,0.4-0.5l23.2-1.3c0.3,0,0.5,0.2,0.5,0.4c0,0.1,0,0.2-0.1,0.3l-3.2,3.8
		c-0.1,0.1-0.2,0.2-0.3,0.2l-20,1.1C1009.6,307.9,1009.4,307.7,1009.4,307.4C1009.4,307.4,1009.4,307.4,1009.4,307.4z"/>
                                                                <path d="M1004,311.3c0.6,0.1,0.7,0.3,0.3,0.8L988.6,331c-0.3,0.4-0.8,0.6-1.3,0.6l-9.8,0.6c-0.4,0-0.7,0.2-0.9,0.6
		c-0.6,1.2-1.6,1.7-3.1,1.4c-0.5-0.1-0.6-0.3-0.2-0.7l26-30.9c0.2-0.2,0.4-0.2,0.6-0.1c0.1,0.1,0.2,0.2,0.2,0.4l-0.1,7.4
		c0,0.3,0.2,0.5,0.5,0.6L1004,311.3z"/>

                                                                <ellipse transform="matrix(0.2689 -0.9632 0.9632 0.2689 443.9146 1192.2759)" cx="1007.3" cy="303.7" rx="0.5" ry="0.6"/>
                                                                <path d="M1051.6,307.8c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.2-0.1,0.3c-0.7,0.8-1.6,1.3-2.9,1.3c-3.1,0.1-6.3,0.2-9.4,0.5
		c-0.3,0-0.5-0.1-0.5-0.4c-0.1-0.9,0.4-1.4,1.3-1.5C1044.2,308.1,1047.9,307.8,1051.6,307.8z"/>
                                                                <path d="M1007.1,308c0.2-0.1,0.4-0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0.1,0.4-0.1,0.7-0.4,0.9c-0.4,0.3-0.6,0.2-0.7-0.3
		C1006.7,308.6,1006.8,308.3,1007.1,308z"/>
                                                                <path d="M1025.8,310.9c-2.9-0.1-5.9-0.4-8.8-0.5c-0.3,0-0.4-0.2-0.5-0.4c0-0.1,0-0.2,0-0.4c0-0.2,0.1-0.3,0.3-0.3
		l10.4-0.5c0.3,0,0.5,0.2,0.6,0.5c0,0.1,0,0.1,0,0.2C1027.5,310.5,1026.7,311,1025.8,310.9z"/>
                                                                <path d="M1046.4,312.7c-0.2-0.1-0.2-0.3-0.1-0.5c0.1-0.1,0.2-0.2,0.3-0.2l1.8-0.3c0.2,0,0.4,0.1,0.4,0.3
		c0,0.1,0,0.2-0.1,0.3C1048.1,313.1,1047.4,313.2,1046.4,312.7z"/>
                                                                <path d="M1043,314.5c0.4-0.1,0.7,0,1,0.3c0.2,0.2,0.2,0.5,0,0.7c-3.5,4.1-7,8.3-10.3,12.5c-1.3,1.7-2.7,2.1-4.7,1.9
		c-0.2,0-0.4-0.2-0.4-0.5c0-0.1,0-0.1,0.1-0.2c4-4.9,7.9-9.8,11.9-14.7c0.4-0.5,1-0.5,1.7-0.1C1042.5,314.6,1042.8,314.6,1043,314.5
		z"/>
                                                                <path d="M1177.2,327.9l9.8,0.3c0.2,0,0.4,0.2,0.4,0.4l0,0.2c0,0.2-0.2,0.4-0.4,0.4l-9.8-0.3c-0.2,0-0.4-0.2-0.4-0.4
		l0-0.2C1176.8,328.1,1177,327.9,1177.2,327.9z"/>
                                                                <path d="M1170.6,335.6l4.2-5.4c0.1-0.1,0.2-0.2,0.4-0.2l28.3,2.2c0.3,0,0.5,0.2,0.4,0.5c0,0.1,0,0.2-0.1,0.2l-3.9,5.4
		c-0.1,0.1-0.2,0.2-0.4,0.2l-28.6-2.1c-0.3,0-0.5-0.2-0.4-0.5C1170.5,335.8,1170.5,335.7,1170.6,335.6z"/>
                                                                <path d="M1021.1,332.1l10.6-0.6c0.3,0,0.5,0.2,0.5,0.5l0,0.1c0,0.3-0.2,0.5-0.5,0.5l-10.6,0.6c-0.3,0-0.5-0.2-0.5-0.5
		l0-0.1C1020.6,332.4,1020.8,332.1,1021.1,332.1z"/>
                                                                <path d="M978.9,333.6l29-1.1c0.3,0,0.5,0.2,0.5,0.5l0,0.2c0,0.3-0.2,0.5-0.5,0.5l-29,1.1c-0.3,0-0.5-0.2-0.5-0.5l0-0.2
		C978.4,333.9,978.7,333.6,978.9,333.6z"/>
                                                                <path d="M1015.1,339c0.9-1.2,2.1-2.7,3.4-3.9c0.3-0.2,0.6-0.4,1.1-0.4c3.5-0.2,6.9-0.3,10.3-0.4c0.3,0,0.5,0.2,0.5,0.4
		c0,0.1,0,0.2-0.1,0.3l-3.3,3.8c-0.1,0.2-0.3,0.2-0.5,0.2l-11,0.5c-0.2,0-0.4-0.2-0.5-0.4C1015,339.1,1015,339,1015.1,339z"/>
                                                                <path d="M948.2,366.9c-0.8,1.2-1.8,1.7-3.3,1.6c-0.6-0.1-0.6-0.3-0.3-0.8l27.2-32.4c0.5-0.6,0.7-0.5,0.7,0.3l-0.2,8.1
		c0,0.3,0.3,0.6,0.6,0.6c0,0,0,0,0,0l3.6,0c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.2-0.1,0.3l-17.1,20.5c-0.1,0.1-0.3,0.2-0.4,0.2
		l-10.3,0.6C948.7,366.4,948.5,366.6,948.2,366.9z"/>
                                                                <path d="M1004.4,338c-0.8,1.3-1.7,2.1-3.3,2.1c-6.2,0.3-12.4,0.5-18.5,0.6c-0.4,0-0.6-0.2-0.6-0.6v-3.1
		c0-0.6,0.3-0.8,0.8-0.9l22.8-1.1c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.2-0.1,0.2c-0.2,0.5-0.6,1-1.1,1.3
		C1004.8,337.5,1004.6,337.7,1004.4,338z"/>
                                                                <path d="M979,336.5c0.4-0.5,0.8-0.5,1.1,0c0.1,0.3,0.1,0.6-0.2,0.8c-0.2,0.1-0.5,0.1-0.6-0.1l-0.3-0.4
		C979,336.7,979,336.6,979,336.5z"/>
                                                                <path d="M966.4,338.6L966.4,338.6c0.2,0.1,0.2,0.3,0.1,0.5L814.9,519.6c-0.1,0.1-0.3,0.2-0.5,0l0,0
		c-0.1-0.1-0.2-0.3,0-0.5L966,338.6C966.1,338.5,966.3,338.4,966.4,338.6z"/>
                                                                <path d="M978.9,339.1c0.2,0.3,0.1,0.6-0.3,0.9c-0.2,0.1-0.4,0.1-0.5-0.1c0-0.1-0.1-0.1-0.1-0.2l-0.1-0.8
		c0,0,0-0.1,0-0.1c0.4-0.3,0.6-0.3,0.7-0.1C978.8,338.7,978.8,338.9,978.9,339.1z"/>
                                                                <path d="M979.2,341.1c0.1-0.4,0.3-0.5,0.6-0.2l0.4,0.4c0.2,0.2,0.2,0.4,0,0.5l-0.6,0.5c-0.2,0.1-0.4,0.1-0.5,0
		c-0.1-0.1-0.1-0.2-0.1-0.4L979.2,341.1z"/>

                                                                <ellipse transform="matrix(0.9987 -5.059294e-02 5.059294e-02 0.9987 -15.999 51.9841)" cx="1018.8" cy="342" rx="6.1" ry="0.7"/>
                                                                <path d="M992.1,342.7c2.6-0.5,5.3-0.7,8-0.6c0.3,0,0.4,0.1,0.3,0.4c-0.1,0.9-0.5,1.2-1.2,1.2c-2.4-0.2-4.7-0.4-7.1-0.6
		C991.2,343.1,991.2,342.9,992.1,342.7z"/>
                                                                <path d="M1220.9,342.1l21.8,56.4c0.1,0.2,0,0.3-0.1,0.5l-2.7,3.9c-0.2,0.2-0.5,0.3-0.7,0.1c-0.1-0.1-0.1-0.1-0.2-0.2
		l-21.9-56.3c-0.1-0.2,0-0.3,0.1-0.5l2.8-4.1c0.2-0.2,0.5-0.3,0.7-0.1C1220.8,342,1220.9,342.1,1220.9,342.1z"/>
                                                                <path d="M1017.3,346.7c0.2,0,0.4,0.1,0.5,0.2c0.3,0.2,0.3,0.4,0.1,0.7c-4.3,5-8.4,10.1-12.5,15.4
		c-1.4,1.8-2.7,2.3-4.9,1.8c-0.4-0.1-0.4-0.3-0.2-0.6c4.4-5.5,8.9-11,13.4-16.5c0.7-0.9,1.6-1.2,2.5-1
		C1016.5,346.6,1016.8,346.6,1017.3,346.7z"/>
                                                                <path d="M1229.8,393c-0.3-0.1-0.5-0.3-0.6-0.6c-5.1-12.7-10.1-25.5-15.1-38.4c-0.9-2.3-0.3-4,1.6-5.4
		c0.3-0.3,0.6-0.2,0.8,0.2l17.2,44.4c0.1,0.3,0,0.6-0.3,0.7c-0.1,0-0.2,0-0.3,0L1229.8,393z"/>
                                                                <path d="M1150.4,363.9l13,0.5c0.2,0,0.3,0.1,0.3,0.3l0,0.3c0,0.2-0.1,0.3-0.3,0.3l-13-0.5c-0.2,0-0.3-0.1-0.3-0.3
		l0-0.3C1150.1,364,1150.3,363.9,1150.4,363.9z"/>
                                                                <path d="M992.7,366.7l10.6-0.6c0.4,0,0.7,0.3,0.7,0.6l0,0c0,0.4-0.3,0.7-0.6,0.7l-10.6,0.6c-0.4,0-0.7-0.3-0.7-0.6l0,0
		C992.1,367.1,992.4,366.8,992.7,366.7z"/>
                                                                <path d="M1143.8,372.1l4.2-5.8c0.1-0.1,0.3-0.2,0.4-0.2l29.5,1.5c0.3,0,0.5,0.3,0.5,0.5c0,0.1,0,0.2-0.1,0.3l-4.1,5.5
		c-0.1,0.1-0.3,0.2-0.4,0.2l-29.7-1.2c-0.3,0-0.5-0.3-0.5-0.5C1143.7,372.3,1143.7,372.2,1143.8,372.1z"/>
                                                                <path d="M978.3,367.9c-9.5,0.2-19.1,1.4-28.6,0.7c-0.1,0-0.2-0.1-0.2-0.2c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.3
		c0-0.1,0.1-0.2,0.2-0.2c4.5-0.1,8.8-0.1,13.5-0.5c5.4-0.5,10.4-0.5,15,0C981.4,367.7,981.4,367.9,978.3,367.9z"/>
                                                                <path d="M989.4,370.4c0.5-0.6,1.1-1.2,2-1.2c3.3,0,6.7-0.1,10.2-0.2c0.3,0,0.4,0.1,0.3,0.4c-2.2,5.9-8.5,4.9-14.8,4.7
		c-0.4,0-0.5-0.2-0.3-0.5C987.5,372.5,988.5,371.5,989.4,370.4z"/>
                                                                <path d="M944.4,378.5l3.8,0c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.3l-17.9,21.5c-0.4,0.5-1,0.8-1.7,0.8h-9.8
		c-0.3,0-0.5,0.2-0.6,0.5c-0.4,1.7-1.5,2.4-3.4,2c-0.3-0.1-0.4-0.3-0.4-0.6c0-0.1,0.1-0.1,0.1-0.2l27.9-33.3
		c0.2-0.2,0.5-0.2,0.7-0.1c0.1,0.1,0.2,0.2,0.2,0.4v7.6C943.8,378.2,944,378.5,944.4,378.5z"/>
                                                                <path d="M974.2,374.7l-20.2,0.2c-0.3,0-0.5-0.2-0.5-0.5c0,0,0,0,0,0l0-3.5c0-0.2,0.2-0.4,0.4-0.5l23.6-0.6
		c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.2-0.1,0.3l-3.3,4C974.5,374.6,974.4,374.7,974.2,374.7z"/>
                                                                <path d="M950.5,370.4c0.3-0.4,0.6-0.5,1-0.3c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.4,0.1,0.7-0.1,1.1
		c-0.1,0.3-0.5,0.4-0.7,0.2c0,0-0.1-0.1-0.1-0.1l-0.4-0.4C950.4,371,950.3,370.6,950.5,370.4z"/>
                                                                <path d="M886.8,372.1l5,10.1c0.1,0.2,0.1,0.5-0.1,0.6l-9.7,11.4c-0.2,0.3-0.6,0.3-0.8,0.1c-0.1-0.1-0.1-0.1-0.1-0.2
		l-5.2-10.1c-0.1-0.2-0.1-0.5,0.1-0.7l9.9-11.3c0.2-0.3,0.6-0.3,0.8-0.1C886.7,372,886.8,372,886.8,372.1z"/>
                                                                <path d="M951.6,376.5c-0.1,0.2-0.4,0.3-0.6,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c-0.2-0.5-0.1-1,0.4-1.2
		c0.2-0.1,0.6-0.1,0.7,0.2c0.1,0.1,0.1,0.3,0,0.5L951.6,376.5z"/>
                                                                <path d="M968.4,377.5c-0.8,0.2-1.4,0-2-0.6c-0.1-0.1-0.1-0.2,0-0.3c0,0,0.1-0.1,0.1-0.1l5.2-0.4c0.3,0,0.6,0.2,0.6,0.6
		c0,0.2-0.1,0.4-0.2,0.5c-0.8,0.6-1.7,0.7-2.8,0.3C969.1,377.5,968.8,377.5,968.4,377.5z"/>
                                                                <path d="M985.8,382.2c1.5-1.8,2.2-3.1,5.1-1.9c0.1,0.1,0.2,0.2,0.2,0.4c0,0,0,0,0,0.1l-15.8,19.6
		c-0.2,0.2-0.4,0.3-0.6,0.3l-3.2,0.3c-0.2,0-0.4-0.1-0.5-0.3c0-0.1,0-0.2,0.1-0.3C975.8,394.3,980.8,388.2,985.8,382.2z"/>
                                                                <path d="M1186.4,389.7l4.4-6.2c0.1-0.2,0.3-0.2,0.5-0.2l43.2,12.9c0.1,0,0.2,0.1,0.3,0.3l2.7,7.1
		c0.1,0.2,0,0.5-0.3,0.6c-0.1,0-0.2,0-0.3,0l-50.4-13.8c-0.2-0.1-0.4-0.3-0.3-0.5C1186.4,389.8,1186.4,389.8,1186.4,389.7z"/>
                                                                <path d="M1225.5,391.4c-0.1-0.2-0.1-0.5,0.1-0.6c0.1-0.1,0.3-0.1,0.4-0.1c0.7,0.3,0.9,0.7,0.7,1.3
		c-0.1,0.2-0.2,0.3-0.4,0.3c-0.1,0-0.1-0.1-0.2-0.1L1225.5,391.4z"/>
                                                                <path d="M1126.1,396.2c0.6-1.1,2.2-3.5,3.6-3.6c10.1-0.3,20.3-0.4,30.6-0.5c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.1,0,0.2
		c-1,2.2-2.2,3.6-4.8,3.6c-9.8,0.1-19.6,0.2-29.4,0.3C1126.1,396.8,1125.9,396.6,1126.1,396.2z"/>
                                                                <path d="M1184.6,393.3l7,1.8c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2-0.2,0.3-0.4,0.3l-8.5,0.2c-0.3,0-0.5-0.2-0.6-0.5
		c0-0.1,0-0.2,0.1-0.3l1.2-1.6C1183.9,393.3,1184.2,393.2,1184.6,393.3z"/>
                                                                <path d="M1222.7,404.5l-45.7,0.3c-0.9,0-1.1-0.4-0.6-1.1l4-5.6c0.2-0.3,0.5-0.5,0.9-0.5c7.2-0.2,15.2-1,22.2,0.8
		c6.5,1.7,13,3.4,19.3,5c0.3,0.1,0.4,0.4,0.3,0.6C1223.1,404.4,1223,404.5,1222.7,404.5z"/>
                                                                <path d="M1140.7,400.1c-5.5,0-10.9-0.1-16.2-0.1c-0.3,0-0.5-0.2-0.5-0.5c0-0.1,0-0.1,0-0.2c0.3-0.7,1-1.1,2-1
		c10.1,0.1,20.1,0,30.2-0.1c0.6,0,0.8,0.2,0.4,0.7l-2.6,3.4c-0.3,0.4-0.6,0.5-1.1,0.5h-10.2c-0.3,0-0.5-0.2-0.5-0.5c0,0,0-0.1,0-0.1
		C1142.5,400.8,1142,400.1,1140.7,400.1z"/>
                                                                <path d="M1122.7,401l17.5,0.5c0.2,0,0.3,0.1,0.3,0.3l0,0.7c0,0.2-0.1,0.3-0.3,0.3l-17.5-0.5c-0.2,0-0.3-0.1-0.3-0.3
		l0-0.7C1122.4,401.1,1122.5,401,1122.7,401z"/>
                                                                <path d="M974.5,403.8l-11.1,0.2c-0.5,0-0.6-0.2-0.3-0.6l0.6-0.9c0.2-0.3,0.5-0.4,0.8-0.4c3.4,0.5,5.6,0.5,6.8,0.3
		c1.2-0.3,2.4-0.3,3.4,0c0.4,0.1,0.6,0.5,0.5,0.9C975.1,403.6,974.8,403.8,974.5,403.8z"/>
                                                                <path d="M920.4,403l29.4-0.1c0.2,0,0.4,0.2,0.4,0.4l0,0.2c0,0.2-0.2,0.4-0.4,0.4l-29.4,0.1c-0.2,0-0.4-0.2-0.4-0.4
		l0-0.2C920,403.2,920.2,403,920.4,403z"/>
                                                                <path d="M1115.8,410.1l4.6-6.1c0.1-0.1,0.2-0.1,0.3-0.1l31.1,0.7c0.2,0,0.3,0.2,0.3,0.4c0,0.1,0,0.1-0.1,0.2l-4.3,5.9
		c-0.1,0.1-0.2,0.1-0.3,0.1l-31.4-0.5c-0.2,0-0.3-0.2-0.3-0.3C1115.7,410.3,1115.8,410.2,1115.8,410.1z"/>
                                                                <path d="M914.7,414h3.8c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.3-0.1,0.3L900,437.5c-0.2,0.2-0.5,0.4-0.8,0.4l-10.7,0.3
		c-0.3,0-0.6,0.2-0.7,0.5c-0.5,1.7-1.8,2.3-3.9,1.8c-0.3-0.1-0.4-0.2-0.2-0.5l29.3-34.9c0.2-0.2,0.5-0.3,0.7-0.1
		c0.1,0.1,0.2,0.3,0.2,0.4l-0.1,7.8C914,413.7,914.3,414,914.7,414C914.7,414,914.7,414,914.7,414z"/>
                                                                <path d="M972.9,405.7c-3.1,6.7-9.2,3.7-15,4.6c-0.3,0-0.5-0.1-0.5-0.4c0-0.1,0-0.3,0.1-0.4l3.2-3.8
		c0.2-0.3,0.6-0.5,1-0.5l10.9-0.1C972.9,405.1,973,405.3,972.9,405.7z"/>
                                                                <path d="M920.9,406.4c-0.3-0.4-0.2-0.7,0.3-0.9c0.6-0.2,1,0,1.4,0.5c0.2,0.3,0.2,0.7-0.1,0.9c-0.1,0.1-0.2,0.1-0.4,0.1
		C921.6,407.1,921.2,406.8,920.9,406.4z"/>
                                                                <path d="M947.1,408c-0.8,0.8-1.3,2.1-2.7,2.1c-6.5,0.2-13.1,0.4-19.6,0.3c-0.4,0-0.5-0.2-0.5-0.6l0-3.8
		c0-0.4,0.3-0.6,0.6-0.6h23.4c0.5,0,0.6,0.2,0.3,0.6C948.2,406.8,947.7,407.5,947.1,408z"/>
                                                                <path d="M1110.1,407.2c-0.1-0.2-0.1-0.5,0.1-0.8c0.1-0.2,0.4-0.3,0.7-0.2c0.2,0.1,0.3,0.4,0.2,0.6l-0.3,1
		c0,0.1-0.1,0.2-0.2,0.1c-0.1,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.2-0.1-0.3C1110.2,407.4,1110.2,407.3,1110.1,407.2z"/>
                                                                <path d="M1165.7,407.5c0.1-0.8,0.3-1.5,0.5-1.4c0.2,0.1,0.3,0.7,0.3,1.4c0,0.3,0,0.6,0,0.9c0,0.2-0.2,0.3-0.4,0.3
		c-0.2,0-0.3-0.1-0.3-0.3C1165.7,408.1,1165.7,407.8,1165.7,407.5z"/>
                                                                <path d="M920.5,408c0.3-0.1,0.5,0.1,0.6,0.3c0.1,0.2,0,0.4-0.1,0.5l-0.6,0.6c-0.2,0.2-0.5,0.2-0.7,0c0,0,0,0-0.1-0.1
		C919.3,408.7,919.5,408.3,920.5,408z"/>
                                                                <path d="M921,411.7c0.3-0.7,0.8-1,1.6-0.9c0.2,0,0.3,0.2,0.3,0.4c0,0.1-0.1,0.2-0.2,0.3l-1.2,0.6
		C921,412.3,920.9,412.2,921,411.7z"/>
                                                                <path d="M1139.1,414.2c0.2-0.6,0.7-1,1.3-1h4.8c0.7,0,0.9,0.3,0.4,0.9l-9,11.7c-0.3,0.4-0.8,0.4-1.2,0.2
		c-0.3-0.2-0.4-0.6-0.3-1L1139.1,414.2z"/>
                                                                <path d="M958.4,421.2c-0.1,0.1-0.2,0.1-0.2,0c0,0,0,0,0,0l0,0c-1.4-1.1-1.6-3.2-0.5-4.5c0,0,0,0,0,0l1-1.2
		c1.1-1.4,3.2-1.6,4.5-0.5l0.1,0c0.1,0.1,0.1,0.2,0,0.2c0,0,0,0,0,0L958.4,421.2z"/>
                                                                <path d="M953.3,415.7l55.2,112.8c0.1,0.2,0.1,0.4-0.1,0.5l-3.2,4.3c-0.2,0.2-0.5,0.3-0.7,0.1c-0.1,0-0.1-0.1-0.2-0.2
		l-55.7-112.9c-0.1-0.2-0.1-0.4,0.1-0.6l3.6-4.2c0.2-0.2,0.5-0.2,0.7,0C953.3,415.5,953.3,415.6,953.3,415.7z"/>
                                                                <path d="M754.5,417l57.9,103.3c0.1,0.2,0.1,0.5-0.1,0.7l-3.2,3.7c-0.2,0.3-0.6,0.3-0.9,0.1c-0.1,0-0.1-0.1-0.1-0.2
		L750,421.6c-0.1-0.2-0.1-0.5,0.1-0.7l3.4-3.9c0.2-0.3,0.6-0.3,0.9-0.1C754.5,416.9,754.5,417,754.5,417z"/>
                                                                <path d="M946.5,422.8l0.5-0.2c0.5-0.2,1.1,0,1.4,0.5l4.1,8.4c0.2,0.5,0,1.1-0.5,1.4l-0.5,0.2c-0.5,0.2-1.1,0-1.4-0.5
		l-4.1-8.4C945.8,423.7,946,423.1,946.5,422.8z"/>
                                                                <path d="M748,423.3L748,423.3c0.5-0.3,1-0.1,1.2,0.3l4.5,8c0.2,0.4,0.1,0.9-0.3,1.1l-0.1,0c-0.4,0.2-0.9,0.1-1.1-0.3
		l-4.5-8C747.5,424.1,747.6,423.6,748,423.3z"/>
                                                                <path d="M1133.3,429.3c0.1-0.1,0.2,0,0.3,0c0,0,0,0,0,0l0.1,0.1c0.8,1.1-1.7,4.2-5.6,6.9c0,0,0,0,0,0l-2,1.4
		c-3.9,2.7-7.7,4-8.4,2.9c0,0,0,0,0,0l-0.1-0.1c-0.1-0.1,0-0.2,0-0.3c0,0,0,0,0,0L1133.3,429.3z"/>
                                                                <path d="M946.5,430.3c0.1-0.1,0.2-0.1,0.3,0c0,0,0,0,0,0l0.1,0.1c1.4,1.2,1.6,3.3,0.4,4.7c0,0,0,0,0,0l-1.7,2
		c-1.2,1.4-3.3,1.7-4.7,0.5c0,0,0,0,0,0l-0.1-0.1c-0.1-0.1-0.1-0.2,0-0.3c0,0,0,0,0,0L946.5,430.3z"/>
                                                                <path d="M1127.5,439.2c-0.4,1.1-1.1,1.7-2.2,1.8c-0.2,0-0.5-0.1-0.5-0.4c0-0.2,0.1-0.3,0.2-0.4l2.1-1.3
		C1127.5,438.7,1127.7,438.8,1127.5,439.2z"/>
                                                                <path d="M933.8,439.6c3.5-0.1,7.2,0.1,10.7,0.2c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.1,0,0.2c-0.1,0.3-0.4,0.5-0.7,0.5
		c-3.7,0.1-7.4,0.2-11.1,0.1c-0.4,0-0.5-0.2-0.3-0.6c0.1-0.2,0.3-0.4,0.4-0.6C933.3,439.7,933.5,439.6,933.8,439.6z"/>
                                                                <path d="M889.9,439.7l29.8,0.1c0.3,0,0.6,0.3,0.6,0.6l0,0c0,0.3-0.3,0.6-0.6,0.6l-29.8-0.1c-0.3,0-0.6-0.3-0.6-0.6l0,0
		C889.3,440,889.6,439.7,889.9,439.7z"/>
                                                                <path d="M1094,440.3l17.6-0.2c0.2,0,0.4,0.2,0.4,0.4l0,0.2c0,0.2-0.2,0.4-0.4,0.4l-17.6,0.2c-0.2,0-0.4-0.2-0.4-0.4
		l0-0.2C1093.6,440.5,1093.7,440.3,1094,440.3z"/>
                                                                <path d="M884.2,450.7l4.1,0c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.2l-19.9,23.8c-0.2,0.3-0.5,0.4-0.9,0.4l-11.1,0.7
		c-0.3,0-0.6,0.2-0.7,0.5c-0.5,1.6-1.7,2.1-3.5,1.6c-0.2-0.1-0.4-0.3-0.3-0.6c0-0.1,0-0.1,0.1-0.2l30-35.8c0.2-0.3,0.6-0.3,0.9-0.1
		c0.1,0.1,0.2,0.3,0.2,0.5v7.8C883.5,450.4,883.8,450.7,884.2,450.7L884.2,450.7z"/>
                                                                <path d="M891.3,444.2l-1-1c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0c0.5,0.5,0.7,1,0.7,1.7c0,0.3-0.2,0.5-0.5,0.5
		C891.5,444.3,891.4,444.3,891.3,444.2z"/>
                                                                <path d="M893.5,446.6l0-3.7c0-0.4,0.3-0.6,0.6-0.6h23.7c0.4,0,0.6,0.3,0.6,0.6c0,0.2-0.1,0.3-0.2,0.4l-3.5,4
		c-0.1,0.1-0.3,0.2-0.5,0.2l-20.2-0.3C893.8,447.2,893.5,447,893.5,446.6z"/>
                                                                <path d="M927.2,447.1c0.5-1.3,2.4-3.4,3.5-4.3c0.2-0.2,0.5-0.3,0.8-0.3h11.1c0.6,0,0.7,0.2,0.3,0.7l-3.2,3.8
		c-0.3,0.3-0.6,0.5-1.1,0.5l-11.2,0.1C927.1,447.6,927,447.4,927.2,447.1z"/>
                                                                <path d="M1124.5,443.5l-4.4,6c-0.1,0.1-0.2,0.2-0.4,0.2l-32.4,0.7c-0.3,0-0.5-0.2-0.5-0.5c0-0.1,0-0.2,0.1-0.3l4.7-6.4
		c0.1-0.1,0.2-0.2,0.4-0.2l32.2-0.3c0.3,0,0.5,0.2,0.5,0.5C1124.6,443.3,1124.6,443.4,1124.5,443.5z"/>
                                                                <path d="M889.8,445c0.5,0.4,0.5,0.8,0,1.1c-0.3,0.1-0.6,0.1-0.8-0.2c-0.1-0.2-0.1-0.5,0.1-0.6l0.4-0.3
		C889.5,444.9,889.6,445,889.8,445z"/>
                                                                <path d="M1138.9,447c-0.2,0-0.4-0.1-0.5-0.4c0-0.1,0-0.2,0.1-0.3l0.3-0.6c0.1-0.2,0.4-0.3,0.7-0.2
		c0.1,0.1,0.2,0.2,0.2,0.3C1139.9,446.5,1139.6,446.9,1138.9,447z"/>
                                                                <path d="M1081.1,446c0.1-0.1,0.4-0.2,0.5,0c0.1,0.1,0.1,0.2,0.1,0.3v0.9c0,0.2-0.2,0.4-0.4,0.4c-0.1,0-0.3-0.1-0.4-0.2
		C1080.7,447,1080.7,446.5,1081.1,446z"/>
                                                                <path d="M821,447.8l5.5,10.5c0.1,0.2,0.1,0.4-0.1,0.6l-10.8,12.6c-0.2,0.2-0.5,0.2-0.7,0.1c0,0-0.1-0.1-0.1-0.1
		l-5.8-10.5c-0.1-0.2-0.1-0.4,0.1-0.6l11-12.7c0.2-0.2,0.5-0.3,0.7-0.1C820.9,447.7,821,447.7,821,447.8z"/>
                                                                <path d="M890.4,448.9c-0.3-0.2-0.3-0.4,0-0.6l1-0.7c0.3-0.2,0.8-0.1,1,0.2c0.2,0.3,0.2,0.6-0.1,0.9
		C891.7,449.2,891.1,449.3,890.4,448.9z"/>
                                                                <path d="M934.2,450.9L914.6,475c-0.1,0.1-0.3,0.2-0.4,0.2l-3,0c-0.3,0-0.6-0.2-0.6-0.5c0-0.1,0-0.3,0.1-0.4l19.4-23.8
		c0.1-0.1,0.2-0.2,0.4-0.2l3.2-0.3c0.3,0,0.6,0.2,0.6,0.5C934.4,450.7,934.3,450.8,934.2,450.9z"/>
                                                                <path d="M1066.6,477c1.6-2.1,3-3.6,4.1-4.3c9.5-6.5,19-13,28.4-19.7c1.7-1.2,3.5-1.6,5.5-1.2c0.2,0,0.3,0.2,0.2,0.4
		c0,0.1-0.1,0.1-0.1,0.2c-12.3,8.3-24.6,16.8-36.7,25.3c-0.5,0.4-1,0.5-1.2,0.3C1066.4,477.7,1066.3,477.4,1066.6,477z"/>
                                                                <path d="M1074.5,478.5c-1.3-0.2-2.3-0.2-3.6-0.3c-0.5-0.1-0.6-0.2-0.1-0.5c11.9-8.2,23.8-16.4,35.8-24.6
		c2.1-1.5,4.6-1.8,7.3-1c0.3,0.1,0.3,0.2,0.1,0.4c-12.5,8.6-25.1,17.3-37.7,25.9C1075.8,478.6,1075.2,478.7,1074.5,478.5z"/>
                                                                <path d="M1236,808.3c-0.4-0.1-0.6-0.3-0.5-0.7l49.3-355.1c0-0.3,0.2-0.5,0.5-0.5l4.8,0.1c0.5,0,0.7,0.3,0.6,0.7
		c-1.3,9-2.5,17.9-3.8,26.9c-14.3,104.9-28.7,209.7-43.2,314.6c-0.2,1.3-0.7,2.9-1.5,4.6c-1.4,2.9-2.1,6-2.1,9.3
		c0,0.3-0.3,0.6-0.6,0.6c0,0-0.1,0-0.1,0L1236,808.3z"/>
                                                                <path d="M1083,461.6c-0.2-3.8,0.2-11.6,5.8-9.4c0.3,0.1,0.4,0.3,0.4,0.6l-0.8,5.2c-0.1,0.3-0.2,0.6-0.5,0.9l-4.3,3
		C1083.2,462.1,1083,462,1083,461.6z"/>
                                                                <path d="M1090.3,456.5c0.1-1.7,0.3-5.4,3-4.3c0.7,0.3,1.1,1.1,0.8,1.8c0,0.1-0.1,0.2-0.2,0.3c-0.8,1.1-1.8,1.9-3.1,2.6
		C1090.4,457,1090.3,456.9,1090.3,456.5z"/>
                                                                <path d="M1245.5,796c-0.2,0-0.3-0.2-0.2-0.3c13.8-99.5,27.6-199.1,41.3-298.7c1.9-13.6,3.7-29,5.7-43.6
		c0-0.3,0.2-0.5,0.6-0.5c1.8,0.1,2.5,1,2.3,2.8c-15.7,113.2-31.3,226.5-46.9,339.9c-0.1,0.5-0.5,0.8-1,0.7c0,0,0,0,0,0L1245.5,796z"
                                                                />
                                                                <path d="M1087.3,478.7c0-3.6-0.1-7.5,3.7-8.2c0.3-0.1,0.4,0.1,0.4,0.4l-1.2,7.6c-0.1,0.4-0.3,0.6-0.7,0.6l-1.6,0.1
		C1087.5,479.3,1087.3,479.1,1087.3,478.7z"/>
                                                                <path d="M1080.5,477.5l4.5-3.3c0.2-0.2,0.6-0.1,0.7,0.1c0.1,0.1,0.1,0.2,0.1,0.3l-0.2,3.4c0,0.3-0.3,0.5-0.5,0.5
		l-4.3-0.1c-0.3,0-0.5-0.2-0.5-0.5C1080.3,477.7,1080.4,477.6,1080.5,477.5z"/>
                                                                <path d="M914.6,477.8c-1,3.5-10.5,1.5-13.2,1.9c-0.4,0.1-0.6-0.1-0.4-0.5c0.6-1.8,1.9-2.6,4-2.5c2.4,0.1,5.3,0.1,8.8,0
		c0.5,0,0.9,0.3,0.9,0.8C914.7,477.6,914.7,477.7,914.6,477.8z"/>
                                                                <path d="M858.4,478.6c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.2-0.3-0.1-0.5c0.1-0.1,0.2-0.2,0.3-0.2l31.6-1
		c0.5,0,0.7,0.2,0.6,0.7c-0.2,0.9-1.2,1.6-2.2,1.7C878,479.3,868.2,479.1,858.4,478.6z"/>
                                                                <path d="M819.4,518.2c-0.3,0-0.5-0.2-0.4-0.5c0-0.1,0-0.2,0.1-0.3l31.8-37.9c0.1-0.2,0.4-0.2,0.6,0
		c0.1,0.1,0.1,0.2,0.1,0.3v8.2c0,0.6,0.4,1,1,1h4c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.2l-20.6,24.7c-0.2,0.3-0.5,0.5-0.9,0.5
		c-3.1,0.4-6.7,0.6-10.8,0.6c-1.3,0-1.8,0.7-1.7,2c0.1,0.5-0.2,0.8-0.7,0.8L819.4,518.2z"/>
                                                                <path d="M859,480.2h0.5c0.5,0,0.8,0.2,0.8,0.8v1.2c0,0.2-0.2,0.4-0.4,0.4c-0.1,0-0.2,0-0.3-0.1l-1.1-1.1
		c-0.2-0.3-0.2-0.7,0-0.9C858.6,480.3,858.8,480.2,859,480.2z"/>
                                                                <path d="M1064.4,480.8l18.6-0.7c0.3,0,0.5,0.2,0.6,0.5l0,0.1c0,0.3-0.2,0.5-0.5,0.6l-18.6,0.7c-0.3,0-0.5-0.2-0.6-0.5
		l0-0.1C1063.8,481.1,1064.1,480.8,1064.4,480.8z"/>
                                                                <path d="M862,484.9l0-3.9c0-0.3,0.3-0.6,0.6-0.6c0,0,0,0,0,0l23.8,0.5c0.3,0,0.6,0.3,0.5,0.6c0,0.1-0.1,0.3-0.1,0.4
		l-3.4,3.8c-0.1,0.1-0.3,0.2-0.4,0.2l-20.3-0.4C862.3,485.5,862,485.2,862,484.9z"/>
                                                                <path d="M895.5,485.4l3.7-4.1c0.1-0.1,0.2-0.2,0.3-0.1l11.5,0.2c0.2,0,0.4,0.2,0.4,0.5c0,0.1,0,0.2-0.1,0.3l-3.5,4.2
		c-0.1,0.1-0.2,0.2-0.4,0.2l-11.7-0.3c-0.2,0-0.5-0.2-0.5-0.4C895.4,485.6,895.4,485.5,895.5,485.4z"/>
                                                                <path d="M1122,486.4l1.6-4.4c0.1-0.3,0.4-0.4,0.7-0.4l153.5,3c0.4,0,0.7,0.3,0.7,0.7c0,0,0,0.1,0,0.1l-0.8,4.7
		c0,0.3-0.3,0.6-0.7,0.6l-154.4-3.4c-0.4,0-0.7-0.3-0.7-0.7C1121.9,486.6,1121.9,486.5,1122,486.4z"/>
                                                                <path d="M1106.8,522.2l-5.2-1.9c-0.2-0.1-0.2-0.2-0.2-0.4c2.7-7.9,5.2-17,9.9-23.9c3.2-4.7,6.5-9.3,9.7-13.9
		c0.1-0.2,0.3-0.2,0.5-0.1c0.1,0.1,0.2,0.2,0.1,0.4l-14,39.3C1107.5,522.2,1107.1,522.4,1106.8,522.2z"/>
                                                                <path d="M1062,483.8l33.5-1.2c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.2-0.1,0.3l-4.6,6.3c-0.1,0.1-0.2,0.2-0.4,0.2l-33.8,1.5
		c-0.3,0-0.5-0.2-0.5-0.5c0-0.1,0-0.2,0.1-0.3l4.9-6.6C1061.7,483.8,1061.8,483.8,1062,483.8z"/>
                                                                <path d="M857.4,483c0.1-0.1,0.3-0.2,0.5,0c0,0,0.1,0.1,0.1,0.1l0.3,0.9c0.1,0.3,0,0.7-0.3,0.8c-0.3,0.1-0.7,0-0.8-0.3
		C856.9,484,857,483.5,857.4,483z"/>
                                                                <path d="M858.5,486.6l1.3-1.4c0.3-0.3,0.7-0.3,1-0.1c0.2,0.2,0.3,0.5,0.2,0.8c-0.6,1.2-1.4,1.6-2.3,1.2
		C858.4,487,858.4,486.8,858.5,486.6C858.4,486.6,858.5,486.6,858.5,486.6z"/>
                                                                <path d="M1110.3,485.6c0-0.2,0.2-0.4,0.5-0.4c0.1,0,0.3,0.1,0.3,0.2c0.3,0.5,0.2,0.9-0.2,1.3c-0.2,0.2-0.4,0.2-0.6,0
		c-0.1-0.1-0.2-0.2-0.1-0.4L1110.3,485.6z"/>
                                                                <path d="M1050.2,487.7c0-0.2,0.3-0.4,0.5-0.3c0.2,0,0.3,0.2,0.3,0.3c0.1,0.5,0,0.9-0.1,1.3c-0.1,0.2-0.4,0.3-0.6,0.2
		c-0.2-0.1-0.3-0.3-0.3-0.5L1050.2,487.7z"/>
                                                                <path d="M903.2,488c0.3,0,0.5,0.2,0.5,0.4c0,0.1,0,0.3-0.1,0.4c-6.4,7.8-12.7,15.7-19.1,23.7c-1.2,1.6-2.8,1.9-4.7,1
		c-0.3-0.2-0.4-0.4-0.2-0.7l19.6-24c0.2-0.2,0.5-0.4,0.8-0.4L903.2,488z"/>
                                                                <path d="M1084.7,492.8c0.1-0.7,0.5-1.1,1.3-1.1l1.6,0.1c0.6,0,0.8,0.3,0.6,0.9c-0.8,2.3-2.1,4.4-3.6,6.3
		c-0.1,0.2-0.3,0.2-0.5,0.1c-0.1-0.1-0.2-0.2-0.1-0.3L1084.7,492.8z"/>
                                                                <path d="M1077.9,492.6c0-0.2,0.2-0.3,0.3-0.3l4.2-0.4c0.4,0,0.6,0.2,0.6,0.6c-0.5,4.2-0.6,9.1-3,12.7
		c-1.4,2.1-2.9,4.2-4.5,6.2c-0.1,0.1-0.2,0.2-0.4,0.1c-0.1-0.1-0.1-0.2-0.1-0.3L1077.9,492.6z"/>
                                                                <path d="M868.2,519.1l3.3-3.8c0.1-0.1,0.2-0.1,0.3-0.1l12,0.4c0.2,0,0.3,0.2,0.3,0.3c0,0.1,0,0.1-0.1,0.2l-3.4,4.1
		c-0.1,0.1-0.2,0.1-0.3,0.1l-11.9-0.7c-0.2,0-0.3-0.2-0.3-0.3C868.1,519.2,868.1,519.2,868.2,519.1z"/>
                                                                <path d="M824.7,518.2c-0.2,0-0.4-0.2-0.4-0.5c0-0.1,0-0.2,0.1-0.3c0.2-0.2,0.5-0.3,0.9-0.3c11-0.4,22-0.9,32.9-1.7
		c0.3,0,0.5,0.2,0.5,0.4c0,0.1,0,0.3-0.1,0.4l-2.3,2.6c-0.2,0.2-0.5,0.3-0.8,0.3L824.7,518.2z"/>
                                                                <path d="M818.8,528.7l4.7,0.2c0.4,0,0.5,0.2,0.2,0.5l-21.5,25.8c-0.2,0.3-0.5,0.4-0.8,0.4l-11.9,1
		c-0.4,0-0.6,0.2-0.7,0.6c-0.5,2.1-1.9,2.7-4.3,1.8c-0.1,0-0.2-0.2-0.1-0.3c0,0,0,0,0-0.1l33.1-39.4c0.2-0.2,0.4-0.2,0.6,0
		c0.1,0.1,0.1,0.2,0.1,0.3l0,8.5C818.3,528.4,818.5,528.7,818.8,528.7z"/>
                                                                <path d="M827.2,521.9c-0.1,0.2-0.4,0.3-0.6,0.2c-0.1,0-0.1-0.1-0.1-0.1l-0.8-1.1c-0.2-0.3-0.2-0.7,0.1-1
		c0.2-0.1,0.4-0.2,0.6-0.1C827.6,520,827.8,520.7,827.2,521.9z"/>
                                                                <path d="M829.6,525c-0.3,0-0.6-0.3-0.6-0.7c0-0.1,0-0.1,0-0.2c0.4-1,0.4-2.1,0-3.1c-0.2-0.6,0-1,0.7-0.9l23.8,0.8
		c0.5,0,0.6,0.2,0.4,0.7c-1.3,2.6-2.9,4.2-6.1,4.2C842.3,525.6,836.2,525.4,829.6,525z"/>
                                                                <path d="M862.8,525.8l3.4-4.2c0.1-0.1,0.2-0.2,0.3-0.1l11.9,0.5c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.2l-3.5,4.1
		c-0.1,0.1-0.2,0.1-0.3,0.1l-11.8-0.4c-0.2,0-0.4-0.2-0.4-0.4C862.7,526,862.8,525.9,862.8,525.8z"/>
                                                                <path d="M1032.7,522.9c0-0.1,0.1-0.2,0.2-0.2l0.1,0c11.1-0.6,20.2-0.8,20.2-0.5c0,0,0,0,0,0l0,0.4c0,0.3-9,1-20.1,1.6
		l-0.1,0c-0.1,0-0.2-0.1-0.2-0.2c0,0,0,0,0,0L1032.7,522.9z"/>
                                                                <path d="M1096.5,527.5c-0.2,0-0.3-0.2-0.3-0.4c0,0,0,0,0,0l1.2-4.1c0.1-0.4,0.3-0.6,0.7-0.6c4-0.4,7.4,0.7,10.2,3.5
		c0.3,0.3,0.4,0.8,0.3,1.2l-101.5,286c0,0.1-0.2,0.2-0.3,0.2l-6.8-1c-0.3,0-0.5-0.3-0.5-0.7c0,0,0-0.1,0-0.1l0.6-1.6
		c0.1-0.3,0-0.6-0.2-0.7l-1.8-1.1c-0.4-0.2-0.5-0.6-0.4-1l97.9-274c0.1-0.3,0.4-0.5,0.7-0.6c1.1-0.2,1.9-0.9,2.4-1.9
		c0.1-0.3,0.2-0.6,0.1-0.9C1098.6,528.5,1097.8,527.8,1096.5,527.5z"/>
                                                                <path d="M824.1,522.9l0.4-0.4c0.1-0.1,0.2-0.1,0.3,0.1l0.3,0.9c0.1,0.3-0.1,0.5-0.3,0.6c-0.2,0.1-0.4,0-0.5-0.2
		l-0.2-0.4C823.9,523.2,823.9,523,824.1,522.9z"/>
                                                                <path d="M1030.9,526.1l35.2-2.3c0.2,0,0.4,0.1,0.4,0.3c0,0.1,0,0.2-0.1,0.2l-5,6.9c-0.1,0.1-0.2,0.1-0.3,0.2l-35.7,2.6
		c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.2l5.4-7.2C1030.7,526.2,1030.8,526.1,1030.9,526.1z"/>
                                                                <path d="M826.1,526.7c-0.3-0.3-0.4-0.7-0.2-1.1l0.7-1.3c0.4-0.6,0.6-0.6,0.8,0.1l0.2,1c0.1,0.3,0,0.6-0.2,0.9
		C827.1,527.1,826.6,527.2,826.1,526.7z"/>
                                                                <path d="M822.2,526.1c0.5-0.2,0.9-0.1,1.2,0.3c0.1,0.2,0,0.4-0.2,0.5c-0.1,0.1-0.2,0.1-0.3,0l-0.6-0.2
		c-0.2-0.1-0.3-0.3-0.2-0.5C822,526.2,822.1,526.2,822.2,526.1z"/>
                                                                <path d="M1081.2,526.9c0-0.2,0.1-0.4,0.3-0.5c0.2-0.1,0.4,0,0.5,0.2c0,0,0,0,0,0.1c0.1,0.5-0.1,0.9-0.6,1.1
		c-0.3,0.1-0.4,0.1-0.5-0.2C1080.9,527.4,1081,527.1,1081.2,526.9z"/>
                                                                <path d="M1162.1,619.9c0.1-0.1,0.2-0.2,0.3-0.3c36.5-30.7,73-61.4,109.4-92.1c0.2-0.2,0.5-0.2,0.6,0
		c0.1,0.1,0.1,0.2,0.1,0.4c-0.3,2.6-0.5,6.6-2.5,8.3c-72.9,61.1-145.9,122.2-218.8,183.2c-0.1,0.1-0.3,0.1-0.4,0
		c0-0.1-0.1-0.1-0.1-0.2l1-6.2c0-0.3,0.2-0.6,0.4-0.8c33.8-28.5,67.7-57,101.6-85.5c3.5-2.9,6.1-5.1,7.9-6.4
		C1161.7,620.4,1161.8,620.2,1162.1,619.9z"/>
                                                                <path d="M807.2,528.1L807.2,528.1c0.2,0.2,0.2,0.4,0.1,0.6L544.1,842.4c-0.1,0.2-0.4,0.2-0.6,0.1l0,0
		c-0.2-0.1-0.2-0.4-0.1-0.6l263.2-313.7C806.8,528,807,528,807.2,528.1z"/>
                                                                <path d="M866.2,529.4c1.1-1.3,2.6-1.5,4.4-0.7c0.3,0.2,0.4,0.4,0.1,0.7l-19.4,23.9c-0.2,0.3-0.5,0.4-0.9,0.4h-3
		c-0.3,0-0.6-0.2-0.6-0.5c0-0.1,0-0.3,0.1-0.4C853.4,545,859.8,537.2,866.2,529.4z"/>
                                                                <path d="M1089,529l1.9,2.7c0.1,0.2,0.2,0.4,0.1,0.6l-1.7,4.8c-0.1,0.3-0.5,0.5-0.8,0.4c-0.1,0-0.2-0.1-0.3-0.2
		l-2.7-3.9c-0.2-0.2-0.2-0.5,0-0.8l2.5-3.6c0.2-0.3,0.6-0.4,0.9-0.2C1088.9,528.9,1088.9,528.9,1089,529z"/>
                                                                <path d="M1093.4,530.9c0.6-1.4,1.5-2,2.8-1.6c0.1,0,0.2,0.2,0.2,0.3c0,0.1-0.1,0.1-0.2,0.2l-0.9,0.4
		c-0.3,0.1-0.5,0.4-0.6,0.7c-32.7,91.7-65.5,183.4-98.4,275c-0.1,0.3-0.2,0.5-0.4,0.7c-0.2,0.2-0.5,0.3-0.7,0.3
		c-0.3,0-0.6-0.2-0.6-0.5c0-0.1,0-0.1,0-0.2c1.1-3.4,2.3-6.8,3.6-10.2c30.3-84.4,60.5-168.8,90.6-253.3
		C1090.3,538.7,1091.8,534.8,1093.4,530.9z"/>
                                                                <path d="M750,529.8l6.1,10.5c0.1,0.2,0.1,0.5-0.1,0.7l-11.8,13.8c-0.2,0.3-0.6,0.3-0.9,0.1c0,0-0.1-0.1-0.1-0.1
		l-6.2-10.5c-0.1-0.2-0.1-0.5,0.1-0.7l11.9-13.7c0.2-0.3,0.6-0.3,0.9-0.1C749.9,529.7,749.9,529.8,750,529.8z"/>
                                                                <path d="M1018,531.2c0-0.3,0.3-0.6,0.6-0.6c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.2,1-0.3,1.4c-0.2,0.1-0.5,0.1-0.7-0.1
		c-0.1-0.1-0.1-0.2-0.1-0.3V531.2z"/>
                                                                <path d="M1003.5,536.7L1003.5,536.7c0.3,0.3,0.4,0.7,0.2,1l-258.4,348c-0.2,0.3-0.6,0.4-0.9,0.1l0,0
		c-0.3-0.2-0.4-0.6-0.1-0.9l258.4-348C1002.8,536.5,1003.2,536.5,1003.5,536.7z"/>
                                                                <path d="M1166,625.6l104.1-87.4c0.2-0.2,0.6-0.2,0.8,0.1l0.1,0.1c0.2,0.2,0.2,0.6-0.1,0.8l-104.1,87.4
		c-0.2,0.2-0.6,0.2-0.8-0.1l-0.1-0.1C1165.8,626.1,1165.8,625.8,1166,625.6z"/>
                                                                <path d="M1079.7,540.7c0-0.3,0.2-0.6,0.6-0.7c0.2-0.1,0.4,0,0.4,0.2c0,0.1,0,0.1,0,0.2c-1.7,10.7-3.3,21.4-5,32.1
		c-0.9,5.8-3.1,11-5,16.6c-0.1,0.2-0.3,0.3-0.5,0.2c-0.2-0.1-0.3-0.2-0.2-0.4c2.2-14.2,4.4-28.5,6.7-42.9
		C1076.8,544.7,1077.8,543,1079.7,540.7z"/>
                                                                <path d="M1103.7,548.5l54.3,71.7c0.2,0.2,0.1,0.6-0.1,0.8l-3.5,3c-0.2,0.2-0.6,0.2-0.8,0c0,0,0,0,0,0l-52.5-69.7
		c-0.1-0.1-0.1-0.3-0.1-0.5l1.8-5.1c0.1-0.3,0.4-0.4,0.7-0.3C1103.6,548.4,1103.7,548.4,1103.7,548.5z"/>
                                                                <path d="M1073.7,549.2c0.1-0.1,0.3-0.2,0.5,0c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.4c-2.3,15.3-4.7,30.6-7.1,45.9
		c-1.4,8.9-5.2,17.5-8.1,26.1c-1.4,4.1-1.8,4-1.1-0.3c3-18.4,6-37.8,9-58.2C1067.9,557.1,1069.9,553.6,1073.7,549.2z"/>
                                                                <path d="M834.1,560.8l5-5.7c0.1-0.1,0.3-0.2,0.4-0.2l11.5,0.5c0.3,0,0.5,0.3,0.5,0.6c0,0.1-0.1,0.2-0.1,0.3l-4.8,5.9
		c-0.1,0.1-0.3,0.2-0.4,0.2l-11.7-0.7c-0.3,0-0.5-0.3-0.5-0.6C834,561,834,560.9,834.1,560.8z"/>
                                                                <path d="M790.8,559.3c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6l34.6-2.4c0.6,0,0.7,0.2,0.3,0.6l-3.5,4.2
		c-0.2,0.3-0.5,0.4-0.9,0.4L790.8,559.3z"/>
                                                                <path d="M784.9,570h4.3c0.5,0,0.6,0.2,0.3,0.6c-7.3,8.8-14.6,17.5-21.9,26.2c-0.6,0.8-1.7,1.2-3.2,1.4
		c-3.5,0.3-6.9,0.6-10.4,0.8c-0.3,0-0.6,0.2-0.8,0.5l-0.9,2c-0.1,0.3-0.4,0.5-0.7,0.5H749c-0.3,0-0.5-0.2-0.5-0.5
		c0-0.1,0-0.2,0.1-0.3l34.5-41.1c0.1-0.2,0.4-0.2,0.5,0c0.1,0.1,0.1,0.2,0.1,0.3v8.6C783.8,569.5,784.3,570,784.9,570z"/>
                                                                <path d="M788,561.1c0.2-0.2,0.5-0.2,0.7,0c0.2,0.2,0.2,0.5,0.1,0.7c-0.2,0.3-0.4,0.4-0.8,0.5c-0.2,0.1-0.5,0-0.6-0.3
		c-0.1-0.2,0-0.4,0.1-0.5L788,561.1z"/>
                                                                <path d="M791.3,562.1c-0.2-0.2-0.3-0.3-0.3-0.4c0.1-0.6,0.6-1.2,1.2-1.3c0.5-0.1,0.9,0.3,1,0.8l0.2,2.4
		c0,0.2-0.2,0.5-0.4,0.5c-0.2,0-0.4-0.1-0.4-0.2l-0.6-1.2C791.7,562.5,791.5,562.2,791.3,562.1z"/>
                                                                <path d="M794.8,565.7l0-4c0-0.3,0.2-0.5,0.5-0.5c0,0,0,0,0,0l24.3,1.4c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.2-0.1,0.3
		l-3.3,3.8c-0.1,0.1-0.2,0.2-0.4,0.2l-20.9-1.2C795,566.1,794.8,565.9,794.8,565.7z"/>
                                                                <path d="M828.7,567.5l3.5-4.1c0.1-0.1,0.2-0.1,0.3-0.1l12,0.7c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.2l-3.5,4.1
		c-0.1,0.1-0.2,0.1-0.3,0.1l-12-0.7c-0.2,0-0.4-0.2-0.4-0.4C828.6,567.7,828.6,567.6,828.7,567.5z"/>
                                                                <path d="M789.6,564.8l0.3-0.8c0.1-0.3,0.4-0.4,0.7-0.3c0.1,0,0.3,0.1,0.3,0.3c0.2,0.4,0.2,0.7,0,1.1
		c-0.1,0.2-0.2,0.3-0.3,0.4c-0.3,0.2-0.6,0.1-0.9-0.2C789.6,565.1,789.5,565,789.6,564.8z"/>
                                                                <path d="M1008.8,564.2c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0.1-0.1,0.1l-6.2,1c-0.2,0-0.4-0.1-0.5-0.4c0-0.1,0-0.2,0.1-0.3
		c0.3-0.4,0.8-0.6,1.6-0.7C1005.3,563.9,1007,563.9,1008.8,564.2z"/>
                                                                <path d="M792.2,565.8c0.2-0.3,0.5-0.3,0.8-0.2c0.1,0.1,0.2,0.2,0.3,0.4l0.2,1.2c0,0.3-0.2,0.6-0.5,0.6c0,0,0,0,0,0
		l-1.3,0.2c-0.5,0.1-0.6-0.1-0.4-0.6L792.2,565.8z"/>
                                                                <path d="M1000.9,566.9l20.2-1.9c0.3,0,0.6,0.2,0.7,0.5l0,0.1c0,0.3-0.2,0.6-0.5,0.7l-20.2,1.9c-0.3,0-0.6-0.2-0.7-0.5
		l0-0.1C1000.3,567.2,1000.6,566.9,1000.9,566.9z"/>
                                                                <path d="M998.3,570.2l36.9-3.5c0.2,0,0.4,0.1,0.4,0.3c0,0.1,0,0.2-0.1,0.2l-5.2,7.2c-0.1,0.1-0.2,0.1-0.3,0.2
		l-37.3,3.7c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.3l5.7-7.5C998.1,570.3,998.2,570.3,998.3,570.2z"/>
                                                                <path d="M787.7,567.2c0-0.2,0.2-0.3,0.4-0.2c0,0,0.1,0,0.1,0l0.8,0.4c0.2,0.1,0.3,0.4,0.2,0.6
		c-0.1,0.2-0.2,0.3-0.4,0.3h-0.7c-0.4,0-0.6-0.2-0.5-0.6L787.7,567.2z"/>
                                                                <path d="M1051.1,569.4c0.6,0.4,0.7,0.8,0.3,1.3c-0.2,0.2-0.6,0.2-0.8,0c-0.1-0.1-0.2-0.2-0.2-0.4v-0.5
		c0-0.2,0.2-0.4,0.4-0.4C1051,569.4,1051.1,569.4,1051.1,569.4z"/>
                                                                <path d="M836.7,570.4c0.3,0.1,0.4,0.3,0.3,0.6c0,0.1,0,0.1-0.1,0.2l-19.1,23.6c-0.2,0.3-0.6,0.5-1,0.5h-3.3
		c-0.2,0-0.4-0.2-0.4-0.4c0-0.1,0-0.2,0.1-0.3c6.3-7.6,12.5-15.2,18.6-22.8C833.2,569.9,834.7,569.9,836.7,570.4z"/>

                                                                <ellipse transform="matrix(0.1478 -0.989 0.989 0.1478 269.4708 1465.9762)" cx="985.4" cy="576.6" rx="1.1" ry="0.6"/>
                                                                <path d="M798.6,604.5l6.6-8c0.1-0.1,0.3-0.2,0.5-0.2l11.6,0.9c0.3,0,0.5,0.3,0.5,0.6c0,0.1-0.1,0.2-0.1,0.3l-6.5,7.8
		c-0.1,0.1-0.3,0.2-0.5,0.2l-11.7-0.7c-0.3,0-0.5-0.3-0.5-0.6C798.5,604.7,798.5,604.6,798.6,604.5z"/>
                                                                <path d="M755.4,601.9c-0.3,0-0.5-0.1-0.6-0.2c-0.2-0.1-0.3-0.5-0.2-0.7c0.1-0.1,0.2-0.2,0.4-0.2l36.2-3.5
		c0.2,0,0.4,0.1,0.4,0.3c0,0.1,0,0.2-0.1,0.3l-5,5.9c-0.2,0.3-0.6,0.4-0.9,0.4C775.6,603.6,765.5,602.8,755.4,601.9z"/>
                                                                <path d="M748.3,612.8l5.2,0.2c0.2,0,0.3,0.1,0.3,0.3c0,0.1,0,0.1-0.1,0.2l-23.5,28.2c-0.1,0.1-0.1,0.1-0.2,0.1
		l-13.7,1.7c-0.1,0-0.2,0.1-0.2,0.2l-0.9,2.5c0,0.1-0.2,0.2-0.3,0.2h-3.7c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.1,0.1-0.2l36.4-43.4
		c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.1,0.1,0.1,0.2l0,9.7C748,612.7,748.1,612.8,748.3,612.8z"/>
                                                                <path d="M755.7,604.8c-0.3,0-0.6-0.2-0.7-0.6c-0.1-0.3,0-0.6,0.3-0.7c0,0,0.1,0,0.1,0c1.6-0.3,2.4,0.4,2.4,2
		c0,1.5,0.1,2.9,0.3,4.4c0.1,0.4-0.1,0.7-0.5,0.7l-1.7,0.3c-0.3,0.1-0.6-0.1-0.7-0.5c0-0.2,0-0.4,0.2-0.6
		C757.5,608.4,757.6,606.7,755.7,604.8z"/>
                                                                <path d="M753.3,604.2c0,0.6-0.5,0.9-1.3,0.8c-0.3,0-0.5-0.3-0.5-0.6c0-0.2,0.2-0.4,0.4-0.5l0.8-0.2
		C753.2,603.6,753.4,603.8,753.3,604.2z"/>
                                                                <path d="M759.3,608.3l0.3-3.9c0-0.3,0.3-0.5,0.5-0.5c0,0,0,0,0,0l23.9,1.8c0.3,0,0.5,0.3,0.5,0.5c0,0.1,0,0.2-0.1,0.3
		l-3.3,3.9c-0.1,0.1-0.3,0.2-0.4,0.2l-21-1.8C759.5,608.8,759.3,608.6,759.3,608.3z"/>
                                                                <path d="M754,608.2c-0.3-0.7-0.1-1.2,0.5-1.7c0.2-0.2,0.5-0.1,0.7,0.1c0.1,0.1,0.1,0.3,0.1,0.4l-0.3,1.2
		c-0.1,0.3-0.3,0.5-0.6,0.4C754.2,608.5,754.1,608.4,754,608.2z"/>
                                                                <path d="M793.4,610.9l3.3-3.8c0.1-0.1,0.2-0.2,0.4-0.1l11.8,0.8c0.2,0,0.4,0.2,0.4,0.5c0,0.1,0,0.2-0.1,0.2l-3.2,4
		c-0.1,0.1-0.2,0.2-0.4,0.2l-11.9-1.1c-0.2,0-0.4-0.2-0.4-0.5C793.3,611,793.3,611,793.4,610.9z"/>
                                                                <path d="M978.4,609.2c1.7,0.2,1.7,0.4,0,0.7c-3.1,0.5-6.1,0.8-9.2,1.1c-0.4,0-0.6-0.2-0.5-0.6c0.2-0.9,1-1.6,2-1.6
		C972.9,608.8,975.5,609,978.4,609.2z"/>
                                                                <path d="M753.2,610.6l-0.1,0.4c-0.1,0.2-0.3,0.4-0.6,0.3c-0.1,0-0.2-0.1-0.2-0.1l-0.4-0.4c-0.2-0.2-0.2-0.5,0-0.6
		c0.1-0.1,0.1-0.1,0.2-0.1c0.4-0.1,0.7,0,0.8,0.2C753.1,610.4,753.2,610.5,753.2,610.6z"/>
                                                                <path d="M966.7,612.9l22-2.7c0.1,0,0.3,0.1,0.3,0.2l0.1,0.7c0,0.1-0.1,0.3-0.2,0.3l-22,2.7c-0.1,0-0.3-0.1-0.3-0.2
		l-0.1-0.7C966.5,613,966.6,612.9,966.7,612.9z"/>
                                                                <path d="M964.5,616.3l38.7-4.8c0.2,0,0.4,0.1,0.4,0.3c0,0.1,0,0.2-0.1,0.3l-5.8,7.7c-0.1,0.1-0.2,0.1-0.3,0.2l-38.8,5
		c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.3l5.8-7.9C964.3,616.4,964.3,616.3,964.5,616.3z"/>
                                                                <path d="M799.6,613.8c0.7-0.1,1.3,0,1.9,0.3c0.4,0.2,0.5,0.5,0.2,0.8c-5.7,7-11.3,13.9-16.9,20.8
		c-1.7,2.1-3.5,3.2-6.1,2.2c-0.4-0.1-0.4-0.4-0.2-0.7c6.1-7.4,12.2-14.9,18.3-22.4c0.6-0.7,1.2-1.1,1.9-1.1
		C799.1,613.9,799.4,613.9,799.6,613.8z"/>
                                                                <path d="M1019,614.4c0.2-0.2,0.5-0.1,0.6,0.1c0.1,0.1,0.1,0.2,0.1,0.3l0,0.9c0,0.3-0.3,0.5-0.5,0.5
		c-0.2,0-0.3-0.1-0.4-0.2C1018.4,615.3,1018.5,614.8,1019,614.4z"/>
                                                                <path d="M672.9,618.8l6.6,10.5c0.2,0.3,0.1,0.6-0.1,0.8l-13,15.1c-0.3,0.3-0.7,0.3-1,0.1c-0.1,0-0.1-0.1-0.1-0.2
		l-6.7-10.5c-0.2-0.3-0.1-0.6,0.1-0.9l13-15.1c0.3-0.3,0.7-0.3,1-0.1C672.9,618.6,672.9,618.7,672.9,618.8z"/>
                                                                <path d="M800.9,618.6l0.1,0.1c0.2,0.1,0.2,0.4,0.1,0.5l-14.3,17.9c-0.1,0.2-0.4,0.2-0.5,0.1l-0.1-0.1
		c-0.2-0.1-0.2-0.4-0.1-0.5l14.3-17.9C800.5,618.5,800.7,618.5,800.9,618.6z"/>
                                                                <path d="M950.9,624.7c-0.1,0.2-0.5,0.3-0.7,0.1c-0.1-0.1-0.2-0.2-0.2-0.4l0-1.6c0-0.3,0.2-0.5,0.5-0.5
		c0.2,0,0.3,0.1,0.4,0.2C951.4,623.3,951.4,624,950.9,624.7z"/>
                                                                <path d="M1164.2,628.3l79.6,105.3c0.1,0.1,0.1,0.3,0.1,0.4l-0.9,7c0,0.3-0.3,0.5-0.6,0.5c-0.2,0-0.3-0.1-0.4-0.2
		L1159.6,632c-0.2-0.2-0.1-0.6,0.1-0.8l3.6-2.9C1163.6,628,1163.9,628,1164.2,628.3C1164.1,628.3,1164.1,628.3,1164.2,628.3z"/>
                                                                <path d="M1052,721l105.1-88.2c0.2-0.2,0.5-0.2,0.7,0.1l0.1,0.2c0.2,0.2,0.2,0.5-0.1,0.7L1052.8,722
		c-0.2,0.2-0.5,0.2-0.7-0.1l-0.1-0.2C1051.8,721.5,1051.8,721.2,1052,721z"/>
                                                                <path d="M761.3,650.2l8.9-10.9c0.1-0.1,0.2-0.1,0.3-0.1l12.1,1.5c0.2,0,0.3,0.2,0.3,0.4c0,0.1,0,0.1-0.1,0.2l-8.7,10.6
		c-0.1,0.1-0.2,0.1-0.3,0.1l-12.3-1.2c-0.2,0-0.3-0.2-0.3-0.4C761.3,650.3,761.3,650.3,761.3,650.2z"/>
                                                                <path d="M747.7,649.3l-30-3.1c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6l38.1-4.6c0.2,0,0.4,0.1,0.4,0.3
		c0,0.1,0,0.2-0.1,0.3l-6,7.2C749.5,649.1,748.7,649.4,747.7,649.3z"/>
                                                                <path d="M673.5,704.2c1.3,0.4,2.5,0.3,3.7-0.2c0.3-0.1,0.6,0,0.8,0.3c0.1,0.2,0.1,0.4-0.1,0.6l-24.3,29.3
		c-0.7,0.8-1.6,1.3-2.6,1.5c-3.9,0.7-8,1.4-12.4,2.1c-1.5,0.2-2.1,0.9-2.1,2.4c0,0.4-0.2,0.6-0.5,0.6l-3.8,0.6
		c-0.8,0.1-0.9-0.1-0.4-0.7l78.5-93.5c0.1-0.1,0.3-0.2,0.5,0c0.1,0.1,0.1,0.2,0.1,0.3v9.6c0,0.4,0.3,0.7,0.7,0.7c0,0,0,0,0,0
		l4.6-0.1c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.2-0.1,0.3l-23.9,28.7c-0.2,0.3-0.5,0.4-0.9,0.5l-13.7,2.1c-0.3,0-0.5,0.2-0.6,0.5
		l-0.8,1.7c-0.2,0.3-0.4,0.6-0.8,0.6l-3.4,0.5c-0.4,0.1-0.7,0.3-0.7,0.8v7.9C671.8,703,672.3,703.8,673.5,704.2z"/>
                                                                <path d="M718.8,649.5c-2-1.4-3.5,0.5-4.2,0.2c-0.2-0.1-0.3-0.3-0.4-0.6c-0.1-0.3,0.1-0.7,0.5-0.7c0,0,0,0,0.1,0
		l5.5-0.4c0.3,0,0.6,0.2,0.6,0.5c0,0,0,0,0,0l0.3,6c0,0.3-0.2,0.6-0.5,0.7l-2.3,0.5c-0.2,0-0.5-0.1-0.5-0.4c0-0.2,0-0.4,0.2-0.4
		c1.3-0.8,1.9-2,1.8-3.7C719.7,650.5,719.4,649.9,718.8,649.5z"/>
                                                                <path d="M722.3,652.9v-4.1c0-0.2,0.2-0.4,0.4-0.4c0,0,0,0,0.1,0L747,651c0.2,0,0.4,0.2,0.4,0.5c0,0.1,0,0.2-0.1,0.2
		l-3,3.8c-0.1,0.1-0.2,0.2-0.4,0.2l-21.3-2.2C722.4,653.4,722.3,653.2,722.3,652.9z"/>
                                                                <path d="M939.2,650.3c0.1-0.2,0.2-0.3,0.4-0.2c0.1,0,0.2,0.1,0.2,0.3c0.2,1.1,0.1,2.2-0.3,3.3
		c-0.2,0.3-0.6,0.4-0.9,0.3c-0.3-0.1-0.4-0.4-0.3-0.7L939.2,650.3z"/>
                                                                <path d="M716.6,652.6c-0.4-0.8-0.1-1.4,0.8-1.7c0.3-0.1,0.7,0.1,0.8,0.4c0,0.1,0,0.1,0,0.2v0.8c0,0.5-0.4,0.8-0.9,0.8
		C717.1,653.1,716.8,652.9,716.6,652.6z"/>
                                                                <path d="M756.5,656.3l3.1-3.8c0.1-0.1,0.2-0.2,0.4-0.2l12.1,1.3c0.2,0,0.4,0.2,0.4,0.5c0,0.1,0,0.2-0.1,0.2l-3.2,3.8
		c-0.1,0.1-0.2,0.2-0.4,0.2l-12-1.2c-0.2,0-0.4-0.3-0.4-0.5C756.4,656.4,756.4,656.3,756.5,656.3z"/>
                                                                <path d="M716.1,654.9c0.2,0,0.4,0.1,0.6,0.3c0.1,0.2,0.1,0.4,0,0.6c0,0-0.1,0.1-0.1,0.1c-0.9,0.3-1.5,0.1-2-0.6
		c-0.1-0.2-0.1-0.6,0.2-0.7c0.1,0,0.2-0.1,0.2-0.1C715.3,654.5,715.7,654.6,716.1,654.9z"/>
                                                                <path d="M947.2,656.4c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2l-13.4,2c-0.2,0-0.4-0.1-0.5-0.3
		c0-0.1,0-0.2,0.1-0.3l1.8-2.4c0.2-0.3,0.6-0.5,1-0.4L947.2,656.4z"/>
                                                                <path d="M931.7,660.6l22.5-3.3c0.4-0.1,0.7,0.2,0.7,0.5l0,0c0.1,0.4-0.2,0.7-0.5,0.7l-22.5,3.3
		c-0.4,0.1-0.7-0.2-0.7-0.5l0,0C931.1,661,931.4,660.7,931.7,660.6z"/>
                                                                <path d="M929.2,664.3l40.2-6.1c0.3,0,0.5,0.1,0.5,0.4c0,0.1,0,0.3-0.1,0.4l-5.8,7.9c-0.1,0.1-0.2,0.2-0.3,0.2
		l-40.3,6.3c-0.3,0-0.5-0.1-0.5-0.4c0-0.1,0-0.3,0.1-0.4l6-8C929,664.4,929.1,664.3,929.2,664.3z"/>
                                                                <path d="M764.8,660.3l-17.7,21.8c-0.1,0.1-0.2,0.2-0.3,0.2l-4.4,0.7c-0.3,0-0.5-0.1-0.5-0.4c0-0.1,0-0.3,0.1-0.4
		l18.6-22.8c0.1-0.1,0.3-0.2,0.4-0.2l3.5,0.4c0.3,0,0.4,0.3,0.4,0.5C764.9,660.2,764.9,660.3,764.8,660.3z"/>
                                                                <path d="M985.3,661c0-0.3,0.3-0.6,0.6-0.5c0.2,0,0.4,0.2,0.5,0.4c0.2,0.7,0,1.2-0.4,1.6c-0.2,0.2-0.5,0.2-0.7,0
		c-0.1-0.1-0.1-0.3-0.1-0.4L985.3,661z"/>
                                                                <path d="M1060.6,667.5c0.1-0.2,0.2-0.2,0.3-0.2c0.3,0.1,0.5,0.4,0.4,0.9c-2,12.3-4,24.6-5.9,36.9
		c-0.3,2.3-1.6,3.7-3.4,4.8c-0.2,0.1-0.4,0.1-0.5-0.1c0-0.1-0.1-0.2-0.1-0.3c1.1-7.6,2.3-15.2,3.5-22.5
		C1056.1,679.9,1058.1,674,1060.6,667.5z"/>
                                                                <path d="M913.8,671.9c0-0.3,0.3-0.6,0.6-0.6c0.2,0,0.4,0.2,0.5,0.4c0.3,0.8,0.2,1.5-0.3,2.1c-0.2,0.2-0.5,0.2-0.7,0.1
		c-0.1-0.1-0.2-0.2-0.2-0.4L913.8,671.9z"/>
                                                                <path d="M929.3,674.1l4.5-0.8c0.4-0.1,0.7,0.2,0.8,0.5c0,0.1,0,0.1,0,0.2l-3.6,30.7c0,0.3-0.3,0.5-0.6,0.6l-4.7,0.7
		c-0.4,0.1-0.7-0.2-0.7-0.6c0-0.1,0-0.1,0-0.2l3.8-30.5C928.8,674.4,929,674.2,929.3,674.1z"/>
                                                                <path d="M936.6,673.3l0.4,0c0.2,0,0.4,0.2,0.4,0.5l-3.5,30.5c0,0.2-0.2,0.4-0.5,0.4l-0.4,0c-0.2,0-0.4-0.2-0.4-0.5
		l3.5-30.5C936.1,673.5,936.4,673.3,936.6,673.3z"/>
                                                                <path d="M722.9,697.4l10.7-13.1c0.1-0.1,0.2-0.2,0.4-0.2l12.1,1.5c0.2,0,0.4,0.2,0.4,0.5c0,0.1,0,0.2-0.1,0.2
		l-10.8,13.2c-0.1,0.1-0.2,0.2-0.4,0.2l-12-1.6c-0.2,0-0.4-0.2-0.4-0.5C722.8,697.5,722.9,697.5,722.9,697.4z"/>
                                                                <path d="M679.2,691.3l39.1-5.7c0.3,0,0.6,0.2,0.7,0.5c0,0.2,0,0.3-0.1,0.5l-8.1,9.7c-0.1,0.2-0.3,0.2-0.5,0.2l-31-4
		c-0.3,0-0.6-0.3-0.5-0.7C678.7,691.5,678.9,691.3,679.2,691.3z"/>
                                                                <path d="M1034.5,779.2c-0.4-0.1-0.7-0.4-0.6-0.8c2.6-17.1,5.2-34.1,7.8-51.1c1.9-12.8,5.8-24,10.4-35.6
		c0-0.1,0.2-0.2,0.3-0.1c0.1,0,0.2,0.2,0.2,0.3c-3.9,25-7.8,50.1-11.6,75.1c-0.2,1.1-0.6,2.3-1.4,3.6c-1.5,2.5-2.3,5.3-2.2,8.4
		c0,0.4-0.3,0.6-0.6,0.6c0,0-0.1,0-0.1,0L1034.5,779.2z"/>
                                                                <path d="M679.7,701.3c2.5-3.6,1.3-7.7-3.5-5.3c-0.2,0.1-0.5,0-0.6-0.2c-0.1-0.1-0.1-0.2,0-0.4c0.2-0.5,0.4-0.7,0.8-0.7
		c1.8-0.1,3.3-0.5,5.2-0.2c0.4,0.1,0.7,0.3,0.7,0.8l0.3,6c0,0.5-0.2,0.7-0.7,0.7l-1.9,0C679.5,702,679.3,701.8,679.7,701.3z"/>
                                                                <path d="M684,699.2l-0.2-3.7c0-0.3,0.2-0.6,0.6-0.6c0,0,0.1,0,0.1,0l24,2.9c0.3,0,0.6,0.3,0.5,0.7
		c0,0.1-0.1,0.2-0.1,0.3l-3,3.6c-0.1,0.2-0.3,0.2-0.5,0.2l-20.8-2.8C684.2,699.7,684,699.5,684,699.2z"/>
                                                                <path d="M679.3,697.5c0.3,0.5,0.3,1.1,0.1,1.7c-0.1,0.4-0.5,0.6-1,0.5c-0.2,0-0.3-0.1-0.4-0.3
		c-0.6-0.7-0.4-1.4,0.5-2.1c0.1-0.1,0.2-0.1,0.3,0C679.1,697.4,679.3,697.5,679.3,697.5z"/>
                                                                <path d="M717.9,703.5l3.1-3.8c0.1-0.1,0.2-0.2,0.3-0.1l12.2,1.6c0.2,0,0.4,0.2,0.3,0.4c0,0.1,0,0.1-0.1,0.2l-3,3.8
		c-0.1,0.1-0.2,0.2-0.4,0.1l-12.4-1.5c-0.2,0-0.4-0.2-0.3-0.4C717.8,703.7,717.9,703.6,717.9,703.5z"/>
                                                                <path d="M677.5,701.5c0.2,0,0.3,0.1,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.6,0.4-1.1,0.4-1.7,0c-0.3-0.2-0.4-0.6-0.1-1
		c0.1-0.1,0.2-0.2,0.4-0.3c0.4-0.1,0.8,0.1,1.2,0.4C677.3,701.5,677.4,701.5,677.5,701.5z"/>
                                                                <path d="M912.5,706l-15.6,2.9c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.3l3.2-4.2c0.1-0.1,0.2-0.1,0.3-0.1l12.4,1.3
		c0.2,0,0.3,0.1,0.4,0.3C912.8,705.8,912.7,706,912.5,706z"/>
                                                                <path d="M726.2,707c0.5,0.1,0.6,0.3,0.3,0.8c-5.2,6.4-10.5,12.9-15.8,19.5c-1.2,1.6-3.3,2.1-6.1,1.7
		c-0.3,0-0.4-0.2-0.2-0.5l17.6-21.7c0.2-0.2,0.4-0.3,0.7-0.3L726.2,707z"/>
                                                                <path d="M894.9,710.8l23.8-4.2c0.3-0.1,0.7,0.2,0.7,0.5l0,0c0.1,0.3-0.2,0.7-0.5,0.7l-23.8,4.2
		c-0.3,0.1-0.7-0.2-0.7-0.5l0,0C894.3,711.1,894.5,710.8,894.9,710.8z"/>
                                                                <path d="M892.3,714.5l42.1-7.6c0.3,0,0.5,0.1,0.6,0.4c0,0.1,0,0.3-0.1,0.4l-6.1,8.3c-0.1,0.1-0.2,0.2-0.3,0.2L886,724
		c-0.3,0-0.5-0.1-0.6-0.4c0-0.1,0-0.3,0.1-0.4l6.4-8.5C892,714.6,892.1,714.5,892.3,714.5z"/>
                                                                <path d="M951,709.5c0.1-0.3,0.3-0.4,0.6-0.4c0.2,0,0.3,0.2,0.4,0.4c0.2,0.8,0.1,1.5-0.3,2.1c-0.1,0.3-0.5,0.3-0.7,0.2
		c-0.2-0.1-0.3-0.3-0.3-0.6L951,709.5z"/>
                                                                <path d="M589.1,715.3l7.3,10.7c0.2,0.3,0.2,0.6,0,0.8L582,743.7c-0.3,0.3-0.7,0.3-1,0.1c0,0-0.1-0.1-0.1-0.1l-7.6-10.7
		c-0.2-0.3-0.2-0.6,0-0.8l14.6-16.8c0.3-0.3,0.7-0.3,1-0.1C589,715.3,589,715.3,589.1,715.3z"/>
                                                                <path d="M923.6,718.8l2.9-0.7c0.2,0,0.4,0.1,0.4,0.3c0,0.1,0,0.2-0.1,0.3l-3.3,4.5c-0.1,0.2-0.3,0.2-0.5,0.1
		c-0.1-0.1-0.2-0.2-0.1-0.3l0.4-3.8C923.4,719,923.5,718.9,923.6,718.8z"/>
                                                                <path d="M875.5,724.9l0.3-1.7c0.1-0.3,0.4-0.5,0.7-0.5c0.2,0,0.3,0.1,0.4,0.3c0.6,1,0.5,1.8-0.3,2.5
		c-0.3,0.3-0.7,0.2-0.9,0C875.5,725.3,875.4,725.1,875.5,724.9z"/>
                                                                <path d="M1049.9,723.3l1.8,0.3c0.4,0.1,0.6,0.4,0.6,0.8l-6.4,43.1c-0.1,0.4-0.4,0.6-0.8,0.6l-1.8-0.3
		c-0.4-0.1-0.6-0.4-0.6-0.8l6.4-43.1C1049.2,723.5,1049.6,723.2,1049.9,723.3z"/>
                                                                <path d="M682.5,746.9l13-15.9c0.1-0.1,0.2-0.1,0.3-0.1l12.5,1.6c0.2,0,0.3,0.2,0.3,0.4c0,0.1,0,0.1-0.1,0.2l-13,16.2
		c-0.1,0.1-0.2,0.1-0.3,0.1l-12.5-1.8c-0.2,0-0.3-0.2-0.3-0.4C682.5,747,682.5,747,682.5,746.9z"/>
                                                                <path d="M639,740.7c-0.3,0-0.6-0.3-0.7-0.5c-0.2-0.4,0-0.7,0.4-0.8c13.9-2.3,27.8-4.6,41.7-7c0.2,0,0.4,0.1,0.4,0.3
		c0,0.1,0,0.2-0.1,0.3l-10.1,12.2c-0.2,0.2-0.5,0.3-0.8,0.3L639,740.7z"/>
                                                                <path d="M535.6,849.7c-0.2,0-0.4-0.1-0.5-0.3l-79.4-105.8c-0.2-0.3-0.2-0.6,0-0.9l5.5-5.9c0.3-0.3,0.6-0.3,0.8,0
		l79.1,105.7c0.3,0.4,0.3,1-0.1,1.4c-1.4,1.6-2.8,3.3-4.3,5.2C536.5,849.5,536.1,849.7,535.6,849.7z"/>
                                                                <path d="M499.2,911.3c1.9,0.9,3.9,0.8,5.8-0.2c0.3-0.1,0.6,0,0.8,0.3c0.1,0.2,0.1,0.4-0.1,0.6l-26.1,31.4
		c-0.1,0.1-0.3,0.2-0.5,0c-0.1-0.1-0.1-0.2-0.1-0.3c1.7-7.9-7.6-10.5-11-5c-2.9,4.7-2.2,8.3,2.4,10.9c0.5,0.3,0.5,0.5-0.1,0.7
		l-12.9,3.3c-0.2,0.1-0.4,0.2-0.4,0.4l-0.7,2.2c-0.1,0.3-0.3,0.5-0.6,0.6l-4.7,1c-0.4,0.1-0.6,0.3-0.6,0.7l0.2,7.8
		c0,0.2-0.2,0.4-0.4,0.4c-0.1,0-0.3-0.1-0.3-0.1l-3.3-3.8c-0.3-0.3-0.3-0.7,0-1l184-219.2c0.2-0.2,0.4-0.2,0.6,0
		c0.1,0.1,0.1,0.2,0.1,0.3l0.2,9.5c0,0.4,0.2,0.7,0.6,0.8c1.7,0.6,3.4,0.5,5.1-0.2c0.3-0.1,0.5,0,0.6,0.3c0.1,0.2,0,0.4-0.1,0.5
		l-26.2,31.5c-0.2,0.3-0.5,0.5-0.9,0.5c-4.9,1.1-9.8,2-14.6,2.9c-1.2,0.2-1.7,1-1.5,2.4c0,0.3-0.2,0.6-0.5,0.6c0,0,0,0,0,0l-4.5,0.7
		c-0.3,0.1-0.6,0.3-0.6,0.7l0,9.7c0,0.4,0.2,0.8,0.6,0.9c1.8,0.8,3.6,0.8,5.5-0.1c0.2-0.1,0.5,0,0.6,0.3c0.1,0.2,0,0.4-0.1,0.5
		l-27.4,32.9c-0.2,0.3-0.5,0.5-0.9,0.6c-4.8,1.1-9.6,2.2-14.5,3.2c-1.7,0.3-2.5,0.9-2.4,2.6c0,0.3-0.2,0.6-0.5,0.7l-4.6,0.9
		c-0.3,0.1-0.6,0.3-0.6,0.7l0.2,10.1c0,0.3,0.2,0.6,0.5,0.7c1.8,0.8,3.6,0.7,5.4-0.1c0.3-0.1,0.6,0,0.7,0.3c0.1,0.2,0.1,0.4-0.1,0.6
		l-28,33.7c-0.5,0.6-1.2,1-1.9,1.2c-5.2,1.3-10.3,2.5-15.3,3.6c-1.2,0.3-1.8,1.1-1.8,2.7c0,0.3-0.2,0.5-0.5,0.6l-4.6,1
		c-0.4,0.1-0.6,0.4-0.6,0.8l-0.1,10.3C498.7,910.9,498.9,911.1,499.2,911.3z"/>
                                                                <path d="M637.4,743.6l-1.6,1c-0.4,0.2-0.6,0.1-0.7-0.3c-0.2-0.7,0-1.1,0.5-1.2c1.8-0.3,3.7-0.4,5.6-0.3
		c0.6,0,1,0.5,1.1,1.1l0.2,5.8c0,0.4-0.2,0.6-0.6,0.6l-2.3,0c-0.7,0-0.8-0.2-0.2-0.7c1.3-1,1.8-2.5,1.5-4.4
		c-0.2-1.2-1.4-2.1-2.6-1.9C637.9,743.3,637.7,743.4,637.4,743.6z"/>
                                                                <path d="M643.8,747.5v-3.7c0-0.3,0.2-0.5,0.5-0.5c0,0,0.1,0,0.1,0l23.8,3.7c0.3,0,0.5,0.3,0.4,0.6
		c0,0.1-0.1,0.2-0.1,0.3l-2.8,3.4c-0.1,0.1-0.3,0.2-0.5,0.2l-21-3.4C644,748,643.8,747.8,643.8,747.5z"/>

                                                                <ellipse transform="matrix(0.7934 -0.6088 0.6088 0.7934 -361.9612 433.4767)" cx="457.5" cy="749.9" rx="0.7" ry="6.1"/>
                                                                <path d="M637.9,746c0.3-0.3,0.8-0.3,1.1,0c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.7,0.1,1.3-0.4,1.6c-0.3,0.2-0.8,0.1-1-0.2
		c0,0,0,0,0-0.1C637.3,747,637.4,746.4,637.9,746z"/>
                                                                <path d="M677.8,752.7l2.9-3.6c0.1-0.1,0.2-0.2,0.4-0.2l12.4,1.9c0.2,0,0.4,0.3,0.4,0.5c0,0.1,0,0.1-0.1,0.2l-2.9,3.6
		c-0.1,0.1-0.2,0.2-0.4,0.2l-12.4-1.9c-0.2,0-0.4-0.3-0.3-0.5C677.8,752.8,677.8,752.7,677.8,752.7z"/>
                                                                <path d="M637.8,750.1c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c-0.8,0.7-1.7,0.9-2.5,0.5c-0.4-0.2-0.6-0.6-0.4-1
		c0.2-0.4,0.7-0.6,1.1-0.4L637.8,750.1z"/>
                                                                <path d="M876.5,757.4l-17.8,3.6c-0.2,0-0.4-0.1-0.5-0.3c0-0.1,0-0.2,0.1-0.3l4-5.4c0.1-0.1,0.2-0.2,0.4-0.1l13.7,1.8
		c0.2,0,0.4,0.2,0.3,0.4C876.8,757.2,876.7,757.4,876.5,757.4z"/>
                                                                <path d="M686.3,757.5L671.2,776c-0.3,0.3-0.7,0.4-1,0.1c-0.1,0-0.1-0.1-0.1-0.1l-1.6-2.2c-0.2-0.3-0.2-0.6,0-0.9
		l13.5-16.7c0.2-0.2,0.4-0.3,0.6-0.3l3.2,0.3c0.4,0,0.7,0.4,0.7,0.8C686.4,757.2,686.4,757.3,686.3,757.5z"/>
                                                                <path d="M853.7,767l44.4-9.3c0.2,0,0.4,0.1,0.5,0.3c0,0.1,0,0.2-0.1,0.3l-6.5,8.9c-0.1,0.1-0.1,0.1-0.2,0.1l-44.9,9.6
		c-0.2,0-0.4-0.1-0.5-0.3c0-0.1,0-0.2,0.1-0.3l7-9.2C853.5,767.1,853.6,767,853.7,767z"/>
                                                                <path d="M856.5,763.1l24.9-5.2c0.3-0.1,0.7,0.1,0.7,0.5l0,0.1c0.1,0.3-0.1,0.7-0.5,0.7l-24.9,5.2
		c-0.3,0.1-0.7-0.1-0.7-0.5l0-0.1C855.9,763.5,856.1,763.1,856.5,763.1z"/>

                                                                <ellipse transform="matrix(0.3469 -0.9379 0.9379 0.3469 -116.4568 1355.5522)" cx="915.2" cy="761.4" rx="1.2" ry="0.9"/>
                                                                <path d="M1114.4,767.2c-4.2-0.4-8.2-1.6-12.6-0.6c-0.3,0.1-0.6-0.1-0.7-0.4c-0.3-0.9,0-1.4,0.9-1.7
		c3.3-1.2,6.6-1.6,10.1-1.1c41.7,5.4,83.2,10.9,124.7,16.5c0.5,0.1,0.8,0.5,0.8,1c0,0,0,0,0,0c-0.6,4-1.2,8.2-1.7,12.5
		c-0.1,1.2-0.8,2.1-2.1,2.8c-0.3,0.2-0.6,0.4-0.7,0.7c-1.7,2.7-2.5,5.7-2.6,9c0,1-0.6,1.5-1.6,1.3L1116,790.8
		c-0.6-0.1-0.7-0.4-0.3-0.8l0.6-0.8c0.2-0.3,0.4-0.6,0.4-1l0.1-18.4C1116.8,768.4,1115.8,767.3,1114.4,767.2z"/>
                                                                <path d="M1016.7,814.3c-1.9,0.5-5.4-0.1-7.3-0.6c-0.4-0.1-0.5-0.3-0.4-0.7l8.4-23.3c0.1-0.4,0.4-0.6,0.7-0.7
		c3.2-1,6.4-1.8,9.6-2.4c5.1-0.9,9.6,1.7,14.4,0.3c2.3-0.7,4.6-1.2,6.8-1.7c0.7-0.1,1.2-0.8,1.2-1.5v-2c0-0.4,0.2-0.7,0.7-0.8
		c17-4.1,34-8.3,50.9-12.3c4.4-1.1,8.3-0.2,12.6,0.4c0.3,0,0.5,0.3,0.6,0.6v18.2c0,0.5-0.3,0.9-0.8,1
		C1081.7,797.3,1049.2,805.8,1016.7,814.3z"/>
                                                                <path d="M664,770.3l78.4,115.8c0.1,0.2,0.1,0.4,0,0.6l-5.2,6.9c-0.2,0.2-0.5,0.3-0.8,0.1c0,0-0.1-0.1-0.1-0.1
		l-79-115.7c-0.1-0.2-0.1-0.5,0-0.7l5.7-7c0.2-0.2,0.5-0.3,0.8-0.1C663.9,770.2,664,770.3,664,770.3z"/>
                                                                <path d="M1046.2,781.1l2.2-3c0,0,0.1-0.1,0.1,0c0.1,0,0.2,0.1,0.2,0.1c0,0,0.1,0.1,0,0.2c-0.3,1.6-0.4,3.1-0.2,4.8
		c0,0.3-0.2,0.6-0.5,0.6l-6.8,1.7c-0.7,0.2-1-0.1-1-0.8c0.1-4.5-0.6-12.5,4.5-14.4c0.3-0.1,0.6-0.1,0.9,0c1.4,0.7,2.2,1.8,2.4,3.4
		c0,0.2-0.2,0.5-0.4,0.5c-0.1,0-0.2,0-0.3-0.1c-4.8-3.6-8.4,5.6-2.1,7.5C1045.6,781.5,1046,781.4,1046.2,781.1z"/>

                                                                <ellipse transform="matrix(0.1271 -0.9919 0.9919 0.1271 141.9511 1715.4554)" cx="1045.6" cy="777.1" rx="2" ry="0.9"/>

                                                                <ellipse transform="matrix(0.1668 -0.986 0.986 0.1668 -69.9379 1473.0996)" cx="836.6" cy="777.9" rx="1.4" ry="0.9"/>
                                                                <path d="M655.5,779.9L655.5,779.9c0.5-0.3,1-0.2,1.3,0.2l6.3,9.2c0.3,0.4,0.2,1-0.2,1.3l-0.1,0c-0.4,0.3-1,0.2-1.3-0.2
		l-6.3-9.2C655,780.7,655.1,780.2,655.5,779.9z"/>
                                                                <path d="M1034.9,781.3c0.2,0,0.3,0.2,0.3,0.4c0,0.1-0.1,0.2-0.3,0.3l-2.3,0.6c-0.7,0.2-1-0.1-0.7-0.8l0.3-0.6
		c0.2-0.4,0.5-0.6,0.9-0.4L1034.9,781.3z"/>
                                                                <path d="M597.4,791.3c-0.5-0.1-0.9-0.3-1.2-0.7c-0.2-0.3-0.1-0.6,0.2-0.8c0.1,0,0.1-0.1,0.2-0.1l43.5-8.3
		c0.2,0,0.5,0.1,0.5,0.3c0,0.1,0,0.3-0.1,0.4l-11.8,14.4c-0.2,0.2-0.5,0.4-0.8,0.3C617.8,794.9,607.6,793.1,597.4,791.3z"/>
                                                                <path d="M1300.7,792.5C1300.7,792.5,1300.7,792.5,1300.7,792.5l-47.4,14.5c-0.4,0.1-0.8-0.1-0.9-0.5c0-0.1,0-0.2,0-0.3
		c0.6-3.5-0.2-6.5-2.2-9.1c-0.2-0.3-0.3-0.5-0.2-0.9l1.8-13.3c0.1-0.6,0.7-1.1,1.3-1c0,0,0,0,0,0l61.7,8.2c0.3,0,0.5,0.3,0.5,0.6
		c0,0,0,0.1,0,0.1l-1,3.1c-0.1,0.3-0.3,0.4-0.5,0.3C1309.3,793.5,1305,792.9,1300.7,792.5z"/>
                                                                <path d="M640.7,798.2l12.8-15.6c0.2-0.2,0.6-0.3,0.8-0.1c0,0,0.1,0.1,0.1,0.1l6.2,9.2c0.1,0.2,0.1,0.5,0,0.7l-6.9,8.5
		c-0.1,0.2-0.4,0.2-0.6,0.2l-12-2.1c-0.3-0.1-0.5-0.4-0.5-0.7C640.6,798.4,640.6,798.3,640.7,798.2z"/>
                                                                <path d="M595.1,794.2l-1.5,0.9c-0.3,0.2-0.6,0.1-0.8-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.6,0.3-1,1.1-1.2
		c1.7-0.3,3.4-0.4,5-0.5c0.7,0,1.3,0.5,1.3,1.2c0,0,0,0,0,0l0.2,5.8c0,0.3-0.2,0.6-0.5,0.7l-2.5,0.5c-0.2,0-0.4-0.1-0.5-0.3
		c0-0.2,0-0.3,0.2-0.4c1.4-1,2-2.5,1.8-4.4c-0.2-1.3-1.4-2.3-2.8-2.1C595.7,793.9,595.4,794,595.1,794.2z"/>
                                                                <path d="M601.8,797.9v-3.5c0-0.3,0.3-0.6,0.6-0.6c0,0,0.1,0,0.1,0l23.8,4.3c0.3,0.1,0.5,0.4,0.5,0.7
		c0,0.1-0.1,0.2-0.1,0.3l-2.6,3.2c-0.1,0.2-0.4,0.2-0.6,0.2l-21.2-3.9C601.9,798.4,601.7,798.2,601.8,797.9z"/>
                                                                <path d="M976.1,807.3c-7.2,1.1-14.6-2.2-21.8-2.5c-0.1,0-0.2-0.1-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2l42.2-10.3
		c0.3-0.1,0.5,0.1,0.6,0.3c0,0.1,0,0.2,0,0.3l-4,11.1c-0.1,0.3-0.4,0.6-0.7,0.7c-1.1,0.3-2,0.4-2.6,0.4
		C985.2,806.4,980.6,806.6,976.1,807.3z"/>
                                                                <path d="M891.2,920.3c-0.4-0.1-0.4-0.2-0.1-0.5c0.1-0.1,0.4-0.2,0.7-0.3c112.2-34.3,224.4-68.7,336.5-103.1
		c5.7-1.7,11.2,2.1,16.2,0.5c2.5-0.8,5-1.6,7.5-2.4c0.3-0.1,0.5-0.3,0.5-0.6l0.2-4.3c0-0.4,0.2-0.7,0.7-0.8
		c14.7-4.5,29.5-9,44.1-13.5c4.9-1.5,9.7-0.4,14.6,0.6c0.7,0.1,1.1,0.8,1,1.4c-0.7,4.7-1.2,9.3-1.4,14c-0.1,1.2,0.4,3.7,0.1,5.4
		c-0.1,0.4-0.3,0.7-0.7,0.8L895.4,955c-0.4,0.1-0.7,0-0.9-0.3c-1.4-2.5-2.4-4.6-1.4-7.5c3.2-9.8,5.2-16.1,5.9-18.8
		C900.8,922.2,895.8,921.1,891.2,920.3z"/>

                                                                <ellipse transform="matrix(0.3697 -0.9291 0.9291 0.3697 -365.1018 1056.5593)" cx="596.2" cy="797.4" rx="1.4" ry="1"/>
                                                                <path d="M855.7,858.1c-0.2,0-0.3-0.2-0.1-0.4L897,799c0.7-1,1.9-1.5,3.1-1.3c35.8,5.8,71,11.4,105.7,17
		c8.7,1.4,15.4,3.4,12.5,13.9c-0.1,0.5-0.1,0.9,0.1,1.2c0.2,0.2,0.3,0.5,0.3,0.8l0.2,35.7c0,0.8-0.5,1.5-1.2,1.7
		c-10.2,3.1-20,6-29.3,8.8c-6,1.8-10.8,2.7-14.4,2.9c-5.9,0.2-11.1-0.9-16.9-2C923.5,871.1,889.8,864.6,855.7,858.1z M907.8,850.7
		c-1.2,3.7,0.6,8,4.5,9.4c0.2,0.1,0.4,0.1,0.6,0l5.1-2c0.3-0.1,0.5-0.1,0.7,0c1.6,0.8,3.1,0.7,4.6-0.4c0.6-0.4,1.4-0.3,1.8,0.2
		c0.1,0.2,0.2,0.3,0.2,0.5c1.1,5.6,5.9,5.3,9.8,2.8c0.3-0.2,0.6-0.2,1,0l1.1,0.7c0.3,0.2,0.6,0.2,0.9,0.1l4.5-1.7
		c0.2-0.1,0.5-0.3,0.6-0.5c2.6-5.4,1.3-9.6-3.7-12.5c-0.3-0.2-0.6-0.2-0.9-0.2c-2.3,0.5-4.4,1.4-6.3,2.7c-0.3,0.2-0.7,0.3-1,0.3
		c-1.2-0.1-2.4,0.2-3.6,0.8c-0.3,0.2-0.7,0-0.9-0.3c0,0,0-0.1,0-0.1l-0.8-3c-0.1-0.4-0.3-0.7-0.6-0.9l-3.3-2.4
		c-0.3-0.2-0.6-0.3-0.9-0.2c-2.2,0.5-4.2,1.4-6,2.5c-0.3,0.2-0.7,0.3-1,0.3C911.4,847.1,908.7,847.8,907.8,850.7z"/>
                                                                <path d="M1248,801.7c-5.8,0.3-2.5,13.6,2.2,7.4c0.1-0.2,0.4-0.2,0.6-0.1c0.1,0.1,0.2,0.2,0.2,0.3l0,2.9
		c0,0.5-0.3,0.9-0.8,1l-5.9,1.8c-0.4,0.1-0.6,0-0.7-0.4c-0.6-5.8-0.6-11.8,3-16.1c0.3-0.3,0.7-0.5,1.2-0.3c1.7,0.5,2.7,1.8,2.8,3.7
		c0,0.3-0.2,0.6-0.5,0.6c0,0-0.1,0-0.1,0c-0.4-0.1-0.8-0.2-1.1-0.5C1248.7,801.8,1248.4,801.7,1248,801.7z"/>
                                                                <path d="M594.8,800.4c0.3-0.1,0.6,0,0.9,0.2c0.4,0.2,0.4,0.5,0,0.7c-0.5,0.3-1,0.5-1.6,0.5c-1.1,0-1.6-1-1.2-1.9
		c0.1-0.3,0.3-0.4,0.6-0.2C594,800,594.4,800.2,594.8,800.4z"/>
                                                                <path d="M636,804.1l2.7-3.4c0.1-0.1,0.3-0.2,0.4-0.2l12.5,2.3c0.2,0,0.4,0.3,0.3,0.5c0,0.1,0,0.1-0.1,0.2l-2.9,3.4
		c-0.1,0.1-0.3,0.2-0.4,0.1l-12.3-2.3c-0.2,0-0.4-0.3-0.3-0.5C635.9,804.2,636,804.1,636,804.1z"/>

                                                                <ellipse transform="matrix(9.758288e-02 -0.9952 0.9952 9.758288e-02 324.4464 1970.1145)" cx="1248.6" cy="806.2" rx="2.2" ry="0.9"/>
                                                                <path d="M865,805.2c-2,3.3-8.8,4-12.3,4.8c-0.2,0-0.4-0.1-0.5-0.3c0-0.1,0-0.3,0.1-0.4c2.9-3.2,8.4-3.9,12.3-4.6
		C865.1,804.6,865.2,804.8,865,805.2z"/>
                                                                <path d="M641,807.4l3.4,0.6c0.3,0.1,0.5,0.4,0.5,0.7c0,0.1-0.1,0.2-0.1,0.3c-4,4.9-8.1,9.8-12.1,14.8
		c-2.2,2.7-4.5,3.6-7.8,3.6c-0.5,0-0.6-0.2-0.3-0.5l15.4-19.1C640.3,807.5,640.6,807.4,641,807.4z"/>
                                                                <path d="M838.1,811.1l-19.5,4.5c-0.2,0.1-0.5-0.1-0.5-0.3c0-0.1,0-0.3,0.1-0.4l5.3-6.9c0.1-0.1,0.3-0.2,0.4-0.2
		l14.3,2.4c0.2,0,0.4,0.3,0.4,0.5C838.4,811,838.3,811.1,838.1,811.1z"/>
                                                                <path d="M647,809L647,809c0.2,0.2,0.2,0.4,0.1,0.6l-12.6,15.5c-0.1,0.2-0.4,0.2-0.5,0.1l0,0c-0.2-0.1-0.2-0.4-0.1-0.5
		l12.6-15.5C646.6,808.9,646.8,808.9,647,809z"/>
                                                                <path d="M1235.8,810.2h0.8c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.1,0.3-0.2,0.4l-1.9,0.9c-0.5,0.2-1,0-1.2-0.4
		c-0.1-0.2-0.1-0.4,0-0.6c0.2-0.8,0.7-1,1.5-0.8C1235.4,810.2,1235.6,810.3,1235.8,810.2z"/>
                                                                <path d="M813.2,822l46.7-11.1c0.2,0,0.4,0.1,0.5,0.3c0,0.1,0,0.2-0.1,0.3l-6.9,9.4c-0.1,0.1-0.1,0.1-0.2,0.2l-47,11.3
		c-0.2,0.1-0.4-0.1-0.5-0.3c0-0.1,0-0.2,0.1-0.3l7.2-9.7C813,822.1,813.1,822,813.2,822z"/>
                                                                <path d="M816,817.9l26.3-6.2c0.4-0.1,0.7,0.1,0.8,0.5l0,0c0.1,0.4-0.1,0.7-0.5,0.8l-26.3,6.2c-0.4,0.1-0.7-0.1-0.8-0.5
		l0,0C815.4,818.3,815.7,818,816,817.9z"/>
                                                                <path d="M1237.9,812.4c0.4-0.3,1-0.5,1.6-0.5c0.4,0,0.6,0.3,0.6,0.7c0,0.4-0.3,0.6-0.7,0.6l0,0h-1.3
		c-0.2,0-0.5-0.2-0.5-0.4C1237.7,812.7,1237.8,812.5,1237.9,812.4z"/>

                                                                <ellipse transform="matrix(0.1994 -0.9799 0.9799 0.1994 -95.7695 1512.0441)" cx="877.4" cy="814.6" rx="1.4" ry="0.9"/>
                                                                <path d="M497.5,820.9l8.1,10.8c0.2,0.3,0.2,0.6,0,0.9l-15.9,18.7c-0.3,0.3-0.7,0.3-1,0.1c0,0-0.1-0.1-0.1-0.1
		l-8.4-10.8c-0.2-0.3-0.2-0.7,0-0.9l16.2-18.7c0.3-0.3,0.7-0.3,1-0.1C497.4,820.8,497.5,820.8,497.5,820.9z"/>
                                                                <path d="M850.8,823.5c0.3-0.1,0.5,0.1,0.6,0.4c0,0.2,0,0.3-0.1,0.5l-0.4,0.4c-0.4,0.4-0.6,0.3-0.7-0.2
		C850.1,823.9,850.3,823.6,850.8,823.5z"/>
                                                                <path d="M626.2,830.4c-0.7-0.4-0.6-0.6,0.1-0.8l4.1-0.8c0.8-0.1,1,0.1,0.4,0.7C629.6,831.2,628.1,831.4,626.2,830.4z"
                                                                />
                                                                <path d="M627.8,832.3c0.2,0,0.3,0.2,0.3,0.4c0,0.1,0,0.1-0.1,0.2l-12,14.8c-0.1,0.1-0.3,0.2-0.4,0.1
		c-0.1-0.1-0.1-0.2-0.1-0.3l1.2-16.1c0-0.5,0.5-1,1.1-0.9c0,0,0.1,0,0.1,0L627.8,832.3z"/>
                                                                <path d="M552.8,843.5c-0.3-0.1-0.5-0.4-0.5-0.7c0-0.1,0.1-0.2,0.2-0.3c0.8-0.8,2.8-1,3.6-1.1
		c16.5-3.4,32.9-6.9,49.3-10.5c0.3-0.1,0.7,0.1,0.7,0.5c0,0.1,0,0.1,0,0.2l-1.7,22.2c0,0.4-0.4,0.7-0.8,0.7c0,0-0.1,0-0.1,0
		L552.8,843.5z"/>
                                                                <path d="M803.9,865.9l-19.4-3.8c-0.3-0.1-0.5-0.3-0.4-0.6c0-0.1,0-0.1,0.1-0.2l13.8-18.8c0.1-0.1,0.2-0.2,0.3-0.2
		l47-11.5c0.3-0.1,0.6,0.1,0.6,0.4c0,0.1,0,0.3-0.1,0.4l-22.3,31c-0.1,0.1-0.2,0.2-0.3,0.2l-18,4.8c-0.3,0.1-0.5-0.1-0.6-0.3
		l-0.4-1.1C804.2,866,804.1,865.9,803.9,865.9z"/>
                                                                <ellipse transform="matrix(0.2284 -0.9736 0.9736 0.2284 -198.8482 1417.7761)" cx="795" cy="834.3" rx="1.5" ry="1"/>
                                                                <path d="M1021.1,864.5c-0.4-0.1-0.6-0.4-0.6-0.7v-20.6c0-0.4,0.4-0.8,0.8-0.8c0,0,0.1,0,0.1,0l73.2,12.4
		c0.2,0,0.4,0.3,0.4,0.5c0,0.2-0.2,0.3-0.3,0.4c-14.4,4.4-29,8.8-43.6,13.3c-1.4,0.4-2.7,0.5-4.1,0.2
		C1038.4,867.5,1029.8,866,1021.1,864.5z"/>
                                                                <path d="M552,846.5c-1,0.2-1.8,0.7-2.5,1.6c-0.2,0.3-0.6,0.3-0.9,0.1c-0.2-0.1-0.3-0.4-0.2-0.6l0.2-0.6
		c0.1-0.4,0.4-0.7,0.8-0.9c2.2-0.7,4.3-0.8,6.5-0.2c0.3,0.1,0.5,0.4,0.5,0.7l0.2,6c0,0.3-0.2,0.6-0.5,0.6l-2.7,0.5
		c-0.2,0-0.5-0.1-0.5-0.4c0-0.2,0-0.3,0.2-0.4c1.4-1.1,2-2.6,1.6-4.6C554.5,847.1,553.3,846.3,552,846.5z"/>
                                                                <path d="M558.2,850.5v-3.3c0-0.3,0.3-0.6,0.6-0.6c0,0,0.1,0,0.1,0c14.1,3,28.3,6,42.4,9.1c1.3,0.3,2.2,0.8,2.6,1.5
		c0.5,0.9,0.3,2-0.5,3.2c-0.2,0.3-0.4,0.3-0.7,0.3l-44-9.5C558.4,851.1,558.2,850.9,558.2,850.5z"/>
                                                                <path d="M915.7,848.8c6.8-2.9,9.8,5.8,2,7.4c-0.3,0.1-0.5-0.1-0.5-0.4c0.2-2.4-0.4-4.5-1.7-6.4
		C915.4,849,915.4,848.9,915.7,848.8z"/>
                                                                <path d="M552.1,851.4c-0.6,0.1-0.9-0.1-0.8-0.7l0-1c0-0.6,0.5-1,1.1-0.9c0.5,0,0.9,0.4,0.9,0.9
		C553.4,850.6,553,851.2,552.1,851.4z"/>

                                                                <ellipse transform="matrix(0.9767 -0.2147 0.2147 0.9767 -162.017 215.8287)" cx="912.4" cy="853.6" rx="3.1" ry="4.5"/>
                                                                <path d="M933.2,851.8c4.3-1.9,8.2,1.1,5.9,5.8c-0.1,0.3-0.4,0.5-0.7,0.6l-3.2,1.3c-0.3,0.1-0.5,0-0.4-0.3
		c0.2-2.5-0.3-4.7-1.7-6.8C932.9,852.1,933,852,933.2,851.8z"/>
                                                                <path d="M551.3,853.2c0.3-0.1,0.4,0.1,0.5,0.4c0.1,0.4-0.1,0.6-0.5,0.7l-1.9,0.3c-0.5,0.1-0.7-0.1-0.7-0.6v-1.7
		c0-0.2,0.2-0.4,0.4-0.4c0.1,0,0.2,0,0.3,0.1l1,1C550.7,853.3,551,853.3,551.3,853.2z"/>
                                                                <path d="M534.8,852.5L534.8,852.5c0.3,0.2,0.3,0.5,0.1,0.7l-57,68c-0.2,0.2-0.4,0.2-0.6,0.1l-0.1-0.1
		c-0.2-0.2-0.2-0.4-0.1-0.6l57-68C534.4,852.4,534.6,852.3,534.8,852.5z"/>

                                                                <ellipse transform="matrix(0.9715 -0.2368 0.2368 0.9715 -176.4919 244.6203)" cx="929.9" cy="856.9" rx="3.2" ry="4.6"/>
                                                                <path d="M570.2,880.4c-0.1-0.1-0.2-0.2-0.3-0.3c-2.7-8.8-6.4-17.1-11.1-25c-0.2-0.3-0.1-0.8,0.3-1
		c0.1,0,0.2-0.1,0.2-0.1c1.5-0.3,3-0.2,4.6,0.1c7.2,1.5,14.3,3,21.4,4.6c0.4,0.1,0.6,0.4,0.6,0.8V871c0,0.4-0.1,0.7-0.3,1
		C581.6,877.2,578.4,884.2,570.2,880.4z"/>
                                                                <path d="M552.8,860l-0.4-0.3c-0.4-0.3-0.4-0.6-0.1-1l2.6-3.2c0.5-0.6,1.4-0.7,2.1-0.2c0.2,0.1,0.3,0.3,0.4,0.5l6.7,13
		c0.4,0.7,0.4,1.4,0.1,2.2l-4.3,11.3c-0.1,0.3-0.4,0.6-0.7,0.6l-14.4,3.4c-0.2,0.1-0.4-0.1-0.5-0.3c0-0.1,0-0.2,0-0.2l8.8-24.9
		C553.2,860.5,553.1,860.2,552.8,860z"/>
                                                                <path d="M594.5,861.2l-6.1,7.5c-0.1,0.1-0.3,0.2-0.5,0c-0.1-0.1-0.1-0.2-0.1-0.3l-0.1-8.9c0-0.2,0.1-0.4,0.3-0.4
		c0,0,0.1,0,0.1,0l6.3,1.4c0.2,0,0.3,0.2,0.3,0.4C594.5,861.1,594.5,861.2,594.5,861.2z"/>
                                                                <path d="M550.8,860.2l0.5,0.2c0.1,0,0.2,0.2,0.2,0.3l-9.1,26.1c0,0.1-0.2,0.2-0.3,0.2l-0.5-0.2c-0.1,0-0.2-0.2-0.2-0.3
		l9.1-26.1C550.5,860.2,550.7,860.2,550.8,860.2z"/>
                                                                <path d="M854.5,862.1c-0.4,0-0.7-0.1-0.9-0.2c-0.2-0.2-0.3-0.5-0.1-0.7c0.1-0.2,0.3-0.2,0.5-0.2
		c44.8,8.7,89.6,17.2,134.3,25.9c0.5,0.1,0.8,0.3,1,0.6c0.1,0.2,0.1,0.4-0.1,0.5c-0.1,0-0.2,0.1-0.3,0.1
		c-39-7.4-77.8-14.9-116.4-22.4c-8.5-1.6-14.2-2.8-17.1-3.5C855.2,862.1,854.9,862.1,854.5,862.1z"/>
                                                                <path d="M600.8,862.1c0.3,0.1,0.5,0.4,0.4,0.6c0,0.1-0.1,0.2-0.1,0.2c-3.6,4.2-7.1,8.6-10.7,13.2
		c-2.3,2.9-4.7,3.5-8.2,3.8c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.1,0.1-0.2l14.5-17.9c0.2-0.3,0.5-0.4,0.9-0.3L600.8,862.1z"/>
                                                                <path d="M600.8,876.2c-3,0.1-5.9,0.6-8.8,1.6c-0.2,0.1-0.4,0-0.5-0.2c0-0.1,0-0.3,0.1-0.4l11.2-14
		c0.2-0.2,0.5-0.3,0.7-0.1c0.1,0.1,0.2,0.3,0.2,0.4l-1.1,12.4c0,0,0,0,0,0.1c-0.3,0.5-0.7,0.7-1.1,0.4
		C601.3,876.3,601.1,876.2,600.8,876.2z"/>
                                                                <path d="M798.4,867.2l-22,5.7c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.3l6.4-8.8c0.1-0.1,0.2-0.2,0.4-0.1l15.6,3.1
		c0.2,0,0.3,0.2,0.3,0.4C798.6,867.1,798.5,867.2,798.4,867.2z"/>
                                                                <path d="M968.8,881.7c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.2c0.7-0.2,1.4-0.2,2.2-0.2c5.2,0.3,9.7-0.4,15.1-2.1
		c10.9-3.2,21.7-6.6,32.6-10c0.8-0.3,1.5-1.1,2.1-2.5c0.1-0.3,0.4-0.4,0.7-0.4l22.6,4.1c0.2,0,0.3,0.2,0.3,0.5
		c0,0.1-0.1,0.2-0.3,0.3c-16,5-32,9.9-48,14.7c-1.4,0.4-2.9,0.5-4.3,0.2C984.4,884.6,976.7,883.2,968.8,881.7z"/>
                                                                <path d="M770.8,879.7l49.4-13.2c0.2,0,0.4,0.1,0.4,0.2c0,0.1,0,0.2-0.1,0.3l-7.4,10.2c0,0.1-0.1,0.1-0.2,0.1
		l-49.7,13.4c-0.2,0-0.4-0.1-0.4-0.2c0-0.1,0-0.2,0.1-0.3l7.7-10.4C770.6,879.7,770.7,879.7,770.8,879.7z"/>
                                                                <path d="M773.8,875.3l27.9-7.3c0.4-0.1,0.8,0.1,0.9,0.5l0,0c0.1,0.4-0.1,0.8-0.5,0.9l-27.9,7.3
		c-0.4,0.1-0.8-0.1-0.9-0.5l0,0C773.2,875.8,773.4,875.4,773.8,875.3z"/>

                                                                <ellipse transform="matrix(0.1994 -0.9799 0.9799 0.1994 -182.1195 1517.8368)" cx="837.8" cy="870.4" rx="1.4" ry="0.9"/>
                                                                <path d="M581.8,883.4c-0.2,0-0.3-0.2-0.3-0.4c0-0.1,0.1-0.3,0.3-0.3l18.2-4.3c0.3-0.1,0.6,0.1,0.7,0.5c0,0,0,0.1,0,0.1
		v0.9c0,0.5-0.2,0.7-0.7,0.5l-2.2-0.6c-0.3-0.1-0.6,0-0.9,0.1c-2.2,1.4-2.8,3.2-1.6,5.6c0.1,0.3,0,0.6-0.3,0.7c-0.1,0-0.2,0.1-0.3,0
		L581.8,883.4z"/>

                                                                <ellipse transform="matrix(0.5534 -0.8329 0.8329 0.5534 -468.604 892.6172)" cx="598.1" cy="883.3" rx="1.4" ry="0.9"/>
                                                                <path d="M506.6,898.4c-0.3-0.1-0.5-0.4-0.5-0.7c0-0.2,0.1-0.3,0.3-0.4c0.6-0.4,1.3-0.7,2.1-0.9
		c19.9-4.5,39.5-9.1,59-13.8c1.2-0.3,2.5-0.3,3.7,0c9.9,2.2,20.1,4.6,30.4,7c3.8,0.9,3.1,2.7,4.9,5.2c8.3,11.3,16.6,22.6,24.8,33.8
		c0.1,0.2,0.1,0.5-0.1,0.6c-0.1,0.1-0.2,0.1-0.4,0.1L506.6,898.4z"/>
                                                                <path d="M599,886.2l1.2-1.3c0.4-0.4,0.6-0.3,0.6,0.2c0,1-0.5,1.6-1.5,1.7c-0.2,0-0.4-0.1-0.4-0.3
		C598.9,886.3,598.9,886.2,599,886.2z"/>
                                                                <path d="M603,888c-0.5,0.2-0.8,0-1-0.6c-0.1-0.3,0-0.6,0.3-0.7s0.6,0,0.7,0.3l0.2,0.5C603.2,887.7,603.2,887.9,603,888
		C603,888,603,888,603,888z"/>
                                                                <path d="M751.7,892l0.2,0c0.5,0.1,0.8,0.6,0.6,1.1l-0.3,1.1c-0.1,0.5-0.6,0.8-1.1,0.6l-0.2,0c-0.5-0.1-0.8-0.6-0.6-1.1
		l0.3-1.1C750.7,892.2,751.2,891.9,751.7,892z"/>
                                                                <path d="M734.7,897c0.4-0.5,1.2-0.7,1.7-0.3c0,0,0,0,0,0c0.1,0.1,0.1,0.3-0.2,0.6c-19.1,25.9-38.3,51.9-57.6,77.9
		c-0.1,0.2-0.4,0.2-0.6,0.1c-0.1-0.1-0.2-0.2-0.2-0.3v-1.2c0-0.3,0.1-0.6,0.3-0.9L734.7,897z"/>

                                                                <ellipse transform="matrix(0.9998 -1.919744e-02 1.919744e-02 0.9998 -17.0931 16.9315)" cx="873.3" cy="898.8" rx="5.9" ry="1"/>
                                                                <path d="M807.4,903.8c-0.3-0.1-0.5-0.3-0.4-0.6c0-0.2,0.2-0.4,0.4-0.4c4.5-1.1,12.7-4.5,17.1-3.7
		c4.9,0.9,9.5,2.2,14.4,2.2c7.1-0.1,14.2-0.3,21.4-0.5c1.3-0.1,2.5-0.5,3.7-0.5c0.4,0,0.8,0.3,0.8,0.8c0,0.2-0.1,0.4-0.2,0.6
		c-0.5,0.5-1.3,0.7-2.3,0.5c-0.9-0.2-2.9-0.2-5.8,0.1c-12.4,1.1-24.8,2.1-37.2,3C815,905.4,811.4,904.6,807.4,903.8z"/>
                                                                <path d="M504.9,902.1l-1.8,1.8c-0.1,0.1-0.3,0.1-0.5,0c-0.1-0.1-0.1-0.1-0.1-0.2v-1.5c0-0.5,0.2-0.8,0.6-0.9
		c2.3-0.8,4.6-0.9,7-0.3c0.3,0.1,0.5,0.3,0.5,0.6l0.5,5.9c0,0.3-0.1,0.5-0.4,0.5l-7.2,2c-0.4,0.1-0.8-0.1-0.9-0.5c0,0,0-0.1,0-0.1
		l-0.1-1.3c0-0.6,0.2-0.8,0.8-0.5c3.2,1.9,7.4-1.3,5.6-4.3c-0.8-1.4-2.4-2.1-3.6-1.6C505.2,901.9,505,902,504.9,902.1z"/>
                                                                <path d="M866.7,917.4l0.1-16c0-0.4,0.2-0.6,0.6-0.5c4,0.8,8.1,0.7,12-0.3c0.3-0.1,0.6,0.1,0.7,0.4c0,0.1,0,0.1,0,0.2
		l-0.5,16.1c0,0.5-0.3,0.9-0.8,1c-3.9,1-7.7,1-11.6-0.1C866.9,918.1,866.7,917.8,866.7,917.4z"/>
                                                                <path d="M512.5,905.4v-3.1c0-0.5,0.2-0.6,0.7-0.5c39.1,9.7,78.2,19.5,117.4,29.3c3.5,0.9,5.1,3.1,6.8,6
		c0.2,0.4,0.1,0.5-0.3,0.4l-123.9-31.2C512.7,906.1,512.5,905.9,512.5,905.4z"/>
                                                                <path d="M803.5,946.6l-0.3-41.5c0-0.3,0.3-0.6,0.6-0.6c0,0,0.1,0,0.1,0c5.5,1.4,10.5,2.7,16.3,2.2
		c14.6-1.3,29.3-2.4,44.2-3.5c0.3,0,0.5,0.2,0.6,0.5c0,0,0,0,0,0v6.2c0,0.5-0.3,0.9-0.8,1.1c-3.4,1.2-6.8,1.8-9.9,3.6
		c-2.3,1.4-4.6,2.7-6.9,4.1c-0.8,0.5-1,0.7-1.2,1.6c-0.1,0.3-0.3,0.6-0.5,0.8c-5.9,4.6-10.8,10-14.7,16.3
		c-5.4,8.7-11.4,15.3-15.3,25c-0.2,0.5-0.3,0.5-0.4,0c-0.5-3.6,1-11.8-3.2-13c-2.8-0.8-5.4-1.4-7.8-2
		C803.8,947.3,803.5,947,803.5,946.6z"/>

                                                                <ellipse transform="matrix(0.5255 -0.8508 0.8508 0.5255 -530.0556 860.3506)" cx="506.3" cy="905.4" rx="1.5" ry="1.1"/>
                                                                <path d="M525.3,935.8c-0.5-0.2-0.9-0.2-1.4-0.1l-44.4,11.4c-0.2,0.1-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.3
		c8.3-10,16.6-19.9,24.9-30c1.7-2,2.6-3.4,5.3-4.1c4-0.9,10-3.1,14-2.1c8.4,2.1,16.8,4.2,25.2,6.4c0.3,0.1,0.4,0.3,0.3,0.6
		c0,0.1,0,0.1-0.1,0.2l-13.2,16.1c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.4-0.4,0.6
		C531.7,936.8,528.5,937,525.3,935.8z"/>
                                                                <path d="M800.7,910.4c0-0.2,0.2-0.4,0.5-0.5c0.2-0.1,0.5,0.1,0.6,0.3c0,0,0,0.1,0,0.1v19.9c0,0.4-0.1,0.7-0.3,1.1
		L789,949c-0.2,0.3-0.5,0.5-0.8,0.6l-18.7,5.7c-0.2,0.1-0.4-0.1-0.4-0.2c0-0.1,0-0.2,0-0.3C779.6,940,790.1,925.2,800.7,910.4z
		 M795.3,932.2c1.5,0.3,3-0.9,3.5-2.9c0.5-1.9-0.4-3.8-1.8-4.1c-1.5-0.3-3,0.9-3.5,2.9C793,930,793.8,931.8,795.3,932.2z"/>
                                                                <path d="M856.8,918.3c0,0.1-0.1,0.3-0.3,0.3l-7.8,2.9c-0.3,0.1-0.5,0-0.6-0.3c-0.1-0.2,0-0.5,0.2-0.6l9.8-5.7
		c0.1,0,0.2,0,0.2,0.1c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.1-0.1,0.2C857.5,916.1,857,917.1,856.8,918.3z"/>
                                                                <path d="M538.5,934.4c-0.3,0-0.5-0.2-0.6-0.5c0-0.1,0-0.3,0.1-0.4l12.5-15.5c0.3-0.3,0.6-0.4,1-0.3l3.6,0.9
		c0.4,0.1,0.5,0.3,0.2,0.7c-3.1,3.8-6.2,7.7-9.3,11.5C543.9,933.4,541.7,934.1,538.5,934.4z"/>
                                                                <path d="M852.6,926.3c-0.9,0.3-0.8,0.7,0.2,1.2c0,0,0.1,0,0.1,0.1c2.9,0.8,5.8,1.4,8.7,1.9c0.4,0.1,0.7,0.3,1,0.5
		c0.3,0.3,0.3,0.7,0,1c-0.2,0.2-0.4,0.3-0.7,0.2c-2.5-0.5-4.9-0.8-7.2-0.9c-2.1-0.1-4.7,0.6-7.3,0.3c-0.3,0-0.5,0-0.8,0.2l-1,0.6
		c-0.2,0.1-0.5,0.1-0.6-0.1c-0.1-0.1-0.1-0.2-0.1-0.4c0.6-3,1.9-7.1,4.6-8c4.7-1.4,9.4-2.9,14.1-4.5c0.3-0.1,0.7-0.1,0.9,0.2
		c1.2,0.9,1.8,2.2,1.7,3.7c0,0.3,0.1,0.7,0.4,0.8c3.2,2,9.5,1.4,12.6,0.1c0.8-0.3,1.2-1.2,1-2c-0.2-0.6-0.1-1.1,0.3-1.5
		c0.2-0.2,0.5-0.3,0.8-0.2c4.1,1.3,8.2,2.3,12.3,3c4.4,0.8,3.9,3.9,2.8,7.4c-6.4,19.9-12.7,39.7-19,59.6c-0.9,2.9-4.2,7.6-7.7,7.2
		c-6.6-0.7-3.6-12.3-2.4-16.2c4.4-15.4,9-30.7,13.6-46.1c0.2-0.8,0.1-1.3-0.4-1.5c-0.3-0.1-0.7,0-0.9,0.3c0,0,0,0.1,0,0.1
		c-4.9,16.6-9.8,33.1-14.7,49.6c-1.1,3.6-1.1,7.3-0.2,11c0.1,0.2-0.1,0.5-0.3,0.5c-0.1,0-0.1,0-0.2,0c-0.5-0.1-0.8-0.4-1-1
		c-1-3.5-1-6.9-0.1-10.2c4.5-15.3,8.9-30.7,13.4-46c1.3-4.4-1.5-6.2-5.3-7.2c-5.9-1.5-11.9-2.8-18-3.8
		C853.2,926.2,852.9,926.2,852.6,926.3z M871.7,991.6c2,0.5,4.2-1,4.9-3.4c0,0,0,0,0,0c0.7-2.4-0.5-4.9-2.5-5.4l0,0
		c-2-0.5-4.2,1-4.9,3.4c0,0,0,0,0,0C868.6,988.6,869.7,991,871.7,991.6L871.7,991.6z"/>
                                                                <path d="M557,930.2c-3.1,0.3-6.2,1-9.2,2.1c-0.2,0.1-0.4,0-0.5-0.2c0-0.1,0-0.3,0.1-0.4l9.8-12
		c0.2-0.3,0.6-0.4,0.9-0.3l3.6,0.9c0.3,0.1,0.6,0.3,0.8,0.6c1.4,2.7,3.6,3.8,6.7,3.3c0.2,0,0.4,0.1,0.4,0.3c0,0.1,0,0.2-0.1,0.3
		l-10,12.6c-0.2,0.2-0.4,0.2-0.6,0c-0.1-0.1-0.1-0.2-0.1-0.3v-5.3c0-0.9-0.7-1.6-1.6-1.6C557.1,930.2,557.1,930.2,557,930.2z"/>
                                                                <path d="M878.1,922c-3.3,1.1-6,0.9-9.4,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0.1-0.3,0.3-0.3
		c3,0.4,6.1,0.3,9.1-0.4c0.3-0.1,0.4,0,0.4,0.3c0,0.1,0,0.2,0,0.3C878.2,921.9,878.2,922,878.1,922z"/>
                                                                <path d="M755.7,925.3c0.5,0.1,0.6,0.4,0.1,0.7c-0.1,0.1-0.3,0.2-0.7,0.3c-7.7,2.3-15.4,4.5-23,6.7
		c-0.2,0.1-0.4,0-0.4-0.2c0-0.1,0-0.2,0.1-0.3l7.4-10c0.4-0.5,1-0.7,1.5-0.6L755.7,925.3z"/>
                                                                <path d="M833.9,936c3.5-4.8,7.6-10,12.5-13.3c0.5-0.3,0.5-0.2,0.2,0.2c-1.6,2.3-2.6,4.9-3.1,7.7c-0.2,1-0.9,1.7-1.8,2
		c-2.6,0.9-5,2.2-7.2,3.9c-0.2,0.2-0.5,0.1-0.6-0.1C833.8,936.4,833.8,936.1,833.9,936z"/>
                                                                <path d="M429.7,962.2c-0.6-0.2-1-0.7-1-1.4l-0.4-26.9c0-0.9,0.6-1.7,1.5-2l35.7-8.8c0.3-0.1,0.6,0,0.9,0.2
		c0.6,0.4,0.7,0.8,0.3,1.3c-9.6,11.5-19.3,23-29.1,34.7c-0.2,0.2-0.2,0.5,0,0.8l3.4,3.9c0.3,0.3,0.2,0.8-0.1,1
		c-0.2,0.2-0.4,0.2-0.7,0.1L429.7,962.2z"/>
                                                                <path d="M586.1,926.4c0.4,0.1,0.5,0.3,0.3,0.6c0,0.1-0.1,0.2-0.2,0.2l-19.3,5c-0.3,0.1-0.5-0.1-0.6-0.4
		c0-0.1,0-0.3,0.1-0.4l6.2-7.8c0.2-0.3,0.6-0.4,1-0.3C577.7,924.2,581.9,925.3,586.1,926.4z"/>
                                                                <path d="M718.3,950.8c1.8-2.5,6.8-10.4,9.6-11.3c16.8-5,33.5-9.8,50.2-14.6c0.2-0.1,0.4,0,0.4,0.2c0,0.1,0,0.2-0.1,0.3
		l-7.1,9.7c-0.4,0.6-1,1-1.8,1.2l-50.9,15.1c-0.2,0.1-0.5-0.1-0.6-0.3C718.2,951.1,718.2,950.9,718.3,950.8z"/>
                                                                <path d="M440.8,959.5c-0.6-0.2-0.7-0.5-0.3-1l27.2-32.3c0.3-0.3,0.7-0.3,1-0.1c0,0,0.1,0.1,0.1,0.1
		c0.8,0.9,1.7,1.3,3,1c0.2,0,0.5,0.1,0.5,0.3c0,0.1,0,0.3-0.1,0.4L445,960.4c-0.2,0.2-0.5,0.3-0.8,0.2L440.8,959.5z"/>
                                                                <path d="M729.3,937c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0-0.3c0.4-0.6,0.9-0.9,1.7-1.2c9.4-2.8,18.8-5.6,28.4-8.3
		c0.4-0.1,0.7,0.1,0.9,0.5c0.1,0.2,0,0.5-0.1,0.7c-0.2,0.2-0.5,0.3-1,0.5C749.2,931.5,739.3,934.3,729.3,937z"/>

                                                                <ellipse transform="matrix(0.2487 -0.9686 0.9686 0.2487 -301.2271 1469.0803)" cx="796.3" cy="928.7" rx="1.5" ry="0.9"/>
                                                                <path d="M864.9,931.5c-0.2,0-0.4-0.2-0.4-0.5v-0.4c0-0.4,0.2-0.6,0.6-0.5l8.6,2c0.8,0.2,1.4,1,1.2,1.9c0,0,0,0,0,0
		c0,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1C871.2,933,868.1,932.1,864.9,931.5z"/>
                                                                <path d="M536,938.6c-0.2-0.1-0.3-0.2-0.2-0.4c0-0.1,0.1-0.2,0.2-0.2l20.1-5.3c0.4-0.1,0.8,0.1,0.9,0.5
		c0,0.1,0,0.1,0,0.2l0,1.2c0,0.3-0.3,0.6-0.6,0.5c-0.1,0-0.2,0-0.2-0.1c-5.5-2.8-7.6,5.3-2.3,5.9c0.4,0,0.7,0,1-0.2l1.8-1.4
		c0.2-0.1,0.4-0.1,0.5,0.1c0.1,0.1,0.1,0.2,0.1,0.3c-0.1,0.6-0.5,1-1.2,1.3C549.3,943.5,542.8,940.6,536,938.6z"/>

                                                                <ellipse transform="matrix(0.2957 -0.9553 0.9553 0.2957 -505.4258 1189.9772)" cx="554.3" cy="937.8" rx="1.3" ry="1"/>
                                                                <path d="M459.4,956.2c-0.2-0.1-0.4-0.1-0.5-0.2c-0.5-0.2-0.7-0.7-0.6-1.2c0.1-0.3,0.4-0.5,0.7-0.6l64.5-16.7
		c0.5-0.1,1.1-0.1,1.6,0l139.8,37.3c1.3,0.3,2.1,1.7,1.7,3c-0.1,0.3-0.2,0.6-0.4,0.9c-2,2.6-4.1,5.5-6.4,8.5
		c-2.1,2.9-6.3,3.7-9.5,4.5c-1.5,0.4-2.6,1.7-2.6,3.2c0,1.9,0,4,0,6.2c0,2-2.8,8.2-5.6,7.4C581.2,991.2,520.3,973.7,459.4,956.2z"/>
                                                                <path d="M721.9,953.4c-0.5-0.1-0.5-0.4-0.2-0.9c0.1-0.1,0.2-0.2,0.3-0.2l46.1-13.7c0.2-0.1,0.4,0,0.5,0.2
		c0,0.1,0,0.2-0.1,0.3l-14.3,19.9c-0.8,1.1-2.2,1.6-3.5,1.3C741.2,958.1,731.6,955.8,721.9,953.4z"/>

                                                                <ellipse transform="matrix(0.4274 -0.9041 0.9041 0.4274 -580.4683 968.9615)" cx="474.7" cy="942.7" rx="2.8" ry="2.2"/>
                                                                <path d="M801.4,943.5c0.1,0,0.2,0.1,0.3,0.3c0.3,0.9,0,1.5-0.8,1.8c-2.8,1-5.7,1.9-8.6,2.6c-0.6,0.2-0.8,0-0.4-0.6
		l3.7-5.1c0.2-0.2,0.5-0.3,0.7-0.3L801.4,943.5z"/>
                                                                <path d="M471.1,945.7c0.3,0.1,0.6,0.3,0.8,0.6c0.2,0.2,0.2,0.5,0,0.7c-0.2,0.1-0.4,0.2-0.6,0.1c-0.6-0.3-0.9-0.8-1-1.5
		c0-0.1,0.1-0.2,0.2-0.2c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.4,0.3C471,945.6,471,945.6,471.1,945.7z"/>
                                                                <path d="M650.7,1027.4c-0.6-0.2-1-0.7-1-1.4l-0.4-30.6c0-0.9,0.6-1.7,1.5-2l150.2-46c0.3-0.1,0.6,0,0.8,0.3
		c1.4,2,6.8,2.5,9.3,3.1c1.3,0.3,2.2,1.5,2.2,2.8c0,6.5,0.4,12.8-0.9,19.2c-0.6,3.3-1.3,6.5-2,9.8c-0.1,0.4-0.3,0.6-0.6,0.7
		l-145.2,48c-0.4,0.1-0.7,0.1-1.1,0L650.7,1027.4z"/>

                                                                <ellipse transform="matrix(0.284 -0.9588 0.9588 0.284 -411.152 1360.6206)" cx="705.5" cy="955.6" rx="1.7" ry="1.1"/>
                                                                <path d="M461,961.7c-0.6-3.5-4.3-2.5-5.9-0.4c-0.2,0.2-0.5,0.2-0.7,0.1c0,0-0.1-0.1-0.1-0.1c-0.3-0.5-0.4-1-0.2-1.5
		c0.1-0.3,0.5-0.6,1-0.8c2.5-1,4.9-1,7.4-0.2c0.2,0.1,0.4,0.3,0.4,0.5l0.5,6c0,0.3-0.1,0.5-0.4,0.5l-7.9,2.1
		c-0.3,0.1-0.7-0.1-0.8-0.5c0-0.1,0-0.1,0-0.2v-2.5c0-0.6,0.2-0.7,0.5-0.1C456.7,967.9,461.6,965.6,461,961.7z"/>
                                                                <path d="M464.7,963.3l-0.1-3.2c0-0.3,0.2-0.5,0.5-0.6c0.1,0,0.1,0,0.2,0l176.6,50.6c0.3,0.1,0.5,0.4,0.4,0.7
		c0,0.1-0.1,0.1-0.1,0.2l-2.4,3.1c-0.1,0.2-0.4,0.2-0.6,0.2l-174.1-50.5C464.9,963.8,464.7,963.6,464.7,963.3z"/>
                                                                <path d="M451.9,971.9c-2.9,0.7-7.4-6-8.9-8.1c-0.2-0.2-0.4-0.5-0.7-0.6c-0.5-0.3-0.8-0.7-0.9-1.2
		c-0.1-0.4,0.1-0.5,0.4-0.4c3.9,0.8,7.5,6.7,10.2,9.6c0.2,0.2,0.1,0.4,0,0.6C452.1,971.9,452,971.9,451.9,971.9z"/>

                                                                <ellipse transform="matrix(0.4352 -0.9003 0.9003 0.4352 -608.2322 956.3748)" cx="458.2" cy="963" rx="1.6" ry="1.1"/>
                                                                <path d="M456.4,971.4c-0.1,0.2-0.3,0.5-0.6,0.7c-0.4,0.3-0.7,0.3-1-0.1l-1-1c-0.2-0.2-0.2-0.6,0-0.8
		c0.1-0.1,0.1-0.1,0.2-0.1l3.2-1c0.3-0.1,0.6,0.1,0.7,0.3c0.1,0.2,0,0.4-0.1,0.5l-1,1.1C456.6,971,456.5,971.2,456.4,971.4z"/>
                                                                <path d="M671.3,981.3l2.1-0.3c0.3,0,0.5,0.1,0.6,0.4c0,0.1,0,0.3-0.1,0.4c-3.5,4.6-6.4,4.9-11.8,6.4
		c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.3l6-8c0.2-0.3,0.5-0.3,0.8-0.1l1.8,1.5C670.6,981.2,671,981.3,671.3,981.3z"/>
                                                                <path d="M640.5,1018c-0.4-0.1-0.7-0.5-0.6-0.9c0-0.1,0.1-0.2,0.1-0.3l7-9.7c0.1-0.2,0.3-0.2,0.5-0.1
		c0.1,0.1,0.1,0.2,0.1,0.3c0,3.2,1.1,9.5-1.9,11.6c-0.3,0.2-0.6,0.3-0.9,0.2L640.5,1018z"/>
                                                                <path d="M648.1,1029.6c-2.5-2.9-4.9-6-7.1-9.1c-0.1-0.1,0-0.3,0.1-0.3c0,0,0.1,0,0.1,0c8.8,0.1,4.5,8,10.3,9.5
		c0.3,0.1,0.6,0.3,0.9,0.5l0.8,0.8c0.2,0.2,0.2,0.6,0,0.8c-0.1,0.1-0.3,0.2-0.5,0.2C650.8,1031.4,649.4,1031.2,648.1,1029.6z"/>
                                                            </g>
                                                        </g>
                                                        <g id="E1" style={{color: objects[2].elements[0]}} className="action-group">
                                                            <g>
                                                                <path d="M1205.6,121.8c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1c-1.3,4.2-1.9,8-2,12.6c-0.1,34.5-0.4,69-0.8,103.4
                                                                    c0,0.3-0.2,0.5-0.5,0.5c-0.2,0-0.4-0.1-0.5-0.3l-28.7-75.2c-0.2-0.5-0.1-1,0.2-1.4L1205.6,121.8z"/>
                                                                <path d="M1026.2,134.7c0.2-0.3,0.6-0.3,0.9,0c0.1,0.1,0.1,0.2,0.2,0.3c1.4,3.7,2,7.6,2,11.9c-0.1,31-0.2,62-0.3,92.9
                                                                    c0,8.5-5.9,13.6-11.1,19.6c-0.2,0.2-0.5,0.2-0.7,0c-0.1,0-0.1-0.1-0.1-0.2l-33.7-76.1c-0.2-0.5-0.1-1.1,0.2-1.5L1026.2,134.7z"/>
                                                                <path d="M1189.9,266.2c0.3,0,0.6,0.3,0.6,0.6l0.1,14.8c0,0.2-0.1,0.5-0.2,0.7L1009.7,525c-0.2,0.2-0.5,0.3-0.7,0.1
                                                                    c-0.1,0-0.1-0.1-0.2-0.2l-48.2-98.2c-0.3-0.6-0.2-1.2,0.2-1.7l204.1-253.2c1.1-1.3,1.9-1.2,2.5,0.4l25.3,66.1
                                                                    c0.1,0.3,0,0.6-0.3,0.7c-0.1,0-0.1,0-0.2,0l-3.1,0.2c-0.3,0-0.6,0.2-0.8,0.5c-1.7,3.2-3.5,5.9-3.5,9.7c0,5.2,0,10.3-0.2,15.5
                                                                    c0,0.3,0.2,0.6,0.6,0.7L1189.9,266.2z"/>
                                                                <path d="M1015.2,277.8L813.7,517.5c-0.2,0.2-0.6,0.3-0.8,0.1c-0.1,0-0.1-0.1-0.1-0.2l-50.6-90.2
                                                                    c-0.2-0.4-0.2-0.9,0.2-1.3c70.4-78,140.9-155.9,211.5-233.8c0.6-0.7,1.5-1.2,2.1-1.9c0.2-0.2,0.5-0.2,0.6-0.1
                                                                    c0.1,0,0.1,0.1,0.1,0.2l38.7,86.6C1015.5,277.2,1015.4,277.5,1015.2,277.8z M940.9,326.3c1.5,1,2.7,0.9,3.6-0.1
                                                                    c3.6-4,7-7.9,10.2-11.7c0.9-1.2,1.1-2.7,0.5-4.1l-5.3-11.3c-0.6-1.4-2.3-2-3.7-1.3c-0.4,0.2-0.7,0.4-0.9,0.7l-9.8,11.4
                                                                    c-1.1,1.3-1.3,3.1-0.6,4.6l5.2,10.9C940.2,325.6,940.5,326,940.9,326.3z M879.3,398c1.9,0.9,3,0.8,4.4-0.9c3.7-4.2,7.1-8.2,10.2-12
                                                                    c1-1.2,1.2-3,0.5-4.4l-5.8-11.4c-0.7-1.4-2.4-1.9-3.8-1.2c-0.3,0.2-0.6,0.4-0.8,0.6c-3.3,3.7-6.7,7.6-10.4,11.6
                                                                    c-1.6,1.7-1.9,3.4-1,5.2c2.1,4.1,4.1,8.2,6.2,12.1C879.1,397.7,879.2,397.9,879.3,398z M813.7,475.3c1.3,0.2,2.6,0.6,3.5-0.6
                                                                    c3.8-4.4,7.7-9.1,11.9-13.9c0.9-1.1,1.1-2.6,0.4-3.9l-6.4-11.9c-0.8-1.5-2.6-2-4.1-1.2c-0.3,0.2-0.6,0.4-0.9,0.7l-11.7,13.9
                                                                    c-1,1.2-1.2,2.8-0.5,4.1c2.1,4,4.3,8,6.6,12C812.9,475,813.2,475.2,813.7,475.3z"/>
                                                                <path d="M752.6,436.2c0.9-1,1.7-0.9,2.4,0.3l50.6,89.7c0.2,0.4,0.2,0.8-0.1,1.2L542.6,840.1c-0.4,0.4-0.7,0.4-1.1,0
                                                                    l-69.4-92.8c-0.2-0.3-0.2-0.7,0-1L752.6,436.2z M740.9,557.7c2.1,1.7,3.5,1.8,5.3-0.3c4.2-4.8,8.3-9.5,12.1-14
                                                                    c1.2-1.4,1.3-3.4,0.4-5l-6.4-11c-1-1.6-3.1-2.2-4.7-1.2c-0.3,0.2-0.6,0.4-0.8,0.7l-12.4,14.3c-1.2,1.3-1.3,3.3-0.4,4.8l6.8,11.4
                                                                    C740.7,557.5,740.8,557.6,740.9,557.7z M656,637c1.9,3.2,8.1,16.1,12.3,11.3c4.8-5.5,9.3-10.8,13.5-15.8c1.3-1.5,1.5-3.7,0.4-5.5
                                                                    c-2-3.2-4.2-6.8-6.8-10.6c-2.5-3.8-5.1-1.7-7.4,0.9c-3.8,4.3-7.5,8.6-11.2,13C654.8,632.8,654.3,634.2,656,637z M577.5,745.4
                                                                    c2.2,3.1,4.8,3,7.2,0.3c4.9-5.6,9.5-11.1,13.9-16.3c1.4-1.7,1.5-4.1,0.2-5.9l-7.5-10.8c-1.2-1.7-3.6-2.1-5.3-1
                                                                    c-0.3,0.2-0.5,0.4-0.7,0.6l-15,17.2c-1.2,1.4-1.3,3.4-0.3,4.9C572.4,738,574.9,741.6,577.5,745.4z"/>
                                                                <path d="M949.9,438.1c0.7-1,1.6-1.7,2.5-2.2c0.2-0.1,0.5,0,0.7,0.2c0,0,0,0,0,0l48.6,98.5c0.2,0.4,0.2,0.9-0.1,1.3
                                                                    L743.3,882.8c-0.2,0.3-0.6,0.3-0.9,0.1c-0.1,0-0.1-0.1-0.1-0.2l-68.5-101.2c-0.2-0.3-0.2-0.6,0-0.9
                                                                    C761.8,671.3,850,562.1,938.3,453C942.2,448.1,946.1,443.2,949.9,438.1z"/>
                                                                <path d="M460.9,758.1c0.8-0.9,1.6-0.9,2.4,0.1l69.6,92.3c0.3,0.3,0.3,0.8,0,1.2l-56.3,67c-0.3,0.3-0.8,0.4-1.1,0.1
                                                                    c0,0-0.1-0.1-0.1-0.1c-20.2-25.2-40.4-50.4-60.6-75.7c-2.5-3.1-3.1-6-2.8-10.2c0.5-7.2,1.1-14.2,1.7-21c0.1-0.6,0.3-1.3,0.8-1.7
                                                                    L460.9,758.1z M477,842.1c2.2,3,10,17.3,14.9,11.8c5.6-6.4,11-12.7,16.4-19c1.3-1.6,1.4-3.9,0.1-5.6c-2.3-2.9-4.7-6-7.2-9.4
                                                                    c-2-2.8-4.8-4.9-7.6-1.7c-5.5,6.2-11,12.5-16.4,18.8C476,838.5,475.9,840.6,477,842.1z"/>
                                                                <path d="M663.1,793.7c0.5-0.6,1-0.6,1.5,0.1l68.9,100.9c0.3,0.4,0.3,0.9,0,1.2L677,971.8c-0.3,0.4-0.6,0.4-0.9,0
                                                                    c-20-27.4-40.2-54.9-60.4-82.5c-1.8-2.4-2.6-5.3-2.5-8.5c0.4-8.6,0.9-17.2,1.6-26c0.1-0.9,0.4-1.8,1-2.5L663.1,793.7z"/>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        )}

                                        {currentSlide === 3 && (
                                            <div className="obj__it-img finish--3">
                                                <img src={require("../assets/img/objects/baggagebelt.svg").default}/>
                                                <div className="obj__el">
                                                    <svg viewBox="0 0 1931 1086">
                                                        <g
                                                            style={{color: objects[3].elements[1]}}
                                                            id="E2"
                                                            className="action-group">
                                                            <g>
                                                                <path d="M1635.2,592.7L1683.8,94c0-0.2,0.2-0.4,0.4-0.4l88.6-1.1c0.2,0,0.4,0.2,0.4,0.4c0,0,0,0,0,0l-55.8,508.3
			c0,0.2-0.2,0.4-0.4,0.4l-81.4-8.5C1635.3,593.1,1635.2,592.9,1635.2,592.7z"/>
                                                                <path d="M1474.7,413.4c-1.2-0.1-2.7,0-4.4,0.2c-0.3,0-0.5-0.1-0.5-0.4l22.6-316.5c0-0.1,0.1-0.2,0.2-0.2l165.8-2.4
			c0.6,0,0.9,0.3,0.8,0.9l-46.7,495c0,0.5-0.3,0.7-0.8,0.7l-5.5-0.5c-0.3,0-0.5-0.2-0.4-0.5l15.5-166.5c0-0.1-0.1-0.2-0.2-0.2
			C1572.3,419.7,1523.5,416.5,1474.7,413.4z"/>
                                                                <path d="M1298.6,483l18.2-383.3c0-0.6,0.4-0.9,1-0.9l150-2.2c0.4,0,0.8,0.3,0.8,0.8c0,0,0,0,0,0.1l-27,397.8
			c0,0.4-0.4,0.7-0.8,0.7c0,0,0,0,0,0l-141.4-12.1C1298.9,483.8,1298.5,483.4,1298.6,483z"/>
                                                                <path d="M953.2,440.8l0.1,12.6c0,0.4-0.3,0.8-0.8,0.8c0,0-0.1,0-0.1,0l-4.5-0.4c-0.6-0.1-1-0.4-1-1l-1.1-348
			c0-0.5,0.4-0.9,0.9-0.9l347.5-4.8c0.4,0,0.6,0.2,0.5,0.5l-17,381.8c0,0.4-0.2,0.5-0.6,0.5l-133.9-11.4c-0.4,0-0.7-0.4-0.6-0.7
			l5.4-214.2c0-0.3-0.3-0.6-0.6-0.6c0,0,0,0,0,0c-35.9-0.1-71.2-1.9-93.9-2.4c-1.7,0-3.7,0.2-6.1,0.8c-0.3,0.1-0.5,0.3-0.5,0.6
			l-2.4,207.4c0,0.4-0.3,0.6-0.7,0.6c0,0,0,0,0,0l-29.1-2.5c-0.4,0-0.7-0.4-0.7-0.7l0.3-34.4c0-0.2-0.2-0.5-0.4-0.5
			c-4.1-0.2-10.7-1.4-14.5,0c-15.3,5.5-30.5,10.9-45.8,16.4C953.3,440.4,953.2,440.5,953.2,440.8z"/>
                                                                <path d="M814.5,105.7l111.7-1.5c0.3,0,0.5,0.2,0.5,0.5l1.9,346.7c0,0.3-0.2,0.5-0.5,0.5c0,0,0,0-0.1,0l-106.5-9.1
			c-0.3,0-0.5-0.3-0.5-0.5l-7.2-336.2C813.9,106,814.2,105.7,814.5,105.7C814.5,105.7,814.5,105.7,814.5,105.7z"/>
                                                                <path d="M703.4,432.3L691,107.9c0-0.3,0.2-0.6,0.6-0.6c0,0,0,0,0,0l102.8-1.3c0.3,0,0.6,0.3,0.6,0.6l7.9,334.1
			c0,0.3-0.3,0.6-0.6,0.6c0,0,0,0-0.1,0l-98.3-8.4C703.6,432.8,703.4,432.6,703.4,432.3z"/>
                                                                <path d="M576.8,108.9l95.3-1.3c0.3,0,0.6,0.3,0.6,0.6l13,322.3c0,0.4-0.3,0.7-0.6,0.7c0,0-0.1,0-0.1,0l-91.5-7.8
			c-0.3,0-0.6-0.3-0.6-0.6l-16.8-313.3C576.2,109.2,576.4,108.9,576.8,108.9C576.8,108.9,576.8,108.9,576.8,108.9z"/>
                                                                <path d="M485.9,349.6l-16.3-238.5c0-0.3,0.2-0.7,0.6-0.7c0,0,0,0,0,0l88.5-1.2c0.3,0,0.5,0.2,0.5,0.4l13.7,244.9
			c0,0.4-0.2,0.6-0.6,0.6c-27.8-1.8-55.2-3.6-82.2-5.2c-1.1-0.1-2.4,0.3-3.7,0.1C486,350,485.9,349.8,485.9,349.6z"/>
                                                                <path d="M440.8,468c-0.3,0-0.5-0.3-0.6-0.6l-27-355.3c0-0.5,0.3-0.9,0.8-1c0,0,0,0,0.1,0l38.4-0.4
			c0.3,0,0.6,0.2,0.6,0.5l22.6,320.4c0,0.5-0.3,0.9-0.8,1c-7,1.7-13.6,4.5-19.8,8.4c-2.6,1.6-5.3,6.6-5,9.6
			c0.4,5.9,0.9,11.7,1.3,17.6c0,0.4-0.3,0.8-0.7,0.8c0,0-0.1,0-0.1,0L440.8,468z"/>
                                                            </g>
                                                        </g>
                                                        <g
                                                            style={{color: objects[3].elements[0]}}
                                                            id="E1"
                                                            className="action-group">
                                                            <g>
                                                                <path d="M1615,592.7c-0.1,0-0.2-0.1-0.2-0.2c-0.1-1.2-0.1-2.5,0-3.8c15.6-164.7,31.2-329.5,46.8-494.4
			c0-0.3,0.2-0.5,0.5-0.5l18-0.5c0.3,0,0.5,0.2,0.5,0.5l-48.5,499.6c-0.1,0.5-0.5,0.9-1.1,0.9c0,0,0,0,0,0L1615,592.7z"/>
                                                                <path d="M1457.6,498.9l-13.7-1.2c-0.2,0-0.4-0.2-0.4-0.5c0-1.3,0-2.6,0.1-3.9c8.8-132.1,17.8-264.1,27-396.1
			c0-0.4,0.3-0.6,0.7-0.6l16-0.4c0.3,0,0.6,0.2,0.6,0.6c0,0,0,0,0,0.1l-28.4,400.5C1459.4,498.4,1458.8,499,1457.6,498.9z"/>
                                                                <path d="M1476.9,294.8c-3.2,39.4-5.7,79-8.5,118.5c-0.1,2-0.9,4.3-1.1,6.6c-1.2,17.7-2.4,35.4-3.8,53.1
			c-0.5,6.2-1,16.4-1.8,24.6c0,0.3-0.2,0.4-0.5,0.4c0,0,0,0,0,0c-0.2,0-0.3-0.2-0.3-0.4c9.4-133.7,18.8-267.2,28.3-400.8
			c0-0.3,0.3-0.5,0.6-0.5c0.1,0,0.2,0,0.2,0.1l0.5,0.3c0.3,0.1,0.4,0.4,0.3,0.7c-4.1,57.9-8.2,115.8-12.2,173.6
			C1478.1,278.9,1477.5,286.9,1476.9,294.8z"/>
                                                                <path d="M1279.1,482.7l17.1-383.2c0-0.4,0.3-0.6,0.7-0.6l14.3-0.4c0.4,0,0.7,0.3,0.7,0.7c0,0,0,0,0,0l-18,384.8
			c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0l-13.3-1.2C1279.3,483.4,1279.1,483.1,1279.1,482.7z"/>
                                                                <path d="M1313.8,98.8l0.8,0c0.3,0,0.6,0.3,0.6,0.6l-18.1,383.6c0,0.3-0.3,0.6-0.6,0.6l-0.8,0c-0.3,0-0.6-0.3-0.6-0.6
			l18.1-383.6C1313.2,99.1,1313.5,98.8,1313.8,98.8z"/>
                                                                <path d="M930.1,452.9l-2-348.1c0-0.4,0.3-0.7,0.7-0.7l11.1-0.5c0.4,0,0.7,0.3,0.7,0.7c0,0,0,0,0,0l1.4,349.4
			c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0-0.1,0l-10.5-0.8C930.4,453.5,930.1,453.2,930.1,452.9z"/>
                                                                <path d="M942.8,103.9l0.9,0c0.4,0,0.6,0.3,0.6,0.6l1.2,348.7c0,0.4-0.3,0.6-0.6,0.6l-0.9,0c-0.4,0-0.6-0.3-0.6-0.6
			l-1.2-348.7C942.1,104.2,942.4,103.9,942.8,103.9z"/>
                                                                <path d="M804.4,442.2l-7.9-335.9c0-0.3,0.2-0.5,0.5-0.5c0,0,0,0,0,0l10.5-0.1c0.3,0,0.5,0.2,0.5,0.5c0,0,0,0,0,0
			l7.4,336.8c0,0.3-0.2,0.5-0.5,0.5c0,0,0,0-0.1,0l-9.9-0.8C804.6,442.7,804.4,442.5,804.4,442.2z"/>
                                                                <path d="M809.9,105.7l2.3,0c0.2,0,0.4,0.2,0.4,0.4l7.1,336.6c0,0.2-0.2,0.4-0.4,0.4l-2.3,0c-0.2,0-0.4-0.2-0.4-0.4
			l-7.1-336.6C809.5,105.9,809.7,105.7,809.9,105.7z"/>
                                                                <path d="M687.3,432l-13.1-323.8c0-0.3,0.2-0.6,0.5-0.6l9.4-0.5c0.3,0,0.6,0.2,0.6,0.5c0,0,0,0,0,0l12.6,325.1
			c0,0.3-0.2,0.6-0.5,0.6c0,0-0.1,0-0.1,0l-8.9-0.8C687.5,432.5,687.3,432.3,687.3,432z"/>
                                                                <path d="M686.7,107.4l2.3-0.1c0.3,0,0.5,0.2,0.5,0.4L702,432.4c0,0.3-0.2,0.5-0.4,0.5l-2.3,0.1
			c-0.3,0-0.5-0.2-0.5-0.4l-12.5-324.7C686.3,107.6,686.5,107.4,686.7,107.4z"/>
                                                                <path d="M578,422.7l-17.4-312.8c0-0.4,0.3-0.7,0.6-0.7c0,0,0,0,0,0l8.3-0.3c0.4,0,0.7,0.3,0.7,0.6l17.1,313.6
			c0,0.4-0.3,0.7-0.6,0.7c0,0-0.1,0-0.1,0l-8-0.5C578.3,423.3,578.1,423,578,422.7z"/>
                                                                <path d="M572.5,109l1.3-0.1c0.4,0,0.8,0.3,0.8,0.7l17,313.1c0,0.4-0.3,0.8-0.7,0.8l-1.3,0.1c-0.4,0-0.8-0.3-0.8-0.7
			l-17-313.1C571.8,109.4,572.1,109,572.5,109z"/>
                                                                <path d="M455,110.4h8.1c0.2,0,0.5,0.2,0.5,0.4l16.6,239.9c0,0.2-0.2,0.5-0.4,0.5l-1.1,0.1c-0.2,0-0.4,0.3-0.4,0.5
			l5.3,77.8c0,0.2-0.1,0.4-0.4,0.5l-5.5,1.4c-0.3,0.1-0.5-0.1-0.6-0.3c0,0,0-0.1,0-0.1l-22.6-320.3
			C454.5,110.6,454.7,110.4,455,110.4C455,110.4,455,110.4,455,110.4z"/>
                                                                <path d="M465.4,110.5l2.3-0.2c0.2,0,0.4,0.1,0.4,0.3l16.3,239.7c0,0.2-0.1,0.4-0.3,0.4l-2.3,0.2
			c-0.2,0-0.4-0.1-0.4-0.3l-16.3-239.7C465,110.7,465.2,110.5,465.4,110.5z"/>
                                                                <path d="M1049,255l7.3,0.1c0.3,0,0.6,0.3,0.6,0.6l-2.6,209.7c0,0.3-0.3,0.6-0.6,0.6l-7.3-0.1c-0.3,0-0.6-0.3-0.6-0.6
			l2.6-209.7C1048.4,255.3,1048.7,255,1049,255z"/>
                                                                <path d="M1056.6,466.7c-0.5,0-0.8-0.3-0.8-0.9l0-6.2c0-0.4,0.3-0.7,0.7-0.7c0,0,0,0,0.1,0l67.6,5.7
			c0.3,0,0.5-0.2,0.5-0.5c0,0,0,0,0,0l4.4-198.5c0-0.5-0.4-0.9-0.8-0.9l-69.5-2.2c-0.3,0-0.5-0.2-0.5-0.5V256c0-0.4,0.4-0.8,0.8-0.8
			c0,0,0,0,0,0l72.8,2.1c0.2,0,0.4,0.1,0.4,0.4l-5,213.8c0,0.3-0.2,0.5-0.5,0.6c-2.1,0.3-3.5,0.4-4.2,0.3
			C1101.2,470.6,1079.2,468.7,1056.6,466.7z"/>
                                                                <path d="M1129.6,473c-0.5-0.1-0.8-0.5-0.8-1l4.9-213.8c0-0.3,0.3-0.6,0.6-0.6l11.8-0.6c0.2,0,0.3,0.1,0.3,0.3
			c0,0,0,0,0,0l-5.3,212.8c0,0.3-0.1,0.5-0.4,0.7C1137.5,473.1,1133.7,473.8,1129.6,473z"/>
                                                                <path d="M487.7,352c-0.5,0-0.6-0.2-0.1-0.5c0.4-0.3,0.9-0.4,1.6-0.4c25.6,1.6,51.2,3.2,76.9,4.8
			c0.2,0,0.3,0.2,0.3,0.3c0,0.1-0.1,0.3-0.2,0.3c-1,0.4-2.1,0.6-3.4,0.5C537.8,355.5,512.8,353.8,487.7,352z"/>
                                                                <path d="M485.6,361.2l4.5,66.8c0,0.2-0.2,0.5-0.4,0.5l-4.1,1c-0.3,0.1-0.5-0.1-0.6-0.4c0,0,0,0,0-0.1l-5.3-75.7
			c0-0.3,0.2-0.5,0.5-0.5c0,0,0.1,0,0.1,0l82.9,5.4c0.3,0,0.5,0.2,0.5,0.5l3.7,65.1c0,0.3-0.2,0.5-0.5,0.5c0,0-0.1,0-0.1,0l-4.6-0.5
			c-0.2,0-0.4-0.2-0.4-0.5l-3.4-57.3c0-0.3-0.2-0.5-0.5-0.5l-71.8-4.9C485.9,360.7,485.6,360.9,485.6,361.2
			C485.6,361.2,485.6,361.2,485.6,361.2z"/>
                                                                <path d="M569.8,423.9c-0.2,0.2-0.4,0.2-0.6,0.2c-0.2-0.1-0.3-0.2-0.3-0.4l-3.8-64.9c0-0.4,0.2-0.6,0.5-0.7l6.7-1.1
			c0.3-0.1,0.6,0.2,0.7,0.5c0,0,0,0,0,0.1l3.6,64.1c0,0.3-0.1,0.5-0.4,0.6C574,423.1,571.9,423.6,569.8,423.9z"/>
                                                                <path d="M487.7,362.3l4.2,0.3c0.3,0,0.5,0.2,0.5,0.5c0.6,12.2,1.5,24.5,2.7,36.7c0.1,1.4,0.1,3.4-0.1,5.8
			c-0.2,2.4,0.7,4,0.8,6.3c0.2,4.5,0.7,9.1,1.3,13.7c0.1,0.7-0.4,1.4-1.1,1.6l-3.8,0.8c-0.3,0.1-0.5-0.1-0.6-0.4l-4.4-64.8
			C487.1,362.5,487.3,362.3,487.7,362.3z"/>
                                                                <path d="M1470.9,416.1c-0.4,0-0.5-0.2-0.3-0.5c0.3-0.7,1.3-1,2.1-0.9c48.6,3.1,97.3,6.3,146.2,9.5
			c0.3,0,0.5,0.3,0.5,0.6c0,0.1-0.1,0.2-0.1,0.3c-1.3,1.4-3.2,0.6-4.6,0.5C1566.8,422.5,1518.9,419.4,1470.9,416.1z"/>
                                                                <path d="M539.3,425.8c-7.9-4.8,18-10.3,19.7-10.6c0.2,0,0.4,0.1,0.5,0.3c0,0.2-0.1,0.4-0.2,0.4
			c-3.2,1.3-13.9,3.3-13.3,8c0,0.3,0.1,0.5,0.3,0.7c1.9,2.1,4.1,3.4,6.6,3.9c10.1,1.9,20.3,3.2,30.6,3.8c0.2,0,0.4,0.2,0.4,0.5
			c0,0.1,0,0.2-0.1,0.2c-0.2,0.3-0.6,0.4-1,0.4c-13.3-1.2-26.4-2.2-39.4-5.7C542.3,427.4,540.9,426.8,539.3,425.8z"/>
                                                                <path d="M559.5,424.2c-3.9,0.8-8.3,3.9-11.6,0c-0.6-0.6-0.5-1.6,0.2-2.2c0.1-0.1,0.1-0.1,0.2-0.2
			c3.5-2,7.3-3.5,11.3-4.3c0.2-0.1,0.4,0.1,0.5,0.3c0,0,0,0,0,0.1l0.3,5.2C560.3,423.6,560,424.1,559.5,424.2z"/>
                                                                <path d="M1480,428.5c-0.9-0.1-1.4,0.4-1.5,1.3l-4.6,62.5c-0.1,1-0.7,1.9-1.7,2.2l-8.5,3c-0.4,0.1-0.6,0-0.6-0.4
			l5.6-78.9c0-0.4,0.4-0.7,0.8-0.7c0,0,0,0,0,0l148.2,9.7c0.2,0,0.4,0.2,0.4,0.5c0,0,0,0,0,0L1602.8,594c0,0.3-0.3,0.6-0.7,0.6
			c0,0,0,0,0,0l-9.6-1c-0.4,0-0.5-0.3-0.5-0.6l13.8-155.2c0-0.3-0.2-0.6-0.6-0.7c0,0,0,0,0,0L1480,428.5z"/>
                                                                <path d="M958.3,440.6c-0.1,0-0.1-0.1-0.2-0.2c0,0,0.1-0.1,0.2-0.2c13.1-4.8,26.2-9.6,39.3-14.3
			c4.1-1.5,7.5-1.5,11.5-0.8c0.6,0.1,0.6,0.3,0,0.5c-13.4,5-26.7,9.9-40.1,14.7C965.5,441.7,962,441.1,958.3,440.6z"/>
                                                                <path d="M583.8,455.9c-6.6-0.5-44.6-2.6-45-11.1c-0.3-5.8-0.6-11.7-1-17.6c-0.1-0.8,0.2-0.9,0.7-0.3l0.7,0.7
			c0,0,0.1,0.1,0.1,0.1c3.8,1.6,6.8,2.6,8.9,3c11.2,1.8,22.5,3.1,33.8,4c0.6,0,1,0.5,1.1,1.1l1.1,19.8
			C584.3,455.8,584.1,455.9,583.8,455.9z"/>
                                                                <path d="M500.6,430.1c0,0.1-0.1,0.2-0.2,0.3c0,0-0.1,0.1-0.2,0.1c-13.6,2.7-27.8,6.4-39.6,13.8
			c-0.2,0.1-0.4,0-0.5-0.1c0-0.1-0.1-0.1,0-0.2c0.1-0.4,0.2-0.7,0.5-0.9c11.1-7.8,26.5-11.5,39.7-14.1c0.3-0.1,0.6,0.1,0.7,0.5
			c0,0,0,0.1,0,0.1c0,0.3-0.1,0.4-0.2,0.4C500.7,430,500.6,430,500.6,430.1z"/>
                                                                <path d="M1480.4,434c-1.5,18.6-2.3,37.3-4.3,55.8c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.3,0-0.3-0.2
			c1.4-19.6,2.9-39.2,4.3-58.8c0-0.3,0.1-0.5,0.3-0.6c0.2-0.2,0.3-0.2,0.3,0.1C1480.5,432.4,1480.5,433.5,1480.4,434z"/>
                                                                <path d="M493.8,464.1c-0.5,0.2-1.2,0.2-1.9,0.1c-11.7-2.5-22.5-4.1-34.1-9.5c-2.6-1.2-3.9-3.3-5.8-5.3
			c-0.2-0.2-0.3-0.5-0.1-0.8l2.6-5.2c0.1-0.3,0.3-0.4,0.6-0.6c3.8-1.9,7.4-4.4,11.2-5.8c4.2-1.6,8.1-2.8,12-3.9
			c1.3-0.4,1.4-0.3,0.1,0.3c-3,1.3-6.6,1.9-9.5,3.5c-3.1,1.6-6.1,3.3-9,5.1c-0.2,0.1-0.4,0.3-0.5,0.5l-2.8,5.5
			c-0.3,0.5-0.2,1.2,0.2,1.6c2.1,2.3,3.9,4.8,6.9,5.9c4.8,1.9,9.2,3.9,14.3,4.9c5.3,1.1,10.6,2.2,15.8,3.2c0.1,0,0.2,0.2,0.2,0.3
			C493.9,464,493.9,464,493.8,464.1z"/>
                                                                <path d="M694.4,442.2c39.8,3.5,79.5,6.8,119.3,10.6c0.2,0,0.3,0.2,0.3,0.3c0,0.1-0.1,0.2-0.2,0.3
			c-0.3,0.1-0.7,0.2-1.1,0.1c-70.7-6.1-141.5-12.3-212.2-18.5c-4.1-0.4-7.4-0.7-9.7-1.1c-0.2,0-0.3-0.2-0.2-0.4c0-0.1,0-0.1,0.1-0.2
			c0.2-0.2,0.6-0.3,1-0.2c2.8,0.3,5.3,0.8,8,0.8c3.8,0,7.3,0.8,11.2,0.9c2.9,0.1,5.6,1,8.4,0.8c3.6-0.2,7.2,0.7,10.5,0.9
			c3.4,0.2,6.4,0.9,9.7,0.9c1.6,0,3.2,0.1,4.8,0.4c6.2,1.1,12.8,0.5,18.9,1.6c3.3,0.6,6.7,0.4,10,0.9c2.7,0.4,5.5,0.3,8,0.6
			C684.2,441.3,688.6,441.7,694.4,442.2z"/>
                                                                <path d="M586.4,455.6l-1.1-19.9c0-0.4,0.3-0.7,0.7-0.8c0,0,0.1,0,0.1,0l225.8,20c0.4,0,0.7,0.3,0.7,0.7l0.6,21.4
			c0,0.4-0.3,0.7-0.7,0.8c0,0-0.1,0-0.1,0l-225.3-21.5C586.7,456.3,586.4,456,586.4,455.6z"/>
                                                                <path d="M954.8,529.7v-87.3c0-0.3,0.3-0.6,0.6-0.6c0,0,0,0,0.1,0l9.9,0.9c0.3,0,0.6,0.3,0.6,0.6l0.3,87.4
			c0,0.3-0.3,0.6-0.6,0.6c0,0,0,0-0.1,0l-10.1-1C955,530.3,954.8,530,954.8,529.7z"/>
                                                                <path d="M456.6,474.3c-1.8-1.9-3.5-3.6-3.7-6.3c-0.5-5.3-0.9-10.7-1.3-15.9c0-0.2,0.2-0.4,0.4-0.4
			c0.1,0,0.2,0,0.3,0.1c3.6,4.4,7,5.1,12,7.1c6.3,2.5,13.2,3.5,19.8,4.9c10.3,2.1,20.9,3.2,31.5,4.5c0.3,0,0.5,0.2,0.5,0.6l1.4,20
			c0,0.2-0.2,0.5-0.4,0.5c0,0-0.1,0-0.1,0c-14.4-1.3-28.6-3.7-42.6-7.2c-4.8-1.2-9.3-3.3-14-5.2C459,476.3,457.6,475.4,456.6,474.3z
			"/>
                                                                <path d="M819.9,454.4c-0.4,0-0.6-0.2-0.7-0.4c-0.1-0.2,0-0.5,0.2-0.5c0.1,0,0.1,0,0.2,0c27.4,2.3,55.3,5.9,81.9,13.1
			c9.1,2.5,18.9,6.3,25.9,12.9c14.4,13.4-27.4,29.2-34.8,32.1c-3.3,1.3-6.4,1.3-9.7,0.6c-1-0.2-1-0.5-0.1-0.8
			c7.8-2.9,47.1-17.6,35.2-31.4c-5.9-6.9-17.2-11.2-25.8-13.7C869.2,459.3,843.9,456.9,819.9,454.4z"/>
                                                                <path d="M815.7,478.1c-0.4,0-0.6-0.3-0.6-0.7l-0.5-21.4c0-0.5,0.4-0.8,0.8-0.8c0,0,0.1,0,0.1,0
			c17.4,1.5,34.5,3.7,51.4,6.5c10,1.7,52.5,10.2,51.9,24c-0.2,5-6.6,9.8-10.7,11.9c-0.3,0.1-0.5,0.1-0.8,0
			c-9.5-4.6-18.5-7.9-27.2-9.7C859.1,483.6,837.6,480.3,815.7,478.1z"/>
                                                                <path d="M519.7,489.1l-1.4-19.6c0-0.4,0.3-0.8,0.7-0.8c0,0,0.1,0,0.1,0l233.2,23.8c0.4,0,0.7,0.4,0.7,0.7l0.7,21.2
			c0,0.4-0.3,0.8-0.8,0.8c0,0-0.1,0-0.1,0l-232.5-25.4C520,489.7,519.7,489.4,519.7,489.1z"/>
                                                                <path d="M967.5,491.4c0-0.1,0.1-0.2,0.2-0.3l44.2-19c0.3-0.1,0.4,0,0.5,0.2c0.6,1.8,0.2,2.9-1.6,3.7
			c-14.3,6.2-28.6,12.4-43,18.7c-0.2,0.1-0.4,0-0.5-0.2c0,0,0-0.1,0-0.2L967.5,491.4z"/>
                                                                <path d="M458.1,499.5c-1.6-1.9-3.3-3.4-3.5-6.1c-0.4-6.1-0.9-12.3-1.3-18.4c0-0.2,0.1-0.5,0.4-0.5
			c0.2,0,0.3,0.1,0.4,0.2c3.1,4.5,7.5,5.4,12.3,7.5c6.2,2.7,13.1,3.7,19.7,5.2c10.3,2.3,20.8,3.4,31.3,4.6c0.2,0,0.4,0.2,0.4,0.4
			l1.5,23.1c0,0.4-0.1,0.5-0.5,0.5c-9.7-1.4-19.6-2.4-29-4.5c-4.6-1-9.1-2.1-13.7-3.2C470.9,507,461.7,503.6,458.1,499.5z"/>
                                                                <path d="M1001.2,506.6l0.3-24.7c0-0.2,0.1-0.3,0.3-0.4l9.7-4.1c0.2-0.1,0.5,0,0.6,0.2c0,0.1,0,0.1,0,0.2l-0.2,29.8
			c0,0.2-0.2,0.4-0.5,0.4c0,0,0,0,0,0l-9.8-1C1001.3,507.1,1001.2,506.9,1001.2,506.6z"/>
                                                                <path d="M929.8,491.3c0.1-0.1,0.3-0.2,0.4-0.1c0.1,0,0.1,0.1,0.1,0.2c1,4.6-0.2,8.6-3.7,11.8
			c-9.6,9-23.7,15.1-35.9,19.7c-0.2,0.1-0.4,0-0.5-0.2c0,0,0-0.1,0-0.1V515c0-0.6,0.4-1.2,1-1.4c11.9-4.5,24.2-9.9,34.4-17.7
			C927,494.9,928.4,493.3,929.8,491.3z"/>
                                                                <path d="M452.3,754.8c-15.2-3-47.6-11.4-47.6-32c0-0.9,0.6-2.6,1.9-5c3.2-5.9,9.7-11.2,15.3-14.7
			c14.8-9.2,31.3-16,47.5-22.6c39.3-16,78.6-32.1,117.9-48.2c0.3-0.1,0.6,0,0.7,0.3c0,0.1,0,0.1,0,0.2l0,4.8c0,0.4-0.2,0.6-0.5,0.8
			c-36.5,15.2-72.9,30.3-109.4,45.3c-10.9,4.5-21.5,9.5-31.9,15c-7.7,4.1-15,9-19.6,16c-8.5,12.8,7.3,23.8,17.4,28
			c7.1,2.9,14.4,5.1,21.9,6.6c19.6,3.9,39.5,5.6,59.6,5c25-0.7,50.1-3.3,74.8-7.8c27.4-5.1,50.3-15.9,75.5-29.1
			c122.6-64,245.1-128,367.7-192c23-12,43.5-17.8,70.2-22.2c60.5-10,126-10.7,188.6-6.8c42.2,2.7,99.3,11.5,149.1,5
			c8-1,20.4-3.2,25.3-9.1c0.2-0.2,0.4-0.3,0.6-0.3l9,0.7c0.3,0,0.6,0.3,0.5,0.6c0,0.2-0.1,0.4-0.3,0.5c-1,0.6-1.6,1.6-2.6,2.1
			c-8.2,3.4-17,5.6-26.3,6.7c-33.4,4-63.4,2.9-98.3-0.3c-30.3-2.8-53.1-4.6-68.4-5.3c-39.1-2-76.7-2-114.4,0.4
			c-23.9,1.5-47.6,4.7-71,9.5c-18.5,3.8-36.1,10.3-52.6,19.3c-5.4,2.9-8.1,4.4-8.1,4.4c-121,64.5-242,128.9-363,193.4
			c-27.9,14.9-54.2,24.3-85.2,29.1C547.3,760.8,500.4,764.1,452.3,754.8z"/>
                                                                <path d="M521.5,515.9L520,493c0-0.3,0.2-0.6,0.6-0.7c0,0,0.1,0,0.1,0L753.3,518c0.3,0,0.6,0.3,0.6,0.6l0.5,18.5
			c0,0.3-0.2,0.5-0.4,0.6l-12,4.6c-0.1,0-0.2,0.1-0.3,0L522,516.5C521.7,516.5,521.5,516.2,521.5,515.9z"/>
                                                                <path d="M756.6,515.4c-0.4-0.1-0.6-0.3-0.6-0.7l-0.7-21.4c0-0.3,0.2-0.5,0.5-0.5c0,0,0,0,0.1,0
			c16.8,1.5,33.8,3,50.1,7.9c3.2,1,13.8,5.7,10.1,10.1c-4.4,5.1-11.9,7.9-18,10c-0.6,0.2-1.3,0.3-1.9,0.2c-11.3-2.1-22.6-3.7-34-4.7
			C760.5,516,758.7,515.7,756.6,515.4z"/>
                                                                <path d="M779,493.8c-0.3-0.1-0.5-0.1-0.8-0.1c-0.2,0-0.3-0.1-0.3-0.4c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.1
			c4.9,0.8,10,1.2,14.9,2.4c1.3,0.3,2.6,0.1,3.8,0.4c4.8,1.1,9.7,2.3,14.4,3.7c3.5,1,13.3,5.3,12.7,9.6
			c-0.9,6.1-19.1,12.5-24.3,14.2c-0.7,0.2-1.6,0.3-2.3,0.2c-0.3,0-0.4-0.3-0.4-0.5c0-0.2,0.1-0.3,0.3-0.4c5.2-1.9,10.2-4.1,14.9-6.6
			c2.3-1.2,3.7-3.1,5.6-4.9c0.2-0.2,0.3-0.4,0.3-0.7c0.1-1.9,0.1-3.5-1.3-4.8C807.2,496.4,791.4,496.2,779,493.8z"/>
                                                                <path d="M422.7,788.6c-6-3.9-13.3-10.5-14-18.2c-1.1-12.5-2.2-25-3.2-37.4c0-0.2,0.1-0.3,0.3-0.4
			c0.1,0,0.3,0.1,0.3,0.2c3.1,6.7,13.5,12.9,19.7,15.5c9.2,3.8,19.4,6.7,30.6,8.7c38.5,6.9,76,5.7,115.4,0.9
			c17.8-2.2,33.4-4.8,46.8-7.9c24.5-5.7,46.7-15.7,68.8-27.5c124.1-66.1,248.2-132.3,372.4-198.4c18.1-9.7,38-14.9,59.8-18.6
			c47.3-8,96.4-10,144.3-8.2c7.6,0.3,15.5,0.3,22.8,0.8c25.7,1.6,51.4,3.7,77.1,6.1c33.5,3.2,68.3,3.7,100.3-1
			c6-0.9,12.7-2.8,20.2-5.9c0.3-0.1,0.5-0.3,0.8-0.5l1.8-1.5c0.2-0.2,0.5-0.1,0.6,0.1c0.1,0.1,0.1,0.2,0.1,0.3l-1.9,23.6
			c0,0.3-0.1,0.5-0.3,0.7c-5,6.7-21,9.6-29.1,10.6c-27,3.5-52.4,3.5-80.6,1.1c-22.4-1.9-44.7-3.8-67.1-5.7
			c-43.4-3.7-86.9-4-130.4-1.1c-32.1,2.2-63.3,6-92.2,15.3c-11.7,3.8-22.1,9.2-34.1,16.1C930,626.6,807.9,696.8,685.7,767
			c-11.9,6.9-26.7,14.7-40.1,19.8c-13.7,5.3-28.7,9.4-45.1,12.3c-27.4,4.9-52.8,7.6-76.2,8.2c-15.8,0.4-31.6-0.4-47.3-2.5
			C459.1,802.4,438.4,798.6,422.7,788.6z"/>
                                                                <path d="M1008.2,509.1c0.4,0.1,0.6,0.2,0.7,0.3c0.2,0.3,0.2,0.5-0.1,0.7l-40.4,19.4c-0.3,0.2-0.5,0.1-0.5-0.3v-4.6
			c0-0.4,0.2-0.6,0.5-0.8c9.9-4.7,19.9-9.3,29.8-14C1001.4,508.2,1004.7,508.6,1008.2,509.1z"/>
                                                                <path d="M824.3,513.1c-2.1,2.4-3.3,4.1-6.2,5.7c-5.6,3-11.5,5.6-17.5,7.7c-0.3,0.1-0.4,0-0.5-0.2
			c-0.4-0.9-0.1-1.6,0.9-1.9c6.3-2.2,12.4-4.8,18.3-8c1.8-1,3-2.4,4.4-3.8c0.2-0.2,0.5-0.2,0.7,0
			C824.5,512.6,824.5,512.9,824.3,513.1L824.3,513.1z"/>
                                                                <path d="M884.6,514c0.3,0,0.4,0.1,0.5,0.2c0.1,0.2,0,0.5-0.2,0.6c0,0,0,0-0.1,0l-182,75.6c-0.3,0.1-0.5,0-0.5-0.3
			v-4.2c0-0.4,0.2-0.6,0.5-0.7c57.7-23.6,115.4-47.2,173.1-70.8C878.8,513.1,881.6,513.6,884.6,514z"/>
                                                                <path d="M702.9,602.7l-0.5-10.3c0-0.2,0.1-0.4,0.3-0.5L887.8,515c0.3-0.1,0.5,0,0.7,0.3c0,0.1,0,0.1,0,0.2v8.2
			c0,0.2-0.1,0.4-0.3,0.5l-184.6,79c-0.3,0.1-0.5,0-0.7-0.3C702.9,602.8,702.9,602.8,702.9,602.7z"/>
                                                                <path d="M501.3,515.7c-0.1,0-0.2-0.2-0.2-0.3c0-0.1,0.2-0.2,0.3-0.2l26.9,3.5c0.3,0,0.4,0.3,0.4,0.5c0,0,0,0,0,0
			c-0.1,0.4-0.3,0.5-0.8,0.5C519.1,518.9,510.2,517.5,501.3,515.7z"/>
                                                                <path d="M790.5,522.6c0.3,0.1,0.6,0.2,0.8,0.3c0.1,0.1,0.1,0.3,0,0.4c0,0-0.1,0.1-0.1,0.1l-34.1,13.1
			c-0.2,0.1-0.5,0-0.5-0.2c0,0,0-0.1,0-0.1l-0.5-17c0-0.4,0.3-0.7,0.6-0.7c0,0,0,0,0.1,0C768,519.4,779.3,520.8,790.5,522.6z"/>
                                                                <path d="M531.2,520.1c-0.2,0-0.4-0.3-0.4-0.5c0,0,0,0,0,0c0.1-0.4,0.3-0.5,0.7-0.5c54.4,6.4,108.9,12.7,163.3,19.1
			c0.1,0,0.2,0.1,0.2,0.2l0.2,0.4c0,0.1,0,0.1-0.1,0.1c-4.3-0.3-7.9-0.9-12.8-1c-1.9,0-4.1-0.2-6.8-0.5
			c-45.8-5.4-91.6-10.8-137.3-16.2C536,520.9,533.6,520.5,531.2,520.1z"/>
                                                                <path d="M701.2,560.4c0.2-0.6,0.3-0.9,0.5-1c29.6-11.4,59.3-22.9,88.9-34.3c1.3-0.5,2.6-0.4,3.9-0.3
			c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.2-0.2,0.3c-30.9,12-61.6,24-92.2,35.8c-0.6,0.2-1,0.1-1.2-0.5
			C701.1,560.6,701.1,560.5,701.2,560.4z"/>
                                                                <path d="M701.5,563.4c0.1-0.3,0.2-0.5,0.5-0.6l95.4-37.1c0.2-0.1,0.4,0,0.5,0.2c0.5,0.9,0.3,1.5-0.4,1.8
			c-31.8,12.5-63.6,25-95.5,37.5c-0.3,0.1-0.6,0-0.7-0.3c0-0.1-0.1-0.2,0-0.3L701.5,563.4z"/>
                                                                <path d="M1365,552.8c-0.6,0-1.2-0.1-1.7-0.1c-0.3,0-0.4-0.2-0.2-0.4c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1
			c9,0.3,17.9,1.5,26.9,1.7c4,0.1,8.1,0.6,11.7,0.7c9.9,0.2,19.8,0.2,29.7,0.7c10.1,0.5,22.1-0.3,30.1-0.4
			c21.1-0.2,36.8-1.5,58.8-3.8c10.2-1.1,20.2-3.1,30.2-4.9c8.9-1.6,17.3-5.1,25.8-8.1c3.7-1.3,12.2-7.6,13.6-11.3
			c0.1-0.3,0.3-0.4,0.6-0.4l4.4,0.4c0.7,0.1,1.2,0.7,1.1,1.3c0,0.3-0.2,0.6-0.4,0.8c-3.7,3.3-7.7,7.5-12.4,9.2
			c-8.4,3-16.7,6.5-25.4,8.2c-11,2.1-21.9,4.4-33,5.6c-21.3,2.3-42,4.1-63.9,4.2c-2.1,0-5.6,0.1-10.3,0.4
			c-5.2,0.3-10.3,0.1-15.7,0.1c-2.4,0-5-0.5-7.1-0.5c-7.8,0-15.5-0.1-23.3-0.3c-1.6,0-3.4,0.3-4.8,0.2
			C1388.4,554.6,1376.7,553.6,1365,552.8z"/>
                                                                <path d="M1286.7,573.6c-22.7-2.2-45.5-1.9-68,0.1c-14.5,1.3-28.5,4-41.3,9.2c-2.3,0.9-4.4,2.2-6.5,3.7
			c-42.4,31.3-84.7,62.7-127,94.2c-0.2,0.1-0.4,0.1-0.5-0.1c0-0.1-0.1-0.1-0.1-0.2l0.7-32c0-0.2,0.1-0.4,0.3-0.6
			c43-29.9,85.7-60.3,129.2-89.4c3.8-2.6,10.9-5,13.9-5.8c9.5-2.5,18.8-4.2,27.9-5.2c24.4-2.6,49.2-2.6,74-0.4
			c17.5,1.6,35,3.3,52.4,5c16,1.6,32.1,2.5,48.1,3.6c5.3,0.4,10.9,0.6,16.9,0.7c12.5,0.1,24.9,0.7,37.5,0.8
			c9.8,0.1,20.2-0.7,30.3-0.8c8.9-0.1,17.4-0.3,26-1.2c13.3-1.4,26.8-2.2,39.9-4.6c9.8-1.8,19.9-3.1,29.3-6.4
			c5.4-1.9,10.7-3.8,16-5.8c3.6-1.3,6.9-5,9.8-7.4c0.2-0.1,0.4-0.1,0.5,0c0.1,0.1,0.1,0.2,0.1,0.3l-2.1,23.2c0,0.3-0.1,0.5-0.3,0.7
			c-2,2.3-4.2,4.3-6.5,6.3c-1.8,1.5-4,2.8-6.6,3.8c-9.1,3.7-17.7,7.2-27.6,9c-9.6,1.8-19.1,4-28.6,5.1c-14.2,1.6-28.4,3.4-42.8,3.8
			c-11.6,0.3-23.2,0.7-34.9,1.2c-9.7,0.4-21.3-0.5-30.1-0.6C1373.1,583.4,1329.8,577.7,1286.7,573.6z"/>
                                                                <path d="M698,539.3c-0.2,0-0.4-0.1-0.4-0.3c-0.1-0.3,0-0.4,0.4-0.4c13.2,1.6,26.6,3.1,40,4.7c0.2,0,0.3,0.2,0.2,0.3
			c0,0.1-0.1,0.2-0.1,0.2c-0.8,0.6-1.8,0.8-3,0.6c-11.1-1.3-22.2-2.7-33.3-4c-0.3,0-0.5-0.1-0.8-0.3
			C700.2,539.5,699.2,539.3,698,539.3z"/>
                                                                <path d="M590.2,573.8c-0.4,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.2-0.3-0.1-0.5c0-0.1,0.1-0.1,0.2-0.1
			c30.8-11.1,61.7-22.2,92.5-33.2c3.7-1.3,9.3,0,13.2,0.6c0.2,0,0.3,0.2,0.3,0.4c0,0.1-0.1,0.2-0.2,0.3
			c-30.3,11.1-60.7,22.3-91.2,33.5C600.6,575.8,593.9,574.2,590.2,573.8z"/>
                                                                <path d="M1037.5,646.6c-0.3,0-0.5-0.3-0.5-0.6c0-0.2,0.1-0.3,0.2-0.4c42.3-29,84.2-58.1,126.3-86.5
			c4.9-3.3,11.6-6.1,16.4-7.4c15.5-4.2,31.3-6.7,47.4-7.3c4.1-0.2,8.3-0.7,12.2-0.8c10.8,0,21.6,0,32.4,0c0.1,0,0.2,0,0.3,0.1
			c0.2,0.1,0.3,0.1,0.3,0.1c0.2,0.1,0.3,0.3,0.2,0.5c-0.1,0.2-0.2,0.3-0.4,0.3c-27.2-0.4-54.3-0.5-81.2,5.7
			c-5.6,1.3-13.1,3.6-18,6.8c-4.2,2.8-8.4,5.6-12.6,8.5c-38.4,26.7-76.8,53.3-115.1,80C1042.3,647.7,1040.8,646.8,1037.5,646.6z"/>
                                                                <path d="M1280.3,544.5c5.2,0.2,10.4,0.6,15.6,1.1c20.3,1.9,40.6,4.6,60.9,5.7c1.7,0.1,3.4,0.4,5.1,0.9
			c0.2,0.1,0.2,0.2,0.1,0.3c-0.1,0.2-0.3,0.2-0.6,0.2c-4.2-0.6-8.9-0.6-12.1-0.9c-21.4-2.3-42.9-4.3-64.3-6.2
			c-2.4-0.2-4.8-0.4-7.2-0.5c-0.2,0-0.3-0.2-0.3-0.4c0-0.1,0.1-0.2,0.2-0.3C1278.6,544.1,1279.7,544.5,1280.3,544.5z"/>
                                                                <path d="M1583.5,594.3c-1.1,1.1-2.5,2-3.9,2.7c-8.2,3.5-17.6,8-26.7,10c-6,1.3-12,2.5-17.9,3.8
			c-12.1,2.5-24.4,3.5-36.6,4.9c-5.7,0.7-11.3,1.1-16.8,1.3c-16.5,0.6-33.1,1.7-49.5,1.2c-16.9-0.4-33.9-0.5-50.8-2
			c-15.3-1.3-30.7-2.3-45.9-4.1c-27.2-3.3-45.1-5.2-53.6-5.9c-20.1-1.7-40.1-1.6-60.2,0.1c-15.1,1.3-31.1,4-44.5,10.2
			c-6.6,3-13.8,9.3-18.3,12.9c-38.4,30.8-76.8,61.5-115.2,92.1c-0.1,0.1-0.3,0.1-0.4,0c0-0.1-0.1-0.1-0.1-0.2l0.8-36.1
			c0-0.3,0.1-0.5,0.4-0.7c42.6-31.8,84.9-63.9,127.9-95.2c1.6-1.2,3.2-2.1,4.9-2.8c16.7-7.4,35.5-9.5,53.9-10.7
			c20.1-1.3,40.5-0.9,61.2,1.3c10.1,1.1,31.6,3.2,64.4,6.3c21.9,2.1,47.4,3.4,76.3,4c16.7,0.3,32.1-0.4,48.1-0.9
			c14-0.4,27.6-2,41.4-3.6c11.3-1.3,22.4-3.7,33.6-5.9c9.2-1.8,18.1-5.9,26.8-9.2c4-1.5,7.3-5.4,10.4-8.3c0.1-0.1,0.4-0.1,0.5,0
			c0.1,0.1,0.1,0.2,0.1,0.3l-2.4,26.3c0,0.5-0.3,0.9-0.6,1.3L1583.5,594.3z"/>
                                                                <path d="M593.2,700.4l-7-125.2c0-0.3,0.2-0.6,0.5-0.6c0,0,0.1,0,0.1,0l14.2,1.8c0.3,0,0.5,0.3,0.5,0.5l6.8,125.9
			c0,0.3-0.2,0.6-0.5,0.6c0,0-0.1,0-0.1,0l-13.9-2.5C593.4,700.9,593.2,700.7,593.2,700.4z"/>
                                                                <path d="M364.4,690.7h-1.5c-0.2,0-0.4-0.2-0.3-0.4c0-0.1,0.1-0.3,0.2-0.3l223.1-86c0.2-0.1,0.5,0,0.6,0.3
			c0,0,0,0.1,0,0.2v1.2c0,0.4-0.2,0.6-0.5,0.7c-72.2,28.2-144.4,56.3-216.6,84.5c-1,0.4-2.4,0.4-4.1-0.1
			C365,690.7,364.7,690.7,364.4,690.7z"/>
                                                                <path d="M606.9,651.3c-0.2-1-0.3-1.9-0.1-2.9c0-0.1,0-0.3,0-0.4c-0.4-1.2,0.2-2,1.3-2.5c31-13.3,62-26.6,93-39.9
			c0.2-0.1,0.4,0,0.5,0.2c0,0.1,0,0.1,0,0.2v4.4c0,0.3-0.2,0.6-0.5,0.7l-93.4,40.7C607.3,651.9,607,651.8,606.9,651.3z"/>
                                                                <path d="M1289.9,610.2c-30.3-3.2-62.2-3.2-92.4,1.5c-0.8,0.1-0.9,0-0.1-0.3c0.8-0.4,1.9-0.7,3.1-0.9
			c28.1-5,57.3-5.4,85.6-2.5c17.4,1.8,34.8,3.7,52.2,5.8c24.7,2.9,49.7,4.6,74.6,5.4c9.4,0.3,18.7,0.5,28.1,0.4
			c13.2,0,27.3-0.9,40.9-1.4c11.4-0.4,22.8-2,34.2-3.1c1-0.1,1.9-0.1,2.8-0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
			c-9.9,1.3-19.8,2.5-29.7,3.6c-15.1,1.6-30.5,1.7-45.7,2.3c-5.7,0.2-11.3,0.2-16.9,0.1c-15.2-0.4-30.5-0.6-45.7-1.8
			c-14.9-1.2-29.7-2.3-44.5-4C1320.9,613.6,1305.4,611.9,1289.9,610.2z"/>
                                                                <path d="M368.6,695.5l-0.1-2.6c0-0.2,0.1-0.3,0.3-0.4l217.4-84.7c0.2-0.1,0.4,0,0.5,0.2c0,0,0,0.1,0,0.1v2
			c0,0.2-0.1,0.3-0.3,0.4l-217.3,85.3c-0.2,0.1-0.4,0-0.5-0.2C368.6,695.6,368.6,695.6,368.6,695.5z"/>
                                                                <path d="M688.4,653.9l-1.5-34.7c0-0.2,0.1-0.4,0.3-0.5l12.9-5.6c0.3-0.1,0.6,0,0.7,0.3c0,0.1,0,0.1,0,0.2l1.6,42.4
			c0,0.3-0.2,0.6-0.5,0.6c0,0-0.1,0-0.1,0l-13.1-2C688.6,654.3,688.4,654.1,688.4,653.9z"/>
                                                                <path d="M428.2,729.2c-14.8-17.6,33.1-36.8,42.9-40.9c38.6-16.1,77.5-32.3,116.4-48.4c0.5-0.2,0.8,0,0.8,0.5l0.7,10.8
			c0,0.7-0.2,1.1-0.9,1.4c-38.2,16.3-76.5,32.7-114.7,49.2c-14.3,6.2-35.1,14.7-44.6,27.5C428.8,729.3,428.5,729.4,428.2,729.2
			C428.3,729.2,428.3,729.2,428.2,729.2z"/>
                                                                <path d="M844.1,777.8L1033.2,648c0.1,0,0.1-0.1,0.2-0.1l6.5,0.7c0.2,0,0.3,0.2,0.3,0.4c0,0.1-0.1,0.2-0.1,0.2
			L852.3,779.8c-0.1,0-0.2,0.1-0.2,0.1l-7.8-1.5c-0.2,0-0.3-0.2-0.3-0.4C844,777.9,844,777.8,844.1,777.8z"/>
                                                                <path d="M1041.5,682.5L853.9,821.9c-0.1,0.1-0.3,0.1-0.4,0c0,0-0.1-0.1-0.1-0.2l-0.5-40.6c0-0.1,0-0.2,0.1-0.2
			L1041.4,650c0.1-0.1,0.3-0.1,0.4,0.1c0,0,0.1,0.1,0,0.2l-0.2,32C1041.6,682.4,1041.6,682.4,1041.5,682.5z"/>
                                                                <path d="M699.8,657.5c0.3,0.1,0.4,0.2,0.5,0.4c0,0.1,0,0.2-0.1,0.2l-88.7,42.5c-0.3,0.2-0.7,0-0.8-0.3
			c0-0.1-0.1-0.2-0.1-0.2l-0.3-7.2c0-0.4,0.2-0.7,0.5-0.8c25.4-11.9,50.7-23.9,76.1-35.9C688.9,655.2,697.6,657.1,699.8,657.5z"/>
                                                                <path d="M854,826.1l187-139.4c0.1-0.1,0.3-0.1,0.4,0.1c0,0.1,0.1,0.1,0.1,0.2l-0.5,36.3c0,0.1,0,0.2-0.1,0.2
			l-186,148.7c-0.1,0.1-0.3,0.1-0.4-0.1c0-0.1-0.1-0.1-0.1-0.2l-0.5-45.5C853.8,826.3,853.9,826.2,854,826.1z"/>
                                                                <path d="M254.4,749.4c-6.9,8-13.8,16.1-20.7,24.1c-0.2,0.2-0.3,0.5-0.4,0.8l-4.8,24.6c-0.1,0.4,0,0.9,0.2,1.3
			c4.9,8.3,9.9,16.7,14.9,25.1c0.6,1.1,1.5,1.9,2.5,2.5c12.1,7.3,24.2,14.5,36.4,21.8c0.7,0.4,1.5,0.8,2.4,1
			c17.6,5,35.1,9.9,52.7,14.9c3.7,1.1,7.4,1.8,11.2,2.3c20.5,2.7,41,5.2,61.5,7.7c2.4,0.3,4.7,0.5,6.9,0.5c26,0.6,52,0.4,78,0.8
			c5.5,0.1,11-0.1,16.4-0.6c22.9-2,45.8-4,68.6-6.2c5.6-0.5,11.1-1.3,16.7-2.3c23.1-4.3,46.2-8.7,69.3-13c5.1-1,10.1-2.2,15-3.7
			c20.3-6.2,40.6-12.4,60.9-18.6c3.8-1.2,7.5-2.6,11.1-4.3c15.5-7.3,30.9-14.6,46.4-21.9c2.2-1,4.2-2.2,6.2-3.5
			c10.9-7.2,22.1-14.6,33.6-22.3c0.6-0.4,1.3-0.6,2.1-0.4l7.3,1.2c0.2,0,0.3,0.2,0.3,0.4c0,0.1-0.1,0.2-0.2,0.3
			c-11.1,7.5-22.2,14.9-33.1,22.3c-1.7,1.1-3.4,2.1-5.2,3c-16.6,8-33.3,16-50,23.8c-3.6,1.7-7.4,3.1-11.3,4.3
			c-20.9,6.4-41.8,12.8-62.7,19.2c-4.8,1.5-9.7,2.7-14.7,3.7c-24.8,4.7-49.7,9.4-74.6,14.1c-5.5,1-11.1,1.8-16.7,2.3
			c-23.2,2.1-46.3,4.2-69.5,6.3c-5.6,0.5-11.2,0.7-16.9,0.7c-26.2-0.1-52.5-0.3-78.7-0.7c-5.4-0.1-10.9-0.4-16.3-1.1
			c-20.4-2.5-40.8-5.1-61.2-7.7c-3.7-0.5-7.4-1.2-11-2.3c-17.8-5.1-35.5-10.2-53.3-15.3c-2.1-0.6-4-1.4-5.8-2.6
			c-11.9-7.3-23.8-14.5-35.7-21.7c-1-0.6-1.7-1.4-2.3-2.4c-4.9-8.7-9.8-17.3-14.6-25.8c-0.2-0.4-0.3-0.9-0.2-1.3l5.3-24.4
			c0.1-0.6,0.4-1.1,0.8-1.5c7.8-8.9,15.7-17.9,23.7-26.9c0.5-0.5,1.4-1.2,2.8-2.1c9.9-6.4,19.8-12.7,29.7-19.1
			c3.1-2,6.3-3.7,9.6-5.2c22.8-10.1,43.3-19.4,68.9-29c2.6-1,4.4-0.9,6.8,0c0.2,0.1,0.2,0.2,0.2,0.4c0,0.1-0.1,0.2-0.2,0.2
			c-10.6,4.1-21.1,8.1-31.5,12.8c-11.1,4.9-22.2,9.9-33.3,14.9c-3.8,1.7-7.5,3.7-10.9,5.8c-9.4,6-18.8,12.1-28,18.4
			C257.5,746.3,255.9,747.7,254.4,749.4z"/>
                                                                <path d="M257.3,748.3c1.6-1.8,4.8-3.5,6.4-4.6c8.4-5.6,16.9-11.2,25.4-16.6c3.2-2,6.6-3.8,10.3-5.5
			c21.9-9.7,41.1-18.7,66.1-27.9c0.4-0.1,0.6,0,0.6,0.4l0.2,2.3c0,0.4-0.1,0.6-0.5,0.7c-25.9,9.9-45.7,19-68.3,29.3
			c-3.5,1.6-6.8,3.4-10,5.5c-9.2,6-18.3,12-27.3,18.1c-1.8,1.2-3.4,2.6-4.8,4.2c-7,8.2-14,16.4-21,24.6c-0.2,0.2-0.3,0.2-0.3-0.1
			c-0.2-2.1,0-3.6,0.6-4.3C246.2,761.1,253.7,752.3,257.3,748.3z"/>
                                                                <path d="M863.2,817.6c-3.3,2.4-6.6,4.9-9.9,7.3c-0.3,0.2-0.4,0.1-0.5-0.2c0-0.2,0-0.3,0.2-0.4
			c39.4-29.4,78.9-58.7,118.4-88c0.4-0.3,0.5-0.2,0.3,0.2c-0.5,1-1.8,1.5-2.5,2c-35,26-70,52.1-104.9,78.3c0,0-0.2,0.2-0.4,0.4
			c-0.1,0.1-0.2,0.2-0.3,0.2C863.4,817.5,863.3,817.6,863.2,817.6z"/>
                                                                <path d="M403.5,928.8c-20.2-2.6-40.4-5.2-60.5-8.1c-3.7-0.5-7.3-1.4-10.9-2.5c-17.7-5.4-35.4-10.8-53-16.3
			c-2-0.6-3.9-1.5-5.6-2.6c-11.9-7.7-23.8-15.4-35.7-23.1c-1-0.7-1.8-1.5-2.4-2.6c-4.6-8.6-9.2-17.2-13.8-25.7
			c-0.7-1.4-1.2-2.9-1.4-4.5c-1.6-12.3-3-24.6-4.3-36.8c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2,0.1,0.3,0.2c4.2,7.5,8.5,15,12.7,22.5
			c0.6,1,1.3,1.8,2.3,2.4c12.2,7.5,24.4,14.9,36.7,22.3c1.8,1.1,3.8,2,5.9,2.5c17.4,5,34.9,10,52.3,15c3.8,1.1,7.5,1.9,11.4,2.4
			c20.4,2.7,40.7,5.3,61.1,7.8c5.6,0.7,11.2,1.1,16.8,1.1c26.2,0.4,52.5,0.7,78.7,0.8c5.6,0,11.2-0.2,16.9-0.7
			c23.2-2.1,46.5-4.2,69.7-6.3c5.6-0.5,11.2-1.3,16.7-2.3c26.5-5,53-10,79.5-15c2-0.4,3.9-0.9,5.9-1.5c23.3-7.1,46.5-14.3,69.7-21.7
			c3.7-1.2,7.3-2.6,10.8-4.3c14.6-7.1,29.2-14,43.8-21c3.6-1.7,7-3.6,10.2-5.7c11-7.3,21.9-14.7,32.8-22.1c0.1-0.1,0.3-0.1,0.4,0.1
			c0,0.1,0.1,0.1,0.1,0.2l0.5,40.2c0,0.3-0.1,0.5-0.3,0.7c-10.7,7.8-21.5,15.6-32.2,23.4c-3,2.2-6.3,4.1-9.6,5.9
			c-17.1,8.7-32.9,16.8-47.3,24.2c-2.1,1.1-4.2,1.9-6.4,2.7c-23.1,7.7-46.2,15.3-69.4,22.8c-4.7,1.5-9.6,2.8-14.5,3.8
			c-25.7,5.1-51.4,10.3-77.1,15.4c-1.7,0.3-3.4,0.6-5.1,0.8c-24.9,2.4-49.8,4.8-74.7,7.2c-5.6,0.5-11.2,0.8-16.8,0.8
			c-26.4-0.2-52.8-0.5-79.2-0.8C413.7,929.8,408.6,929.4,403.5,928.8z"/>
                                                                <path d="M819.9,848.2c9.7-7.2,19.6-14.5,29.6-21.2c0.1-0.1,0.3-0.1,0.4,0.1c0,0.1,0.1,0.2,0,0.2
			c-0.1,0.2-0.2,0.3-0.4,0.5c-10.2,7.3-20.3,14.6-30.3,22c-2.9,2.1-5.9,4-9,5.6c-15.7,8.1-31.4,16.1-47.2,24.1
			c-3.5,1.8-7,3.3-10.7,4.5c-21.7,7.3-43.5,14.5-65.3,21.6c-4.6,1.5-9.3,2.7-14.1,3.7c-26.4,5.3-52.7,10.6-79.1,15.9
			c-2.2,0.5-4.4,0.8-6.7,1c-23.8,2.2-47.6,4.5-71.5,6.8c-5.6,0.6-11.3,0.8-16.8,0.8c-26.4-0.1-52.8-0.6-79.3-0.8
			c-4.4,0-8.8-0.3-13-0.9c-21.2-2.8-42.3-5.7-63.5-8.6c-3.4-0.5-6.8-1.2-10-2.2c-24.3-7.5-40.9-12.2-54-16.7
			c-2.1-0.7-4.3-1.8-6.5-3.3c-11.8-7.7-23.7-15.4-35.5-23.1c-0.3-0.2-0.5-0.4-0.5-0.7c0-0.4,0.1-0.5,0.5-0.3
			c12.1,7.9,24.2,15.8,36.4,23.6c1.7,1.1,3.6,2,5.6,2.6c18.1,5.5,36.2,11,54.2,16.5c3.3,1,6.7,1.8,10.2,2.2
			c20.9,2.8,41.8,5.6,62.7,8.4c18.5,2.5,37.8,1,56.5,1.6c15.4,0.4,30.8,0.5,46.1,0.1c0.8,0,1.6-0.1,2.4-0.1c24.3-2.3,48.7-4.6,73-7
			c5.6-0.5,11.2-1.4,16.7-2.5c24.1-4.8,48.2-9.7,72.2-14.6c4.8-1,9.6-2.2,14.3-3.8c22-7.2,43.9-14.4,65.8-21.7
			c2.4-0.8,4.8-1.8,7-2.9c17-8.6,34-17.3,51-26C814.2,852.1,817.1,850.3,819.9,848.2z"/>
                                                                <path d="M410.3,986.3c-20.6-2.9-41.2-5.8-61.8-8.8c-3.3-0.5-6.5-1.3-9.6-2.3c-17.9-5.9-35.8-11.8-53.8-17.6
			c-1.9-0.6-3.6-1.5-5.3-2.6c-12-8.3-24-16.6-36-24.9c-1-0.7-1.7-1.6-2.3-2.6c-4.7-9.3-9.5-18.6-14.2-27.8c-0.7-1.5-1.2-3-1.4-4.6
			c-1.6-13.9-3.2-27.8-4.8-41.7c0-0.2,0.1-0.3,0.3-0.4c0.1,0,0.3,0.1,0.3,0.2c4.2,8.1,8.6,16.2,13,24.3c0.6,1,1.4,1.9,2.4,2.5
			c11.9,7.7,23.7,15.4,35.5,23.1c1.7,1.1,3.6,2,5.6,2.6c18.5,5.8,37.1,11.5,55.8,17.1c3.4,1,7,1.8,10.6,2.3
			c20.5,2.7,41,5.5,61.5,8.2c4.6,0.6,9.3,1,13.9,1c25.9,0.3,51.9,0.5,77.8,0.7c5.6,0,11.2-0.2,16.8-0.7c22.6-2.1,45.1-4.3,67.7-6.4
			c5.6-0.5,11.1-1.3,16.5-2.4c25.3-5,50.6-10.1,75.9-15.3c4.9-1,9.6-2.3,14.3-3.8c21.6-7.1,43.1-14.2,64.6-21.4
			c3.6-1.2,7-2.7,10.4-4.4c16.4-8.4,32.8-16.8,49.3-25.2c1.1-0.5,2.3-1.3,3.7-2.4c11.3-8.2,22.6-16.4,34-24.6
			c0.2-0.2,0.6-0.1,0.7,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0.2,14.3,0.4,28.6,0.6,43c0,2.1-0.9,2.7-2.4,3.9c-10.1,8-20.2,15.9-30.4,23.9
			c-2.7,2.2-5.6,4.1-8.7,5.8c-15.5,8.6-31.1,17.1-46.7,25.6c-3.2,1.7-6.4,3.2-9.8,4.4c-21.9,7.8-43.7,15.5-65.6,23.2
			c-4.2,1.5-8.4,2.7-12.8,3.6c-26.3,5.6-52.6,11.3-78.9,16.9c-1.6,0.3-3.1,0.6-4.7,0.7c-23.9,2.5-47.8,4.9-71.6,7.4
			c-5.8,0.6-11.4,0.9-16.8,1c-26.7,0.2-53.5-0.5-80.2-0.8C419.3,987.3,414.8,987,410.3,986.3z"/>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        )}

                                        {currentSlide === 4 && (
                                            <div className="obj__it-img finish--4">
                                                <img src={require("../assets/img/objects/fueler.svg").default}/>
                                                <div className="obj__el">
                                                    <svg viewBox="0 0 1931 1086">
                                                        <g
                                                            style={{color: objects[4].elements[0]}}
                                                            className="action-group"
                                                            id="E1">
                                                            <g>
                                                                <path d="M101.5,476.1l-43.3-9.5c-1.7-0.4-2.5,0.3-2.3,2l1.5,22.4c0,0.5-0.2,0.7-0.7,0.6l-1.7-0.4
			c-0.4-0.1-0.6-0.4-0.6-0.8c0.3-15.1,0-30.5,1-45.4c0.8-12.3,1.6-24.5,2.5-36.7c0-0.3,0.3-0.5,0.6-0.5c0,0,0,0,0,0l70.8,10.8
			c0.2,0,0.5,0.2,0.7,0.4c0.1,0.1,0.1,0.3,0.1,0.5c-0.1,0.2-0.1,0.4,0.1,0.6l13.4,18.8c0.4,0.6,1,1,1.8,1l7.8,0.9
			c0.3,0,0.6-0.1,0.8-0.3l1.4-1.2c0.2-0.2,0.5-0.3,0.8-0.3l3.3,0.2c0.3,0,0.6-0.2,0.8-0.4c1.4-2.7,3.5-12.1-1.7-11.7
			c-0.3,0-0.5-0.1-0.6-0.4l-1.3-3.5c-0.1-0.2,0-0.5,0.3-0.6c0.1,0,0.1,0,0.2,0c32.7,5,65.3,10,98,14.9c0.6,0.1,0.9,0.5,0.7,1.1
			c-0.1,0.3,0,0.7,0.2,1l17.2,21.3c0.7,0.9,1.6,1.4,2.8,1.5c3.3,0.3,8.1,2,10.6-1c0.2-0.3,0.5-0.4,0.9-0.3c8,2,7.9-12.8,2.5-13.4
			c-0.4,0-0.7-0.3-0.7-0.7c-0.2-1.3-0.7-2.4-1.5-3.5c-0.7-0.8-0.5-1.1,0.6-1l51,7.8c0.6,0.1,1.1-0.3,1.2-0.9c0-0.1,0-0.1,0-0.2
			c0-0.8,0-1.7,0.2-2.7c11-62.8,22-125.6,33.1-188.4c1.2-6.6,2.6-13.1,4.4-19.5c5.4-19.6,10.7-39.2,16.1-58.8c2-7,4.7-14.1,6-21.6
			c0.1-0.3,0-0.6-0.2-0.8c-2.2-2.3-4.8-3.4-7.8-3.1c-94.4,9.1-188.8,18.1-283.1,27.2c-0.2,0-0.4-0.2-0.5-0.4c0-0.1,0-0.2,0-0.2
			c19.2-37.4,50.4-56.8,91.6-63.1c94.6-14.4,189.3-28.8,284-43.2c13.4-2,26.2-3.8,40.2-4.7c12.3-0.9,24.6-1.8,36.9-2.7
			c17.5-1.3,35.1-0.9,52.6-0.9c5.7,0,11.4,0.4,17.1,1c52.9,6.4,105.9,12.7,158.8,19.1c5,0.6,11,2.3,14.9,5.9
			c9.3,8.6,10.3,19.1,10.5,31.4c2.3,149,4.7,298.1,6.7,447.1c0,1-0.1,2-0.2,3c-0.1,0.3-0.3,0.6-0.6,0.7c-4.4,1.9-9.2,3.2-14.6,3.9
			c-12.4,1.5-25,4.2-37.3,8c-29,8.9-54.9,20.6-78.8,39.6c-22.1,17.6-42.5,41.2-56.8,66.7c-0.2,0.3-0.4,0.5-0.8,0.6l-19.3,3.6
			c-0.2,0-0.4-0.1-0.5-0.3c0-0.1,0-0.2,0.1-0.3c12.7-18.3,25.4-36.6,38.2-54.8c21.2-30.2,46-54.3,80.4-68.8c2.5-1,5.2-1.8,8.1-2.1
			c14.8-2,29.5-4,44.3-6c1.9-0.3,3.6-0.3,5.3-0.3c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0c-1.8-136.2-3.7-272.1-5.6-407.8
			c-0.1-8.9,0-18.6-11.2-19.3c-67.5-4.2-135-8.7-202.5-11.1c-7.1-0.2-20-1.6-28,0c-16.3,3.3-30.2,9.6-41.7,18.8
			c-14.5,11.6-25.5,34.1-30.4,51.8c-5.5,20.1-10.9,40.2-16.1,60.4c-1.7,6.4-3.1,12.9-4.2,19.5c-10.1,57.9-20.2,115.7-30.3,173.5
			c-3.3,18.8-3.9,38.3-5.3,57.4c-1.2,17.4,0.4,35.3,1.1,52.8c0,0.7-0.3,1.1-1,1.2c-23.1,2.6-47.1,3.5-70.4,1.5
			c-4.7-0.4-9.9-1.3-15.5-2.7c-74.1-18.4-148.9-37.1-224.4-56c-0.6-0.1-0.9-0.5-0.9-1.1l-1.7-25.9
			C101.9,476.3,101.8,476.1,101.5,476.1z M357.4,425.7c-0.6,0.4-0.6,0.9-0.2,1.5c0.2,0.3,0.6,0.3,0.9,0.1c0.2-0.1,0.2-0.3,0.3-0.5
			l0-0.6c0-0.4-0.3-0.7-0.6-0.7C357.7,425.6,357.6,425.6,357.4,425.7z M92.6,448c10.2,2.1,20.5,3.9,30.9,5.4
			c3.5,0.5,8.9,1.6,13.6,1.9c0.4,0,0.7-0.2,0.8-0.5c1.1-3.2,1.3-6.4,0.6-9.7c-0.1-0.3-0.3-0.6-0.6-0.6c-8-1.4-16.1-2.6-24.3-3.8
			c-2-0.3-3.7-1.3-5.7-1.7c-4.5-0.8-9.1-1.4-13.6-1.6c-0.4,0-0.7,0.3-0.7,0.6l-1.2,9.5C92.2,447.8,92.3,448,92.6,448z M254.8,478.1
			c1.9,0.2,4.9,1,7.4,1c0.3,0,0.7-0.2,0.8-0.5c1.1-2.3,3.2-11.3-1-11.9c-10.8-1.6-21.6-3.3-32.3-5.1c-1.1-0.2-1.6-1.1-2.8-1.3
			c-5.2-0.9-10.5-1.5-15.8-1.9c-0.4,0-0.6,0.1-0.6,0.5l-1.5,10.5c-0.1,0.4,0.2,0.7,0.6,0.8C224.4,473.4,239.5,476,254.8,478.1z"/>
                                                                <path d="M742.9,561.8c-37.3,4.8-69.5,33.1-90.5,62.1c-15.5,21.4-30.9,42.8-46.3,64.3c-0.2,0.3-0.5,0.5-0.8,0.5
			l-169.5,31.7c-0.3,0.1-0.6-0.1-0.6-0.4c0,0,0,0,0-0.1c-2.8-66.5-5.6-132.9-8.2-199.5c-0.2-4.3,0-8.6,0.5-12.9
			c2.4-21.1,4.8-42,8.2-63.1c0-0.3,0.3-0.4,0.5-0.4c0.1,0,0.3,0.1,0.3,0.2c3.3,5.4,8.1,7.3,14.5,5.6c17.4-4.7,34.8-9.4,52.2-14.2
			c0.3-0.1,0.5,0.1,0.6,0.3c0,0.1,0,0.2,0,0.3l-5.7,19.7c-0.1,0.4-0.4,0.6-0.8,0.6c-3-0.1-5.8-0.4-8.6-1c-3.3-0.7-5.3,4.9-2.5,7.1
			c2,1.6,8.6,1.9,11.2,2.3c3.8,0.6,6.6-0.2,7.8-3.9c2.8-9,5.5-18.2,8.2-27.5c0.1-0.4,0.4-0.7,0.8-0.8
			c73.6-20.2,147.2-40.5,220.9-60.8c15.8-4.4,24.6-18.6,24.4-34.6c-0.8-52.9-1.7-105.8-2.4-158.7c0-1.5-0.6-3.9-1.8-7
			c-3.5-9.4-9.7-11.3-19.6-11.8c-65.1-3.2-130.2-6.8-195.3-9.5c-20.5-0.9-38.1,7.9-50.3,24.5c-0.3,0.4-0.4,0.3-0.5-0.1
			c-0.1-0.6,0.1-1.1,0.4-1.6c6.2-9.3,12.9-19,22.5-25.5c14.1-9.5,28.8-14.3,46.3-13.3c70.2,4,140.4,8,210.6,12.1
			c4.7,0.3,7.1,3.4,7.1,8c0.1,17.1,0.3,34.1,0.6,51.2c1.8,116.9,3.3,233,5.1,350.8c0,0.3-0.2,0.5-0.5,0.6
			C768.8,558.5,755.9,560.2,742.9,561.8z M696,503.5c0.2,5.8-1.5,13.1,7.5,12.1c9.2-1,18.4-2,27.7-3c5.3-0.6,8-3.2,7.3-8.6
			c0-0.3,0.2-0.6,0.5-0.6c0,0,0,0,0.1,0c2,0,4,0.6,5.9,0.4c8.5-0.8,16.9-1.7,25.2-2.6c0.5-0.1,0.8-0.4,0.8-0.9l-0.4-16.7
			c0-0.4-0.2-0.6-0.6-0.6c-3.3-0.4-6.3-0.5-8.8-0.3c-7.4,0.7-14.7,1.4-21.7,2.1c-0.6,0.1-0.9-0.2-1-0.8c-0.5-6.8-3.3-10-10.2-9.5
			c-8,0.6-16,1.3-24,2.1c-10.5,1-8.8,9.1-8.4,16.9c0,0.5-0.3,0.9-0.8,1l-11.2,1c-0.4,0-0.6,0.2-0.6,0.6l0.1,5.8
			c0,0.3,0.2,0.6,0.6,0.7c3.7,0.9,7.5,1,11.4,0.4C695.6,502.8,695.9,503,696,503.5C696,503.4,696,503.4,696,503.5z"/>
                                                            </g>
                                                        </g>
                                                        <g
                                                            style={{color: objects[4].elements[2]}}
                                                            className="action-group"
                                                            id="E3">
                                                            <g>
                                                                <path d="M1673.6,233.9c1.6-0.6,2.3-0.9,4-0.5c16.9,4.1,34.2,7.9,50.5,14.2c9,3.5,18.9,6.9,26.9,10.8
			c19.9,9.7,50.2,27.8,52.3,52.8c1,11.8,0.3,19.9-0.2,30c-0.2,4.2-0.7,8.1-1.4,11.9c-1,5.5-1.6,11.1-4,15.9c-2.4,4.9-5.6,9.2-9.6,13
			c-0.2,0.2-0.5,0.4-0.9,0.4c-2.1,0.4-4.2,0.6-6.3,0.8c-115.5,10.9-231,21.8-346.4,32.6c-1.9,0.2-3.7,0.4-5.6,0.7
			c-0.3,0.1-0.6,0.2-0.8,0.4c-2.7,2.7-9.3,7.7-10.2,11.3c-1.8,7-0.3,13.3,4.5,18.8c0.1,0.2,0.1,0.4,0,0.5c-0.1,0-0.1,0.1-0.2,0.1
			c-12,1.4-23.9,2.7-35.6,3.9c-2.2,0.2-4.3,0.8-6.3,1.6c-7.3,3.2-14.3,6.2-21.9,8.7c-0.3,0.1-0.5,0.4-0.5,0.7l-0.1,5.1
			c0,0.5-0.2,0.7-0.7,0.8l-130.3,15.9c-0.5,0.1-0.8-0.2-0.8-0.7l6.2-308.1c0-0.5,0.2-0.7,0.7-0.7c133,7.7,265.9,15,398.9,22.4
			c8.7,0.5,17.3,1.2,25.7,2.1c0.6,0.1,1,0.4,1.2,1l10.2,33.1C1672.9,233.8,1673.2,234,1673.6,233.9
			C1673.5,233.9,1673.6,233.9,1673.6,233.9z M1303.1,315.4c-10.4,16.8-20.9,33.7-31.2,50.5c-0.1,0.2-0.1,0.4,0,0.6L1301,414
			c0.5,0.7,1.4,1,2.1,0.5c0.2-0.1,0.4-0.3,0.5-0.5l30.3-50.6c0.2-0.3,0.2-0.6,0-0.9l-27.8-47.6c-0.5-0.9-1.2-1-2-0.5
			C1303.7,314.6,1303.3,315,1303.1,315.4z"/>
                                                                <path d="M1583.9,436c-1-0.1-1.8-0.2-2.6-0.3c-0.2,0-0.3-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2l139.8-16.2
			c0.2,0,0.3,0.1,0.4,0.3c0,0.2-0.1,0.3-0.2,0.4c-13.9,3.3-27.5,7.2-41.4,9.3c-7.8,1.2-15.5,2.4-23.3,3.5c-0.4,0.1-0.6-0.1-0.6-0.5
			V430c0-0.4-0.2-0.6-0.6-0.6c-4.7,0.5-9.4,1.1-14,1.7c-8.3,1.1-17.1,3.9-25.8,5.5c-0.3,0.1-0.6,0.2-0.9,0.4
			c-0.8,0.5-1.9,0.7-3.3,0.6C1602.1,437,1593,436.4,1583.9,436z"/>
                                                                <path d="M61.2,540.3c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0,0,0h0c-0.6,0.1-1.3-2.8-1.6-6.3c0,0,0,0,0,0l0-0.3
			c-0.3-3.5,0-6.4,0.6-6.4l0,0c0.1,0,0.2,0.1,0.2,0.1c0,0,0,0,0,0L61.2,540.3z"/>
                                                                <path 
                                                                      d="M1581.2,686.8c1.8,0.4,2.9,1.7,3.5,4L1581.2,686.8z"/>
                                                            </g>
                                                        </g>
                                                        <g
                                                            style={{color: objects[4].elements[1]}}
                                                            className="action-group"
                                                            id="E2">
                                                            <g>
                                                                <path className="st4" d="M883.6,570.8l-53.5-9.4c-0.3-0.1-0.5-0.2-0.5-0.6l-1.2-108.7c0-0.8-0.4-1.3-1.3-1.4l-5.3-0.4
			c-0.1,0-0.2-0.1-0.2-0.2l-5-334.4c0-0.3,0.2-0.5,0.5-0.5c36.9-3.7,73.8-7.2,110.7-10.6c4.2-0.4,8.4-1.4,10.8,3.1
			c1.2,2.2,1.8,4.4,1.8,6.6c0.2,188.9,0.1,325.9,0.8,431.7c0.1,19.7,0.1,39.3-0.1,58.6c0,0.6-0.3,0.9-0.9,0.8l-47.4-9
			c-0.4-0.1-0.6-0.3-0.7-0.7l-5.8-22.6C886,572,885,571.1,883.6,570.8z"/>
                                                                <path className="st4" d="M1232.6,262.6c-2.5,129.6-5.1,259.3-7.8,389.1c0,0.4-0.3,0.7-0.7,0.8l-225.7,49.1c-0.5,0.1-1-0.1-1.3-0.6
			c-17.6-30.7-35.3-61.5-53.1-92.2c-0.7-1.2-1.1-2.5-1.1-3.9c-1-160.4-0.8-320.9-1.1-481.3c0-6,0.9-12.6-2.7-17.9
			c-0.2-0.2-0.1-0.6,0.1-0.7c0.1-0.1,0.2-0.1,0.3-0.1c95.5,7.9,191.1,15.8,286.8,23.7c5.1,0.4,8.6,2.8,8.5,8.1
			C1234.8,169.1,1233.5,214.1,1232.6,262.6z M1224.4,267.7l-273.3-0.5c-0.3,0-0.5,0.2-0.5,0.5l0,0c0.4,111.4,0.8,222.8,1.2,334.3
			c0,1.4,0.3,2.7,1,3.8c16,28.5,31.9,56.9,47.8,85.4c0.2,0.3,0.4,0.4,0.7,0.3l215.9-45.8c0.7-0.1,1.2-0.7,1.2-1.4l6.5-376.2
			C1224.8,267.8,1224.7,267.7,1224.4,267.7z"/>
                                                                <path className="st4" d="M1096.2,645.2c0-1.6-0.8-2.2-2.3-1.9l-46.5,9.2c-0.3,0.1-0.5,0.3-0.5,0.6l0.4,18.5c0,0.6-0.3,1-0.9,1.1
			l-48.2,10c-0.3,0.1-0.5,0-0.7-0.3c-14.3-25.7-28.6-51.3-43.1-76.9c-0.7-1.2-1-2.5-1-3.8c-0.4-109.8-0.8-220.3-1.2-331.6
			c0-0.5,0.3-0.8,0.8-0.8h5.6c0.4,0,0.6,0.2,0.6,0.6v300.9c0,0.3-0.1,0.6-0.4,0.8c-10.6,8.2-0.3,22.8,11.5,12.7
			c4.1-3.5,5-9.6,5-14.8c0.2-100,0.3-199.9,0.4-299.8c0-0.2,0.2-0.5,0.4-0.5c0,0,0,0,0,0l228.1,0.4c0.4,0,0.6,0.3,0.6,0.6
			c0,0,0,0,0,0l-4.9,305c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.8-10.8-5.6-23.3-17.3-27
			c-37.8-11.8-52.9,64-17,72.8c20,4.9,31.7-16.5,33.8-33.2c0-0.3,0.2-0.6,0.4-0.8c0.2-0.2,0.3-0.1,0.3,0.2l-0.9,52.8
			c0,0.4-0.3,0.7-0.7,0.8c-32.1,6.7-64.3,13.3-96.5,19.9c-1.7,0.3-3.4,0.3-5-0.1c-0.3-0.1-0.5-0.4-0.5-0.7V645.2z M985.8,563.5
			c0.1,0,0.3,0,0.5,0c58.4-8.7,116.8-17.2,175.2-25.7c0.6-0.1,0.9-0.5,0.9-1.1l3.9-259.1c0-0.4-0.2-0.6-0.6-0.6l-180.7,0.5
			c-0.1,0-0.2,0.1-0.2,0.2c-0.2,94.7-0.4,189.5-0.5,284.3c0,0.3,0.1,0.5,0.2,0.7c0.2,0.2,0.4,0.4,0.6,0.5
			C985.5,563.4,985.8,563.5,985.8,563.5z"/>

                                                                <ellipse
                                                                    transform="matrix(6.801531e-02 -0.9977 0.9977 6.801531e-02 653.1894 1468.1355)"
                                                                    className="st4" cx="1112.4" cy="384.5" rx="16"
                                                                    ry="9.6"/>

                                                                <ellipse
                                                                    transform="matrix(6.801531e-02 -0.9977 0.9977 6.801531e-02 621.3364 1437.9371)"
                                                                    className="st4" cx="1080.3" cy="386.4" rx="16.3"
                                                                    ry="10.1"/>

                                                                <ellipse
                                                                    transform="matrix(6.627395e-02 -0.9978 0.9978 6.627395e-02 590.8334 1408.0983)"
                                                                    className="st4" cx="1047.8" cy="388.4" rx="16.6"
                                                                    ry="10.5"/>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="finish__box-title text-center">
                                    Отправь раскрашенный <br/>
                                    объект в Аэропорт
                                </div>
                                <div className="finish__swipe-icon">
                                    <svg width="67" height="65" viewBox="0 0 67 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M43.9095 62.9937L66.2708 50.0834C66.7091 49.8304 66.8594 49.2694 66.6064 48.8312L62.9535 42.5041C62.7005 42.0659 62.1395 41.9156 61.7013 42.1686L39.3399 55.0789C38.9017 55.3319 38.7514 55.8929 39.0044 56.3311L42.6573 62.6582C42.9103 63.0964 43.4713 63.2467 43.9095 62.9937ZM64.5603 48.954L43.7867 60.9477L41.0504 56.2083L61.8241 44.2147L64.5603 48.954Z" fill="white"/>
                                        <path d="M62.7372 43.6875C63.0079 43.5312 63.192 43.2397 63.1966 42.9047C63.2036 42.31 63.252 37.0405 62.6962 35.0824C61.785 31.8816 56.5618 23.669 56.3397 23.3209C55.0686 21.1175 52.2903 20.3844 50.0365 21.6856C49.122 22.2136 48.461 23.0176 48.0922 23.9408L47.9153 23.6343C46.593 21.3441 43.7136 20.5222 41.4972 21.8019C40.5819 22.3303 39.9205 23.1356 39.5522 24.0595C38.2229 21.9479 35.5229 21.2808 33.3223 22.5513C32.4086 23.0788 31.7479 23.8815 31.3791 24.8047L25.946 15.3943C24.6229 13.1045 21.7436 12.2827 19.5264 13.5628C17.3091 14.8429 16.5804 17.7479 17.9027 20.0382L31.2131 43.0926C31.4661 43.5308 32.0271 43.6811 32.4653 43.4281C32.9035 43.1751 33.0538 42.6141 32.8008 42.1759L19.4904 19.1215C18.6736 17.7069 19.1014 15.9251 20.443 15.1505C21.7846 14.3759 23.5408 14.8968 24.3575 16.3115L34.23 33.4111C34.483 33.8494 35.044 33.9997 35.4822 33.7467C35.9204 33.4937 36.0707 32.9327 35.8177 32.4945L33.2864 28.11C32.4696 26.6954 32.8966 24.9141 34.2382 24.1395C35.6267 23.3378 37.2692 23.7666 38.0603 25.1368L39.7818 28.1111C39.8114 28.166 39.8414 28.2217 39.8735 28.2773L41.0836 30.3603C41.3369 30.7973 41.8974 30.9468 42.3348 30.6942L42.3364 30.6933C42.7742 30.4395 42.9237 29.879 42.6699 29.4413L41.3878 27.2262C40.6719 25.8359 41.1127 24.1408 42.413 23.3901C43.7547 22.6155 45.51 23.1368 46.3268 24.5515L48.4117 28.1627C48.6647 28.6009 49.2257 28.7512 49.6639 28.4982C50.1021 28.2452 50.2524 27.6843 49.9994 27.2461C49.1827 25.8314 49.6104 24.0496 50.9529 23.2746C52.3397 22.4739 53.9815 22.9031 54.7726 24.2733C54.8466 24.3904 60.0946 32.6406 60.9325 35.5842C61.3418 37.0245 61.3823 41.3409 61.3632 42.8825C61.357 43.3888 61.7626 43.8035 62.2686 43.8109C62.4395 43.8117 62.5998 43.7668 62.7372 43.6875Z" fill="white"/>
                                        <path d="M40.2576 56.6662C40.4401 56.5607 40.5881 56.3917 40.6639 56.1765C40.8315 55.6986 40.5804 55.1757 40.1026 55.0081C40.0523 54.9906 35.0354 53.2256 33.4796 52.4779C32.0012 51.767 29.8129 50.4096 27.6967 49.0964C25.6837 47.8484 23.7825 46.6693 22.3786 45.9609C21.0213 45.2765 19.968 43.7729 20.5789 42.2241C20.8528 41.5325 21.392 40.9905 22.0987 40.7021C22.8186 40.4071 23.6242 40.4056 24.3675 40.6994L31.7451 43.6143C32.2163 43.8016 32.749 43.5692 32.9347 43.0989C33.1212 42.6282 32.8901 42.0958 32.4193 41.9093L25.0418 38.9944C23.8568 38.5259 22.5646 38.5299 21.4046 39.0049C20.231 39.4856 19.3332 40.3892 18.8747 41.5494C17.9789 43.8184 19.1053 46.3615 21.5531 47.5965C22.8855 48.2689 24.8409 49.4813 26.7305 50.6534C28.8877 51.9917 31.1186 53.3752 32.6855 54.1292C34.3322 54.9208 39.2853 56.6633 39.4951 56.737C39.7573 56.8301 40.0337 56.7954 40.2576 56.6662Z" fill="white"/>
                                        <path d="M7.22371 1.83292C6.83318 1.4424 6.20002 1.4424 5.80949 1.83292L-0.554468 8.19689C-0.944992 8.58741 -0.944992 9.22058 -0.554467 9.6111C-0.163943 10.0016 0.469222 10.0016 0.859746 9.6111L6.5166 3.95425L12.1735 9.6111C12.564 10.0016 13.1971 10.0016 13.5877 9.6111C13.9782 9.22058 13.9782 8.58741 13.5877 8.19689L7.22371 1.83292ZM7.5166 37.8589L7.5166 2.54003L5.5166 2.54003L5.5166 37.8589L7.5166 37.8589Z" fill="white"/>
                                    </svg>
                                </div>
                                <div className="finish__swipe-title text-center">
                                    Свайпни его на экран на стенде
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {wsStatus === 'ready' && (
                <div className="sc__main h-full fadeIn animated">
                    <div className="container h100 d-flex flex-column align-content-between">
                        <div className="sc__main-ct">
                            <div className="sc__main-hd d-flex align-items-center justify-content-between">
                                <div className="logo">
                                    <img src={require("../assets/img/logo.png")}/>
                                </div>
                                {countdownDate && (
                                    <div className="countdown__box d-flex align-items-center">
                                        <div className="icon__timer"/>
                                        <Countdown
                                            date={countdownDate}
                                            renderer={renderer}
                                            onComplete={()=>{
                                                let mess =  {"action": "timeIsOut"};
                                                sendMessage(JSON.stringify(mess));
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                            {mainStatus === 'intro' && (
                                <div className="steps fadeIn animated">
                                    <div className="steps__it steps__it--1">
                                        <div className="steps__it-bl d-flex align-items-center">
                                            <div className="steps__it-bg">
                                                <svg width="335" height="144" viewBox="0 0 335 144" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                                    <path d="M0.5 34C0.5 23.2304 9.23045 14.5 20 14.5H110.822C114.948 14.5 118.977 13.2554 122.384 10.9289L132.68 3.89688C135.921 1.68387 139.754 0.5 143.678 0.5H315C325.77 0.5 334.5 9.23045 334.5 20V110C334.5 120.77 325.77 129.5 315 129.5H143.678C139.552 129.5 135.523 130.745 132.116 133.071L121.82 140.103C118.579 142.316 114.746 143.5 110.822 143.5H20C9.23045 143.5 0.5 134.77 0.5 124V34Z" fill="white" stroke="#E5E8F7"/>
                                                </svg>
                                            </div>
                                            <div className="steps__it-numb">
                                                1
                                            </div>
                                            <div className="steps__it-icon">
                                                <img src={require("../assets/img/step-1.svg").default}/>
                                            </div>
                                            <div className="steps__it-descr">
                                                Раскрась один <br/>
                                                из объектов
                                            </div>
                                        </div>
                                    </div>
                                    <div className="steps__it steps__it--2">
                                        <div className="steps__it-bl d-flex align-items-center">
                                            <div className="steps__it-bg">
                                                <svg width="335" height="144" viewBox="0 0 335 144" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                                    <path d="M0.5 34C0.5 23.2304 9.23045 14.5 20 14.5H110.822C114.948 14.5 118.977 13.2554 122.384 10.9289L132.68 3.89688C135.921 1.68387 139.754 0.5 143.678 0.5H315C325.77 0.5 334.5 9.23045 334.5 20V110C334.5 120.77 325.77 129.5 315 129.5H143.678C139.552 129.5 135.523 130.745 132.116 133.071L121.82 140.103C118.579 142.316 114.746 143.5 110.822 143.5H20C9.23045 143.5 0.5 134.77 0.5 124V34Z" fill="white" stroke="#E5E8F7"/>
                                                </svg>
                                            </div>
                                            <div className="steps__it-numb">
                                                2
                                            </div>
                                            <div className="steps__it-icon">
                                                <img src={require("../assets/img/step-2.svg").default}/>
                                            </div>
                                            <div className="steps__it-descr">
                                                Отправь его <br/>
                                                в аэропорт
                                            </div>
                                        </div>
                                    </div>
                                    <div className="steps__it steps__it--3">
                                        <div className="steps__it-bl d-flex align-items-center">
                                            <div className="steps__it-bg">
                                                <svg width="335" height="144" viewBox="0 0 335 144" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                                    <path d="M0.5 34C0.5 23.2304 9.23045 14.5 20 14.5H110.822C114.948 14.5 118.977 13.2554 122.384 10.9289L132.68 3.89688C135.921 1.68387 139.754 0.5 143.678 0.5H315C325.77 0.5 334.5 9.23045 334.5 20V110C334.5 120.77 325.77 129.5 315 129.5H143.678C139.552 129.5 135.523 130.745 132.116 133.071L121.82 140.103C118.579 142.316 114.746 143.5 110.822 143.5H20C9.23045 143.5 0.5 134.77 0.5 124V34Z" fill="white" stroke="#E5E8F7"/>
                                                </svg>
                                            </div>
                                            <div className="steps__it-numb">
                                                3
                                            </div>
                                            <div className="steps__it-icon">
                                                <img src={require("../assets/img/step-3.svg").default}/>
                                            </div>
                                            <div className="steps__it-descr">
                                                Узнай как <br/>
                                                авиационная <br/>
                                                сервисная платформа <br/>
                                                помогает гражданской <br/>
                                                авиации
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {mainStatus === 'start' && (
                                <div className="start__box fadeIn animated">
                                    <div className="start__box-bg">
                                        <svg width="335" height="451" viewBox="0 0 335 451" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                            <path d="M0.5 37C0.5 26.2305 9.23045 17.5 20 17.5H110.136C114.061 17.5 117.902 16.3736 121.205 14.2547L137.739 3.64798C140.939 1.59519 144.67 0.522568 148.471 0.562065L315.203 2.29434C325.893 2.4054 334.5 11.1028 334.5 21.7933V417C334.5 427.77 325.77 436.5 315 436.5H251.13C247.195 436.5 243.344 437.633 240.035 439.762L228.174 447.397C225.027 449.423 221.363 450.5 217.62 450.5H20C9.23045 450.5 0.5 441.77 0.5 431V37Z" fill="white" stroke="#E5E8F7"/>
                                        </svg>
                                    </div>
                                    <div className="start__box-title text-center">
                                        Выбери объект <br/>
                                        для раскрашивания
                                    </div>
                                    <div ref={sliderRef} className="keen-slider">
                                        <div className="keen-slider__slide">
                                            <div className="obj__it obj__it--0">
                                                <div className="obj__it-img">
                                                    <img src={require("../assets/img/objects/ssj100.svg").default}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="keen-slider__slide">
                                            <div className="obj__it obj__it--1">
                                                <div className="obj__it-img">
                                                    <img src={require("../assets/img/objects/suitcase.svg").default}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="keen-slider__slide">
                                            <div className="obj__it obj__it--2">
                                                <div className="obj__it-img">
                                                    <img src={require("../assets/img/objects/stairs.svg").default}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="keen-slider__slide">
                                            <div className="obj__it obj__it--3">
                                                <div className="obj__it-img">
                                                    <img src={require("../assets/img/objects/baggagebelt.svg").default}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="keen-slider__slide">
                                            <div className="obj__it obj__it--4">
                                                <div className="obj__it-img">
                                                    <img src={require("../assets/img/objects/fueler.svg").default}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {slLoaded && instanceRef.current && (
                                        <div onClick={(e) =>
                                            e.stopPropagation() || instanceRef.current?.prev()
                                        } className={'sl__nav sl__nav--prev' + (currentSlide === 0 ? ' sl__nav--disabled' : '')}>
                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="20" cy="20" r="22" transform="matrix(-1 0 0 1 44 4)" fill="white" stroke="#F0F4FF" strokeWidth="4"/>
                                                <path d="M26 18L20 24L26 30" stroke="#170C61" strokeWidth="2"/>
                                            </svg>
                                        </div>
                                    )}

                                    {slLoaded && instanceRef.current && (
                                        <div onClick={(e) =>
                                            e.stopPropagation() || instanceRef.current?.next()
                                        } className={'sl__nav sl__nav--next' + (currentSlide === 4 ? ' sl__nav--disabled' : '')}>
                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="24" cy="24" r="22" fill="white" stroke="#F0F4FF" strokeWidth="4"/>
                                                <path d="M22 18L28 24L22 30" stroke="#170C61" strokeWidth="2"/>
                                            </svg>
                                        </div>
                                    )}

                                    {slLoaded && instanceRef.current && (
                                        <div className="dots">
                                            {[
                                                ...Array(instanceRef.current.track.details.slides.length).keys(),
                                            ].map((idx) => {
                                                return (
                                                    <button
                                                        key={idx}
                                                        onClick={() => {
                                                            instanceRef.current?.moveToIdx(idx)
                                                        }}
                                                        className={"dot" + (currentSlide === idx ? " active" : "")}
                                                    ></button>
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>
                            )}

                            {mainStatus === 'draw' && (
                                <div className="start__box start__box--draw fadeIn animated">
                                    <div className="start__box-bg">
                                        <svg width="335" height="451" viewBox="0 0 335 451" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                            <path d="M0.5 37C0.5 26.2305 9.23045 17.5 20 17.5H110.136C114.061 17.5 117.902 16.3736 121.205 14.2547L137.739 3.64798C140.939 1.59519 144.67 0.522568 148.471 0.562065L315.203 2.29434C325.893 2.4054 334.5 11.1028 334.5 21.7933V417C334.5 427.77 325.77 436.5 315 436.5H251.13C247.195 436.5 243.344 437.633 240.035 439.762L228.174 447.397C225.027 449.423 221.363 450.5 217.62 450.5H20C9.23045 450.5 0.5 441.77 0.5 431V37Z" fill="white" stroke="#E5E8F7"/>
                                        </svg>
                                    </div>

                                    <div className="hint">
                                        <div className="hint__toggle d-flex align-items-center justify-content-center">
                                            ?
                                        </div>
                                        <div className="hint__message">
                                            Нажимай на разные части <br/>
                                            объекта и раскрашивай <br/>
                                            одним из цветов палитры
                                        </div>
                                    </div>

                                    <div className="draw__object">
                                        {currentSlide === 0 && (
                                            <div className="obj__it obj__it--0">
                                                <div className="obj__it-img">
                                                    <img src={require("../assets/img/objects/ssj100.svg").default}/>
                                                    <div className="obj__el">
                                                        <svg viewBox="0 0 1931 1086">
                                                            <g
                                                                onClick={()=>{
                                                                    setObjects({
                                                                        ...objects,
                                                                        0: {
                                                                            ...objects[0],
                                                                            active: 1
                                                                        }
                                                                    });
                                                                }} id="E1"
                                                                style={{color: objects[0].elements[0]}}
                                                                className={'action-group' + (objects[0].active == 1 ? ' active' : '')}>
                                                                <g>
                                                                    <path d="M113.3,625.5c0.3,0.2,0.5,0.3,0.7,0.2c-0.8,1.4-1.2,2.2,0.3,3.3c3.8,2.7,8,4.5,12.5,5.5
			c2.2,0.5,4.9,0.6,7.9,0.2c14-1.8,27.9-3.6,41.9-5.4c3.1-0.4,5.3-1.7,8.1-3c0.3-0.1,0.5-0.4,0.7-0.6c4.2-6.4,8.7-12.6,13.5-18.6
			c7.5-9.3,16.7-17.7,26-25.7c1-0.9,1.1-2.4,0.2-3.4c-0.4-0.4-0.9-0.7-1.5-0.8c-3.1-0.4-6.2-1.4-9.5-1.4
			c-13.1,0.2-25.4,1.4-37.1,3.9c-3,0.6-6.1,1.8-9.4,3.7c-0.2,0-0.3-0.2-0.3-0.5c7-6,13.8-13.6,21.9-18.4c13.6-8.1,23-13.4,28.1-16.1
			c60.8-31.4,126.5-52.8,194.6-61.1c65-7.9,130-15.5,195.1-22.9c0.3,0,0.6,0.1,0.8,0.3l0.7,0.7c0.2,0.2,0.6,0.2,0.8,0
			c7.1-6.6,14.3-13.2,21.5-19.7c2.6-2.4,5.4-2.5,8.8-2.3c0.2,0,0.3,0.2,0.3,0.3c0,0.1,0,0.1,0,0.1c-3.1,6.2-6.1,12.3-9.1,18.4
			c-0.2,0.4-0.3,0.7-0.3,0.9c0,0.4,0.3,0.8,0.7,0.9c0.4,0,0.7-0.2,0.8-0.6c0.3-1.2,1.1-1.8,2.4-1.9c133.9-15.1,267.8-30,401.8-44.7
			c2.8-0.3,5.1-0.3,7,0c0.4,0.1,0.6-0.1,0.9-0.4l8.6-11.6c0.2-0.2,0.4-0.4,0.7-0.4l3-0.1c0.4,0,0.5,0.2,0.5,0.5l-2.1,11
			c-0.1,0.4,0.2,0.7,0.6,0.8c0.3,0,0.5-0.1,0.7-0.3l1.2-1.6c0.1-0.2,0.4-0.3,0.6-0.4c45.4-5,90.7-10,136.1-15
			c70.8-7.9,117.3-12.4,176.3-14.8c2.1-0.1,4.2,0,6.3,0.1c7.8,0.7,16.5,7.1,21.5,12.7c8.1,8.8,10.1,27.3,9.3,38.5
			c-0.3,4.4-1.3,9-3,13.8c-5.1,14.3-12.1,27.6-21.2,39.9c-4.7,6.3-10.9,11.3-17,16.5c-2.9,2.4-5.9,4.7-9.2,6.7
			c-18.3,11.4-39.5,24.7-59.9,34c-16.8,7.6-33.7,15.2-51.2,21.4c-23.2,8.3-47,15.6-69.5,25.4c-11.3,4.9-22.4,9.8-33.4,14.6
			c-1.1,0.5-2.2,0.6-3.4,0.3c-15.3-3.3-30.6-6.7-46-10c-19.3-4.2-38.5-6.7-58.2-7.9c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0.1,0,0.1
			c0.1,0.5,0.4,0.7,0.9,0.8c2.3,0.3,4.6,0.5,6.9,0.8c0.3,0,0.4,0.1,0.6,0.2c0.3,0.3,0.2,0.4-0.2,0.5c-25,1.9-49.9,4.2-74.8,7
			c-22.9,2.5-46.5,6.1-68.6,12.4c-21,6-41.5,13.3-61.6,21.9c-9,3.8-27.2,11.7-23.3,24.6c0.8,2.5,4.6,5.9,6.5,7.8
			c0.2,0.3,0.5,0.4,0.9,0.4c11.2-0.2,22.6,0.1,33.4-0.4c27-1.2,53.4-2.1,79.3-2.9c4.5-0.1,4.5,0.2,0.1,1
			c-17.2,3.1-35.1,6.4-53.8,9.9c-27.3,5.1-55.2,8.2-82.7,11.7c-19.2,2.5-38.3,2-57.5,3.6c-30,2.5-60,5.6-89.9,9.4
			c-6.4,0.8-15,1-20.1,0.2c-2.8-0.5-4.2,0.3-6.5,0.5c-12.7,1.3-25.4,2.5-38.1,4.4c-74.7,11.4-148.7,26.3-223.6,36.4
			c-15.1,2-30.5,5.4-45.3,6.7c-10,0.9-20.1,1.6-30.1,2.3c-0.4,0-0.6,0.2-0.6,0.6v0.7c0,0.3,0.2,0.5,0.5,0.5l34.9-2.7
			c0.5,0,0.7,0.2,0.7,0.7l0.2,41.7c0,0.4-0.2,0.7-0.6,0.7l-76.7,3.5c-0.3,0-0.6-0.2-0.6-0.6c0,0,0,0,0,0l-0.1-38.7
			c0-0.5,0.4-1,0.9-1l38.2-3.2c0.6,0,1-0.6,1-1.2c0,0,0,0,0-0.1c-0.1-0.8-0.7-1.1-1.7-0.9c-0.6,0.1-1,0.2-1,0.2
			c-16.6,1.5-33.2,3.2-49.9,3.5c-34.5,0.6-69.1,0.3-103.6-0.7c-6.8-0.2-13.5-0.7-19.9-1.6c-22.8-3.1-41.9-6.6-57.3-10.4
			c-9.2-2.3-17.8-8-26-13.5c-3.3-2.2-4-4.3-2.3-7.8c6.7-14.2,13.7-24.4,24.8-36.3c19.8-21.2,40.9-41.3,63.3-60.1
			c0.1-0.1,0.3-0.1,0.4,0c0,0.1,0.1,0.1,0.1,0.2l-0.1,0.6c0,0.2,0,0.5,0.2,0.6C112,625.6,112.5,625.7,113.3,625.5z M1355.5,487.1
			c3.6-0.4,5.8-6.3,4.9-13s-4.4-11.9-8-11.4c0,0,0,0,0,0c-3.6,0.4-5.8,6.3-4.9,13S1352,487.6,1355.5,487.1
			C1355.5,487.1,1355.5,487.1,1355.5,487.1z M1334.2,489.9c3.6-0.5,5.8-6.3,4.9-13.1c0,0,0,0,0,0c-0.9-6.8-4.5-11.8-8.2-11.4
			c-3.6,0.5-5.8,6.3-4.9,13.1c0,0,0,0,0,0C1326.9,485.3,1330.5,490.4,1334.2,489.9z M1306.8,489.6c2.6,5.2,7.9,3.8,9.7-1
			c2.4-6.6,1.5-12.6-2.9-18.1c-2.5-3.1-6.6-3.3-8.3,0.8C1302.9,476.7,1304.3,484.5,1306.8,489.6z M1290.3,495.8
			c3.7-0.5,6-6.4,5.1-13.3l0,0c-0.9-6.9-4.6-12-8.4-11.5l0,0c-3.7,0.5-6,6.4-5.1,13.3S1286.6,496.3,1290.3,495.8z M1268,498.8
			c3.8-0.5,6-6.5,5.1-13.5c0,0,0,0,0,0c-0.9-6.9-4.7-12.2-8.5-11.7c0,0,0,0,0,0c-3.7,0.5-6,6.5-5.1,13.5
			C1260.4,494.1,1264.2,499.3,1268,498.8z M1245.2,501.9c3.8-0.5,6.1-6.5,5.2-13.5c0,0,0,0,0,0c-0.9-7-4.7-12.2-8.5-11.7h0
			c-3.8,0.5-6.1,6.5-5.2,13.5C1237.6,497.1,1241.4,502.4,1245.2,501.9z M1222.1,505c3.8-0.5,6.2-6.6,5.3-13.7
			c-0.9-7-4.8-12.3-8.6-11.8h0c-3.8,0.5-6.2,6.6-5.3,13.7C1214.4,500.3,1218.3,505.5,1222.1,505L1222.1,505z M1198.8,508.1
			c3.9-0.5,6.2-6.7,5.3-13.8c0,0,0,0,0,0c-1-7.1-5-12.3-8.8-11.8c-3.9,0.5-6.2,6.7-5.3,13.8c0,0,0,0,0,0
			C1190.9,503.4,1194.9,508.7,1198.8,508.1z M1174.9,511.4c4-0.5,6.4-6.7,5.4-13.8c0,0,0,0,0,0c-0.9-7.1-4.9-12.4-8.9-11.9
			c0,0,0,0,0,0c-4,0.5-6.4,6.7-5.4,13.8c0,0,0,0,0,0C1166.9,506.6,1170.9,511.9,1174.9,511.4L1174.9,511.4z M1150.7,514.6
			c4-0.6,6.5-6.8,5.5-14c0,0,0,0,0,0c-1-7.2-5-12.5-9.1-12c-4,0.6-6.5,6.8-5.5,14l0,0C1142.7,509.8,1146.7,515.2,1150.7,514.6z
			 M1126.2,518c4.1-0.6,6.5-6.9,5.5-14.1s-5.1-12.6-9.2-12c-4.1,0.6-6.5,6.9-5.5,14.1C1118,513.1,1122.2,518.5,1126.2,518z
			 M1101.3,521.3c4.1-0.6,6.6-7,5.6-14.3c-1-7.3-5.3-12.7-9.4-12.1c-4.1,0.6-6.6,7-5.6,14.3C1092.9,516.5,1097.1,521.9,1101.3,521.3
			z M1075.9,524.8c4.2-0.6,6.7-7,5.7-14.4c0,0,0,0,0,0c-1-7.3-5.3-12.8-9.4-12.2v0c-4.2,0.6-6.7,7-5.7,14.4
			C1067.5,519.9,1071.7,525.4,1075.9,524.8z M1050.1,528.3c4.2-0.6,6.8-7.1,5.8-14.5v0c-1.1-7.4-5.4-12.9-9.6-12.3l0,0
			c-4.2,0.6-6.8,7.1-5.8,14.5c0,0,0,0,0,0C1041.6,523.4,1045.9,528.9,1050.1,528.3L1050.1,528.3z M1023.9,531.8
			c4.3-0.6,6.9-7.2,5.8-14.7c0,0,0,0,0,0c-1.1-7.5-5.5-13-9.8-12.4l0,0c-4.3,0.6-6.9,7.2-5.8,14.7v0
			C1015.3,526.9,1019.6,532.4,1023.9,531.8L1023.9,531.8z M997.2,535.4c4.4-0.6,7-7.3,5.9-14.8c-1.1-7.5-5.5-13.1-9.9-12.5
			c-4.4,0.6-7,7.3-5.9,14.8c0,0,0,0,0,0C988.5,530.5,992.9,536,997.2,535.4L997.2,535.4z M970.2,539.1c4.4-0.7,7.1-7.3,6-14.9
			c0,0,0,0,0,0c-1.1-7.6-5.6-13.2-10-12.6c-4.4,0.7-7.1,7.3-6,14.9c0,0,0,0,0,0C961.2,534.1,965.7,539.8,970.2,539.1z M942.6,542.8
			c4.5-0.7,7.2-7.4,6.1-15.1v0c-1.1-7.7-5.7-13.3-10.2-12.7c-4.5,0.7-7.2,7.4-6.1,15.1c0,0,0,0,0,0
			C933.6,537.8,938.1,543.5,942.6,542.8z M914.6,546.6c4.6-0.7,7.4-7.5,6.2-15.2c-1.1-7.7-5.8-13.4-10.3-12.7c0,0,0,0,0,0
			c-4.6,0.7-7.4,7.5-6.2,15.2S910,547.3,914.6,546.6z M886.1,550.4c4.6-0.7,7.4-7.6,6.2-15.3v0c-1.2-7.8-5.9-13.5-10.5-12.8
			c0,0,0,0,0,0c-4.6,0.7-7.4,7.6-6.2,15.3v0C876.8,545.4,881.5,551.1,886.1,550.4L886.1,550.4z M857,554.4c4.7-0.7,7.6-7.6,6.4-15.5
			c-1.2-7.8-5.9-13.6-10.6-12.9c0,0,0,0,0,0c-4.7,0.7-7.6,7.6-6.4,15.5C847.6,549.3,852.3,555.1,857,554.4
			C857,554.4,857,554.4,857,554.4z M827.5,558.4c4.8-0.7,7.7-7.7,6.5-15.6c-1.2-7.9-6-13.7-10.8-13h0c-4.8,0.7-7.7,7.7-6.5,15.6
			C817.8,553.3,822.7,559.1,827.5,558.4C827.5,558.4,827.5,558.4,827.5,558.4z M797.5,562.4c4.9-0.7,7.8-7.8,6.6-15.8
			c-1.2-8-6.1-13.8-11-13.1s-7.8,7.8-6.6,15.8C787.7,557.3,792.6,563.1,797.5,562.4z M766.9,566.5c4.9-0.8,7.9-7.9,6.7-15.9v0
			c-1.2-8.1-6.2-14-11.1-13.2c-4.9,0.8-7.9,7.9-6.7,15.9c0,0,0,0,0,0C756.9,561.4,761.9,567.3,766.9,566.5z M735.7,570.7
			c5-0.8,8.1-8,6.9-16.1c-1.2-8.1-6.3-14.1-11.4-13.3c-5,0.8-8.1,8-6.9,16.1C725.6,565.5,730.7,571.5,735.7,570.7z M704,575
			c5.1-0.8,8.2-8.1,7-16.3c0,0,0,0,0,0c-1.3-8.2-6.4-14.2-11.5-13.4c-5.1,0.8-8.2,8.1-7,16.3S698.9,575.8,704,575z M671.7,579.3
			c5.2-0.8,8.3-8.2,7.1-16.4l0,0c-1.3-8.2-6.5-14.3-11.7-13.5c-5.2,0.8-8.3,8.2-7.1,16.4c0,0,0,0,0,0
			C661.2,574.1,666.5,580.2,671.7,579.3z M638.8,583.8c5.3-0.8,8.5-8.3,7.1-16.6s-6.7-14.4-11.9-13.6c0,0,0,0,0,0
			c-5.3,0.8-8.5,8.3-7.1,16.6C628.2,578.5,633.5,584.6,638.8,583.8C638.8,583.8,638.8,583.8,638.8,583.8z M605.2,588.3
			c5.4-0.9,8.6-8.4,7.3-16.8c0,0,0,0,0,0c-1.3-8.4-6.8-14.5-12.2-13.7h0c-5.4,0.9-8.6,8.4-7.3,16.8v0
			C594.4,583,599.9,589.1,605.2,588.3L605.2,588.3z M571,592.9c5.4-0.9,8.8-8.5,7.4-17c0,0,0,0,0,0c-1.4-8.5-6.9-14.7-12.3-13.8l0,0
			c-5.4,0.9-8.8,8.5-7.4,17l0,0C560.1,587.5,565.6,593.7,571,592.9C571,592.9,571,592.9,571,592.9z M536.2,597.5
			c5.6-0.9,8.9-8.6,7.5-17.1c-1.4-8.6-7-14.8-12.6-13.9c-5.6,0.9-8.9,8.6-7.5,17.1S530.6,598.4,536.2,597.5z M195.8,626.1
			c16,3.5,32.9,3.5,49.7,4.7c3.2,0.2,5-1.1,5.4-3.9c1.4-10.3,2.4-23.2,3-38.6c0.2-4.3-0.6-6.5-5-7.4c-5.3-1-15.5-4.5-19.8-0.1
			c-13.2,13.5-26,25.2-34.6,42.6c-0.4,0.9-0.1,2,0.8,2.4C195.5,626,195.7,626.1,195.8,626.1z M261.9,631.1c0.3,0.2,0.6,0.2,0.9,0
			c10.1-4.9,20.3-9.8,30.7-14.6c5.8-2.7,6.1-2.5,5.1-9c-0.9-5.8-2.1-11.5-3.4-17.2c-0.8-3.5-0.9-5.8-5.3-6.3
			c-8.2-0.9-16.3-1.9-24.2-3.1c-1.5-0.2-2.9,0.8-3.1,2.3c0,0.1,0,0.2,0,0.4c-0.3,14.3-1.3,28.8-3,43.6
			C259.4,629.4,260.2,630.1,261.9,631.1z"/>
                                                                    <path d="M242.6,756.5l0.4,8.8c0,0.4-0.3,0.7-0.6,0.7l-15.1,1.1c-0.4,0-0.7-0.3-0.7-0.7c0,0,0-0.1,0-0.1l0.7-9.6
			c0-0.4,0.3-0.6,0.7-0.6l14-0.2C242.3,755.8,242.6,756.1,242.6,756.5z"/>
                                                                </g>
                                                            </g>
                                                            <g
                                                                onClick={()=>{
                                                                    setObjects({
                                                                        ...objects,
                                                                        0: {
                                                                            ...objects[0],
                                                                            active: 2
                                                                        }
                                                                    });
                                                                }}
                                                                style={{color: objects[0].elements[1]}}
                                                                className={'action-group' + (objects[0].active == 2 ? ' active' : '')} id="E2">
                                                                <g>
                                                                    <path d="M815.2,400c-0.9-0.2-1.3,0.2-1.1,1.4c0,0.1,0,0.2,0.1,0.2l18.4,33.6c0,0,0,0.1,0.1,0.1l1.1,1.1
			c0.2,0.2,0.2,0.5,0,0.7c-0.1,0.1-0.2,0.1-0.3,0.2l-62.2,6.9c-0.5,0.1-0.7-0.2-0.7-0.7c1.4-10.7,3.1-21.1,5.2-31.2
			c1.4-6.7,3.6-8.6,10.1-11c11.9-4.3,22.2-7.4,33.4-6.6c0.7,0,1.3,0.5,1.5,1.2l2,5.5c0.1,0.2,0,0.5-0.3,0.6c-0.1,0-0.2,0-0.3,0
			C820,401.2,817.7,400.6,815.2,400z"/>
                                                                    <path d="M835.6,435.3c-0.8-0.2-1.4-0.7-1.8-1.4l-16.7-31c-0.1-0.2,0-0.4,0.1-0.5c0.1,0,0.1,0,0.2,0
			c5.1,0.3,7.4,2.4,10.5,6.6c6.4,8.6,12.9,17.3,19.4,26c0.4,0.5,0.2,0.7-0.4,0.8C843.1,436.3,839.4,436.1,835.6,435.3z"/>
                                                                    <path d="M1024.7,657.1c0.3-0.4,0.3-0.7,0-1c-0.1-0.1-0.3-0.2-0.6-0.3c-5.1-0.8-8.4-1-10-0.5
			c-5.4,1.7-10.8,3.5-16.1,5.3c-6,2-14,1.1-20.2,0.6c-0.3,0-0.6-0.2-0.7-0.5c-1.4-3.5,3.2-7.3,5.8-8.7c0.4-0.2,0.5-0.7,0.3-1.1
			c-0.1-0.1-0.2-0.3-0.3-0.3c-0.1-0.1-0.3-0.1-0.5-0.3c-0.2-0.1-0.4-0.1-0.6-0.2c-51.6-2.5-103.1-5-154.5-7.5
			c-0.2,0-0.4-0.2-0.4-0.5c0-0.1,0.1-0.3,0.2-0.4c9.7-5.3,18-8.5,30.1-13.2c36.5-14.1,69.2-21.7,108.8-26.3
			c24.4-2.8,52.9-5.5,85.5-7.8c11.6-0.8,25.1,1.9,35.8,4.1c23.5,5,62.3,13.5,116.5,25.5c0.1,0,2.1,0.5,6,1.5c3.4,0.9,8,1.7,13.5,2.4
			c85,10.6,170,21.2,255,31.8c14.4,1.8,32.2,4.3,50.2,6.2c23.3,2.5,55.2,7.1,86.9,11c44.5,5.4,89.1,10.7,133.5,16.6
			c46,6.2,92.3,12,138.8,14.5c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2l-6.9,2.2c-0.3,0.1-0.5,0.5-0.4,0.8c0,0,0,0,0,0.1
			c0.3,0.6,0.7,0.8,1.3,0.6c3.1-1.2,6.2-2.1,9.4-2.8c0.2-0.1,0.6-0.3,1-0.8c0.2-0.2,0.5-0.4,0.8-0.3l5.8,0.4c0.2,0,0.4,0.2,0.4,0.4
			c0,0.2-0.1,0.3-0.3,0.4c-9.9,2-19.8,5.7-29.7,6.9c-25.7,3.1-45.7,5.1-60,5.9c-5.3,0.3-16.2,0.3-32.8,0c-0.4,0-0.5-0.2-0.3-0.6
			c0.3-0.5,0.7-0.9,1.3-1.1c0.6-0.2,0.8-0.9,0.6-1.4c-0.2-0.4-0.5-0.7-1-0.7c-22.8-1.3-45.6-3.2-68.3-5.2
			c-206.3-18.5-412.5-37.9-618.8-57.2c-4.2-0.4-8.5-0.8-12.7-1.1c-5.4-0.4-8.4,2.8-9.3,7.9c0,0.3-0.3,0.5-0.6,0.5l-32.4-0.9
			c-0.4,0-0.8,0.3-1,0.7c-0.2,0.5,0,0.8,0.5,0.8c16.5,0.8,33.2,0.7,49.4,2.1c28.5,2.4,57,4.8,85.5,7.3c0.3,0,0.6,0.3,0.6,0.7
			c0,0.1,0,0.2-0.1,0.3c-2.4,3.3-4.9,6.6-7.5,9.7c-0.4,0.5-1,0.9-1.6,1c-19.9,5.3-40.4,4.8-60.7,3.6c-22.9-1.3-45-7.2-68.1-7.6
			c-15.8-0.3-31.5,0.4-47.2,1.9c-5,0.5-14.1,1.3-18.5-1.5c-0.3-0.2-0.4-0.7-0.2-1c0-0.1,0.1-0.1,0.1-0.2c7.9-6.9,16.8-12.2,26.8-16
			c10.2-3.8,19.4-9,31.2-7.5C1024.2,657.4,1024.5,657.3,1024.7,657.1z M1032.2,663.7c0.3-0.5-0.1-1.2-0.9-1.6c0,0,0,0,0,0
			c-0.7-0.4-1.6-0.4-1.8,0.1c-0.3,0.5,0.1,1.2,0.9,1.6l0,0C1031.1,664.2,1031.9,664.2,1032.2,663.7z"/>
                                                                    <path d="M822,665.6c-2.6-2.1-5.6-4.7-6.1-8c-0.8-5,4-13.1,9.7-12.8c51,2.4,102,4.8,153.1,7.2c0.3,0,0.5,0.2,0.5,0.5
			c0,0.2-0.1,0.3-0.2,0.4c-2.8,2.1-4,4.9-3.7,8.4c0,0.4-0.1,0.6-0.5,0.6l-152.3,4C822.2,665.8,822.1,665.7,822,665.6z"/>
                                                                    <path d="M1070.3,663.5c-0.3,0-0.5-0.3-0.4-0.5c0,0,0,0,0,0c0.5-4,3.3-6.3,7.5-5.9c141.8,13.2,283.6,26.4,425.4,39.6
			c90.3,8.4,180.7,17,271.2,23.9c0.4,0,0.6,0.2,0.6,0.6c0,1.2-1.2,1.7-2.3,1.6c-23.7-1.7-47.3-3.2-71-5.1
			c-195.2-16.1-390.3-33.2-585.5-49.9C1101.2,666.4,1086.1,665,1070.3,663.5z"/>
                                                                </g>
                                                            </g>
                                                            <g
                                                                onClick={()=>{
                                                                    setObjects({
                                                                        ...objects,
                                                                        0: {
                                                                            ...objects[0],
                                                                            active: 3
                                                                        }
                                                                    });
                                                                }}
                                                                style={{color: objects[0].elements[2]}}
                                                                className={'action-group' + (objects[0].active == 3 ? ' active' : '')}  id="E3">
                                                                <g>
                                                                    <path d="M888.5,694.3c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1,0.1-0.1,0.2-0.2c24.7-5.7,49.6-10.4,74.7-14.2
			c0.2,0,0.4,0.1,0.5,0.3c0,0.1,0,0.1,0,0.2l-0.4,1.3c-0.1,0.4,0,0.7,0.3,0.9c7.4,5.7,20.4,2.7,29.4,2.1c19.7-1.5,39.8-2,59.4,0.4
			c12.1,1.5,24.2,3.2,36.2,5.1c5.1,0.8,11,0.8,16.4,1.4c0.3,0,0.6,0.2,0.9,0.4c13.4,13.8,20.1,41.9,10.3,59.3
			c-1.4,2.4-3.5,4.5-6.3,6.3c-14,8.8-28.1,17.4-42.3,25.9c-23.9,14.4-49.8,25.4-76.8,32c-24.4,6-49.1,11.2-74.1,15.7
			c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.3c5.7-5.7,9.5-12.6,11.3-20.8c7.1-32.3-1.8-77.9-23.7-104.1
			C899.9,700.5,894.7,696.8,888.5,694.3z"/>
                                                                    <path d="M845.8,826.2c-18.7-17.7-26.1-45.8-27.5-70.9c-0.8-14.5,0.5-31.7,11.9-41.8c4.7-4.1,14.9-9.4,20.3-10.3
			c17.3-3.1,31.5,27,35.9,39.8c8.2,24.3,14.5,64.3-0.3,87.2c-6.7,10.4-20.5,7.8-30.1,3.6C851.8,831.8,849.6,829.8,845.8,826.2z
			 M835,724.8c11.6,0.7,19.1,10.9,23.5,21.3c1.9,4.4,1.8,7.6,2.2,12.5c0.8,10.7,3.3,21.3,7.6,32c3.3,8.3,1.1,21.9-4.1,29.1
			c-0.6,0.8-1.5,1.2-2.7,1c-0.4,0-0.7,0.2-0.8,0.6l-0.3,1.3c-0.1,0.7,0.3,1.3,0.9,1.4c0.4,0.1,0.8,0,1.1-0.3c4.3-3.9,7-8.8,8.2-14.6
			c0.4-2,0.7-5.6,0.7-10.8c0.2-15.6-3.2-31.7-8.5-46.3c-4.8-13.1-12-27.1-26.9-29c-0.3,0-0.6,0-0.8,0.1c-0.4,0.2-0.6,0.3-0.7,0.4
			c-0.2,0.3-0.2,0.8,0.2,1C834.7,724.7,834.8,724.8,835,724.8z"/>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {currentSlide === 1 && (
                                            <div className="obj__it obj__it--1">
                                                <div className="obj__it-img">
                                                    <img src={require("../assets/img/objects/suitcase.svg").default}/>
                                                    <div className="obj__el">
                                                        <svg viewBox="0 0 1931 1086">
                                                            <g
                                                                onClick={()=>{
                                                                    setObjects({
                                                                        ...objects,
                                                                        1: {
                                                                            ...objects[1],
                                                                            active: 1
                                                                        }
                                                                    });
                                                                }}
                                                                style={{color: objects[1].elements[0]}}
                                                                className={'action-group' + (objects[1].active == 1 ? ' active' : '')}
                                                                id="E1">
                                                                <g>
                                                                    <path d="M624.6,792.5c-0.6-2.7-1-4.7-1.1-6c-3-37.1-5-65.4-6.1-84.8c-0.1-1.7,0.4-3.9,1.3-6.5
			c0.2-0.1,0.4-0.2,0.5-0.4c0.2-0.3,0.5-0.3,0.8-0.2c0.2,0.1,0.3,0.3,0.2,0.5l-0.1,2.6c0,0.1,0.1,0.3,0.3,0.3c0,0,0.1,0,0.1,0
			c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.5c-0.1-3.2-0.3-6.4-0.8-9.5c-0.1-0.3,0.2-0.6,0.5-0.7c0.2,0,0.4,0,0.5,0.2
			c5.5,5.8,11,10.3,18.2,14.9c73.1,46.7,146.3,93.5,219.4,140.2c12.8,8.2,27.7,15.1,44.9,20.6c7.3,2.4,16.9,3.8,23.5,4
			c17.2,0.4,32.6-6.7,48.7-13.1c0.4-0.1,0.5-0.6,0.4-0.9c-0.1-0.2-0.2-0.3-0.4-0.4c-0.6-0.3-1.4-0.2-2.2,0.1
			c-15.2,6.3-28.6,12-45.5,12.3c-6.8,0.1-15.7-1.1-22.1-3.1c-21.9-7.1-40.2-17.1-59.5-29.4c-65.3-41.4-130.6-82.9-196-124.4
			c-9.7-6.1-32.9-19.8-34.3-32.2c-0.5-4.3-0.8-8.6-1-12.8c-0.3-5-2.1-13.5,0.5-18.8c0.1-0.3,0.5-0.5,0.8-0.3
			c0.3,0.1,0.4,0.4,0.4,0.7l-0.3,1.6c0,0.1,0.1,0.3,0.2,0.3c0,0,0,0,0.1,0c0.1,0,0.2,0,0.3,0c0.3,0,0.5-0.2,0.5-0.5c0,0,0,0,0-0.1
			l-1-10.2c0-0.4,0.2-0.7,0.6-0.7c0.2,0,0.4,0,0.5,0.2c6,5.8,12,10.3,19.7,14.9c73.1,44,146.2,88.1,219.3,132.2
			c14.2,8.5,30.4,15.5,48.8,21c8,2.4,18.8,3.2,26.8,2.8c16-1,29.3-6.9,43.4-12.4c0.5-0.2,0.6-0.5,0.4-1l-0.1-0.2
			c-0.2-0.4-0.7-0.6-1.1-0.5c-15.2,5.6-28,11.1-44.3,12.2c-8,0.5-18.8-1-26.3-3.3c-18.4-5.7-35.2-13.3-51.8-23.3
			c-68-40.6-136-81.3-204-122c-7-4.2-12.9-7.8-17.6-10.9c-5.3-3.5-10.4-7.9-15.2-13.2c-2.5-2.1-3.9-4.5-4-7.1
			c-0.5-8.7-1.2-17.5-1.9-26.2c-0.2-2.1,0.4-4.6,1.8-7.4c0.3-0.3,0.6-0.4,1.1-0.3c0.3,0.1,0.4,0.3,0.4,0.6c-0.1,0.7-0.1,1.5,0,2.3
			c0,0.2,0.2,0.4,0.4,0.3c0.2-0.1,0.4-0.3,0.4-0.6l-0.7-11.3c0-0.3,0.2-0.5,0.5-0.5c0.1,0,0.3,0,0.4,0.1c6.3,6,12.4,10.1,20.7,14.8
			c71.2,40.2,142.3,80.5,213.5,120.7c18.5,10.5,37.2,18.7,57.8,24.3c4.7,1.3,9.9,1.5,14.8,2c20,2,35.5-4.5,54.5-11.1
			c0.1,0,0.3-0.1,0.8-0.2c0.5-0.1,0.7-0.4,0.5-0.8c-0.4-0.8-1.4-1.2-2.2-0.9c-15.7,5.9-30.2,11.5-47.9,11.3
			c-26.6-0.2-54.8-13.6-77.5-26.4C779.4,676.3,709.7,637.1,640,598c-3.5-1.9-6.9-3.9-10.3-5.9c-6.7-3.8-12.7-8.8-18.1-14.8
			c-4.2-2.9-3.8-9-3.9-14.9c1-8.2-1.6-16.2,0.6-24.3c0.1-0.3,0.3-0.5,0.6-0.5c0.1,0,0.2,0,0.3,0c0.2,0.1,0.2,0.2,0.2,0.3
			c-0.1,0.6-0.1,1.2,0.1,1.8c0,0.2,0.3,0.4,0.5,0.4c0.2,0,0.4-0.3,0.4-0.5l-0.7-12c0-0.3,0.2-0.6,0.5-0.6c0.1,0,0.3,0,0.4,0.1
			c5,4.4,10.5,8.2,16.4,11.3c73.2,38.7,146.5,77.4,219.8,116c26.7,14,58.5,27.8,88.6,25c14.6-1.4,27.2-6.5,41-10.6
			c0.5-0.1,0.7-0.6,0.6-1.1c-0.1-0.3-0.3-0.5-0.6-0.6l-0.7-0.2c-0.3-0.1-0.7-0.1-1,0c-21,7.2-36.4,12.1-57.2,10.2
			c-5.7-0.5-12.2-1.8-19.6-3.9c-15.7-4.4-29.9-10-42.7-16.6c-75-39.1-150-78.3-224.9-117.5c-7.5-3.9-24.2-13.2-25-22.6
			c-1.2-13.5-1.9-23-2.1-28.4c-0.1-1.4,0.5-2.9,1.6-4.6c0.1-0.2,0.3-0.2,0.5-0.1c0.1,0.1,0.2,0.2,0.2,0.3l0.1,1.3
			c0,0.6,0.2,0.7,0.6,0.2l0.3-0.3c0.1-0.1,0.1-0.2,0.1-0.3l-1-12.8c0-0.3,0.2-0.6,0.5-0.6c0.1,0,0.3,0,0.4,0.1
			c5.6,4.4,11.1,8,17.8,11.3c76.2,37.2,152.4,74.3,228.7,111.4c14.6,7.1,30.8,12.8,48.6,17.1c8.4,2,18.5,3.1,27.2,2.9
			c17-0.5,31.3-5.3,46.8-10.3c0.5-0.2,0.8-0.7,0.6-1.2c-0.2-0.4-0.6-0.7-1.1-0.6c-0.8,0.2-1.7,0.4-2.7,0.8
			c-23.2,7.9-46.2,12.3-70.5,6.4c-17.7-4.3-33.9-10-48.6-17.1c-75.4-36.4-150.8-72.8-226.2-109.2c-7.2-3.5-24.8-12.5-25.6-21.3
			c-1.1-13.3-1.8-23-2.1-28.9c-0.1-1.6,0.5-3.3,1.7-4.9c0.3-0.4,0.5-0.4,0.5,0.2v1.1c0,0.4,0.2,0.6,0.7,0.6c0.4,0,0.6-0.2,0.6-0.7
			c-0.4-4.7-0.8-9.3-1.1-13.8c0-0.3,0.2-0.5,0.5-0.5c0.1,0,0.2,0,0.3,0.1c6.3,4.4,12.4,7.9,19.9,11.3
			c68.1,30.4,136.3,60.9,204.4,91.3c14.3,6.4,25,11,31.9,13.8c14.2,5.7,28.8,10.2,43.7,13.5c5.5,1.2,11.3,1.5,17,2
			c19,1.7,38.5-3.5,56.4-9.4c0.4-0.1,0.6-0.5,0.5-0.9c0-0.1-0.1-0.2-0.1-0.3c-0.6-0.7-1.5-0.8-2.7-0.4c-14.5,4.8-29.1,8.5-44.4,9
			c-27,1-57-9.4-81.6-20.3c-43.9-19.5-119.1-52.8-225.7-99.9c-9.5-4.2-17.6-8.9-23.8-16.6c-0.2-0.3-0.3-0.6-0.4-0.9
			c-0.7-9.9-1.4-19.9-2-29.8c-0.2-3.3,0.5-5.4,2.8-7.6c0.2-0.2,0.3-0.5,0.3-0.8l-0.9-11.5c0-0.3,0.2-0.6,0.5-0.7
			c0.1,0,0.3,0,0.4,0.1c6.1,4.1,11.8,7,19.1,10c75.7,30.7,151.5,61.3,227.2,92c19.2,7.8,38.6,13.5,58.2,17.3c3,0.6,8.9,1,17.7,1.4
			c19.5,0.8,35.7-3.2,54.8-8.4c0.6-0.2,0.7-0.5,0.5-1l-0.4-0.7c-0.1-0.2-0.4-0.4-0.7-0.3c-19.5,4.7-36.9,9.9-58,8.1
			c-4.8-0.4-9.6-0.5-14.2-1.4c-20.5-4-40.4-9.9-59.8-17.8c-76.4-31.2-153.3-61.5-229.9-92.2c-3.1-1.2-7.2-3.5-12.5-6.9
			c-2.6-1.7-4.6-3.9-6.7-6.2c-0.2-0.2-0.3-0.5-0.4-0.8c-1.3-15.6-2.1-26.4-2.4-32.6c0-1,0.4-2.2,1.2-3.5c0.2-0.4,0.4-0.4,0.7,0
			l0.2,0.3c0.2,0.3,0.6,0.4,1,0.2c0.2-0.1,0.4-0.4,0.3-0.7l-1.1-15.6c0-0.3,0.2-0.6,0.5-0.6c0.1,0,0.2,0,0.3,0.1
			c5.5,3.2,11.1,5.9,17,8c78.4,28.5,156.8,56.8,235.2,85c19.1,6.9,38.6,11.9,58.6,15.2c0.1,0,0.2,0,0.2-0.1c0.7-1.2,0.4-1.9-0.7-2.1
			c-19.9-3.6-39.8-8.6-58.8-15.4c-77.7-27.7-155.4-55.5-233.1-83.4c-8.2-2.9-17.5-6.9-22.9-13.3c-0.2-0.2-0.3-0.6-0.3-0.9
			c-0.7-12.3-1.5-22.7-2.2-31.1c-0.1-1.6,0-3.1,0.6-4.5c0.1-0.4,0.4-0.6,0.8-0.5l0.9,0c0.6,0,1-0.4,1.1-1c0,0,0-0.1,0-0.1l-1.2-16.4
			c0-0.5,0.2-0.7,0.6-0.4c7.3,3.8,14.8,6.9,22.6,9.4c80.1,25.3,160.2,50.6,240.2,75.9c2.5,0.8,6.4,1.8,11.7,3c0.3,0.1,0.5,0,0.7-0.3
			c0.5-1,0.3-1.6-0.6-1.9c-6.2-1.8-12.6-3.5-18.5-5.3c-79.7-25-159.4-49.9-239.1-74.9c-7-2.2-15.5-5.6-21.3-11.4
			c-0.2-0.2-0.4-0.5-0.4-0.8c-2.3-30.8-4.4-61.6-6.5-92.4c-0.6-9.2-0.9-15.8-0.7-20c0.4-13,2.8-25.8,7.2-38.6
			c6.7-19.8,26.2-30.6,45.7-33.4c13.9-2,25.8-3.4,37-1.6c76,12.4,152,24.7,228,37.1c5.8,0.9,11.5,1.9,17.2,3c5.6,1,9.9,2.8,14.9,5
			c5.4,2.4,10.5,6.4,15.5,9.7c6.5,4.4,12.4,12.9,17.5,18.9c1.3,1.6,2.4,3.3,3.1,5.2c3.8,9.8,8.5,19.9,10.6,30.3
			c3.4,16.8,6.3,32,6.2,49.4c-0.6,254.2-0.6,508.5-1.9,762.7c-0.1,13.6-1.8,24.5-6.3,36.3c-0.7,1.7-1.8,3.3-3.4,4.7
			c-0.4,0.3-0.5,0.3-0.4-0.2c1-4.3,2.7-7.4,2.4-12c-0.1-1.1-1-2-2.2-1.9c-0.1,0-0.3,0-0.4,0.1c-5.6,1.5-11.8,4.7-15.7,6.2
			c-10.1,3.8-20.2,5-30.9,3.4c-4.7-0.7-9.3-1.3-13.9-2.8c-16-5.4-31.1-12.5-45.3-21.5c-2.3-1.4-4.3-1.8-6.9-2.3
			c-1.1-0.2-1.8,0.1-2.1,0.9c-0.8,2.3-0.6,6.9-0.3,9.3c0.6,4.7,3.4,10.3,4.9,15.4c0.2,0.6,0,0.8-0.5,0.4
			c-62.1-45.6-124.3-91.3-186.4-136.9c-1.7-1.2-4-3.2-6.9-6c-3.1-3-4.4-7.7-6-11.7c-1.9-4.6-3.3-10.3-5.3-15.4c-1.5-3.7-5-7.5-8-10
			c-9-7.2-18.8-17.8-20.4-29.7c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.2,0L624.6,792.5z M942.1,405.8c11.6-1.7,22.8-4.2,33.8-7.2
			c0.5-0.1,0.8-0.7,0.6-1.2c-0.1-0.4-0.5-0.7-0.9-0.7c-0.9,0-1.8,0.1-2.9,0.4c-11.1,2.8-22.2,4.9-33.3,6.5c-0.6,0.1-1,0.6-0.9,1.1
			c0.1,0.4,0.4,0.8,0.8,0.9C940.2,405.9,941.1,405.9,942.1,405.8z"/>
                                                                    <path 
                                                                          d="M984.3,52.2c-1.7,0.2-3.3,0-4.7-0.7C981.3,51.1,982.9,51.4,984.3,52.2z"/>
                                                                    <path d="M1040.2,196.2c0.2-16.8-1.7-32.2-5.2-48.5c-2.5-11.6-7.1-22.5-11.6-33.6c-0.7-1.8-1.8-3.5-3.1-5
			c-4.2-4.6-8.3-9.3-12.4-14c-4.2-4.8-11.2-8.3-16.5-11.9c-0.2-0.1-0.2-0.3,0-0.4c0.1-0.1,0.3-0.1,0.5,0c6.1,3.7,14.6,8.3,19.7,14.1
			c4.4,5,12,12,14.5,18.1c3.9,9.8,8.7,19.9,10.7,30.4c2.5,12.9,5.6,25.8,5.6,39c0,7-0.1,19.5-0.3,37.4c-3,220.1-5.7,440.2-9.1,660.2
			c-0.4,23.9,0,52-1,67.8c-0.4,7.2-2.1,14.8-5.1,22.9c-2.2,6.1-6,10.7-10.1,15.7c-0.2,0.2-0.3,0.3-0.5,0.3c-0.2,0-0.5-0.2-0.5-0.4
			c0-0.1,0-0.2,0.1-0.3c3-3.3,5.7-6.2,7.4-10.5c4.5-11.5,6.3-21.4,6.5-34.5C1033.7,694,1036.7,445.1,1040.2,196.2z"/>
                                                                </g>
                                                            </g>
                                                            <g
                                                                onClick={()=>{
                                                                    setObjects({
                                                                        ...objects,
                                                                        1: {
                                                                            ...objects[1],
                                                                            active: 2
                                                                        }
                                                                    });
                                                                }}
                                                                style={{color: objects[1].elements[1]}}
                                                                className={'action-group' + (objects[1].active == 2 ? ' active' : '')}
                                                                id="E2">
                                                                <g>
                                                                    <path d="M833.2,29.8c0.6-0.1,1.2,0,1.8,0.3c-0.4,0.6-1.1,0.9-2,1c-4.5,0.5-8.3,1.4-12.9,0.8c-7-1-13.3,0.4-19.3,0.5
			c-2.1,0-4,0.5-5.7,1.3c-0.3,0.2-0.6,0.5-0.5,0.9v4.6c0,0.4-0.3,0.7-0.7,0.7c0,0-0.1,0-0.1,0L760,35c-0.2,0-0.4-0.2-0.4-0.4
			s0.2-0.4,0.4-0.4c17-1,34-2.5,51.2-4.4C815.5,29.3,822.9,29.3,833.2,29.8z"/>
                                                                    <path d="M903.9,46.3c11.2,3.9,22,8.5,32.6,13.7c0.1,0,0.2,0.2,0.1,0.3c0,0.1-0.1,0.1-0.2,0.1L818.5,43.3
			c-0.4-0.1-0.5-0.3-0.2-0.6l0.2-0.2c0.1-0.1,0.3-0.2,0.5-0.2c3.3,0.2,7.1-0.4,11.5-1.6c9.5-2.7,21.3-3.9,32.2-3.5
			c0.7,0,1.4,0.4,1.9,1c0.2,0.2,0.5,0.4,0.8,0.4C878.6,40.3,891.5,41.9,903.9,46.3z"/>
                                                                    <path d="M1104.6,940c-3,3.8-6.4,9-10.9,11.1c-15,7.3-30.1,14.6-45.2,21.9c-0.2,0.1-0.4,0-0.5-0.2
			c-0.1-0.1,0-0.3,0-0.4c1.9-2.3,3.8-4.2,4.9-7.1c2.1-5.8,4.4-11.7,5.3-17.7c0.9-6.4,1.5-13.5,1.7-21.2
			c4.2-207.5,8.5-414.9,12.7-622.3c0-0.3,0.2-0.5,0.5-0.5c0,0,0.1,0,0.1,0c3.2,1,5.8,2,9.3,1.3c6.2-1.4,10.8-2.3,13.7-2.9
			c1.9-0.4,3.1-1,3.3-3.1c0.6-6,0.8-12.3,0.6-19c-0.1-1.9,0.7-3.5,0.5-5.4c-0.4-3.3-0.4-6.6-0.1-9.8c0.4-3.6-0.4-7.5,1.1-11.4
			c2.5-6.8,1.8-13.9,2.3-22.1c0.2-3.8-2.2-4-5.5-4.7c-2.1-0.5-3.5-0.9-5.7-0.5c-6,1.1-12.1,2.2-18.1,3.2c-0.3,0.1-0.5-0.1-0.5-0.4
			c0.2-14.4,0.4-28.9,0.7-43.3c0.4-16.6-2.6-31.2-5.8-47.2c-2-10-6.8-19.9-10.6-29.4c-0.7-1.9-1.8-3.6-3.1-5.1
			c-5.3-6-11.4-14.4-18.1-18.7c-9.2-5.9-18.6-12.2-29.6-14.1c-12.3-2.1-24.7-4-37.1-5.6c-0.3,0-0.4-0.2-0.3-0.5
			c0.1-0.2,0.2-0.3,0.5-0.3c2.6-0.1,5-0.5,7-1.2c2.8-1,1.9-5.5,1.9-7.9c0-0.3,0.3-0.6,0.6-0.6c0,0,0.1,0,0.1,0
			c6,0.9,12,1.7,18.1,2.5c4.8,0.6,10.6,0.5,16,1.3c1.4,0.2,2.3,1.2,3.8,0c0.3-0.2,0.6-0.3,1-0.4c6.3-0.6,14-0.4,23,0.7
			c22.8,2.9,40.9,5.4,54.2,7.7c12.4,2.1,24.5,8.7,34.8,16.5c6,4.5,11.2,10.8,16.5,16.8c1.5,1.6,2.6,3.5,3.5,5.5
			c3.5,8,7.1,15.8,9.2,24.3c3.3,13.2,5.6,25.5,5.1,39.4c-1.3,36.4-2.6,72.9-4,109.3c-0.2,6.4-7.7,8.8-12.8,10
			c-0.7,0.2-0.8,0.6-0.4,1.2c0.3,0.4,0.8,0.6,1.3,0.4l6.8-2.2c0.4-0.1,0.5,0,0.5,0.4c0,5.5-0.2,10.9-0.6,16.3
			c-0.1,1.1,0,2.1,0.3,3.1c0.1,0.4,0.3,0.5,0.7,0.5l1.1-0.1c0.4,0,0.6,0.1,0.8,0.4c0.9,1.4,1.2,3.2,1.2,5.1
			c-0.3,6.1-0.7,17.2-1.2,33.5c-0.2,7.9-24.6,11.7-30.8,13.2c-1.5,0.4-2.6,1.7-2.7,3.2c-0.4,7-0.5,14.2-0.5,21.7
			c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.3,0.5,0.4,0.7c0.2,0.3,0.6,0.4,1,0.2c0.2-0.1,0.3-0.4,0.3-0.6l0.5-22.7c0-0.7,0.5-1.3,1.2-1.5
			c8.3-2,17.1-3.6,25.1-6.9c0.3-0.1,0.6,0,0.7,0.3c0,0.1,0,0.1,0,0.2l-0.6,17.8c0,0.5,0.4,0.9,0.8,0.9c0.1,0,0.1,0,0.2,0l1.3-0.2
			c0.2,0,0.4,0.1,0.5,0.2c0.8,1.3,1.1,2.4,1.1,3.6c-0.3,11.1-0.7,22.3-1.2,33.4c-0.2,4.3-4.3,7.6-7.9,8.8c-6.9,2.3-13.9,4.3-21,6
			c-1.6,0.4-2.8,1.1-3.8,2.3c-0.3,0.3-0.4,0.7-0.4,1.1l-0.6,21.7c0,0.6,0.3,1.2,0.9,1.4l0.6,0.3c0.4,0.2,0.6,0.1,0.6-0.3l0.3-21.9
			c0-1.1,0.7-2,1.8-2.2c7.8-2.1,17-3.9,24.2-7.5c0.3-0.1,0.7,0,0.8,0.3c0,0.1,0.1,0.2,0.1,0.3l-0.7,16.4c0,0.6,0.5,1.1,1.1,1.1
			c0.1,0,0.3,0,0.4-0.1l0.7-0.3c0.4-0.1,0.7,0,0.8,0.4c0.8,1.8,1.2,3.8,1,6.1c-0.5,7.6-0.9,17.3-1.2,29.2c-0.1,5.8-5.5,9.3-10.6,11
			c-8.3,2.8-14.4,4.7-18.3,5.7c-2.5,0.6-3.8,2.2-3.9,4.8c-0.2,5.9-1.2,14.2-0.1,19.9c0.1,0.4,0.5,0.7,0.9,0.6
			c0.4-0.1,0.6-0.4,0.6-0.8l0.4-20.5c0-1.1,0.8-2,1.8-2.3c8.2-2,16.3-4.7,24.1-7.9c0.3-0.1,0.5,0,0.5,0.3l-0.3,15.8
			c0,1.5,0.5,1.7,1.5,0.7l0.4-0.4c0.3-0.3,0.5-0.2,0.7,0.1c0.9,1.7,1.4,3.2,1.3,4.6c-0.5,10.1-0.9,20.2-1.2,30.4
			c-0.3,10.8-19.8,15.3-28,17.4c-3.1,0.8-4.7,2.8-4.6,6.2c0.1,7.3-0.4,13.7-0.3,18.1c0,0.8,0.5,1.1,1.3,1c0.2,0,0.3-0.2,0.3-0.4
			l0.5-20.2c0-1,0.7-1.9,1.7-2.2c8.1-2.7,16.7-4.6,23.8-8.8c0.2-0.1,0.5-0.1,0.7,0.2c0,0.1,0.1,0.2,0.1,0.3l-0.4,14.7
			c0,0.5,0.4,0.9,0.9,0.9c0.3,0,0.6-0.1,0.8-0.4c0.2-0.3,0.3-0.6,0.2-1c0-0.1,0-0.2,0.1-0.3s0.2,0,0.3,0.1c1.1,2.2,1.6,3.6,1.6,4.4
			c-0.3,10.3-0.6,20.6-1.1,30.8c-0.3,6.1-5.5,10.4-10.9,12.4c-6.5,2.4-12.8,4.6-18.7,6.8c-1.6,0.5-2.6,2-2.6,3.7l-0.2,18.8
			c0,0.4,0.1,0.8,0.4,1.2c0.3,0.4,0.5,0.4,0.7,0.1c0.3-0.4,0.4-0.7,0.4-0.8l0.5-19c0-1.2,0.8-2.2,1.9-2.6c7.6-2.7,16.2-4.9,22.8-9
			c0.9-0.6,1.3-0.3,1.3,0.8l-0.4,13.6c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.7c0.1-0.4,0.2-0.8,0.4-1.2c0.2-0.4,0.3-0.4,0.5,0
			c0.6,1.8,1.2,3.2,1.2,5.1c-0.1,9.8-0.4,19.6-1,29.3c-0.3,5.3-4.4,10.1-8.9,12.1c-7.5,3.2-13.9,5.7-19.2,7.5
			c-2.2,0.7-3.3,2.1-3.4,4.3c-0.5,8.8-0.8,15-0.7,18.4c0,0.8,0.5,1.1,1.4,0.9c0.3-0.1,0.4-0.3,0.4-0.5c-0.2-7.1,0-13.4,0.5-18.9
			c0.2-1.9,1.4-2.3,3-2.9c7.3-2.8,15.3-5.1,21.9-9.4c0.3-0.2,0.6-0.1,0.8,0.2c0.1,0.1,0.1,0.2,0.1,0.3l-0.6,13c0,0.5,0.4,1,0.9,1.1
			c0.5,0.1,0.9-0.3,1-0.8l0-1c0-0.2,0.2-0.3,0.3-0.3c0.1,0,0.2,0.1,0.2,0.1c0.8,1.5,1.2,3,1.2,4.4c0.1,8.7-0.3,18.6-1,29.6
			c-0.4,5.8-4.4,10.4-9.4,12.7c-3.8,1.8-10.3,4.8-17.6,7.1c-3.2,1.1-4.3,2.7-4.3,6.2c-0.1,4.7-0.3,9.4-0.5,14.1
			c-0.1,1.3-0.3,2.5,0.9,3.3c0.2,0.1,0.4,0.1,0.5-0.1c0-0.1,0.1-0.1,0.1-0.2l0.7-19c0-0.9,0.6-1.6,1.4-2c7.6-3.2,16.8-5.9,23.4-10.8
			c0.2-0.2,0.5-0.1,0.7,0.1c0.1,0.1,0.1,0.2,0.1,0.3c-0.4,4.2-0.5,8.3-0.4,12.4c0,0.4,0.3,0.8,0.7,0.9c0.4,0.1,0.7-0.2,0.7-0.6l0,0
			c0-0.7,0-1.4-0.1-2c0-0.1,0.1-0.2,0.2-0.2l0.3,0c0.1,0,0.2,0,0.3,0.2c0.7,2.2,1.4,4.4,1.4,6.8c-0.2,8.6-0.5,17.3-0.9,25.9
			c-0.1,2.7-0.6,4.9-1.5,6.6c-4.7,8.8-18.4,12.7-27,16.3c-1.5,0.7-2.5,2.1-2.5,3.8l-0.4,17.7c0,0.5,0.3,0.8,0.8,0.9
			c0.7,0.1,1-0.3,0.8-1c-0.1-0.4-0.2-0.7-0.1-0.9c0.2-5.8,0.4-11.4,0.5-16.8c0-1,0.6-2,1.6-2.4c5.6-2.4,11.2-4.8,17-7.3
			c2.1-0.9,3.9-2.5,6-3.8c0.2-0.1,0.5-0.1,0.6,0.1c0,0.1,0.1,0.2,0.1,0.3l-0.4,11.8c0,0.4,0.3,0.7,0.7,0.7c0.2,0,0.5-0.1,0.6-0.3
			c0.3-0.4,0.4-0.9,0.2-1.5c0-0.1,0-0.3,0.2-0.3l0.3-0.1c0.2-0.1,0.3,0,0.4,0.2c1,2.6,1.4,5.3,1.3,8.2c-1,28.8-2.1,57.5-3.2,86.2
			c-0.2,5.9-2.2,18.9-6.9,23c-4.1,3.5-8.7,6.3-13.8,8.4c-8.2,3.4-9.5,9.4-12.4,16.6C1106,937.9,1105.4,939,1104.6,940z
			 M1128.6,319.7c0.4,0.2,0.9,0.1,1.1-0.3c0.1-0.2,0.1-0.5,0-0.7c-0.4-0.9-0.6-2-0.5-3.2c0.2-6.5,0.4-13,0.7-19.5
			c0-0.8,0.5-1.4,1.3-1.7c1.2-0.4,2.5-0.8,4-1.1c0.4-0.1,0.7-0.3,0.8-0.5c0.2-0.3,0.2-0.6,0.1-1c-0.1-0.3-0.4-0.5-0.7-0.4
			c-2.8,0.2-7.3,1-7.5,4.2c-0.2,3.3-0.4,10.6-0.6,22C1127.2,318.5,1127.7,319.2,1128.6,319.7z M1066.3,705.5c0,4.2,2.4,5.5,5.7,7.2
			c6.3,3.2,17-2.8,20.2-8.5c0.7-1.4,1.2-3.2,1.3-5.5c0.3-5.5,0.5-10.9,0.6-16.4c0-0.3-0.2-0.8-0.6-1.4c-0.2-0.3-0.2-0.6-0.2-0.9
			c5.6-26.9,9.3-52.4,10.9-76.5c2.9-41.3-0.6-84.6-5.5-126.8c-0.1-0.6,0.1-1.2,0.4-1.8c0.2-0.3,0.3-0.6,0.3-0.9
			c0.2-5.5,0.3-11,0.5-16.5c0.2-4.3-0.6-8.3-4.8-9.8c-7-2.6-16.6-1-21.5,5.3c-4.7,6-1.2,16.2-3.1,25.8c-0.1,0.3,0,0.6,0.3,0.9
			c0.6,0.6,1,1.1,1.1,2c5.8,45,9,87.8,6.1,129.8c-1.6,23.1-5,47.8-10.1,73.9c-0.3,1.7-1.3,2.2-1.3,4
			C1066.4,694.8,1066.3,700.2,1066.3,705.5z"/>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {currentSlide === 2 && (
                                            <div className="obj__it obj__it--2">
                                                <div className="obj__it-img">
                                                    <img src={require("../assets/img/objects/stairs.svg").default}/>
                                                    <div className="obj__el">
                                                        <svg viewBox="0 0 1931 1086">
                                                            <g
                                                                onClick={()=>{
                                                                setObjects({
                                                                    ...objects,
                                                                    2: {
                                                                        ...objects[2],
                                                                        active: 2
                                                                    }
                                                                });
                                                            }}
                                                                id="E2"
                                                                style={{color: objects[2].elements[1]}}
                                                                className={'action-group' + (objects[2].active == 2 ? ' active' : '')}>
                                                                <g>
                                                                    <path d="M1233.7,74.9c2.7-4.7,5.1-7,10.6-5.4c25.8,7.6,71.8,21.1,138,40.5c8.6,2.5,10.5,10.8,10.4,18.5
		c-0.2,45.9-1.2,91.6-1.5,137.6c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0l-0.6,0c-0.4,0-0.6-0.2-0.5-0.6l1.5-115c0-0.4-0.3-0.7-0.7-0.7
		c0,0,0,0,0,0h-1c-0.5,0-0.7-0.2-0.7-0.7c0-7.5,2.6-32.8-6.6-35.4c-47.1-13.6-94.1-27.2-141.1-40.8c-0.7-0.2-1.5,0.1-1.9,0.8
		c-8.8,16.2-17.7,32.5-26.7,49c-1.2,2.3-1.6,5.9-1.6,9c-0.6,40.7-0.9,74.4-0.9,101.3c0,2.3-0.1,4.2-0.3,5.5
		c-0.1,0.5-0.3,0.6-0.8,0.6l-3.2-0.5c-0.4-0.1-0.6-0.3-0.6-0.8c0.1-43.2,0.4-79,0.8-107.5c0-3.5,1-6.9,2.8-10.2
		C1216.7,105.4,1225.2,90.1,1233.7,74.9z"/>
                                                                    <path d="M1030.9,100.4c1.8-2.6,4.4-3.5,8-2.7c45.2,10.4,90.5,20.9,135.8,31.3c0.3,0.1,0.5,0.4,0.5,0.7
		c0,0.1-0.1,0.2-0.1,0.2l-1.5,1.9c-0.3,0.4-0.7,0.5-1.2,0.4l-135-30.8c-1.2-0.3-2.5,0.2-3.1,1.3l-11.3,18.2c-0.2,0.4-0.6,0.5-1,0.4
		l-3.1-0.6c-0.2-0.1-0.4-0.3-0.3-0.5c0,0,0-0.1,0-0.1C1022.7,113.1,1026.8,106.5,1030.9,100.4z"/>
                                                                    <path d="M617,813.5c193.4-236.1,387-472.1,580.5-708.1c5.5-6.8,11.4-11,17.9-1.9c0.2,0.3,0.2,0.6,0,0.9l-1.9,3.3
		c-0.1,0.2-0.4,0.3-0.7,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c-1.3-3.1-5-7.4-8.4-3.3c-10.6,13-21.3,26-31.9,39.1
		c-74.1,90.8-148.1,181.7-222.2,272.5c-93.5,114.6-186.9,229.3-280.4,343.9c-2.5,3-8.8,10-12.8,15.3c-11,14.6-23.1,28.7-34.6,43
		c-4.2,5.2-6.8,8.5-7.4,15.4c-1.2,13.4-2.2,26.8-3.1,40.1c-0.5,8.2-1.1,11.8,4.1,18.9c20.1,27.5,40.2,55,60.3,82.4
		c0.2,0.3,0.3,0.6,0.2,1c-0.6,1.8-4.3,5.3-6.2,2.7c-20.3-27.6-40.6-55.1-61-82.6c-4.3-5.8-5.6-11.5-5-18.4c1-12.2,2-24.4,2.9-36.5
		C608.1,828.8,608.7,823.6,617,813.5z"/>
                                                                    <path d="M970.7,177.4c-76.5,83.9-153.2,167.7-229.8,251.5c-95.3,104.2-190.7,208.3-286.1,312.3
		c-1.2,1.3-2.1,2.8-2.8,3.5c-11.6,12.8-23.3,25.5-34.9,38.2c-2.1,2.3-3.4,5.8-3.8,10.3c-1.2,13.9-2.2,27.7-3.2,41.6
		c-0.4,6,3.3,9.3,7,14c19.4,24.2,38.8,48.5,58.3,72.7c0.2,0.3,0.3,0.6,0.1,1c-0.9,1.8-2.4,2.8-4.4,3.1c-0.3,0-0.6,0-0.8-0.3
		c-20.4-25.2-40.7-50.5-60.9-75.8c-4.6-5.8-6.6-11.9-5.9-18.6c1.3-12.1,2.2-24.1,2.9-36.2c0.5-9.3,4.6-14.9,11.1-21.9
		c198-215.5,396.1-430.9,594.2-646.4c2.7-2.9,5.7-4.1,8.9-3.5c9.2,1.6,12.5,15,12.5,23c0.1,31.4,0.1,62.8-0.1,94.2
		c-0.1,10.4-6.6,15.9-12.4,23.2c-0.4,0.4-1,0.5-1.5,0.2c0,0-0.1-0.1-0.1-0.1c-0.9-1-1-2.1,0-3.1c6.3-7,11.3-11.6,11.4-20.3
		c0.3-31.6,0.3-63.2,0.2-94.9c0-4.8-0.9-9-2.5-12.5c-0.1-0.3-0.4-0.6-0.7-0.7c-3.5-2.1-7.6-8.6-12-3.8
		C999.5,146.2,984.5,162.2,970.7,177.4z"/>
                                                                    <path d="M1015,131.3c3.3-3.7,3.6-3.5,1,0.8c-2.8,4.5-6,9.8-9.8,15.8c-2.8,4.4-0.6,7.8-5.7,11.7
		c-0.2,0.2-0.4,0.1-0.4-0.2C998.3,146.5,1007.5,139.8,1015,131.3z"/>
                                                                    <path d="M1182.9,133.2c0.3-0.6,0.7-1.1,1.2-1.4c0.3-0.2,0.6-0.3,1-0.2l7.3,1.6c0.3,0.1,0.5,0.4,0.4,0.7
		c0,0.1-0.1,0.2-0.1,0.3l-1.9,2c-0.3,0.3-0.6,0.4-1,0.3l-6.7-1.4c-0.3-0.1-0.5-0.3-0.6-0.6C1182.4,133.8,1182.5,133.5,1182.9,133.2z
		"/>
                                                                    <path d="M1166,153.3c0.9-1.1,1.6-1,2.2,0.3l31.7,83.6c0.2,0.5-0.1,1-0.5,1.2c-0.1,0-0.2,0.1-0.3,0.1h-2.2
		c-0.4,0-0.6-0.2-0.7-0.5c-10.3-27-20.7-54-31.2-81C1164.5,156,1164.8,154.7,1166,153.3z"/>
                                                                    <path d="M1167,166.8c0.1,0.3,0,0.6-0.3,0.6l-0.1,0.1c-1.1,0.4-2.3-0.1-2.6-1.1c0,0,0,0,0,0l-2.2-6.3
		c-0.4-1,0.2-2.1,1.3-2.5c0,0,0,0,0,0l0.1-0.1c0.3-0.1,0.6,0,0.6,0.3c0,0,0,0,0,0L1167,166.8z"/>
                                                                    <path d="M977.1,173.6l43.8,98.6c0.1,0.2,0,0.4-0.1,0.5l-2.2,2.6c-0.2,0.2-0.5,0.2-0.7,0.1c-0.1,0-0.1-0.1-0.1-0.2
		l-44.1-98.8c-0.1-0.2,0-0.4,0.1-0.5l2.4-2.5c0.2-0.2,0.5-0.2,0.7,0C977.1,173.5,977.1,173.6,977.1,173.6z"/>
                                                                    <path d="M971.8,178.5L971.8,178.5c0.6-0.2,1.2,0,1.4,0.5l3.1,7.1c0.2,0.5,0,1.1-0.5,1.3l-0.1,0c-0.5,0.2-1.1,0-1.3-0.5
		l-3.1-7.1C971.1,179.3,971.3,178.7,971.8,178.5z"/>
                                                                    <path d="M1189.5,241.3c0.1-0.2,0.3-0.3,0.5-0.3l8.8-0.7c0.2,0,0.4,0.2,0.5,0.4c0,0.1,0,0.1,0,0.2l-2.2,4.7
		c0,0.1,0,0.2-0.1,0.2l-0.3,17.1c0,0.3-0.2,0.5-0.5,0.5l-9,0.4c-0.4,0-0.6-0.2-0.6-0.6c0.1-4.7,0.1-9.4,0.1-14
		C1186.5,246.1,1188.2,244,1189.5,241.3z"/>
                                                                    <path d="M1199.6,263.5c-0.8-0.1-1.2-0.6-1.3-1.4c-0.1-4.6-0.1-9.2,0.2-13.9c0.1-2.7,1.3-4.8,2.7-7
		c0.4-0.6,1-0.9,1.7-0.8l192,28.9c1.3,0.2,2.1,1.4,1.9,2.6c0,0,0,0.1,0,0.1c-0.5,2.9-0.8,5.7-0.9,8.3c-0.1,2.3,0.7,5.3,0.4,8
		c-0.1,0.5-0.5,0.8-1,0.8c0,0,0,0,0,0L1199.6,263.5z"/>
                                                                    <path d="M1038.1,260.8c0.3,0,0.6,0.1,0.8,0.3c0.2,0.1,0.2,0.3,0.1,0.5c-0.1,0.1-0.2,0.2-0.3,0.2l-14.2,0.9
		c-0.5,0-0.6-0.2-0.4-0.6c0.7-1.4,1.8-2.5,3.6-2.3C1031.2,260,1034.6,260.4,1038.1,260.8z"/>
                                                                    <path d="M1079.1,260.3l11-0.9c0.3,0,0.5,0.2,0.5,0.4l0,0.5c0,0.3-0.2,0.5-0.4,0.5l-11,0.9c-0.3,0-0.5-0.2-0.5-0.4
		l0-0.5C1078.6,260.5,1078.8,260.3,1079.1,260.3z"/>
                                                                    <path d="M1024.2,264.1l42.1-2.9c0.3,0,0.6,0.2,0.7,0.6l0,0.2c0,0.3-0.2,0.6-0.6,0.7l-42.1,2.9c-0.3,0-0.6-0.2-0.7-0.6
		l0-0.2C1023.6,264.4,1023.8,264.1,1024.2,264.1z"/>
                                                                    <path d="M1071.1,270.1l5.4-6.4c0.1-0.1,0.2-0.2,0.4-0.2l10.6-0.9c0.3,0,0.6,0.2,0.7,0.6c0,0.2,0,0.3-0.1,0.4l-6.6,8.2
		c-0.1,0.2-0.3,0.3-0.5,0.2l-9.4-0.9c-0.3,0-0.6-0.3-0.6-0.7C1071,270.4,1071,270.2,1071.1,270.1z"/>
                                                                    <path d="M1034.8,266.2l29.4-1.9c0.2,0,0.4,0.1,0.4,0.4c0,0.1,0,0.2-0.1,0.3l-3.9,4.7c-0.1,0.1-0.2,0.2-0.3,0.1
		l-25.5-2.8c-0.2,0-0.4-0.2-0.4-0.4C1034.4,266.4,1034.6,266.2,1034.8,266.2L1034.8,266.2z"/>
                                                                    <path d="M1021.6,269.2c-0.9-0.9-1.3-2-0.9-3.3c0.1-0.4,0.3-0.5,0.7-0.4c0.4,0.1,0.6,0.5,0.5,1.1c0,0.4,0.1,0.6,0.5,0.6
		l36,4c0.3,0,0.5,0.3,0.4,0.5c0,0.1-0.1,0.2-0.1,0.3l-8.5,10.3c-0.2,0.3-0.5,0.4-0.9,0.4l-17.2-1.6c-0.4,0-0.7-0.3-0.9-0.6l-5-11.1
		c-0.1-0.3-0.4-0.5-0.8-0.4l-3,0.5C1022.2,269.6,1021.8,269.5,1021.6,269.2z"/>
                                                                    <path d="M1197,290c-1.2-2.5-2.4-5-3.6-7.6c-2-4.4-1.2-10.8-1.1-15.5c0-0.3,0.3-0.6,0.6-0.6l3.2-0.2
		c0.3,0,0.6,0.2,0.6,0.6c0,0,0,0,0,0c0.1,4.5-0.9,11.4,1,15.4c1.3,2.7,2.6,5.5,3.8,8.2c0.1,0.3,0,0.6-0.3,0.7c-0.1,0-0.2,0.1-0.3,0
		l-3.1-0.4C1197.5,290.6,1197.2,290.4,1197,290z"/>
                                                                    <path d="M1204.8,290.5c-0.8-0.1-1.5-0.6-1.9-1.3c-1.5-3.3-4.7-7.8-4.7-11.5c0-3.3,0-6.7,0-10.1c0-1,0.5-1.4,1.5-1.3
		l22.8,2.9c0.6,0.1,1,0.5,1,1.1c0,3.5,0,7-0.1,10.6c-0.1,3.5-3.1,7.5-4.8,10.5c-0.1,0.2-0.4,0.4-0.7,0.4L1204.8,290.5z
		 M1212.6,284.8c2.7,0,4.9-2.7,4.8-5.9c0-3.2-2.3-5.8-5-5.8c-2.7,0-4.9,2.7-4.8,5.9C1207.7,282.2,1209.9,284.8,1212.6,284.8z"/>
                                                                    <path d="M1225.9,268.6l12.7,1.6c0.1,0,0.3,0.2,0.2,0.3l0,0.3c0,0.1-0.2,0.3-0.3,0.2l-12.7-1.6c-0.1,0-0.3-0.2-0.2-0.3
		l0-0.3C1225.6,268.7,1225.8,268.6,1225.9,268.6z"/>
                                                                    <path d="M1398.5,270.7c0.2-0.2,0.5-0.3,0.8-0.1c0.2,0.1,0.3,0.4,0.2,0.6l-0.5,2c-0.1,0.2-0.3,0.3-0.5,0.3
		c-0.1,0-0.2-0.1-0.3-0.3C1397.8,272.3,1397.9,271.5,1398.5,270.7z"/>
                                                                    <path d="M1225.3,271.3c0-0.2,0.2-0.4,0.4-0.4h0.1c7.7,0.2,14,1.3,13.9,2.4c0,0,0,0,0,0l0,0.2c0,1.1-6.3,1.8-14.1,1.6
		l-0.1,0c-0.2,0-0.4-0.2-0.4-0.4c0,0,0,0,0,0L1225.3,271.3z"/>
                                                                    <path d="M1001.2,301.3c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.6,0.3-0.8c7.8-9.3,15.7-18.7,23.5-28.1
		c0.2-0.2,0.5-0.3,0.7-0.1c0.1,0.1,0.1,0.1,0.1,0.2l3.9,8.6c0.2,0.3,0.1,0.6-0.1,0.9l-6.3,7.7c-0.1,0.2-0.4,0.2-0.6,0.1
		c-0.1-0.1-0.1-0.1-0.1-0.2c-0.4-1.6-1.3-3-2.5-4c-0.3-0.2-0.7-0.4-1.1-0.4c-6.4,0-6.6,8.6-1.7,10.7c0.3,0.1,0.4,0.3,0.2,0.6
		c-2.5,5.2-12.3,0.4-13.6,4.5c-0.1,0.5-0.4,0.7-0.9,0.6L1001.2,301.3z"/>
                                                                    <path d="M1249.8,296.2c-1.1,0.3-3.9,0.3-4.4-1.1c-1.4-3.4-4.2-7.6-4.1-11.3c0-3.5,0-7,0-10.5c0-0.5,0.3-0.8,0.8-0.8
		l2.9-0.1c0.4,0,0.6,0.2,0.6,0.6c0,3.4,0,6.8,0,10.2c0,4.1,2.9,8.6,4.6,12.2C1250.3,295.8,1250.2,296.1,1249.8,296.2z"/>
                                                                    <path d="M1251.7,295.1c-1.1-2-2.1-4.1-3-6.2c-2.2-4.8-1.5-10.9-1.3-16c0-0.2,0.2-0.4,0.4-0.4c0,0,0,0,0,0l22.2,2.8
		c0.3,0,0.5,0.3,0.5,0.6c0,3.3,0,6.7-0.2,10.1c-0.2,3.7-2.3,6.5-4,9.6c-0.5,1-1.4,1.4-2.5,1.3l-11.3-1.2
		C1252.2,295.6,1251.9,295.4,1251.7,295.1z M1260.3,290.4c2.6,0,4.7-2.5,4.7-5.7c0,0,0,0,0,0c0-3.2-2.1-5.7-4.7-5.7
		c-2.6,0-4.7,2.5-4.7,5.7c0,0,0,0,0,0C1255.6,287.8,1257.7,290.4,1260.3,290.4z"/>
                                                                    <path d="M1060.4,284c-0.2-0.1-0.3-0.3-0.2-0.4c0,0,0-0.1,0.1-0.1l8.6-10.5c0.3-0.3,0.6-0.4,1-0.4l9.3,0.9
		c0.3,0,0.5,0.3,0.5,0.6c0,0.1-0.1,0.2-0.2,0.3l-0.3,0.3c-0.3,0.3-0.6,0.4-1,0.4l-7.4,0.3c-0.3,0-0.6,0.1-0.8,0.4
		c-1.9,2.3-3.8,4.6-5.6,6.9C1063.1,284.4,1062.3,284.7,1060.4,284z"/>

                                                                    <ellipse transform="matrix(0.9998 -1.745241e-02 1.745241e-02 0.9998 -4.6721 24.5299)" cx="1403.1" cy="280" rx="2.7" ry="6.9"/>
                                                                    <path d="M1211.6,282.5c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0,0,0h-0.1c-1,0-1.8-1.1-1.8-2.5l-0.1-2.2
		c-0.1-1.4,0.7-2.6,1.6-2.6c0,0,0,0,0,0h0.1c0.1,0,0.2,0.1,0.2,0.1c0,0,0,0,0,0L1211.6,282.5z"/>
                                                                    <path d="M1069.3,279.4c1.5-1.8,2.1-3.2,5-2.3c0.2,0.1,0.3,0.2,0.2,0.4c0,0,0,0.1-0.1,0.1c-4.8,5.8-9.5,11.5-14.1,17.3
		c-1.1,1.4-2.6,1.8-4.2,1.5c-0.5-0.1-0.5-0.3-0.2-0.7C1060.4,290.2,1064.8,284.8,1069.3,279.4z"/>
                                                                    <path d="M1225.2,282.5v-4.3c0-0.3,0.2-0.5,0.5-0.6l13.2-0.8c0.4,0,0.6,0.2,0.6,0.6l0,5.5c0,0.4-0.3,0.7-0.7,0.8
		l-13.5,1c-0.3,0-0.5-0.1-0.5-0.4c-0.1-0.4,0-0.7,0.3-1.1C1225.2,283,1225.2,282.8,1225.2,282.5z"/>
                                                                    <path d="M1272,279c0-0.6,0.1-1.2,0.4-1.7c0.1-0.3,0.4-0.4,0.7-0.4l23.6,3c0.3,0,0.4,0.2,0.4,0.5c0,0.1-0.1,0.2-0.2,0.3
		c-0.1,0.1-0.2,0.1-0.3,0.1c-7.9,0.3-15.8,0.7-23.7,1.2c-0.4,0-0.7-0.2-0.8-0.5c-0.1-0.4-0.1-0.9-0.1-1.4
		C1272,280,1272,279.7,1272,279z"/>
                                                                    <path d="M1214.1,278.4c-0.1-0.5,0.2-0.9,0.7-1c0.5-0.1,0.9,0.2,1,0.7c0.2,0.8,0,1.5-0.4,2.1c-0.2,0.2-0.5,0.3-0.7,0.1
		c-0.1-0.1-0.2-0.2-0.2-0.3L1214.1,278.4z"/>
                                                                    <path d="M1316.6,291.7c-3.4-0.1-3.1-9-3.1-10.7c0-0.3,0.3-0.6,0.6-0.6c0,0,0,0,0,0l2.9,0.1c0.2,0,0.3,0.2,0.3,0.4
		c0,0.1-0.1,0.3-0.2,0.3c-4,1.3-4,7.9,0.4,8.6c0.4,0.1,0.6,0.4,0.6,0.8c0,0.1-0.1,0.2-0.1,0.3
		C1317.5,291.5,1317.1,291.7,1316.6,291.7z"/>
                                                                    <path d="M1259.5,288.1c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0,0,0h-0.1c-0.9,0-1.8-1.1-1.8-2.5c0,0,0,0,0,0l-0.1-1.8
		c-0.1-1.4,0.7-2.6,1.6-2.7l0.1,0c0.1,0,0.2,0.1,0.2,0.1c0,0,0,0,0,0L1259.5,288.1z"/>
                                                                    <path d="M1270.3,291.6c2-2.4,1.7-4.1,2-7.3c0-0.4,0.3-0.7,0.7-0.7l32.1-1.8c0.3,0,0.6,0.2,0.6,0.5c0,0,0,0,0,0l0.5,7.7
		c0,0.4-0.3,0.7-0.6,0.7c0,0,0,0,0,0l-34.8,1.8c-0.3,0-0.5-0.2-0.5-0.5C1270.2,291.8,1270.2,291.7,1270.3,291.6z"/>
                                                                    <path d="M1308.2,282.2c0.1-0.2,0.2-0.2,0.4-0.2c0,0,0.1,0,0.1,0.1l0.3,0.3c0.2,0.2,0.2,0.4,0.2,0.6l-0.5,4.5
		c0,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.3-0.1-0.3-0.3C1307.5,285.7,1307.6,283.9,1308.2,282.2z"/>
                                                                    <path d="M1262.1,283.5c0,0,0.3-0.1,0.5-0.2c0.2-0.1,0.3,0,0.4,0.2c0.5,0.8,0.5,1.6,0,2.2c-0.2,0.2-0.5,0.3-0.8,0.1
		c-0.1-0.1-0.2-0.3-0.2-0.4V283.5z"/>
                                                                    <path d="M1354.1,287.4c0.3,0,0.6,0.2,0.8,0.4c0.1,0.1,0.1,0.3,0,0.4c0,0-0.1,0.1-0.1,0.1l-33.8,1.6
		c-0.3,0-0.6-0.2-0.6-0.6c0,0,0,0,0-0.1l0.3-5c0-0.5,0.5-1,1-0.9c0,0,0,0,0.1,0C1332.4,284.5,1343.2,285.9,1354.1,287.4z"/>
                                                                    <path d="M1310.7,283.7c0-0.2,0.2-0.3,0.4-0.3c0.1,0,0.2,0.1,0.3,0.3c0.6,3.9,1.3,7.3,0.8,11.4
		c-7.1,51.5-14.3,103-21.4,154.5c-0.1,0.4-0.3,0.6-0.7,0.6h-1.7c-0.4,0-0.6-0.2-0.6-0.7L1310.7,283.7z"/>

                                                                    <ellipse transform="matrix(0.9999 -1.570734e-02 1.570734e-02 0.9999 -4.3217 20.7328)" cx="1317.7" cy="285.5" rx="0.8" ry="2.1"/>
                                                                    <path d="M1191.5,283.7l0.3,0.3c0.1,0.1,0.2,0.3,0.1,0.4L1011,527.9c-0.1,0.1-0.3,0.2-0.4,0.1l-0.3-0.3
		c-0.1-0.1-0.2-0.3-0.1-0.4L1191,283.8C1191.1,283.7,1191.3,283.6,1191.5,283.7z"/>
                                                                    <path d="M1047.4,285.4c-0.4,1-1.7,2.8-2.7,3.4c-0.3,0.2-0.5,0.1-0.5-0.3v-3c0-0.5,0.2-0.8,0.8-0.8h2
		C1047.4,284.8,1047.6,285,1047.4,285.4z"/>
                                                                    <path d="M1050.3,296.8c-0.2,0-0.4-0.2-0.4-0.5c0-0.1,0-0.2,0.1-0.2c2.6-3.3,5.2-6.6,8.1-9.8c0.8-0.9,1.8-1,3-0.3
		c0.3,0.2,0.3,0.4,0.1,0.7c-2.2,2.7-4.4,5.4-6.6,8.1C1053.3,296.5,1052.3,297,1050.3,296.8z"/>
                                                                    <path d="M1223,287c0.2-0.4,0.6-0.6,1-0.6l13-0.7c0.5,0,0.6,0.2,0.3,0.6l-5.7,7.8c-0.2,0.3-0.5,0.4-0.8,0.5l-10.1,0.4
		c-0.6,0-1.1-0.4-1.2-1.1c0-0.2,0-0.4,0.1-0.6L1223,287z"/>
                                                                    <path d="M1398.4,289.7c-0.6-1-0.7-2.1-0.2-3.4c0.2-0.4,0.4-0.4,0.5,0l1.2,2.6c0.2,0.4,0,1-0.5,1.2
		C1399.1,290.2,1398.6,290.1,1398.4,289.7z"/>
                                                                    <path d="M1199.7,293.1L696.5,977.5c0,0.1-0.1,0.1-0.2,0.1l-21.5,6.7c-0.2,0.1-0.4-0.1-0.5-0.3c0-0.1,0-0.2,0.1-0.3
		L1192.8,286c0.1-0.2,0.4-0.2,0.5-0.1c0,0,0.1,0.1,0.1,0.1l2.7,5.8c0.1,0.1,0.2,0.2,0.3,0.2l3.1,0.4c0.2,0,0.3,0.2,0.3,0.4
		C1199.8,293,1199.8,293.1,1199.7,293.1z M1192.1,299.3c1.2,0,2.2-1.3,2.2-3c0-1.7-0.9-3.1-2.1-3.1c-1.2,0-2.2,1.3-2.2,3
		C1189.9,297.9,1190.9,299.3,1192.1,299.3z M1165.9,334.8c1.2,0,2.2-1.4,2.2-3.1c0,0,0,0,0,0c0-1.7-1-3.1-2.2-3.1c0,0,0,0,0,0
		c-1.2,0-2.2,1.4-2.2,3.1v0C1163.7,333.5,1164.7,334.8,1165.9,334.8C1165.9,334.8,1165.9,334.8,1165.9,334.8z M1138.6,371.7
		c1.2,0,2.3-1.4,2.3-3.1c0-1.7-1-3.1-2.2-3.1c0,0,0,0,0,0c-1.2,0-2.3,1.4-2.3,3.1C1136.4,370.3,1137.4,371.7,1138.6,371.7
		L1138.6,371.7z M1110.3,410.1c1.2,0,2.3-1.4,2.3-3.1s-0.9-3.2-2.2-3.2l0,0c-1.2,0-2.3,1.4-2.3,3.1
		C1108.1,408.6,1109.1,410.1,1110.3,410.1L1110.3,410.1z M1080.9,450c1.3,0,2.4-1.4,2.4-3.1c0-1.8-1-3.2-2.3-3.3h0
		c-1.3,0-2.4,1.4-2.4,3.1c0,0,0,0,0,0C1078.6,448.5,1079.6,450,1080.9,450L1080.9,450z M1050.2,491.5c1.3,0.1,2.4-1.4,2.5-3.2
		c0,0,0,0,0,0c0.1-1.8-0.9-3.3-2.2-3.3c0,0,0,0,0,0c-1.3-0.1-2.4,1.4-2.5,3.2c0,0,0,0,0,0C1047.9,490,1048.9,491.5,1050.2,491.5
		L1050.2,491.5z M1018.2,534.8c1.3,0.1,2.5-1.3,2.6-3.1s-0.9-3.3-2.2-3.4c-1.3-0.1-2.5,1.3-2.6,3.1
		C1015.8,533.2,1016.8,534.7,1018.2,534.8z M984.8,579.8c1.4,0.1,2.6-1.2,2.8-3v0c0.2-1.8-0.8-3.4-2.2-3.5c0,0,0,0,0,0
		c-1.4-0.1-2.6,1.2-2.8,3c0,0,0,0,0,0C982.4,578.1,983.4,579.7,984.8,579.8C984.8,579.8,984.8,579.8,984.8,579.8z M950.1,627
		c1.4,0.1,2.6-1.3,2.8-3.2c0.2-1.9-0.8-3.5-2.2-3.6s-2.6,1.3-2.8,3.2C947.7,625.3,948.7,626.9,950.1,627z M913.6,676.2
		c1.4,0.2,2.8-1.2,3-3c0,0,0,0,0,0c0.3-1.9-0.7-3.5-2.1-3.7c-1.4-0.2-2.8,1.2-3,3v0C911.2,674.3,912.2,676,913.6,676.2z
		 M875.6,727.6c1.4,0.2,2.8-1.2,3.1-3s-0.7-3.6-2.1-3.8c-1.4-0.2-2.8,1.2-3.1,3c0,0,0,0,0,0C873.2,725.6,874.2,727.3,875.6,727.6z
		 M835.9,781.4c1.5,0.2,3-1.2,3.3-3.1c0.3-1.9-0.7-3.7-2.2-3.9l0,0c-1.5-0.2-3,1.2-3.3,3.1S834.4,781.2,835.9,781.4
		C835.9,781.4,835.9,781.4,835.9,781.4z M794,837.8c1.5,0.3,3.1-1,3.5-3l0,0c0.4-2-0.5-3.8-2-4.1l0,0c-1.5-0.3-3.1,1-3.5,3
		S792.5,837.5,794,837.8C794,837.8,794,837.8,794,837.8z M750.1,896.9c1.5,0.5,3.3-0.8,3.8-2.7v0c0.6-2-0.2-3.9-1.8-4.3
		c-1.5-0.5-3.3,0.8-3.8,2.7C747.8,894.5,748.6,896.5,750.1,896.9z M704,958.9c1.5,0.6,3.4-0.6,4.1-2.5c0.7-2,0-4-1.5-4.6l0,0
		c-1.5-0.6-3.4,0.6-4.1,2.5C701.8,956.3,702.5,958.4,704,958.9L704,958.9z"/>
                                                                    <path d="M1239.2,286.6c0.1-0.1,0.3-0.2,0.4-0.1c0,0,0.1,0.1,0.1,0.1l1.7,3.2c0.1,0.2,0.1,0.5-0.1,0.8
		c-145,203-290,406.1-435,609.1c-1,1.4-1.6,2.7-3.5,2.5c-0.7-0.1-1.3,0.5-1.3,1.1c0,0.1,0,0.3,0,0.4c0.4,1.6,0,2.8-1,4.1
		c-11.4,16-22.8,32-34.3,47.9c-0.2,0.3-0.5,0.5-0.9,0.6l-8,2.5c-0.2,0.1-0.4-0.1-0.4-0.2c0-0.1,0-0.2,0-0.3L1239.2,286.6z"/>
                                                                    <path d="M1020.5,291.4c-0.2,0.4-0.6,0.6-1,0.4c-0.3-0.1-0.5-0.4-0.5-0.7v-1.7c0-0.4,0.3-0.7,0.6-0.7
		c0.2,0,0.4,0.1,0.5,0.3C1020.7,289.8,1020.8,290.6,1020.5,291.4z"/>
                                                                    <path d="M1376.8,290.3c0.2,0,0.3,0.2,0.3,0.3c0,0.2-0.2,0.3-0.3,0.3l-59.1,3.1c-0.4,0-0.5-0.1-0.2-0.4
		c0.8-1,1.5-1.5,2.3-1.5c13.2-0.7,26.4-1.4,39.7-2.3C1364.6,289.4,1370.4,289.6,1376.8,290.3z"/>
                                                                    <path d="M1023.2,291.7c0.1-0.1,0.2-0.1,0.3,0l0.1,0.1c1.1,0.8,0.8,3-0.6,4.8c0,0,0,0,0,0l-0.1,0.2
		c-1.4,1.8-3.4,2.6-4.5,1.8c0,0,0,0,0,0l-0.1-0.1c-0.1-0.1-0.1-0.2,0-0.3L1023.2,291.7z"/>
                                                                    <path d="M1245.5,297.5l2.7,0.3c0.3,0,0.5,0.3,0.5,0.6c0,0.1,0,0.2-0.1,0.3c-140.9,199.2-281.8,398.4-422.5,597.6
		c-0.5,0.7-0.9,1-1.5,1.1c-0.4,0.1-0.8,0-1.2-0.3c-0.3-0.2-0.6-0.2-1-0.2l-14.2,4c-0.2,0-0.4-0.1-0.4-0.2c0-0.1,0-0.2,0-0.3
		l433.9-607.6c0.2-0.2,0.5-0.3,0.7-0.1c0.1,0.1,0.1,0.1,0.2,0.2l2,4C1244.9,297.2,1245.1,297.4,1245.5,297.5z M1241.5,304.5
		c1.1,0,2.1-1.3,2.1-3c0,0,0,0,0,0c0-1.7-0.9-3-2-3h0c-1.1,0-2.1,1.3-2.1,3c0,0,0,0,0,0C1239.4,303.2,1240.3,304.5,1241.5,304.5
		L1241.5,304.5z M1217.2,338.6c1.2,0,2.1-1.3,2.1-2.9c0,0,0,0,0,0c0-1.6-0.9-3-2.1-3c-1.2,0-2.1,1.3-2.1,2.9c0,0,0,0,0,0
		C1215.1,337.3,1216,338.6,1217.2,338.6z M1191.9,374.2c1.2,0,2.2-1.3,2.2-2.9c0-1.7-0.9-3-2.1-3.1c0,0,0,0,0,0
		c-1.2,0-2.2,1.3-2.2,2.9c0,0,0,0,0,0C1189.8,372.8,1190.8,374.1,1191.9,374.2L1191.9,374.2z M1165.9,411c1.2,0,2.2-1.4,2.2-3
		c0,0,0,0,0,0c0-1.7-1-3-2.3-3c-1.2,0-2.2,1.4-2.2,3c0,0,0,0,0,0C1163.7,409.7,1164.7,411,1165.9,411z M1138.7,449.3
		c1.2,0,2.3-1.3,2.3-3.1c0-1.7-0.9-3.1-2.2-3.2c-1.2,0-2.3,1.3-2.3,3.1v0C1136.5,447.8,1137.5,449.3,1138.7,449.3L1138.7,449.3z
		 M1110.5,489.1c1.2,0,2.3-1.4,2.3-3.2c0,0,0,0,0,0c0-1.8-1-3.2-2.2-3.3l0,0c-1.2,0-2.3,1.4-2.3,3.2c0,0,0,0,0,0
		C1108.3,487.6,1109.3,489.1,1110.5,489.1L1110.5,489.1z M1081,530.5c1.3,0.1,2.4-1.3,2.6-3c0.1-1.8-0.8-3.3-2.1-3.4h0
		c-1.3-0.1-2.4,1.3-2.6,3C1078.7,528.9,1079.7,530.4,1081,530.5C1081,530.5,1081,530.5,1081,530.5z M1050.4,573.5
		c1.3,0.1,2.5-1.2,2.6-3c0,0,0,0,0,0c0.1-1.8-0.8-3.3-2.1-3.4s-2.5,1.2-2.6,3v0C1048.1,571.8,1049.1,573.4,1050.4,573.5z
		 M1018.6,618.4c1.3,0.1,2.5-1.3,2.7-3.1s-0.8-3.4-2.2-3.5c-1.3-0.1-2.5,1.3-2.7,3.1C1016.3,616.7,1017.3,618.3,1018.6,618.4z
		 M985.3,665c1.4,0.2,2.6-1.1,2.9-2.9c0,0,0,0,0,0c0.2-1.8-0.7-3.4-2-3.6h0c-1.4-0.2-2.6,1.1-2.9,2.9
		C983,663.2,983.9,664.9,985.3,665C985.3,665,985.3,665,985.3,665z M950.7,713.9c1.4,0.2,2.7-1.2,2.9-3.1c0,0,0,0,0,0
		c0.2-1.9-0.7-3.5-2.1-3.7c-1.4-0.2-2.7,1.2-2.9,3.1c0,0,0,0,0,0C948.3,712.1,949.3,713.7,950.7,713.9z M914.6,764.8
		c1.4,0.2,2.7-1.2,2.9-3.1l0,0c0.2-1.9-0.8-3.5-2.2-3.7c-1.4-0.2-2.7,1.2-2.9,3.1S913.2,764.7,914.6,764.8
		C914.6,764.8,914.6,764.8,914.6,764.8z M876.6,818.1c1.4,0.2,2.9-1.1,3.2-3s-0.6-3.6-2.1-3.9c-1.4-0.2-2.9,1.1-3.2,3v0
		C874.2,816.1,875.1,817.9,876.6,818.1L876.6,818.1z M836.8,873.8c1.5,0.3,3-1,3.4-2.9c0.4-1.9-0.5-3.7-1.9-4c0,0,0,0,0,0
		c-1.5-0.3-3,1-3.4,2.9C834.5,871.6,835.3,873.5,836.8,873.8C836.8,873.8,836.8,873.8,836.8,873.8z"/>
                                                                    <path d="M1268.9,294.7l37.9-1.9c0.3,0,0.6,0.2,0.7,0.6l0,0.5c0,0.3-0.2,0.6-0.6,0.7l-37.9,1.9c-0.3,0-0.6-0.2-0.7-0.6
		l0-0.5C1268.3,295,1268.6,294.7,1268.9,294.7z"/>
                                                                    <path d="M1202.9,293.1l14.6,1.4c0.1,0,0.3,0.1,0.2,0.3l0,0.3c0,0.1-0.1,0.3-0.3,0.2l-14.6-1.4c-0.1,0-0.3-0.1-0.2-0.3
		l0-0.3C1202.6,293.2,1202.8,293.1,1202.9,293.1z"/>
                                                                    <path d="M1314.3,293.7l0.3,0c0.2,0,0.4,0.3,0.4,0.5l-21.6,155.6c0,0.2-0.3,0.4-0.5,0.4l-0.3,0c-0.2,0-0.4-0.3-0.4-0.5
		l21.6-155.6C1313.9,293.8,1314.1,293.7,1314.3,293.7z"/>
                                                                    <path d="M1196.2,300.6l4.1-5.3c0.1-0.1,0.3-0.2,0.4-0.2l27.3,2.9c0.3,0,0.4,0.3,0.4,0.5c0,0.1,0,0.2-0.1,0.2l-3.8,5.1
		c-0.1,0.1-0.3,0.2-0.4,0.2l-27.7-2.7c-0.3,0-0.4-0.2-0.4-0.5C1196.1,300.7,1196.2,300.6,1196.2,300.6z"/>
                                                                    <path d="M1047.9,299.8c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.2,0.1-0.3c2-1.5,8.4-1.1,11-1.2c0.3,0,0.5,0.2,0.5,0.5
		c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3c0,0.2-0.1,0.3-0.2,0.3L1047.9,299.8z"/>
                                                                    <path d="M1006.7,301l28.5-1.8c0.2,0,0.4,0.1,0.5,0.2c0.2,0.3,0.1,0.6-0.3,0.9c-0.1,0.1-0.3,0.1-0.5,0.1
		c-9.3,0.6-18.6,1-27.9,1.4c-0.2,0-0.3-0.1-0.4-0.3c0-0.1-0.1-0.2,0-0.4S1006.6,301,1006.7,301z"/>
                                                                    <path d="M1045.8,301.4l11.4-0.7c0.2,0,0.4,0.1,0.4,0.3c0,0.1,0,0.2-0.1,0.3l-3.1,3.9c-0.1,0.1-0.2,0.1-0.3,0.1
		l-11.6,0.7c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.3l3.2-4C1045.6,301.5,1045.7,301.4,1045.8,301.4z"/>
                                                                    <path d="M946.4,302.2c0-0.3,0.2-0.5,0.5-0.7c0.3-0.1,0.6,0,0.7,0.3l4.6,9.8c0.1,0.3,0.1,0.6-0.1,0.9l-8.8,10.3
		c-0.2,0.2-0.4,0.2-0.6,0c0,0-0.1-0.1-0.1-0.1l-4.7-9.6c-0.2-0.5-0.1-1,0.2-1.4C940.9,308.5,943.7,305.3,946.4,302.2z"/>
                                                                    <path d="M1009.4,307.4v-3.6c0-0.2,0.2-0.4,0.4-0.5l23.2-1.3c0.3,0,0.5,0.2,0.5,0.4c0,0.1,0,0.2-0.1,0.3l-3.2,3.8
		c-0.1,0.1-0.2,0.2-0.3,0.2l-20,1.1C1009.6,307.9,1009.4,307.7,1009.4,307.4C1009.4,307.4,1009.4,307.4,1009.4,307.4z"/>
                                                                    <path d="M1004,311.3c0.6,0.1,0.7,0.3,0.3,0.8L988.6,331c-0.3,0.4-0.8,0.6-1.3,0.6l-9.8,0.6c-0.4,0-0.7,0.2-0.9,0.6
		c-0.6,1.2-1.6,1.7-3.1,1.4c-0.5-0.1-0.6-0.3-0.2-0.7l26-30.9c0.2-0.2,0.4-0.2,0.6-0.1c0.1,0.1,0.2,0.2,0.2,0.4l-0.1,7.4
		c0,0.3,0.2,0.5,0.5,0.6L1004,311.3z"/>

                                                                    <ellipse transform="matrix(0.2689 -0.9632 0.9632 0.2689 443.9146 1192.2759)" cx="1007.3" cy="303.7" rx="0.5" ry="0.6"/>
                                                                    <path d="M1051.6,307.8c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.2-0.1,0.3c-0.7,0.8-1.6,1.3-2.9,1.3c-3.1,0.1-6.3,0.2-9.4,0.5
		c-0.3,0-0.5-0.1-0.5-0.4c-0.1-0.9,0.4-1.4,1.3-1.5C1044.2,308.1,1047.9,307.8,1051.6,307.8z"/>
                                                                    <path d="M1007.1,308c0.2-0.1,0.4-0.1,0.6,0.1c0,0.1,0.1,0.1,0.1,0.2c0.1,0.4-0.1,0.7-0.4,0.9c-0.4,0.3-0.6,0.2-0.7-0.3
		C1006.7,308.6,1006.8,308.3,1007.1,308z"/>
                                                                    <path d="M1025.8,310.9c-2.9-0.1-5.9-0.4-8.8-0.5c-0.3,0-0.4-0.2-0.5-0.4c0-0.1,0-0.2,0-0.4c0-0.2,0.1-0.3,0.3-0.3
		l10.4-0.5c0.3,0,0.5,0.2,0.6,0.5c0,0.1,0,0.1,0,0.2C1027.5,310.5,1026.7,311,1025.8,310.9z"/>
                                                                    <path d="M1046.4,312.7c-0.2-0.1-0.2-0.3-0.1-0.5c0.1-0.1,0.2-0.2,0.3-0.2l1.8-0.3c0.2,0,0.4,0.1,0.4,0.3
		c0,0.1,0,0.2-0.1,0.3C1048.1,313.1,1047.4,313.2,1046.4,312.7z"/>
                                                                    <path d="M1043,314.5c0.4-0.1,0.7,0,1,0.3c0.2,0.2,0.2,0.5,0,0.7c-3.5,4.1-7,8.3-10.3,12.5c-1.3,1.7-2.7,2.1-4.7,1.9
		c-0.2,0-0.4-0.2-0.4-0.5c0-0.1,0-0.1,0.1-0.2c4-4.9,7.9-9.8,11.9-14.7c0.4-0.5,1-0.5,1.7-0.1C1042.5,314.6,1042.8,314.6,1043,314.5
		z"/>
                                                                    <path d="M1177.2,327.9l9.8,0.3c0.2,0,0.4,0.2,0.4,0.4l0,0.2c0,0.2-0.2,0.4-0.4,0.4l-9.8-0.3c-0.2,0-0.4-0.2-0.4-0.4
		l0-0.2C1176.8,328.1,1177,327.9,1177.2,327.9z"/>
                                                                    <path d="M1170.6,335.6l4.2-5.4c0.1-0.1,0.2-0.2,0.4-0.2l28.3,2.2c0.3,0,0.5,0.2,0.4,0.5c0,0.1,0,0.2-0.1,0.2l-3.9,5.4
		c-0.1,0.1-0.2,0.2-0.4,0.2l-28.6-2.1c-0.3,0-0.5-0.2-0.4-0.5C1170.5,335.8,1170.5,335.7,1170.6,335.6z"/>
                                                                    <path d="M1021.1,332.1l10.6-0.6c0.3,0,0.5,0.2,0.5,0.5l0,0.1c0,0.3-0.2,0.5-0.5,0.5l-10.6,0.6c-0.3,0-0.5-0.2-0.5-0.5
		l0-0.1C1020.6,332.4,1020.8,332.1,1021.1,332.1z"/>
                                                                    <path d="M978.9,333.6l29-1.1c0.3,0,0.5,0.2,0.5,0.5l0,0.2c0,0.3-0.2,0.5-0.5,0.5l-29,1.1c-0.3,0-0.5-0.2-0.5-0.5l0-0.2
		C978.4,333.9,978.7,333.6,978.9,333.6z"/>
                                                                    <path d="M1015.1,339c0.9-1.2,2.1-2.7,3.4-3.9c0.3-0.2,0.6-0.4,1.1-0.4c3.5-0.2,6.9-0.3,10.3-0.4c0.3,0,0.5,0.2,0.5,0.4
		c0,0.1,0,0.2-0.1,0.3l-3.3,3.8c-0.1,0.2-0.3,0.2-0.5,0.2l-11,0.5c-0.2,0-0.4-0.2-0.5-0.4C1015,339.1,1015,339,1015.1,339z"/>
                                                                    <path d="M948.2,366.9c-0.8,1.2-1.8,1.7-3.3,1.6c-0.6-0.1-0.6-0.3-0.3-0.8l27.2-32.4c0.5-0.6,0.7-0.5,0.7,0.3l-0.2,8.1
		c0,0.3,0.3,0.6,0.6,0.6c0,0,0,0,0,0l3.6,0c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.2-0.1,0.3l-17.1,20.5c-0.1,0.1-0.3,0.2-0.4,0.2
		l-10.3,0.6C948.7,366.4,948.5,366.6,948.2,366.9z"/>
                                                                    <path d="M1004.4,338c-0.8,1.3-1.7,2.1-3.3,2.1c-6.2,0.3-12.4,0.5-18.5,0.6c-0.4,0-0.6-0.2-0.6-0.6v-3.1
		c0-0.6,0.3-0.8,0.8-0.9l22.8-1.1c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.2-0.1,0.2c-0.2,0.5-0.6,1-1.1,1.3
		C1004.8,337.5,1004.6,337.7,1004.4,338z"/>
                                                                    <path d="M979,336.5c0.4-0.5,0.8-0.5,1.1,0c0.1,0.3,0.1,0.6-0.2,0.8c-0.2,0.1-0.5,0.1-0.6-0.1l-0.3-0.4
		C979,336.7,979,336.6,979,336.5z"/>
                                                                    <path d="M966.4,338.6L966.4,338.6c0.2,0.1,0.2,0.3,0.1,0.5L814.9,519.6c-0.1,0.1-0.3,0.2-0.5,0l0,0
		c-0.1-0.1-0.2-0.3,0-0.5L966,338.6C966.1,338.5,966.3,338.4,966.4,338.6z"/>
                                                                    <path d="M978.9,339.1c0.2,0.3,0.1,0.6-0.3,0.9c-0.2,0.1-0.4,0.1-0.5-0.1c0-0.1-0.1-0.1-0.1-0.2l-0.1-0.8
		c0,0,0-0.1,0-0.1c0.4-0.3,0.6-0.3,0.7-0.1C978.8,338.7,978.8,338.9,978.9,339.1z"/>
                                                                    <path d="M979.2,341.1c0.1-0.4,0.3-0.5,0.6-0.2l0.4,0.4c0.2,0.2,0.2,0.4,0,0.5l-0.6,0.5c-0.2,0.1-0.4,0.1-0.5,0
		c-0.1-0.1-0.1-0.2-0.1-0.4L979.2,341.1z"/>

                                                                    <ellipse transform="matrix(0.9987 -5.059294e-02 5.059294e-02 0.9987 -15.999 51.9841)" cx="1018.8" cy="342" rx="6.1" ry="0.7"/>
                                                                    <path d="M992.1,342.7c2.6-0.5,5.3-0.7,8-0.6c0.3,0,0.4,0.1,0.3,0.4c-0.1,0.9-0.5,1.2-1.2,1.2c-2.4-0.2-4.7-0.4-7.1-0.6
		C991.2,343.1,991.2,342.9,992.1,342.7z"/>
                                                                    <path d="M1220.9,342.1l21.8,56.4c0.1,0.2,0,0.3-0.1,0.5l-2.7,3.9c-0.2,0.2-0.5,0.3-0.7,0.1c-0.1-0.1-0.1-0.1-0.2-0.2
		l-21.9-56.3c-0.1-0.2,0-0.3,0.1-0.5l2.8-4.1c0.2-0.2,0.5-0.3,0.7-0.1C1220.8,342,1220.9,342.1,1220.9,342.1z"/>
                                                                    <path d="M1017.3,346.7c0.2,0,0.4,0.1,0.5,0.2c0.3,0.2,0.3,0.4,0.1,0.7c-4.3,5-8.4,10.1-12.5,15.4
		c-1.4,1.8-2.7,2.3-4.9,1.8c-0.4-0.1-0.4-0.3-0.2-0.6c4.4-5.5,8.9-11,13.4-16.5c0.7-0.9,1.6-1.2,2.5-1
		C1016.5,346.6,1016.8,346.6,1017.3,346.7z"/>
                                                                    <path d="M1229.8,393c-0.3-0.1-0.5-0.3-0.6-0.6c-5.1-12.7-10.1-25.5-15.1-38.4c-0.9-2.3-0.3-4,1.6-5.4
		c0.3-0.3,0.6-0.2,0.8,0.2l17.2,44.4c0.1,0.3,0,0.6-0.3,0.7c-0.1,0-0.2,0-0.3,0L1229.8,393z"/>
                                                                    <path d="M1150.4,363.9l13,0.5c0.2,0,0.3,0.1,0.3,0.3l0,0.3c0,0.2-0.1,0.3-0.3,0.3l-13-0.5c-0.2,0-0.3-0.1-0.3-0.3
		l0-0.3C1150.1,364,1150.3,363.9,1150.4,363.9z"/>
                                                                    <path d="M992.7,366.7l10.6-0.6c0.4,0,0.7,0.3,0.7,0.6l0,0c0,0.4-0.3,0.7-0.6,0.7l-10.6,0.6c-0.4,0-0.7-0.3-0.7-0.6l0,0
		C992.1,367.1,992.4,366.8,992.7,366.7z"/>
                                                                    <path d="M1143.8,372.1l4.2-5.8c0.1-0.1,0.3-0.2,0.4-0.2l29.5,1.5c0.3,0,0.5,0.3,0.5,0.5c0,0.1,0,0.2-0.1,0.3l-4.1,5.5
		c-0.1,0.1-0.3,0.2-0.4,0.2l-29.7-1.2c-0.3,0-0.5-0.3-0.5-0.5C1143.7,372.3,1143.7,372.2,1143.8,372.1z"/>
                                                                    <path d="M978.3,367.9c-9.5,0.2-19.1,1.4-28.6,0.7c-0.1,0-0.2-0.1-0.2-0.2c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.3
		c0-0.1,0.1-0.2,0.2-0.2c4.5-0.1,8.8-0.1,13.5-0.5c5.4-0.5,10.4-0.5,15,0C981.4,367.7,981.4,367.9,978.3,367.9z"/>
                                                                    <path d="M989.4,370.4c0.5-0.6,1.1-1.2,2-1.2c3.3,0,6.7-0.1,10.2-0.2c0.3,0,0.4,0.1,0.3,0.4c-2.2,5.9-8.5,4.9-14.8,4.7
		c-0.4,0-0.5-0.2-0.3-0.5C987.5,372.5,988.5,371.5,989.4,370.4z"/>
                                                                    <path d="M944.4,378.5l3.8,0c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.3l-17.9,21.5c-0.4,0.5-1,0.8-1.7,0.8h-9.8
		c-0.3,0-0.5,0.2-0.6,0.5c-0.4,1.7-1.5,2.4-3.4,2c-0.3-0.1-0.4-0.3-0.4-0.6c0-0.1,0.1-0.1,0.1-0.2l27.9-33.3
		c0.2-0.2,0.5-0.2,0.7-0.1c0.1,0.1,0.2,0.2,0.2,0.4v7.6C943.8,378.2,944,378.5,944.4,378.5z"/>
                                                                    <path d="M974.2,374.7l-20.2,0.2c-0.3,0-0.5-0.2-0.5-0.5c0,0,0,0,0,0l0-3.5c0-0.2,0.2-0.4,0.4-0.5l23.6-0.6
		c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.2-0.1,0.3l-3.3,4C974.5,374.6,974.4,374.7,974.2,374.7z"/>
                                                                    <path d="M950.5,370.4c0.3-0.4,0.6-0.5,1-0.3c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.4,0.1,0.7-0.1,1.1
		c-0.1,0.3-0.5,0.4-0.7,0.2c0,0-0.1-0.1-0.1-0.1l-0.4-0.4C950.4,371,950.3,370.6,950.5,370.4z"/>
                                                                    <path d="M886.8,372.1l5,10.1c0.1,0.2,0.1,0.5-0.1,0.6l-9.7,11.4c-0.2,0.3-0.6,0.3-0.8,0.1c-0.1-0.1-0.1-0.1-0.1-0.2
		l-5.2-10.1c-0.1-0.2-0.1-0.5,0.1-0.7l9.9-11.3c0.2-0.3,0.6-0.3,0.8-0.1C886.7,372,886.8,372,886.8,372.1z"/>
                                                                    <path d="M951.6,376.5c-0.1,0.2-0.4,0.3-0.6,0.2c-0.1-0.1-0.2-0.1-0.2-0.2c-0.2-0.5-0.1-1,0.4-1.2
		c0.2-0.1,0.6-0.1,0.7,0.2c0.1,0.1,0.1,0.3,0,0.5L951.6,376.5z"/>
                                                                    <path d="M968.4,377.5c-0.8,0.2-1.4,0-2-0.6c-0.1-0.1-0.1-0.2,0-0.3c0,0,0.1-0.1,0.1-0.1l5.2-0.4c0.3,0,0.6,0.2,0.6,0.6
		c0,0.2-0.1,0.4-0.2,0.5c-0.8,0.6-1.7,0.7-2.8,0.3C969.1,377.5,968.8,377.5,968.4,377.5z"/>
                                                                    <path d="M985.8,382.2c1.5-1.8,2.2-3.1,5.1-1.9c0.1,0.1,0.2,0.2,0.2,0.4c0,0,0,0,0,0.1l-15.8,19.6
		c-0.2,0.2-0.4,0.3-0.6,0.3l-3.2,0.3c-0.2,0-0.4-0.1-0.5-0.3c0-0.1,0-0.2,0.1-0.3C975.8,394.3,980.8,388.2,985.8,382.2z"/>
                                                                    <path d="M1186.4,389.7l4.4-6.2c0.1-0.2,0.3-0.2,0.5-0.2l43.2,12.9c0.1,0,0.2,0.1,0.3,0.3l2.7,7.1
		c0.1,0.2,0,0.5-0.3,0.6c-0.1,0-0.2,0-0.3,0l-50.4-13.8c-0.2-0.1-0.4-0.3-0.3-0.5C1186.4,389.8,1186.4,389.8,1186.4,389.7z"/>
                                                                    <path d="M1225.5,391.4c-0.1-0.2-0.1-0.5,0.1-0.6c0.1-0.1,0.3-0.1,0.4-0.1c0.7,0.3,0.9,0.7,0.7,1.3
		c-0.1,0.2-0.2,0.3-0.4,0.3c-0.1,0-0.1-0.1-0.2-0.1L1225.5,391.4z"/>
                                                                    <path d="M1126.1,396.2c0.6-1.1,2.2-3.5,3.6-3.6c10.1-0.3,20.3-0.4,30.6-0.5c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.1,0,0.2
		c-1,2.2-2.2,3.6-4.8,3.6c-9.8,0.1-19.6,0.2-29.4,0.3C1126.1,396.8,1125.9,396.6,1126.1,396.2z"/>
                                                                    <path d="M1184.6,393.3l7,1.8c0.2,0.1,0.3,0.3,0.3,0.5c0,0.2-0.2,0.3-0.4,0.3l-8.5,0.2c-0.3,0-0.5-0.2-0.6-0.5
		c0-0.1,0-0.2,0.1-0.3l1.2-1.6C1183.9,393.3,1184.2,393.2,1184.6,393.3z"/>
                                                                    <path d="M1222.7,404.5l-45.7,0.3c-0.9,0-1.1-0.4-0.6-1.1l4-5.6c0.2-0.3,0.5-0.5,0.9-0.5c7.2-0.2,15.2-1,22.2,0.8
		c6.5,1.7,13,3.4,19.3,5c0.3,0.1,0.4,0.4,0.3,0.6C1223.1,404.4,1223,404.5,1222.7,404.5z"/>
                                                                    <path d="M1140.7,400.1c-5.5,0-10.9-0.1-16.2-0.1c-0.3,0-0.5-0.2-0.5-0.5c0-0.1,0-0.1,0-0.2c0.3-0.7,1-1.1,2-1
		c10.1,0.1,20.1,0,30.2-0.1c0.6,0,0.8,0.2,0.4,0.7l-2.6,3.4c-0.3,0.4-0.6,0.5-1.1,0.5h-10.2c-0.3,0-0.5-0.2-0.5-0.5c0,0,0-0.1,0-0.1
		C1142.5,400.8,1142,400.1,1140.7,400.1z"/>
                                                                    <path d="M1122.7,401l17.5,0.5c0.2,0,0.3,0.1,0.3,0.3l0,0.7c0,0.2-0.1,0.3-0.3,0.3l-17.5-0.5c-0.2,0-0.3-0.1-0.3-0.3
		l0-0.7C1122.4,401.1,1122.5,401,1122.7,401z"/>
                                                                    <path d="M974.5,403.8l-11.1,0.2c-0.5,0-0.6-0.2-0.3-0.6l0.6-0.9c0.2-0.3,0.5-0.4,0.8-0.4c3.4,0.5,5.6,0.5,6.8,0.3
		c1.2-0.3,2.4-0.3,3.4,0c0.4,0.1,0.6,0.5,0.5,0.9C975.1,403.6,974.8,403.8,974.5,403.8z"/>
                                                                    <path d="M920.4,403l29.4-0.1c0.2,0,0.4,0.2,0.4,0.4l0,0.2c0,0.2-0.2,0.4-0.4,0.4l-29.4,0.1c-0.2,0-0.4-0.2-0.4-0.4
		l0-0.2C920,403.2,920.2,403,920.4,403z"/>
                                                                    <path d="M1115.8,410.1l4.6-6.1c0.1-0.1,0.2-0.1,0.3-0.1l31.1,0.7c0.2,0,0.3,0.2,0.3,0.4c0,0.1,0,0.1-0.1,0.2l-4.3,5.9
		c-0.1,0.1-0.2,0.1-0.3,0.1l-31.4-0.5c-0.2,0-0.3-0.2-0.3-0.3C1115.7,410.3,1115.8,410.2,1115.8,410.1z"/>
                                                                    <path d="M914.7,414h3.8c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.3-0.1,0.3L900,437.5c-0.2,0.2-0.5,0.4-0.8,0.4l-10.7,0.3
		c-0.3,0-0.6,0.2-0.7,0.5c-0.5,1.7-1.8,2.3-3.9,1.8c-0.3-0.1-0.4-0.2-0.2-0.5l29.3-34.9c0.2-0.2,0.5-0.3,0.7-0.1
		c0.1,0.1,0.2,0.3,0.2,0.4l-0.1,7.8C914,413.7,914.3,414,914.7,414C914.7,414,914.7,414,914.7,414z"/>
                                                                    <path d="M972.9,405.7c-3.1,6.7-9.2,3.7-15,4.6c-0.3,0-0.5-0.1-0.5-0.4c0-0.1,0-0.3,0.1-0.4l3.2-3.8
		c0.2-0.3,0.6-0.5,1-0.5l10.9-0.1C972.9,405.1,973,405.3,972.9,405.7z"/>
                                                                    <path d="M920.9,406.4c-0.3-0.4-0.2-0.7,0.3-0.9c0.6-0.2,1,0,1.4,0.5c0.2,0.3,0.2,0.7-0.1,0.9c-0.1,0.1-0.2,0.1-0.4,0.1
		C921.6,407.1,921.2,406.8,920.9,406.4z"/>
                                                                    <path d="M947.1,408c-0.8,0.8-1.3,2.1-2.7,2.1c-6.5,0.2-13.1,0.4-19.6,0.3c-0.4,0-0.5-0.2-0.5-0.6l0-3.8
		c0-0.4,0.3-0.6,0.6-0.6h23.4c0.5,0,0.6,0.2,0.3,0.6C948.2,406.8,947.7,407.5,947.1,408z"/>
                                                                    <path d="M1110.1,407.2c-0.1-0.2-0.1-0.5,0.1-0.8c0.1-0.2,0.4-0.3,0.7-0.2c0.2,0.1,0.3,0.4,0.2,0.6l-0.3,1
		c0,0.1-0.1,0.2-0.2,0.1c-0.1,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.2-0.1-0.3C1110.2,407.4,1110.2,407.3,1110.1,407.2z"/>
                                                                    <path d="M1165.7,407.5c0.1-0.8,0.3-1.5,0.5-1.4c0.2,0.1,0.3,0.7,0.3,1.4c0,0.3,0,0.6,0,0.9c0,0.2-0.2,0.3-0.4,0.3
		c-0.2,0-0.3-0.1-0.3-0.3C1165.7,408.1,1165.7,407.8,1165.7,407.5z"/>
                                                                    <path d="M920.5,408c0.3-0.1,0.5,0.1,0.6,0.3c0.1,0.2,0,0.4-0.1,0.5l-0.6,0.6c-0.2,0.2-0.5,0.2-0.7,0c0,0,0,0-0.1-0.1
		C919.3,408.7,919.5,408.3,920.5,408z"/>
                                                                    <path d="M921,411.7c0.3-0.7,0.8-1,1.6-0.9c0.2,0,0.3,0.2,0.3,0.4c0,0.1-0.1,0.2-0.2,0.3l-1.2,0.6
		C921,412.3,920.9,412.2,921,411.7z"/>
                                                                    <path d="M1139.1,414.2c0.2-0.6,0.7-1,1.3-1h4.8c0.7,0,0.9,0.3,0.4,0.9l-9,11.7c-0.3,0.4-0.8,0.4-1.2,0.2
		c-0.3-0.2-0.4-0.6-0.3-1L1139.1,414.2z"/>
                                                                    <path d="M958.4,421.2c-0.1,0.1-0.2,0.1-0.2,0c0,0,0,0,0,0l0,0c-1.4-1.1-1.6-3.2-0.5-4.5c0,0,0,0,0,0l1-1.2
		c1.1-1.4,3.2-1.6,4.5-0.5l0.1,0c0.1,0.1,0.1,0.2,0,0.2c0,0,0,0,0,0L958.4,421.2z"/>
                                                                    <path d="M953.3,415.7l55.2,112.8c0.1,0.2,0.1,0.4-0.1,0.5l-3.2,4.3c-0.2,0.2-0.5,0.3-0.7,0.1c-0.1,0-0.1-0.1-0.2-0.2
		l-55.7-112.9c-0.1-0.2-0.1-0.4,0.1-0.6l3.6-4.2c0.2-0.2,0.5-0.2,0.7,0C953.3,415.5,953.3,415.6,953.3,415.7z"/>
                                                                    <path d="M754.5,417l57.9,103.3c0.1,0.2,0.1,0.5-0.1,0.7l-3.2,3.7c-0.2,0.3-0.6,0.3-0.9,0.1c-0.1,0-0.1-0.1-0.1-0.2
		L750,421.6c-0.1-0.2-0.1-0.5,0.1-0.7l3.4-3.9c0.2-0.3,0.6-0.3,0.9-0.1C754.5,416.9,754.5,417,754.5,417z"/>
                                                                    <path d="M946.5,422.8l0.5-0.2c0.5-0.2,1.1,0,1.4,0.5l4.1,8.4c0.2,0.5,0,1.1-0.5,1.4l-0.5,0.2c-0.5,0.2-1.1,0-1.4-0.5
		l-4.1-8.4C945.8,423.7,946,423.1,946.5,422.8z"/>
                                                                    <path d="M748,423.3L748,423.3c0.5-0.3,1-0.1,1.2,0.3l4.5,8c0.2,0.4,0.1,0.9-0.3,1.1l-0.1,0c-0.4,0.2-0.9,0.1-1.1-0.3
		l-4.5-8C747.5,424.1,747.6,423.6,748,423.3z"/>
                                                                    <path d="M1133.3,429.3c0.1-0.1,0.2,0,0.3,0c0,0,0,0,0,0l0.1,0.1c0.8,1.1-1.7,4.2-5.6,6.9c0,0,0,0,0,0l-2,1.4
		c-3.9,2.7-7.7,4-8.4,2.9c0,0,0,0,0,0l-0.1-0.1c-0.1-0.1,0-0.2,0-0.3c0,0,0,0,0,0L1133.3,429.3z"/>
                                                                    <path d="M946.5,430.3c0.1-0.1,0.2-0.1,0.3,0c0,0,0,0,0,0l0.1,0.1c1.4,1.2,1.6,3.3,0.4,4.7c0,0,0,0,0,0l-1.7,2
		c-1.2,1.4-3.3,1.7-4.7,0.5c0,0,0,0,0,0l-0.1-0.1c-0.1-0.1-0.1-0.2,0-0.3c0,0,0,0,0,0L946.5,430.3z"/>
                                                                    <path d="M1127.5,439.2c-0.4,1.1-1.1,1.7-2.2,1.8c-0.2,0-0.5-0.1-0.5-0.4c0-0.2,0.1-0.3,0.2-0.4l2.1-1.3
		C1127.5,438.7,1127.7,438.8,1127.5,439.2z"/>
                                                                    <path d="M933.8,439.6c3.5-0.1,7.2,0.1,10.7,0.2c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.1,0,0.2c-0.1,0.3-0.4,0.5-0.7,0.5
		c-3.7,0.1-7.4,0.2-11.1,0.1c-0.4,0-0.5-0.2-0.3-0.6c0.1-0.2,0.3-0.4,0.4-0.6C933.3,439.7,933.5,439.6,933.8,439.6z"/>
                                                                    <path d="M889.9,439.7l29.8,0.1c0.3,0,0.6,0.3,0.6,0.6l0,0c0,0.3-0.3,0.6-0.6,0.6l-29.8-0.1c-0.3,0-0.6-0.3-0.6-0.6l0,0
		C889.3,440,889.6,439.7,889.9,439.7z"/>
                                                                    <path d="M1094,440.3l17.6-0.2c0.2,0,0.4,0.2,0.4,0.4l0,0.2c0,0.2-0.2,0.4-0.4,0.4l-17.6,0.2c-0.2,0-0.4-0.2-0.4-0.4
		l0-0.2C1093.6,440.5,1093.7,440.3,1094,440.3z"/>
                                                                    <path d="M884.2,450.7l4.1,0c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.2l-19.9,23.8c-0.2,0.3-0.5,0.4-0.9,0.4l-11.1,0.7
		c-0.3,0-0.6,0.2-0.7,0.5c-0.5,1.6-1.7,2.1-3.5,1.6c-0.2-0.1-0.4-0.3-0.3-0.6c0-0.1,0-0.1,0.1-0.2l30-35.8c0.2-0.3,0.6-0.3,0.9-0.1
		c0.1,0.1,0.2,0.3,0.2,0.5v7.8C883.5,450.4,883.8,450.7,884.2,450.7L884.2,450.7z"/>
                                                                    <path d="M891.3,444.2l-1-1c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0c0.5,0.5,0.7,1,0.7,1.7c0,0.3-0.2,0.5-0.5,0.5
		C891.5,444.3,891.4,444.3,891.3,444.2z"/>
                                                                    <path d="M893.5,446.6l0-3.7c0-0.4,0.3-0.6,0.6-0.6h23.7c0.4,0,0.6,0.3,0.6,0.6c0,0.2-0.1,0.3-0.2,0.4l-3.5,4
		c-0.1,0.1-0.3,0.2-0.5,0.2l-20.2-0.3C893.8,447.2,893.5,447,893.5,446.6z"/>
                                                                    <path d="M927.2,447.1c0.5-1.3,2.4-3.4,3.5-4.3c0.2-0.2,0.5-0.3,0.8-0.3h11.1c0.6,0,0.7,0.2,0.3,0.7l-3.2,3.8
		c-0.3,0.3-0.6,0.5-1.1,0.5l-11.2,0.1C927.1,447.6,927,447.4,927.2,447.1z"/>
                                                                    <path d="M1124.5,443.5l-4.4,6c-0.1,0.1-0.2,0.2-0.4,0.2l-32.4,0.7c-0.3,0-0.5-0.2-0.5-0.5c0-0.1,0-0.2,0.1-0.3l4.7-6.4
		c0.1-0.1,0.2-0.2,0.4-0.2l32.2-0.3c0.3,0,0.5,0.2,0.5,0.5C1124.6,443.3,1124.6,443.4,1124.5,443.5z"/>
                                                                    <path d="M889.8,445c0.5,0.4,0.5,0.8,0,1.1c-0.3,0.1-0.6,0.1-0.8-0.2c-0.1-0.2-0.1-0.5,0.1-0.6l0.4-0.3
		C889.5,444.9,889.6,445,889.8,445z"/>
                                                                    <path d="M1138.9,447c-0.2,0-0.4-0.1-0.5-0.4c0-0.1,0-0.2,0.1-0.3l0.3-0.6c0.1-0.2,0.4-0.3,0.7-0.2
		c0.1,0.1,0.2,0.2,0.2,0.3C1139.9,446.5,1139.6,446.9,1138.9,447z"/>
                                                                    <path d="M1081.1,446c0.1-0.1,0.4-0.2,0.5,0c0.1,0.1,0.1,0.2,0.1,0.3v0.9c0,0.2-0.2,0.4-0.4,0.4c-0.1,0-0.3-0.1-0.4-0.2
		C1080.7,447,1080.7,446.5,1081.1,446z"/>
                                                                    <path d="M821,447.8l5.5,10.5c0.1,0.2,0.1,0.4-0.1,0.6l-10.8,12.6c-0.2,0.2-0.5,0.2-0.7,0.1c0,0-0.1-0.1-0.1-0.1
		l-5.8-10.5c-0.1-0.2-0.1-0.4,0.1-0.6l11-12.7c0.2-0.2,0.5-0.3,0.7-0.1C820.9,447.7,821,447.7,821,447.8z"/>
                                                                    <path d="M890.4,448.9c-0.3-0.2-0.3-0.4,0-0.6l1-0.7c0.3-0.2,0.8-0.1,1,0.2c0.2,0.3,0.2,0.6-0.1,0.9
		C891.7,449.2,891.1,449.3,890.4,448.9z"/>
                                                                    <path d="M934.2,450.9L914.6,475c-0.1,0.1-0.3,0.2-0.4,0.2l-3,0c-0.3,0-0.6-0.2-0.6-0.5c0-0.1,0-0.3,0.1-0.4l19.4-23.8
		c0.1-0.1,0.2-0.2,0.4-0.2l3.2-0.3c0.3,0,0.6,0.2,0.6,0.5C934.4,450.7,934.3,450.8,934.2,450.9z"/>
                                                                    <path d="M1066.6,477c1.6-2.1,3-3.6,4.1-4.3c9.5-6.5,19-13,28.4-19.7c1.7-1.2,3.5-1.6,5.5-1.2c0.2,0,0.3,0.2,0.2,0.4
		c0,0.1-0.1,0.1-0.1,0.2c-12.3,8.3-24.6,16.8-36.7,25.3c-0.5,0.4-1,0.5-1.2,0.3C1066.4,477.7,1066.3,477.4,1066.6,477z"/>
                                                                    <path d="M1074.5,478.5c-1.3-0.2-2.3-0.2-3.6-0.3c-0.5-0.1-0.6-0.2-0.1-0.5c11.9-8.2,23.8-16.4,35.8-24.6
		c2.1-1.5,4.6-1.8,7.3-1c0.3,0.1,0.3,0.2,0.1,0.4c-12.5,8.6-25.1,17.3-37.7,25.9C1075.8,478.6,1075.2,478.7,1074.5,478.5z"/>
                                                                    <path d="M1236,808.3c-0.4-0.1-0.6-0.3-0.5-0.7l49.3-355.1c0-0.3,0.2-0.5,0.5-0.5l4.8,0.1c0.5,0,0.7,0.3,0.6,0.7
		c-1.3,9-2.5,17.9-3.8,26.9c-14.3,104.9-28.7,209.7-43.2,314.6c-0.2,1.3-0.7,2.9-1.5,4.6c-1.4,2.9-2.1,6-2.1,9.3
		c0,0.3-0.3,0.6-0.6,0.6c0,0-0.1,0-0.1,0L1236,808.3z"/>
                                                                    <path d="M1083,461.6c-0.2-3.8,0.2-11.6,5.8-9.4c0.3,0.1,0.4,0.3,0.4,0.6l-0.8,5.2c-0.1,0.3-0.2,0.6-0.5,0.9l-4.3,3
		C1083.2,462.1,1083,462,1083,461.6z"/>
                                                                    <path d="M1090.3,456.5c0.1-1.7,0.3-5.4,3-4.3c0.7,0.3,1.1,1.1,0.8,1.8c0,0.1-0.1,0.2-0.2,0.3c-0.8,1.1-1.8,1.9-3.1,2.6
		C1090.4,457,1090.3,456.9,1090.3,456.5z"/>
                                                                    <path d="M1245.5,796c-0.2,0-0.3-0.2-0.2-0.3c13.8-99.5,27.6-199.1,41.3-298.7c1.9-13.6,3.7-29,5.7-43.6
		c0-0.3,0.2-0.5,0.6-0.5c1.8,0.1,2.5,1,2.3,2.8c-15.7,113.2-31.3,226.5-46.9,339.9c-0.1,0.5-0.5,0.8-1,0.7c0,0,0,0,0,0L1245.5,796z"
                                                                    />
                                                                    <path d="M1087.3,478.7c0-3.6-0.1-7.5,3.7-8.2c0.3-0.1,0.4,0.1,0.4,0.4l-1.2,7.6c-0.1,0.4-0.3,0.6-0.7,0.6l-1.6,0.1
		C1087.5,479.3,1087.3,479.1,1087.3,478.7z"/>
                                                                    <path d="M1080.5,477.5l4.5-3.3c0.2-0.2,0.6-0.1,0.7,0.1c0.1,0.1,0.1,0.2,0.1,0.3l-0.2,3.4c0,0.3-0.3,0.5-0.5,0.5
		l-4.3-0.1c-0.3,0-0.5-0.2-0.5-0.5C1080.3,477.7,1080.4,477.6,1080.5,477.5z"/>
                                                                    <path d="M914.6,477.8c-1,3.5-10.5,1.5-13.2,1.9c-0.4,0.1-0.6-0.1-0.4-0.5c0.6-1.8,1.9-2.6,4-2.5c2.4,0.1,5.3,0.1,8.8,0
		c0.5,0,0.9,0.3,0.9,0.8C914.7,477.6,914.7,477.7,914.6,477.8z"/>
                                                                    <path d="M858.4,478.6c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.2-0.3-0.1-0.5c0.1-0.1,0.2-0.2,0.3-0.2l31.6-1
		c0.5,0,0.7,0.2,0.6,0.7c-0.2,0.9-1.2,1.6-2.2,1.7C878,479.3,868.2,479.1,858.4,478.6z"/>
                                                                    <path d="M819.4,518.2c-0.3,0-0.5-0.2-0.4-0.5c0-0.1,0-0.2,0.1-0.3l31.8-37.9c0.1-0.2,0.4-0.2,0.6,0
		c0.1,0.1,0.1,0.2,0.1,0.3v8.2c0,0.6,0.4,1,1,1h4c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.2l-20.6,24.7c-0.2,0.3-0.5,0.5-0.9,0.5
		c-3.1,0.4-6.7,0.6-10.8,0.6c-1.3,0-1.8,0.7-1.7,2c0.1,0.5-0.2,0.8-0.7,0.8L819.4,518.2z"/>
                                                                    <path d="M859,480.2h0.5c0.5,0,0.8,0.2,0.8,0.8v1.2c0,0.2-0.2,0.4-0.4,0.4c-0.1,0-0.2,0-0.3-0.1l-1.1-1.1
		c-0.2-0.3-0.2-0.7,0-0.9C858.6,480.3,858.8,480.2,859,480.2z"/>
                                                                    <path d="M1064.4,480.8l18.6-0.7c0.3,0,0.5,0.2,0.6,0.5l0,0.1c0,0.3-0.2,0.5-0.5,0.6l-18.6,0.7c-0.3,0-0.5-0.2-0.6-0.5
		l0-0.1C1063.8,481.1,1064.1,480.8,1064.4,480.8z"/>
                                                                    <path d="M862,484.9l0-3.9c0-0.3,0.3-0.6,0.6-0.6c0,0,0,0,0,0l23.8,0.5c0.3,0,0.6,0.3,0.5,0.6c0,0.1-0.1,0.3-0.1,0.4
		l-3.4,3.8c-0.1,0.1-0.3,0.2-0.4,0.2l-20.3-0.4C862.3,485.5,862,485.2,862,484.9z"/>
                                                                    <path d="M895.5,485.4l3.7-4.1c0.1-0.1,0.2-0.2,0.3-0.1l11.5,0.2c0.2,0,0.4,0.2,0.4,0.5c0,0.1,0,0.2-0.1,0.3l-3.5,4.2
		c-0.1,0.1-0.2,0.2-0.4,0.2l-11.7-0.3c-0.2,0-0.5-0.2-0.5-0.4C895.4,485.6,895.4,485.5,895.5,485.4z"/>
                                                                    <path d="M1122,486.4l1.6-4.4c0.1-0.3,0.4-0.4,0.7-0.4l153.5,3c0.4,0,0.7,0.3,0.7,0.7c0,0,0,0.1,0,0.1l-0.8,4.7
		c0,0.3-0.3,0.6-0.7,0.6l-154.4-3.4c-0.4,0-0.7-0.3-0.7-0.7C1121.9,486.6,1121.9,486.5,1122,486.4z"/>
                                                                    <path d="M1106.8,522.2l-5.2-1.9c-0.2-0.1-0.2-0.2-0.2-0.4c2.7-7.9,5.2-17,9.9-23.9c3.2-4.7,6.5-9.3,9.7-13.9
		c0.1-0.2,0.3-0.2,0.5-0.1c0.1,0.1,0.2,0.2,0.1,0.4l-14,39.3C1107.5,522.2,1107.1,522.4,1106.8,522.2z"/>
                                                                    <path d="M1062,483.8l33.5-1.2c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.2-0.1,0.3l-4.6,6.3c-0.1,0.1-0.2,0.2-0.4,0.2l-33.8,1.5
		c-0.3,0-0.5-0.2-0.5-0.5c0-0.1,0-0.2,0.1-0.3l4.9-6.6C1061.7,483.8,1061.8,483.8,1062,483.8z"/>
                                                                    <path d="M857.4,483c0.1-0.1,0.3-0.2,0.5,0c0,0,0.1,0.1,0.1,0.1l0.3,0.9c0.1,0.3,0,0.7-0.3,0.8c-0.3,0.1-0.7,0-0.8-0.3
		C856.9,484,857,483.5,857.4,483z"/>
                                                                    <path d="M858.5,486.6l1.3-1.4c0.3-0.3,0.7-0.3,1-0.1c0.2,0.2,0.3,0.5,0.2,0.8c-0.6,1.2-1.4,1.6-2.3,1.2
		C858.4,487,858.4,486.8,858.5,486.6C858.4,486.6,858.5,486.6,858.5,486.6z"/>
                                                                    <path d="M1110.3,485.6c0-0.2,0.2-0.4,0.5-0.4c0.1,0,0.3,0.1,0.3,0.2c0.3,0.5,0.2,0.9-0.2,1.3c-0.2,0.2-0.4,0.2-0.6,0
		c-0.1-0.1-0.2-0.2-0.1-0.4L1110.3,485.6z"/>
                                                                    <path d="M1050.2,487.7c0-0.2,0.3-0.4,0.5-0.3c0.2,0,0.3,0.2,0.3,0.3c0.1,0.5,0,0.9-0.1,1.3c-0.1,0.2-0.4,0.3-0.6,0.2
		c-0.2-0.1-0.3-0.3-0.3-0.5L1050.2,487.7z"/>
                                                                    <path d="M903.2,488c0.3,0,0.5,0.2,0.5,0.4c0,0.1,0,0.3-0.1,0.4c-6.4,7.8-12.7,15.7-19.1,23.7c-1.2,1.6-2.8,1.9-4.7,1
		c-0.3-0.2-0.4-0.4-0.2-0.7l19.6-24c0.2-0.2,0.5-0.4,0.8-0.4L903.2,488z"/>
                                                                    <path d="M1084.7,492.8c0.1-0.7,0.5-1.1,1.3-1.1l1.6,0.1c0.6,0,0.8,0.3,0.6,0.9c-0.8,2.3-2.1,4.4-3.6,6.3
		c-0.1,0.2-0.3,0.2-0.5,0.1c-0.1-0.1-0.2-0.2-0.1-0.3L1084.7,492.8z"/>
                                                                    <path d="M1077.9,492.6c0-0.2,0.2-0.3,0.3-0.3l4.2-0.4c0.4,0,0.6,0.2,0.6,0.6c-0.5,4.2-0.6,9.1-3,12.7
		c-1.4,2.1-2.9,4.2-4.5,6.2c-0.1,0.1-0.2,0.2-0.4,0.1c-0.1-0.1-0.1-0.2-0.1-0.3L1077.9,492.6z"/>
                                                                    <path d="M868.2,519.1l3.3-3.8c0.1-0.1,0.2-0.1,0.3-0.1l12,0.4c0.2,0,0.3,0.2,0.3,0.3c0,0.1,0,0.1-0.1,0.2l-3.4,4.1
		c-0.1,0.1-0.2,0.1-0.3,0.1l-11.9-0.7c-0.2,0-0.3-0.2-0.3-0.3C868.1,519.2,868.1,519.2,868.2,519.1z"/>
                                                                    <path d="M824.7,518.2c-0.2,0-0.4-0.2-0.4-0.5c0-0.1,0-0.2,0.1-0.3c0.2-0.2,0.5-0.3,0.9-0.3c11-0.4,22-0.9,32.9-1.7
		c0.3,0,0.5,0.2,0.5,0.4c0,0.1,0,0.3-0.1,0.4l-2.3,2.6c-0.2,0.2-0.5,0.3-0.8,0.3L824.7,518.2z"/>
                                                                    <path d="M818.8,528.7l4.7,0.2c0.4,0,0.5,0.2,0.2,0.5l-21.5,25.8c-0.2,0.3-0.5,0.4-0.8,0.4l-11.9,1
		c-0.4,0-0.6,0.2-0.7,0.6c-0.5,2.1-1.9,2.7-4.3,1.8c-0.1,0-0.2-0.2-0.1-0.3c0,0,0,0,0-0.1l33.1-39.4c0.2-0.2,0.4-0.2,0.6,0
		c0.1,0.1,0.1,0.2,0.1,0.3l0,8.5C818.3,528.4,818.5,528.7,818.8,528.7z"/>
                                                                    <path d="M827.2,521.9c-0.1,0.2-0.4,0.3-0.6,0.2c-0.1,0-0.1-0.1-0.1-0.1l-0.8-1.1c-0.2-0.3-0.2-0.7,0.1-1
		c0.2-0.1,0.4-0.2,0.6-0.1C827.6,520,827.8,520.7,827.2,521.9z"/>
                                                                    <path d="M829.6,525c-0.3,0-0.6-0.3-0.6-0.7c0-0.1,0-0.1,0-0.2c0.4-1,0.4-2.1,0-3.1c-0.2-0.6,0-1,0.7-0.9l23.8,0.8
		c0.5,0,0.6,0.2,0.4,0.7c-1.3,2.6-2.9,4.2-6.1,4.2C842.3,525.6,836.2,525.4,829.6,525z"/>
                                                                    <path d="M862.8,525.8l3.4-4.2c0.1-0.1,0.2-0.2,0.3-0.1l11.9,0.5c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.2l-3.5,4.1
		c-0.1,0.1-0.2,0.1-0.3,0.1l-11.8-0.4c-0.2,0-0.4-0.2-0.4-0.4C862.7,526,862.8,525.9,862.8,525.8z"/>
                                                                    <path d="M1032.7,522.9c0-0.1,0.1-0.2,0.2-0.2l0.1,0c11.1-0.6,20.2-0.8,20.2-0.5c0,0,0,0,0,0l0,0.4c0,0.3-9,1-20.1,1.6
		l-0.1,0c-0.1,0-0.2-0.1-0.2-0.2c0,0,0,0,0,0L1032.7,522.9z"/>
                                                                    <path d="M1096.5,527.5c-0.2,0-0.3-0.2-0.3-0.4c0,0,0,0,0,0l1.2-4.1c0.1-0.4,0.3-0.6,0.7-0.6c4-0.4,7.4,0.7,10.2,3.5
		c0.3,0.3,0.4,0.8,0.3,1.2l-101.5,286c0,0.1-0.2,0.2-0.3,0.2l-6.8-1c-0.3,0-0.5-0.3-0.5-0.7c0,0,0-0.1,0-0.1l0.6-1.6
		c0.1-0.3,0-0.6-0.2-0.7l-1.8-1.1c-0.4-0.2-0.5-0.6-0.4-1l97.9-274c0.1-0.3,0.4-0.5,0.7-0.6c1.1-0.2,1.9-0.9,2.4-1.9
		c0.1-0.3,0.2-0.6,0.1-0.9C1098.6,528.5,1097.8,527.8,1096.5,527.5z"/>
                                                                    <path d="M824.1,522.9l0.4-0.4c0.1-0.1,0.2-0.1,0.3,0.1l0.3,0.9c0.1,0.3-0.1,0.5-0.3,0.6c-0.2,0.1-0.4,0-0.5-0.2
		l-0.2-0.4C823.9,523.2,823.9,523,824.1,522.9z"/>
                                                                    <path d="M1030.9,526.1l35.2-2.3c0.2,0,0.4,0.1,0.4,0.3c0,0.1,0,0.2-0.1,0.2l-5,6.9c-0.1,0.1-0.2,0.1-0.3,0.2l-35.7,2.6
		c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.2l5.4-7.2C1030.7,526.2,1030.8,526.1,1030.9,526.1z"/>
                                                                    <path d="M826.1,526.7c-0.3-0.3-0.4-0.7-0.2-1.1l0.7-1.3c0.4-0.6,0.6-0.6,0.8,0.1l0.2,1c0.1,0.3,0,0.6-0.2,0.9
		C827.1,527.1,826.6,527.2,826.1,526.7z"/>
                                                                    <path d="M822.2,526.1c0.5-0.2,0.9-0.1,1.2,0.3c0.1,0.2,0,0.4-0.2,0.5c-0.1,0.1-0.2,0.1-0.3,0l-0.6-0.2
		c-0.2-0.1-0.3-0.3-0.2-0.5C822,526.2,822.1,526.2,822.2,526.1z"/>
                                                                    <path d="M1081.2,526.9c0-0.2,0.1-0.4,0.3-0.5c0.2-0.1,0.4,0,0.5,0.2c0,0,0,0,0,0.1c0.1,0.5-0.1,0.9-0.6,1.1
		c-0.3,0.1-0.4,0.1-0.5-0.2C1080.9,527.4,1081,527.1,1081.2,526.9z"/>
                                                                    <path d="M1162.1,619.9c0.1-0.1,0.2-0.2,0.3-0.3c36.5-30.7,73-61.4,109.4-92.1c0.2-0.2,0.5-0.2,0.6,0
		c0.1,0.1,0.1,0.2,0.1,0.4c-0.3,2.6-0.5,6.6-2.5,8.3c-72.9,61.1-145.9,122.2-218.8,183.2c-0.1,0.1-0.3,0.1-0.4,0
		c0-0.1-0.1-0.1-0.1-0.2l1-6.2c0-0.3,0.2-0.6,0.4-0.8c33.8-28.5,67.7-57,101.6-85.5c3.5-2.9,6.1-5.1,7.9-6.4
		C1161.7,620.4,1161.8,620.2,1162.1,619.9z"/>
                                                                    <path d="M807.2,528.1L807.2,528.1c0.2,0.2,0.2,0.4,0.1,0.6L544.1,842.4c-0.1,0.2-0.4,0.2-0.6,0.1l0,0
		c-0.2-0.1-0.2-0.4-0.1-0.6l263.2-313.7C806.8,528,807,528,807.2,528.1z"/>
                                                                    <path d="M866.2,529.4c1.1-1.3,2.6-1.5,4.4-0.7c0.3,0.2,0.4,0.4,0.1,0.7l-19.4,23.9c-0.2,0.3-0.5,0.4-0.9,0.4h-3
		c-0.3,0-0.6-0.2-0.6-0.5c0-0.1,0-0.3,0.1-0.4C853.4,545,859.8,537.2,866.2,529.4z"/>
                                                                    <path d="M1089,529l1.9,2.7c0.1,0.2,0.2,0.4,0.1,0.6l-1.7,4.8c-0.1,0.3-0.5,0.5-0.8,0.4c-0.1,0-0.2-0.1-0.3-0.2
		l-2.7-3.9c-0.2-0.2-0.2-0.5,0-0.8l2.5-3.6c0.2-0.3,0.6-0.4,0.9-0.2C1088.9,528.9,1088.9,528.9,1089,529z"/>
                                                                    <path d="M1093.4,530.9c0.6-1.4,1.5-2,2.8-1.6c0.1,0,0.2,0.2,0.2,0.3c0,0.1-0.1,0.1-0.2,0.2l-0.9,0.4
		c-0.3,0.1-0.5,0.4-0.6,0.7c-32.7,91.7-65.5,183.4-98.4,275c-0.1,0.3-0.2,0.5-0.4,0.7c-0.2,0.2-0.5,0.3-0.7,0.3
		c-0.3,0-0.6-0.2-0.6-0.5c0-0.1,0-0.1,0-0.2c1.1-3.4,2.3-6.8,3.6-10.2c30.3-84.4,60.5-168.8,90.6-253.3
		C1090.3,538.7,1091.8,534.8,1093.4,530.9z"/>
                                                                    <path d="M750,529.8l6.1,10.5c0.1,0.2,0.1,0.5-0.1,0.7l-11.8,13.8c-0.2,0.3-0.6,0.3-0.9,0.1c0,0-0.1-0.1-0.1-0.1
		l-6.2-10.5c-0.1-0.2-0.1-0.5,0.1-0.7l11.9-13.7c0.2-0.3,0.6-0.3,0.9-0.1C749.9,529.7,749.9,529.8,750,529.8z"/>
                                                                    <path d="M1018,531.2c0-0.3,0.3-0.6,0.6-0.6c0.2,0,0.4,0.1,0.5,0.3c0.3,0.6,0.2,1-0.3,1.4c-0.2,0.1-0.5,0.1-0.7-0.1
		c-0.1-0.1-0.1-0.2-0.1-0.3V531.2z"/>
                                                                    <path d="M1003.5,536.7L1003.5,536.7c0.3,0.3,0.4,0.7,0.2,1l-258.4,348c-0.2,0.3-0.6,0.4-0.9,0.1l0,0
		c-0.3-0.2-0.4-0.6-0.1-0.9l258.4-348C1002.8,536.5,1003.2,536.5,1003.5,536.7z"/>
                                                                    <path d="M1166,625.6l104.1-87.4c0.2-0.2,0.6-0.2,0.8,0.1l0.1,0.1c0.2,0.2,0.2,0.6-0.1,0.8l-104.1,87.4
		c-0.2,0.2-0.6,0.2-0.8-0.1l-0.1-0.1C1165.8,626.1,1165.8,625.8,1166,625.6z"/>
                                                                    <path d="M1079.7,540.7c0-0.3,0.2-0.6,0.6-0.7c0.2-0.1,0.4,0,0.4,0.2c0,0.1,0,0.1,0,0.2c-1.7,10.7-3.3,21.4-5,32.1
		c-0.9,5.8-3.1,11-5,16.6c-0.1,0.2-0.3,0.3-0.5,0.2c-0.2-0.1-0.3-0.2-0.2-0.4c2.2-14.2,4.4-28.5,6.7-42.9
		C1076.8,544.7,1077.8,543,1079.7,540.7z"/>
                                                                    <path d="M1103.7,548.5l54.3,71.7c0.2,0.2,0.1,0.6-0.1,0.8l-3.5,3c-0.2,0.2-0.6,0.2-0.8,0c0,0,0,0,0,0l-52.5-69.7
		c-0.1-0.1-0.1-0.3-0.1-0.5l1.8-5.1c0.1-0.3,0.4-0.4,0.7-0.3C1103.6,548.4,1103.7,548.4,1103.7,548.5z"/>
                                                                    <path d="M1073.7,549.2c0.1-0.1,0.3-0.2,0.5,0c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.4c-2.3,15.3-4.7,30.6-7.1,45.9
		c-1.4,8.9-5.2,17.5-8.1,26.1c-1.4,4.1-1.8,4-1.1-0.3c3-18.4,6-37.8,9-58.2C1067.9,557.1,1069.9,553.6,1073.7,549.2z"/>
                                                                    <path d="M834.1,560.8l5-5.7c0.1-0.1,0.3-0.2,0.4-0.2l11.5,0.5c0.3,0,0.5,0.3,0.5,0.6c0,0.1-0.1,0.2-0.1,0.3l-4.8,5.9
		c-0.1,0.1-0.3,0.2-0.4,0.2l-11.7-0.7c-0.3,0-0.5-0.3-0.5-0.6C834,561,834,560.9,834.1,560.8z"/>
                                                                    <path d="M790.8,559.3c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6l34.6-2.4c0.6,0,0.7,0.2,0.3,0.6l-3.5,4.2
		c-0.2,0.3-0.5,0.4-0.9,0.4L790.8,559.3z"/>
                                                                    <path d="M784.9,570h4.3c0.5,0,0.6,0.2,0.3,0.6c-7.3,8.8-14.6,17.5-21.9,26.2c-0.6,0.8-1.7,1.2-3.2,1.4
		c-3.5,0.3-6.9,0.6-10.4,0.8c-0.3,0-0.6,0.2-0.8,0.5l-0.9,2c-0.1,0.3-0.4,0.5-0.7,0.5H749c-0.3,0-0.5-0.2-0.5-0.5
		c0-0.1,0-0.2,0.1-0.3l34.5-41.1c0.1-0.2,0.4-0.2,0.5,0c0.1,0.1,0.1,0.2,0.1,0.3v8.6C783.8,569.5,784.3,570,784.9,570z"/>
                                                                    <path d="M788,561.1c0.2-0.2,0.5-0.2,0.7,0c0.2,0.2,0.2,0.5,0.1,0.7c-0.2,0.3-0.4,0.4-0.8,0.5c-0.2,0.1-0.5,0-0.6-0.3
		c-0.1-0.2,0-0.4,0.1-0.5L788,561.1z"/>
                                                                    <path d="M791.3,562.1c-0.2-0.2-0.3-0.3-0.3-0.4c0.1-0.6,0.6-1.2,1.2-1.3c0.5-0.1,0.9,0.3,1,0.8l0.2,2.4
		c0,0.2-0.2,0.5-0.4,0.5c-0.2,0-0.4-0.1-0.4-0.2l-0.6-1.2C791.7,562.5,791.5,562.2,791.3,562.1z"/>
                                                                    <path d="M794.8,565.7l0-4c0-0.3,0.2-0.5,0.5-0.5c0,0,0,0,0,0l24.3,1.4c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.2-0.1,0.3
		l-3.3,3.8c-0.1,0.1-0.2,0.2-0.4,0.2l-20.9-1.2C795,566.1,794.8,565.9,794.8,565.7z"/>
                                                                    <path d="M828.7,567.5l3.5-4.1c0.1-0.1,0.2-0.1,0.3-0.1l12,0.7c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.2l-3.5,4.1
		c-0.1,0.1-0.2,0.1-0.3,0.1l-12-0.7c-0.2,0-0.4-0.2-0.4-0.4C828.6,567.7,828.6,567.6,828.7,567.5z"/>
                                                                    <path d="M789.6,564.8l0.3-0.8c0.1-0.3,0.4-0.4,0.7-0.3c0.1,0,0.3,0.1,0.3,0.3c0.2,0.4,0.2,0.7,0,1.1
		c-0.1,0.2-0.2,0.3-0.3,0.4c-0.3,0.2-0.6,0.1-0.9-0.2C789.6,565.1,789.5,565,789.6,564.8z"/>
                                                                    <path d="M1008.8,564.2c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0.1-0.1,0.1l-6.2,1c-0.2,0-0.4-0.1-0.5-0.4c0-0.1,0-0.2,0.1-0.3
		c0.3-0.4,0.8-0.6,1.6-0.7C1005.3,563.9,1007,563.9,1008.8,564.2z"/>
                                                                    <path d="M792.2,565.8c0.2-0.3,0.5-0.3,0.8-0.2c0.1,0.1,0.2,0.2,0.3,0.4l0.2,1.2c0,0.3-0.2,0.6-0.5,0.6c0,0,0,0,0,0
		l-1.3,0.2c-0.5,0.1-0.6-0.1-0.4-0.6L792.2,565.8z"/>
                                                                    <path d="M1000.9,566.9l20.2-1.9c0.3,0,0.6,0.2,0.7,0.5l0,0.1c0,0.3-0.2,0.6-0.5,0.7l-20.2,1.9c-0.3,0-0.6-0.2-0.7-0.5
		l0-0.1C1000.3,567.2,1000.6,566.9,1000.9,566.9z"/>
                                                                    <path d="M998.3,570.2l36.9-3.5c0.2,0,0.4,0.1,0.4,0.3c0,0.1,0,0.2-0.1,0.2l-5.2,7.2c-0.1,0.1-0.2,0.1-0.3,0.2
		l-37.3,3.7c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.3l5.7-7.5C998.1,570.3,998.2,570.3,998.3,570.2z"/>
                                                                    <path d="M787.7,567.2c0-0.2,0.2-0.3,0.4-0.2c0,0,0.1,0,0.1,0l0.8,0.4c0.2,0.1,0.3,0.4,0.2,0.6
		c-0.1,0.2-0.2,0.3-0.4,0.3h-0.7c-0.4,0-0.6-0.2-0.5-0.6L787.7,567.2z"/>
                                                                    <path d="M1051.1,569.4c0.6,0.4,0.7,0.8,0.3,1.3c-0.2,0.2-0.6,0.2-0.8,0c-0.1-0.1-0.2-0.2-0.2-0.4v-0.5
		c0-0.2,0.2-0.4,0.4-0.4C1051,569.4,1051.1,569.4,1051.1,569.4z"/>
                                                                    <path d="M836.7,570.4c0.3,0.1,0.4,0.3,0.3,0.6c0,0.1,0,0.1-0.1,0.2l-19.1,23.6c-0.2,0.3-0.6,0.5-1,0.5h-3.3
		c-0.2,0-0.4-0.2-0.4-0.4c0-0.1,0-0.2,0.1-0.3c6.3-7.6,12.5-15.2,18.6-22.8C833.2,569.9,834.7,569.9,836.7,570.4z"/>

                                                                    <ellipse transform="matrix(0.1478 -0.989 0.989 0.1478 269.4708 1465.9762)" cx="985.4" cy="576.6" rx="1.1" ry="0.6"/>
                                                                    <path d="M798.6,604.5l6.6-8c0.1-0.1,0.3-0.2,0.5-0.2l11.6,0.9c0.3,0,0.5,0.3,0.5,0.6c0,0.1-0.1,0.2-0.1,0.3l-6.5,7.8
		c-0.1,0.1-0.3,0.2-0.5,0.2l-11.7-0.7c-0.3,0-0.5-0.3-0.5-0.6C798.5,604.7,798.5,604.6,798.6,604.5z"/>
                                                                    <path d="M755.4,601.9c-0.3,0-0.5-0.1-0.6-0.2c-0.2-0.1-0.3-0.5-0.2-0.7c0.1-0.1,0.2-0.2,0.4-0.2l36.2-3.5
		c0.2,0,0.4,0.1,0.4,0.3c0,0.1,0,0.2-0.1,0.3l-5,5.9c-0.2,0.3-0.6,0.4-0.9,0.4C775.6,603.6,765.5,602.8,755.4,601.9z"/>
                                                                    <path d="M748.3,612.8l5.2,0.2c0.2,0,0.3,0.1,0.3,0.3c0,0.1,0,0.1-0.1,0.2l-23.5,28.2c-0.1,0.1-0.1,0.1-0.2,0.1
		l-13.7,1.7c-0.1,0-0.2,0.1-0.2,0.2l-0.9,2.5c0,0.1-0.2,0.2-0.3,0.2h-3.7c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.1,0.1-0.2l36.4-43.4
		c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.1,0.1,0.1,0.2l0,9.7C748,612.7,748.1,612.8,748.3,612.8z"/>
                                                                    <path d="M755.7,604.8c-0.3,0-0.6-0.2-0.7-0.6c-0.1-0.3,0-0.6,0.3-0.7c0,0,0.1,0,0.1,0c1.6-0.3,2.4,0.4,2.4,2
		c0,1.5,0.1,2.9,0.3,4.4c0.1,0.4-0.1,0.7-0.5,0.7l-1.7,0.3c-0.3,0.1-0.6-0.1-0.7-0.5c0-0.2,0-0.4,0.2-0.6
		C757.5,608.4,757.6,606.7,755.7,604.8z"/>
                                                                    <path d="M753.3,604.2c0,0.6-0.5,0.9-1.3,0.8c-0.3,0-0.5-0.3-0.5-0.6c0-0.2,0.2-0.4,0.4-0.5l0.8-0.2
		C753.2,603.6,753.4,603.8,753.3,604.2z"/>
                                                                    <path d="M759.3,608.3l0.3-3.9c0-0.3,0.3-0.5,0.5-0.5c0,0,0,0,0,0l23.9,1.8c0.3,0,0.5,0.3,0.5,0.5c0,0.1,0,0.2-0.1,0.3
		l-3.3,3.9c-0.1,0.1-0.3,0.2-0.4,0.2l-21-1.8C759.5,608.8,759.3,608.6,759.3,608.3z"/>
                                                                    <path d="M754,608.2c-0.3-0.7-0.1-1.2,0.5-1.7c0.2-0.2,0.5-0.1,0.7,0.1c0.1,0.1,0.1,0.3,0.1,0.4l-0.3,1.2
		c-0.1,0.3-0.3,0.5-0.6,0.4C754.2,608.5,754.1,608.4,754,608.2z"/>
                                                                    <path d="M793.4,610.9l3.3-3.8c0.1-0.1,0.2-0.2,0.4-0.1l11.8,0.8c0.2,0,0.4,0.2,0.4,0.5c0,0.1,0,0.2-0.1,0.2l-3.2,4
		c-0.1,0.1-0.2,0.2-0.4,0.2l-11.9-1.1c-0.2,0-0.4-0.2-0.4-0.5C793.3,611,793.3,611,793.4,610.9z"/>
                                                                    <path d="M978.4,609.2c1.7,0.2,1.7,0.4,0,0.7c-3.1,0.5-6.1,0.8-9.2,1.1c-0.4,0-0.6-0.2-0.5-0.6c0.2-0.9,1-1.6,2-1.6
		C972.9,608.8,975.5,609,978.4,609.2z"/>
                                                                    <path d="M753.2,610.6l-0.1,0.4c-0.1,0.2-0.3,0.4-0.6,0.3c-0.1,0-0.2-0.1-0.2-0.1l-0.4-0.4c-0.2-0.2-0.2-0.5,0-0.6
		c0.1-0.1,0.1-0.1,0.2-0.1c0.4-0.1,0.7,0,0.8,0.2C753.1,610.4,753.2,610.5,753.2,610.6z"/>
                                                                    <path d="M966.7,612.9l22-2.7c0.1,0,0.3,0.1,0.3,0.2l0.1,0.7c0,0.1-0.1,0.3-0.2,0.3l-22,2.7c-0.1,0-0.3-0.1-0.3-0.2
		l-0.1-0.7C966.5,613,966.6,612.9,966.7,612.9z"/>
                                                                    <path d="M964.5,616.3l38.7-4.8c0.2,0,0.4,0.1,0.4,0.3c0,0.1,0,0.2-0.1,0.3l-5.8,7.7c-0.1,0.1-0.2,0.1-0.3,0.2l-38.8,5
		c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.3l5.8-7.9C964.3,616.4,964.3,616.3,964.5,616.3z"/>
                                                                    <path d="M799.6,613.8c0.7-0.1,1.3,0,1.9,0.3c0.4,0.2,0.5,0.5,0.2,0.8c-5.7,7-11.3,13.9-16.9,20.8
		c-1.7,2.1-3.5,3.2-6.1,2.2c-0.4-0.1-0.4-0.4-0.2-0.7c6.1-7.4,12.2-14.9,18.3-22.4c0.6-0.7,1.2-1.1,1.9-1.1
		C799.1,613.9,799.4,613.9,799.6,613.8z"/>
                                                                    <path d="M1019,614.4c0.2-0.2,0.5-0.1,0.6,0.1c0.1,0.1,0.1,0.2,0.1,0.3l0,0.9c0,0.3-0.3,0.5-0.5,0.5
		c-0.2,0-0.3-0.1-0.4-0.2C1018.4,615.3,1018.5,614.8,1019,614.4z"/>
                                                                    <path d="M672.9,618.8l6.6,10.5c0.2,0.3,0.1,0.6-0.1,0.8l-13,15.1c-0.3,0.3-0.7,0.3-1,0.1c-0.1,0-0.1-0.1-0.1-0.2
		l-6.7-10.5c-0.2-0.3-0.1-0.6,0.1-0.9l13-15.1c0.3-0.3,0.7-0.3,1-0.1C672.9,618.6,672.9,618.7,672.9,618.8z"/>
                                                                    <path d="M800.9,618.6l0.1,0.1c0.2,0.1,0.2,0.4,0.1,0.5l-14.3,17.9c-0.1,0.2-0.4,0.2-0.5,0.1l-0.1-0.1
		c-0.2-0.1-0.2-0.4-0.1-0.5l14.3-17.9C800.5,618.5,800.7,618.5,800.9,618.6z"/>
                                                                    <path d="M950.9,624.7c-0.1,0.2-0.5,0.3-0.7,0.1c-0.1-0.1-0.2-0.2-0.2-0.4l0-1.6c0-0.3,0.2-0.5,0.5-0.5
		c0.2,0,0.3,0.1,0.4,0.2C951.4,623.3,951.4,624,950.9,624.7z"/>
                                                                    <path d="M1164.2,628.3l79.6,105.3c0.1,0.1,0.1,0.3,0.1,0.4l-0.9,7c0,0.3-0.3,0.5-0.6,0.5c-0.2,0-0.3-0.1-0.4-0.2
		L1159.6,632c-0.2-0.2-0.1-0.6,0.1-0.8l3.6-2.9C1163.6,628,1163.9,628,1164.2,628.3C1164.1,628.3,1164.1,628.3,1164.2,628.3z"/>
                                                                    <path d="M1052,721l105.1-88.2c0.2-0.2,0.5-0.2,0.7,0.1l0.1,0.2c0.2,0.2,0.2,0.5-0.1,0.7L1052.8,722
		c-0.2,0.2-0.5,0.2-0.7-0.1l-0.1-0.2C1051.8,721.5,1051.8,721.2,1052,721z"/>
                                                                    <path d="M761.3,650.2l8.9-10.9c0.1-0.1,0.2-0.1,0.3-0.1l12.1,1.5c0.2,0,0.3,0.2,0.3,0.4c0,0.1,0,0.1-0.1,0.2l-8.7,10.6
		c-0.1,0.1-0.2,0.1-0.3,0.1l-12.3-1.2c-0.2,0-0.3-0.2-0.3-0.4C761.3,650.3,761.3,650.3,761.3,650.2z"/>
                                                                    <path d="M747.7,649.3l-30-3.1c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6l38.1-4.6c0.2,0,0.4,0.1,0.4,0.3
		c0,0.1,0,0.2-0.1,0.3l-6,7.2C749.5,649.1,748.7,649.4,747.7,649.3z"/>
                                                                    <path d="M673.5,704.2c1.3,0.4,2.5,0.3,3.7-0.2c0.3-0.1,0.6,0,0.8,0.3c0.1,0.2,0.1,0.4-0.1,0.6l-24.3,29.3
		c-0.7,0.8-1.6,1.3-2.6,1.5c-3.9,0.7-8,1.4-12.4,2.1c-1.5,0.2-2.1,0.9-2.1,2.4c0,0.4-0.2,0.6-0.5,0.6l-3.8,0.6
		c-0.8,0.1-0.9-0.1-0.4-0.7l78.5-93.5c0.1-0.1,0.3-0.2,0.5,0c0.1,0.1,0.1,0.2,0.1,0.3v9.6c0,0.4,0.3,0.7,0.7,0.7c0,0,0,0,0,0
		l4.6-0.1c0.3,0,0.5,0.2,0.5,0.5c0,0.1,0,0.2-0.1,0.3l-23.9,28.7c-0.2,0.3-0.5,0.4-0.9,0.5l-13.7,2.1c-0.3,0-0.5,0.2-0.6,0.5
		l-0.8,1.7c-0.2,0.3-0.4,0.6-0.8,0.6l-3.4,0.5c-0.4,0.1-0.7,0.3-0.7,0.8v7.9C671.8,703,672.3,703.8,673.5,704.2z"/>
                                                                    <path d="M718.8,649.5c-2-1.4-3.5,0.5-4.2,0.2c-0.2-0.1-0.3-0.3-0.4-0.6c-0.1-0.3,0.1-0.7,0.5-0.7c0,0,0,0,0.1,0
		l5.5-0.4c0.3,0,0.6,0.2,0.6,0.5c0,0,0,0,0,0l0.3,6c0,0.3-0.2,0.6-0.5,0.7l-2.3,0.5c-0.2,0-0.5-0.1-0.5-0.4c0-0.2,0-0.4,0.2-0.4
		c1.3-0.8,1.9-2,1.8-3.7C719.7,650.5,719.4,649.9,718.8,649.5z"/>
                                                                    <path d="M722.3,652.9v-4.1c0-0.2,0.2-0.4,0.4-0.4c0,0,0,0,0.1,0L747,651c0.2,0,0.4,0.2,0.4,0.5c0,0.1,0,0.2-0.1,0.2
		l-3,3.8c-0.1,0.1-0.2,0.2-0.4,0.2l-21.3-2.2C722.4,653.4,722.3,653.2,722.3,652.9z"/>
                                                                    <path d="M939.2,650.3c0.1-0.2,0.2-0.3,0.4-0.2c0.1,0,0.2,0.1,0.2,0.3c0.2,1.1,0.1,2.2-0.3,3.3
		c-0.2,0.3-0.6,0.4-0.9,0.3c-0.3-0.1-0.4-0.4-0.3-0.7L939.2,650.3z"/>
                                                                    <path d="M716.6,652.6c-0.4-0.8-0.1-1.4,0.8-1.7c0.3-0.1,0.7,0.1,0.8,0.4c0,0.1,0,0.1,0,0.2v0.8c0,0.5-0.4,0.8-0.9,0.8
		C717.1,653.1,716.8,652.9,716.6,652.6z"/>
                                                                    <path d="M756.5,656.3l3.1-3.8c0.1-0.1,0.2-0.2,0.4-0.2l12.1,1.3c0.2,0,0.4,0.2,0.4,0.5c0,0.1,0,0.2-0.1,0.2l-3.2,3.8
		c-0.1,0.1-0.2,0.2-0.4,0.2l-12-1.2c-0.2,0-0.4-0.3-0.4-0.5C756.4,656.4,756.4,656.3,756.5,656.3z"/>
                                                                    <path d="M716.1,654.9c0.2,0,0.4,0.1,0.6,0.3c0.1,0.2,0.1,0.4,0,0.6c0,0-0.1,0.1-0.1,0.1c-0.9,0.3-1.5,0.1-2-0.6
		c-0.1-0.2-0.1-0.6,0.2-0.7c0.1,0,0.2-0.1,0.2-0.1C715.3,654.5,715.7,654.6,716.1,654.9z"/>
                                                                    <path d="M947.2,656.4c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2l-13.4,2c-0.2,0-0.4-0.1-0.5-0.3
		c0-0.1,0-0.2,0.1-0.3l1.8-2.4c0.2-0.3,0.6-0.5,1-0.4L947.2,656.4z"/>
                                                                    <path d="M931.7,660.6l22.5-3.3c0.4-0.1,0.7,0.2,0.7,0.5l0,0c0.1,0.4-0.2,0.7-0.5,0.7l-22.5,3.3
		c-0.4,0.1-0.7-0.2-0.7-0.5l0,0C931.1,661,931.4,660.7,931.7,660.6z"/>
                                                                    <path d="M929.2,664.3l40.2-6.1c0.3,0,0.5,0.1,0.5,0.4c0,0.1,0,0.3-0.1,0.4l-5.8,7.9c-0.1,0.1-0.2,0.2-0.3,0.2
		l-40.3,6.3c-0.3,0-0.5-0.1-0.5-0.4c0-0.1,0-0.3,0.1-0.4l6-8C929,664.4,929.1,664.3,929.2,664.3z"/>
                                                                    <path d="M764.8,660.3l-17.7,21.8c-0.1,0.1-0.2,0.2-0.3,0.2l-4.4,0.7c-0.3,0-0.5-0.1-0.5-0.4c0-0.1,0-0.3,0.1-0.4
		l18.6-22.8c0.1-0.1,0.3-0.2,0.4-0.2l3.5,0.4c0.3,0,0.4,0.3,0.4,0.5C764.9,660.2,764.9,660.3,764.8,660.3z"/>
                                                                    <path d="M985.3,661c0-0.3,0.3-0.6,0.6-0.5c0.2,0,0.4,0.2,0.5,0.4c0.2,0.7,0,1.2-0.4,1.6c-0.2,0.2-0.5,0.2-0.7,0
		c-0.1-0.1-0.1-0.3-0.1-0.4L985.3,661z"/>
                                                                    <path d="M1060.6,667.5c0.1-0.2,0.2-0.2,0.3-0.2c0.3,0.1,0.5,0.4,0.4,0.9c-2,12.3-4,24.6-5.9,36.9
		c-0.3,2.3-1.6,3.7-3.4,4.8c-0.2,0.1-0.4,0.1-0.5-0.1c0-0.1-0.1-0.2-0.1-0.3c1.1-7.6,2.3-15.2,3.5-22.5
		C1056.1,679.9,1058.1,674,1060.6,667.5z"/>
                                                                    <path d="M913.8,671.9c0-0.3,0.3-0.6,0.6-0.6c0.2,0,0.4,0.2,0.5,0.4c0.3,0.8,0.2,1.5-0.3,2.1c-0.2,0.2-0.5,0.2-0.7,0.1
		c-0.1-0.1-0.2-0.2-0.2-0.4L913.8,671.9z"/>
                                                                    <path d="M929.3,674.1l4.5-0.8c0.4-0.1,0.7,0.2,0.8,0.5c0,0.1,0,0.1,0,0.2l-3.6,30.7c0,0.3-0.3,0.5-0.6,0.6l-4.7,0.7
		c-0.4,0.1-0.7-0.2-0.7-0.6c0-0.1,0-0.1,0-0.2l3.8-30.5C928.8,674.4,929,674.2,929.3,674.1z"/>
                                                                    <path d="M936.6,673.3l0.4,0c0.2,0,0.4,0.2,0.4,0.5l-3.5,30.5c0,0.2-0.2,0.4-0.5,0.4l-0.4,0c-0.2,0-0.4-0.2-0.4-0.5
		l3.5-30.5C936.1,673.5,936.4,673.3,936.6,673.3z"/>
                                                                    <path d="M722.9,697.4l10.7-13.1c0.1-0.1,0.2-0.2,0.4-0.2l12.1,1.5c0.2,0,0.4,0.2,0.4,0.5c0,0.1,0,0.2-0.1,0.2
		l-10.8,13.2c-0.1,0.1-0.2,0.2-0.4,0.2l-12-1.6c-0.2,0-0.4-0.2-0.4-0.5C722.8,697.5,722.9,697.5,722.9,697.4z"/>
                                                                    <path d="M679.2,691.3l39.1-5.7c0.3,0,0.6,0.2,0.7,0.5c0,0.2,0,0.3-0.1,0.5l-8.1,9.7c-0.1,0.2-0.3,0.2-0.5,0.2l-31-4
		c-0.3,0-0.6-0.3-0.5-0.7C678.7,691.5,678.9,691.3,679.2,691.3z"/>
                                                                    <path d="M1034.5,779.2c-0.4-0.1-0.7-0.4-0.6-0.8c2.6-17.1,5.2-34.1,7.8-51.1c1.9-12.8,5.8-24,10.4-35.6
		c0-0.1,0.2-0.2,0.3-0.1c0.1,0,0.2,0.2,0.2,0.3c-3.9,25-7.8,50.1-11.6,75.1c-0.2,1.1-0.6,2.3-1.4,3.6c-1.5,2.5-2.3,5.3-2.2,8.4
		c0,0.4-0.3,0.6-0.6,0.6c0,0-0.1,0-0.1,0L1034.5,779.2z"/>
                                                                    <path d="M679.7,701.3c2.5-3.6,1.3-7.7-3.5-5.3c-0.2,0.1-0.5,0-0.6-0.2c-0.1-0.1-0.1-0.2,0-0.4c0.2-0.5,0.4-0.7,0.8-0.7
		c1.8-0.1,3.3-0.5,5.2-0.2c0.4,0.1,0.7,0.3,0.7,0.8l0.3,6c0,0.5-0.2,0.7-0.7,0.7l-1.9,0C679.5,702,679.3,701.8,679.7,701.3z"/>
                                                                    <path d="M684,699.2l-0.2-3.7c0-0.3,0.2-0.6,0.6-0.6c0,0,0.1,0,0.1,0l24,2.9c0.3,0,0.6,0.3,0.5,0.7
		c0,0.1-0.1,0.2-0.1,0.3l-3,3.6c-0.1,0.2-0.3,0.2-0.5,0.2l-20.8-2.8C684.2,699.7,684,699.5,684,699.2z"/>
                                                                    <path d="M679.3,697.5c0.3,0.5,0.3,1.1,0.1,1.7c-0.1,0.4-0.5,0.6-1,0.5c-0.2,0-0.3-0.1-0.4-0.3
		c-0.6-0.7-0.4-1.4,0.5-2.1c0.1-0.1,0.2-0.1,0.3,0C679.1,697.4,679.3,697.5,679.3,697.5z"/>
                                                                    <path d="M717.9,703.5l3.1-3.8c0.1-0.1,0.2-0.2,0.3-0.1l12.2,1.6c0.2,0,0.4,0.2,0.3,0.4c0,0.1,0,0.1-0.1,0.2l-3,3.8
		c-0.1,0.1-0.2,0.2-0.4,0.1l-12.4-1.5c-0.2,0-0.4-0.2-0.3-0.4C717.8,703.7,717.9,703.6,717.9,703.5z"/>
                                                                    <path d="M677.5,701.5c0.2,0,0.3,0.1,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.6,0.4-1.1,0.4-1.7,0c-0.3-0.2-0.4-0.6-0.1-1
		c0.1-0.1,0.2-0.2,0.4-0.3c0.4-0.1,0.8,0.1,1.2,0.4C677.3,701.5,677.4,701.5,677.5,701.5z"/>
                                                                    <path d="M912.5,706l-15.6,2.9c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.3l3.2-4.2c0.1-0.1,0.2-0.1,0.3-0.1l12.4,1.3
		c0.2,0,0.3,0.1,0.4,0.3C912.8,705.8,912.7,706,912.5,706z"/>
                                                                    <path d="M726.2,707c0.5,0.1,0.6,0.3,0.3,0.8c-5.2,6.4-10.5,12.9-15.8,19.5c-1.2,1.6-3.3,2.1-6.1,1.7
		c-0.3,0-0.4-0.2-0.2-0.5l17.6-21.7c0.2-0.2,0.4-0.3,0.7-0.3L726.2,707z"/>
                                                                    <path d="M894.9,710.8l23.8-4.2c0.3-0.1,0.7,0.2,0.7,0.5l0,0c0.1,0.3-0.2,0.7-0.5,0.7l-23.8,4.2
		c-0.3,0.1-0.7-0.2-0.7-0.5l0,0C894.3,711.1,894.5,710.8,894.9,710.8z"/>
                                                                    <path d="M892.3,714.5l42.1-7.6c0.3,0,0.5,0.1,0.6,0.4c0,0.1,0,0.3-0.1,0.4l-6.1,8.3c-0.1,0.1-0.2,0.2-0.3,0.2L886,724
		c-0.3,0-0.5-0.1-0.6-0.4c0-0.1,0-0.3,0.1-0.4l6.4-8.5C892,714.6,892.1,714.5,892.3,714.5z"/>
                                                                    <path d="M951,709.5c0.1-0.3,0.3-0.4,0.6-0.4c0.2,0,0.3,0.2,0.4,0.4c0.2,0.8,0.1,1.5-0.3,2.1c-0.1,0.3-0.5,0.3-0.7,0.2
		c-0.2-0.1-0.3-0.3-0.3-0.6L951,709.5z"/>
                                                                    <path d="M589.1,715.3l7.3,10.7c0.2,0.3,0.2,0.6,0,0.8L582,743.7c-0.3,0.3-0.7,0.3-1,0.1c0,0-0.1-0.1-0.1-0.1l-7.6-10.7
		c-0.2-0.3-0.2-0.6,0-0.8l14.6-16.8c0.3-0.3,0.7-0.3,1-0.1C589,715.3,589,715.3,589.1,715.3z"/>
                                                                    <path d="M923.6,718.8l2.9-0.7c0.2,0,0.4,0.1,0.4,0.3c0,0.1,0,0.2-0.1,0.3l-3.3,4.5c-0.1,0.2-0.3,0.2-0.5,0.1
		c-0.1-0.1-0.2-0.2-0.1-0.3l0.4-3.8C923.4,719,923.5,718.9,923.6,718.8z"/>
                                                                    <path d="M875.5,724.9l0.3-1.7c0.1-0.3,0.4-0.5,0.7-0.5c0.2,0,0.3,0.1,0.4,0.3c0.6,1,0.5,1.8-0.3,2.5
		c-0.3,0.3-0.7,0.2-0.9,0C875.5,725.3,875.4,725.1,875.5,724.9z"/>
                                                                    <path d="M1049.9,723.3l1.8,0.3c0.4,0.1,0.6,0.4,0.6,0.8l-6.4,43.1c-0.1,0.4-0.4,0.6-0.8,0.6l-1.8-0.3
		c-0.4-0.1-0.6-0.4-0.6-0.8l6.4-43.1C1049.2,723.5,1049.6,723.2,1049.9,723.3z"/>
                                                                    <path d="M682.5,746.9l13-15.9c0.1-0.1,0.2-0.1,0.3-0.1l12.5,1.6c0.2,0,0.3,0.2,0.3,0.4c0,0.1,0,0.1-0.1,0.2l-13,16.2
		c-0.1,0.1-0.2,0.1-0.3,0.1l-12.5-1.8c-0.2,0-0.3-0.2-0.3-0.4C682.5,747,682.5,747,682.5,746.9z"/>
                                                                    <path d="M639,740.7c-0.3,0-0.6-0.3-0.7-0.5c-0.2-0.4,0-0.7,0.4-0.8c13.9-2.3,27.8-4.6,41.7-7c0.2,0,0.4,0.1,0.4,0.3
		c0,0.1,0,0.2-0.1,0.3l-10.1,12.2c-0.2,0.2-0.5,0.3-0.8,0.3L639,740.7z"/>
                                                                    <path d="M535.6,849.7c-0.2,0-0.4-0.1-0.5-0.3l-79.4-105.8c-0.2-0.3-0.2-0.6,0-0.9l5.5-5.9c0.3-0.3,0.6-0.3,0.8,0
		l79.1,105.7c0.3,0.4,0.3,1-0.1,1.4c-1.4,1.6-2.8,3.3-4.3,5.2C536.5,849.5,536.1,849.7,535.6,849.7z"/>
                                                                    <path d="M499.2,911.3c1.9,0.9,3.9,0.8,5.8-0.2c0.3-0.1,0.6,0,0.8,0.3c0.1,0.2,0.1,0.4-0.1,0.6l-26.1,31.4
		c-0.1,0.1-0.3,0.2-0.5,0c-0.1-0.1-0.1-0.2-0.1-0.3c1.7-7.9-7.6-10.5-11-5c-2.9,4.7-2.2,8.3,2.4,10.9c0.5,0.3,0.5,0.5-0.1,0.7
		l-12.9,3.3c-0.2,0.1-0.4,0.2-0.4,0.4l-0.7,2.2c-0.1,0.3-0.3,0.5-0.6,0.6l-4.7,1c-0.4,0.1-0.6,0.3-0.6,0.7l0.2,7.8
		c0,0.2-0.2,0.4-0.4,0.4c-0.1,0-0.3-0.1-0.3-0.1l-3.3-3.8c-0.3-0.3-0.3-0.7,0-1l184-219.2c0.2-0.2,0.4-0.2,0.6,0
		c0.1,0.1,0.1,0.2,0.1,0.3l0.2,9.5c0,0.4,0.2,0.7,0.6,0.8c1.7,0.6,3.4,0.5,5.1-0.2c0.3-0.1,0.5,0,0.6,0.3c0.1,0.2,0,0.4-0.1,0.5
		l-26.2,31.5c-0.2,0.3-0.5,0.5-0.9,0.5c-4.9,1.1-9.8,2-14.6,2.9c-1.2,0.2-1.7,1-1.5,2.4c0,0.3-0.2,0.6-0.5,0.6c0,0,0,0,0,0l-4.5,0.7
		c-0.3,0.1-0.6,0.3-0.6,0.7l0,9.7c0,0.4,0.2,0.8,0.6,0.9c1.8,0.8,3.6,0.8,5.5-0.1c0.2-0.1,0.5,0,0.6,0.3c0.1,0.2,0,0.4-0.1,0.5
		l-27.4,32.9c-0.2,0.3-0.5,0.5-0.9,0.6c-4.8,1.1-9.6,2.2-14.5,3.2c-1.7,0.3-2.5,0.9-2.4,2.6c0,0.3-0.2,0.6-0.5,0.7l-4.6,0.9
		c-0.3,0.1-0.6,0.3-0.6,0.7l0.2,10.1c0,0.3,0.2,0.6,0.5,0.7c1.8,0.8,3.6,0.7,5.4-0.1c0.3-0.1,0.6,0,0.7,0.3c0.1,0.2,0.1,0.4-0.1,0.6
		l-28,33.7c-0.5,0.6-1.2,1-1.9,1.2c-5.2,1.3-10.3,2.5-15.3,3.6c-1.2,0.3-1.8,1.1-1.8,2.7c0,0.3-0.2,0.5-0.5,0.6l-4.6,1
		c-0.4,0.1-0.6,0.4-0.6,0.8l-0.1,10.3C498.7,910.9,498.9,911.1,499.2,911.3z"/>
                                                                    <path d="M637.4,743.6l-1.6,1c-0.4,0.2-0.6,0.1-0.7-0.3c-0.2-0.7,0-1.1,0.5-1.2c1.8-0.3,3.7-0.4,5.6-0.3
		c0.6,0,1,0.5,1.1,1.1l0.2,5.8c0,0.4-0.2,0.6-0.6,0.6l-2.3,0c-0.7,0-0.8-0.2-0.2-0.7c1.3-1,1.8-2.5,1.5-4.4
		c-0.2-1.2-1.4-2.1-2.6-1.9C637.9,743.3,637.7,743.4,637.4,743.6z"/>
                                                                    <path d="M643.8,747.5v-3.7c0-0.3,0.2-0.5,0.5-0.5c0,0,0.1,0,0.1,0l23.8,3.7c0.3,0,0.5,0.3,0.4,0.6
		c0,0.1-0.1,0.2-0.1,0.3l-2.8,3.4c-0.1,0.1-0.3,0.2-0.5,0.2l-21-3.4C644,748,643.8,747.8,643.8,747.5z"/>

                                                                    <ellipse transform="matrix(0.7934 -0.6088 0.6088 0.7934 -361.9612 433.4767)" cx="457.5" cy="749.9" rx="0.7" ry="6.1"/>
                                                                    <path d="M637.9,746c0.3-0.3,0.8-0.3,1.1,0c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.7,0.1,1.3-0.4,1.6c-0.3,0.2-0.8,0.1-1-0.2
		c0,0,0,0,0-0.1C637.3,747,637.4,746.4,637.9,746z"/>
                                                                    <path d="M677.8,752.7l2.9-3.6c0.1-0.1,0.2-0.2,0.4-0.2l12.4,1.9c0.2,0,0.4,0.3,0.4,0.5c0,0.1,0,0.1-0.1,0.2l-2.9,3.6
		c-0.1,0.1-0.2,0.2-0.4,0.2l-12.4-1.9c-0.2,0-0.4-0.3-0.3-0.5C677.8,752.8,677.8,752.7,677.8,752.7z"/>
                                                                    <path d="M637.8,750.1c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c-0.8,0.7-1.7,0.9-2.5,0.5c-0.4-0.2-0.6-0.6-0.4-1
		c0.2-0.4,0.7-0.6,1.1-0.4L637.8,750.1z"/>
                                                                    <path d="M876.5,757.4l-17.8,3.6c-0.2,0-0.4-0.1-0.5-0.3c0-0.1,0-0.2,0.1-0.3l4-5.4c0.1-0.1,0.2-0.2,0.4-0.1l13.7,1.8
		c0.2,0,0.4,0.2,0.3,0.4C876.8,757.2,876.7,757.4,876.5,757.4z"/>
                                                                    <path d="M686.3,757.5L671.2,776c-0.3,0.3-0.7,0.4-1,0.1c-0.1,0-0.1-0.1-0.1-0.1l-1.6-2.2c-0.2-0.3-0.2-0.6,0-0.9
		l13.5-16.7c0.2-0.2,0.4-0.3,0.6-0.3l3.2,0.3c0.4,0,0.7,0.4,0.7,0.8C686.4,757.2,686.4,757.3,686.3,757.5z"/>
                                                                    <path d="M853.7,767l44.4-9.3c0.2,0,0.4,0.1,0.5,0.3c0,0.1,0,0.2-0.1,0.3l-6.5,8.9c-0.1,0.1-0.1,0.1-0.2,0.1l-44.9,9.6
		c-0.2,0-0.4-0.1-0.5-0.3c0-0.1,0-0.2,0.1-0.3l7-9.2C853.5,767.1,853.6,767,853.7,767z"/>
                                                                    <path d="M856.5,763.1l24.9-5.2c0.3-0.1,0.7,0.1,0.7,0.5l0,0.1c0.1,0.3-0.1,0.7-0.5,0.7l-24.9,5.2
		c-0.3,0.1-0.7-0.1-0.7-0.5l0-0.1C855.9,763.5,856.1,763.1,856.5,763.1z"/>

                                                                    <ellipse transform="matrix(0.3469 -0.9379 0.9379 0.3469 -116.4568 1355.5522)" cx="915.2" cy="761.4" rx="1.2" ry="0.9"/>
                                                                    <path d="M1114.4,767.2c-4.2-0.4-8.2-1.6-12.6-0.6c-0.3,0.1-0.6-0.1-0.7-0.4c-0.3-0.9,0-1.4,0.9-1.7
		c3.3-1.2,6.6-1.6,10.1-1.1c41.7,5.4,83.2,10.9,124.7,16.5c0.5,0.1,0.8,0.5,0.8,1c0,0,0,0,0,0c-0.6,4-1.2,8.2-1.7,12.5
		c-0.1,1.2-0.8,2.1-2.1,2.8c-0.3,0.2-0.6,0.4-0.7,0.7c-1.7,2.7-2.5,5.7-2.6,9c0,1-0.6,1.5-1.6,1.3L1116,790.8
		c-0.6-0.1-0.7-0.4-0.3-0.8l0.6-0.8c0.2-0.3,0.4-0.6,0.4-1l0.1-18.4C1116.8,768.4,1115.8,767.3,1114.4,767.2z"/>
                                                                    <path d="M1016.7,814.3c-1.9,0.5-5.4-0.1-7.3-0.6c-0.4-0.1-0.5-0.3-0.4-0.7l8.4-23.3c0.1-0.4,0.4-0.6,0.7-0.7
		c3.2-1,6.4-1.8,9.6-2.4c5.1-0.9,9.6,1.7,14.4,0.3c2.3-0.7,4.6-1.2,6.8-1.7c0.7-0.1,1.2-0.8,1.2-1.5v-2c0-0.4,0.2-0.7,0.7-0.8
		c17-4.1,34-8.3,50.9-12.3c4.4-1.1,8.3-0.2,12.6,0.4c0.3,0,0.5,0.3,0.6,0.6v18.2c0,0.5-0.3,0.9-0.8,1
		C1081.7,797.3,1049.2,805.8,1016.7,814.3z"/>
                                                                    <path d="M664,770.3l78.4,115.8c0.1,0.2,0.1,0.4,0,0.6l-5.2,6.9c-0.2,0.2-0.5,0.3-0.8,0.1c0,0-0.1-0.1-0.1-0.1
		l-79-115.7c-0.1-0.2-0.1-0.5,0-0.7l5.7-7c0.2-0.2,0.5-0.3,0.8-0.1C663.9,770.2,664,770.3,664,770.3z"/>
                                                                    <path d="M1046.2,781.1l2.2-3c0,0,0.1-0.1,0.1,0c0.1,0,0.2,0.1,0.2,0.1c0,0,0.1,0.1,0,0.2c-0.3,1.6-0.4,3.1-0.2,4.8
		c0,0.3-0.2,0.6-0.5,0.6l-6.8,1.7c-0.7,0.2-1-0.1-1-0.8c0.1-4.5-0.6-12.5,4.5-14.4c0.3-0.1,0.6-0.1,0.9,0c1.4,0.7,2.2,1.8,2.4,3.4
		c0,0.2-0.2,0.5-0.4,0.5c-0.1,0-0.2,0-0.3-0.1c-4.8-3.6-8.4,5.6-2.1,7.5C1045.6,781.5,1046,781.4,1046.2,781.1z"/>

                                                                    <ellipse transform="matrix(0.1271 -0.9919 0.9919 0.1271 141.9511 1715.4554)" cx="1045.6" cy="777.1" rx="2" ry="0.9"/>

                                                                    <ellipse transform="matrix(0.1668 -0.986 0.986 0.1668 -69.9379 1473.0996)" cx="836.6" cy="777.9" rx="1.4" ry="0.9"/>
                                                                    <path d="M655.5,779.9L655.5,779.9c0.5-0.3,1-0.2,1.3,0.2l6.3,9.2c0.3,0.4,0.2,1-0.2,1.3l-0.1,0c-0.4,0.3-1,0.2-1.3-0.2
		l-6.3-9.2C655,780.7,655.1,780.2,655.5,779.9z"/>
                                                                    <path d="M1034.9,781.3c0.2,0,0.3,0.2,0.3,0.4c0,0.1-0.1,0.2-0.3,0.3l-2.3,0.6c-0.7,0.2-1-0.1-0.7-0.8l0.3-0.6
		c0.2-0.4,0.5-0.6,0.9-0.4L1034.9,781.3z"/>
                                                                    <path d="M597.4,791.3c-0.5-0.1-0.9-0.3-1.2-0.7c-0.2-0.3-0.1-0.6,0.2-0.8c0.1,0,0.1-0.1,0.2-0.1l43.5-8.3
		c0.2,0,0.5,0.1,0.5,0.3c0,0.1,0,0.3-0.1,0.4l-11.8,14.4c-0.2,0.2-0.5,0.4-0.8,0.3C617.8,794.9,607.6,793.1,597.4,791.3z"/>
                                                                    <path d="M1300.7,792.5C1300.7,792.5,1300.7,792.5,1300.7,792.5l-47.4,14.5c-0.4,0.1-0.8-0.1-0.9-0.5c0-0.1,0-0.2,0-0.3
		c0.6-3.5-0.2-6.5-2.2-9.1c-0.2-0.3-0.3-0.5-0.2-0.9l1.8-13.3c0.1-0.6,0.7-1.1,1.3-1c0,0,0,0,0,0l61.7,8.2c0.3,0,0.5,0.3,0.5,0.6
		c0,0,0,0.1,0,0.1l-1,3.1c-0.1,0.3-0.3,0.4-0.5,0.3C1309.3,793.5,1305,792.9,1300.7,792.5z"/>
                                                                    <path d="M640.7,798.2l12.8-15.6c0.2-0.2,0.6-0.3,0.8-0.1c0,0,0.1,0.1,0.1,0.1l6.2,9.2c0.1,0.2,0.1,0.5,0,0.7l-6.9,8.5
		c-0.1,0.2-0.4,0.2-0.6,0.2l-12-2.1c-0.3-0.1-0.5-0.4-0.5-0.7C640.6,798.4,640.6,798.3,640.7,798.2z"/>
                                                                    <path d="M595.1,794.2l-1.5,0.9c-0.3,0.2-0.6,0.1-0.8-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.6,0.3-1,1.1-1.2
		c1.7-0.3,3.4-0.4,5-0.5c0.7,0,1.3,0.5,1.3,1.2c0,0,0,0,0,0l0.2,5.8c0,0.3-0.2,0.6-0.5,0.7l-2.5,0.5c-0.2,0-0.4-0.1-0.5-0.3
		c0-0.2,0-0.3,0.2-0.4c1.4-1,2-2.5,1.8-4.4c-0.2-1.3-1.4-2.3-2.8-2.1C595.7,793.9,595.4,794,595.1,794.2z"/>
                                                                    <path d="M601.8,797.9v-3.5c0-0.3,0.3-0.6,0.6-0.6c0,0,0.1,0,0.1,0l23.8,4.3c0.3,0.1,0.5,0.4,0.5,0.7
		c0,0.1-0.1,0.2-0.1,0.3l-2.6,3.2c-0.1,0.2-0.4,0.2-0.6,0.2l-21.2-3.9C601.9,798.4,601.7,798.2,601.8,797.9z"/>
                                                                    <path d="M976.1,807.3c-7.2,1.1-14.6-2.2-21.8-2.5c-0.1,0-0.2-0.1-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2l42.2-10.3
		c0.3-0.1,0.5,0.1,0.6,0.3c0,0.1,0,0.2,0,0.3l-4,11.1c-0.1,0.3-0.4,0.6-0.7,0.7c-1.1,0.3-2,0.4-2.6,0.4
		C985.2,806.4,980.6,806.6,976.1,807.3z"/>
                                                                    <path d="M891.2,920.3c-0.4-0.1-0.4-0.2-0.1-0.5c0.1-0.1,0.4-0.2,0.7-0.3c112.2-34.3,224.4-68.7,336.5-103.1
		c5.7-1.7,11.2,2.1,16.2,0.5c2.5-0.8,5-1.6,7.5-2.4c0.3-0.1,0.5-0.3,0.5-0.6l0.2-4.3c0-0.4,0.2-0.7,0.7-0.8
		c14.7-4.5,29.5-9,44.1-13.5c4.9-1.5,9.7-0.4,14.6,0.6c0.7,0.1,1.1,0.8,1,1.4c-0.7,4.7-1.2,9.3-1.4,14c-0.1,1.2,0.4,3.7,0.1,5.4
		c-0.1,0.4-0.3,0.7-0.7,0.8L895.4,955c-0.4,0.1-0.7,0-0.9-0.3c-1.4-2.5-2.4-4.6-1.4-7.5c3.2-9.8,5.2-16.1,5.9-18.8
		C900.8,922.2,895.8,921.1,891.2,920.3z"/>

                                                                    <ellipse transform="matrix(0.3697 -0.9291 0.9291 0.3697 -365.1018 1056.5593)" cx="596.2" cy="797.4" rx="1.4" ry="1"/>
                                                                    <path d="M855.7,858.1c-0.2,0-0.3-0.2-0.1-0.4L897,799c0.7-1,1.9-1.5,3.1-1.3c35.8,5.8,71,11.4,105.7,17
		c8.7,1.4,15.4,3.4,12.5,13.9c-0.1,0.5-0.1,0.9,0.1,1.2c0.2,0.2,0.3,0.5,0.3,0.8l0.2,35.7c0,0.8-0.5,1.5-1.2,1.7
		c-10.2,3.1-20,6-29.3,8.8c-6,1.8-10.8,2.7-14.4,2.9c-5.9,0.2-11.1-0.9-16.9-2C923.5,871.1,889.8,864.6,855.7,858.1z M907.8,850.7
		c-1.2,3.7,0.6,8,4.5,9.4c0.2,0.1,0.4,0.1,0.6,0l5.1-2c0.3-0.1,0.5-0.1,0.7,0c1.6,0.8,3.1,0.7,4.6-0.4c0.6-0.4,1.4-0.3,1.8,0.2
		c0.1,0.2,0.2,0.3,0.2,0.5c1.1,5.6,5.9,5.3,9.8,2.8c0.3-0.2,0.6-0.2,1,0l1.1,0.7c0.3,0.2,0.6,0.2,0.9,0.1l4.5-1.7
		c0.2-0.1,0.5-0.3,0.6-0.5c2.6-5.4,1.3-9.6-3.7-12.5c-0.3-0.2-0.6-0.2-0.9-0.2c-2.3,0.5-4.4,1.4-6.3,2.7c-0.3,0.2-0.7,0.3-1,0.3
		c-1.2-0.1-2.4,0.2-3.6,0.8c-0.3,0.2-0.7,0-0.9-0.3c0,0,0-0.1,0-0.1l-0.8-3c-0.1-0.4-0.3-0.7-0.6-0.9l-3.3-2.4
		c-0.3-0.2-0.6-0.3-0.9-0.2c-2.2,0.5-4.2,1.4-6,2.5c-0.3,0.2-0.7,0.3-1,0.3C911.4,847.1,908.7,847.8,907.8,850.7z"/>
                                                                    <path d="M1248,801.7c-5.8,0.3-2.5,13.6,2.2,7.4c0.1-0.2,0.4-0.2,0.6-0.1c0.1,0.1,0.2,0.2,0.2,0.3l0,2.9
		c0,0.5-0.3,0.9-0.8,1l-5.9,1.8c-0.4,0.1-0.6,0-0.7-0.4c-0.6-5.8-0.6-11.8,3-16.1c0.3-0.3,0.7-0.5,1.2-0.3c1.7,0.5,2.7,1.8,2.8,3.7
		c0,0.3-0.2,0.6-0.5,0.6c0,0-0.1,0-0.1,0c-0.4-0.1-0.8-0.2-1.1-0.5C1248.7,801.8,1248.4,801.7,1248,801.7z"/>
                                                                    <path d="M594.8,800.4c0.3-0.1,0.6,0,0.9,0.2c0.4,0.2,0.4,0.5,0,0.7c-0.5,0.3-1,0.5-1.6,0.5c-1.1,0-1.6-1-1.2-1.9
		c0.1-0.3,0.3-0.4,0.6-0.2C594,800,594.4,800.2,594.8,800.4z"/>
                                                                    <path d="M636,804.1l2.7-3.4c0.1-0.1,0.3-0.2,0.4-0.2l12.5,2.3c0.2,0,0.4,0.3,0.3,0.5c0,0.1,0,0.1-0.1,0.2l-2.9,3.4
		c-0.1,0.1-0.3,0.2-0.4,0.1l-12.3-2.3c-0.2,0-0.4-0.3-0.3-0.5C635.9,804.2,636,804.1,636,804.1z"/>

                                                                    <ellipse transform="matrix(9.758288e-02 -0.9952 0.9952 9.758288e-02 324.4464 1970.1145)" cx="1248.6" cy="806.2" rx="2.2" ry="0.9"/>
                                                                    <path d="M865,805.2c-2,3.3-8.8,4-12.3,4.8c-0.2,0-0.4-0.1-0.5-0.3c0-0.1,0-0.3,0.1-0.4c2.9-3.2,8.4-3.9,12.3-4.6
		C865.1,804.6,865.2,804.8,865,805.2z"/>
                                                                    <path d="M641,807.4l3.4,0.6c0.3,0.1,0.5,0.4,0.5,0.7c0,0.1-0.1,0.2-0.1,0.3c-4,4.9-8.1,9.8-12.1,14.8
		c-2.2,2.7-4.5,3.6-7.8,3.6c-0.5,0-0.6-0.2-0.3-0.5l15.4-19.1C640.3,807.5,640.6,807.4,641,807.4z"/>
                                                                    <path d="M838.1,811.1l-19.5,4.5c-0.2,0.1-0.5-0.1-0.5-0.3c0-0.1,0-0.3,0.1-0.4l5.3-6.9c0.1-0.1,0.3-0.2,0.4-0.2
		l14.3,2.4c0.2,0,0.4,0.3,0.4,0.5C838.4,811,838.3,811.1,838.1,811.1z"/>
                                                                    <path d="M647,809L647,809c0.2,0.2,0.2,0.4,0.1,0.6l-12.6,15.5c-0.1,0.2-0.4,0.2-0.5,0.1l0,0c-0.2-0.1-0.2-0.4-0.1-0.5
		l12.6-15.5C646.6,808.9,646.8,808.9,647,809z"/>
                                                                    <path d="M1235.8,810.2h0.8c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.1,0.3-0.2,0.4l-1.9,0.9c-0.5,0.2-1,0-1.2-0.4
		c-0.1-0.2-0.1-0.4,0-0.6c0.2-0.8,0.7-1,1.5-0.8C1235.4,810.2,1235.6,810.3,1235.8,810.2z"/>
                                                                    <path d="M813.2,822l46.7-11.1c0.2,0,0.4,0.1,0.5,0.3c0,0.1,0,0.2-0.1,0.3l-6.9,9.4c-0.1,0.1-0.1,0.1-0.2,0.2l-47,11.3
		c-0.2,0.1-0.4-0.1-0.5-0.3c0-0.1,0-0.2,0.1-0.3l7.2-9.7C813,822.1,813.1,822,813.2,822z"/>
                                                                    <path d="M816,817.9l26.3-6.2c0.4-0.1,0.7,0.1,0.8,0.5l0,0c0.1,0.4-0.1,0.7-0.5,0.8l-26.3,6.2c-0.4,0.1-0.7-0.1-0.8-0.5
		l0,0C815.4,818.3,815.7,818,816,817.9z"/>
                                                                    <path d="M1237.9,812.4c0.4-0.3,1-0.5,1.6-0.5c0.4,0,0.6,0.3,0.6,0.7c0,0.4-0.3,0.6-0.7,0.6l0,0h-1.3
		c-0.2,0-0.5-0.2-0.5-0.4C1237.7,812.7,1237.8,812.5,1237.9,812.4z"/>

                                                                    <ellipse transform="matrix(0.1994 -0.9799 0.9799 0.1994 -95.7695 1512.0441)" cx="877.4" cy="814.6" rx="1.4" ry="0.9"/>
                                                                    <path d="M497.5,820.9l8.1,10.8c0.2,0.3,0.2,0.6,0,0.9l-15.9,18.7c-0.3,0.3-0.7,0.3-1,0.1c0,0-0.1-0.1-0.1-0.1
		l-8.4-10.8c-0.2-0.3-0.2-0.7,0-0.9l16.2-18.7c0.3-0.3,0.7-0.3,1-0.1C497.4,820.8,497.5,820.8,497.5,820.9z"/>
                                                                    <path d="M850.8,823.5c0.3-0.1,0.5,0.1,0.6,0.4c0,0.2,0,0.3-0.1,0.5l-0.4,0.4c-0.4,0.4-0.6,0.3-0.7-0.2
		C850.1,823.9,850.3,823.6,850.8,823.5z"/>
                                                                    <path d="M626.2,830.4c-0.7-0.4-0.6-0.6,0.1-0.8l4.1-0.8c0.8-0.1,1,0.1,0.4,0.7C629.6,831.2,628.1,831.4,626.2,830.4z"
                                                                    />
                                                                    <path d="M627.8,832.3c0.2,0,0.3,0.2,0.3,0.4c0,0.1,0,0.1-0.1,0.2l-12,14.8c-0.1,0.1-0.3,0.2-0.4,0.1
		c-0.1-0.1-0.1-0.2-0.1-0.3l1.2-16.1c0-0.5,0.5-1,1.1-0.9c0,0,0.1,0,0.1,0L627.8,832.3z"/>
                                                                    <path d="M552.8,843.5c-0.3-0.1-0.5-0.4-0.5-0.7c0-0.1,0.1-0.2,0.2-0.3c0.8-0.8,2.8-1,3.6-1.1
		c16.5-3.4,32.9-6.9,49.3-10.5c0.3-0.1,0.7,0.1,0.7,0.5c0,0.1,0,0.1,0,0.2l-1.7,22.2c0,0.4-0.4,0.7-0.8,0.7c0,0-0.1,0-0.1,0
		L552.8,843.5z"/>
                                                                    <path d="M803.9,865.9l-19.4-3.8c-0.3-0.1-0.5-0.3-0.4-0.6c0-0.1,0-0.1,0.1-0.2l13.8-18.8c0.1-0.1,0.2-0.2,0.3-0.2
		l47-11.5c0.3-0.1,0.6,0.1,0.6,0.4c0,0.1,0,0.3-0.1,0.4l-22.3,31c-0.1,0.1-0.2,0.2-0.3,0.2l-18,4.8c-0.3,0.1-0.5-0.1-0.6-0.3
		l-0.4-1.1C804.2,866,804.1,865.9,803.9,865.9z"/>
                                                                    <ellipse transform="matrix(0.2284 -0.9736 0.9736 0.2284 -198.8482 1417.7761)" cx="795" cy="834.3" rx="1.5" ry="1"/>
                                                                    <path d="M1021.1,864.5c-0.4-0.1-0.6-0.4-0.6-0.7v-20.6c0-0.4,0.4-0.8,0.8-0.8c0,0,0.1,0,0.1,0l73.2,12.4
		c0.2,0,0.4,0.3,0.4,0.5c0,0.2-0.2,0.3-0.3,0.4c-14.4,4.4-29,8.8-43.6,13.3c-1.4,0.4-2.7,0.5-4.1,0.2
		C1038.4,867.5,1029.8,866,1021.1,864.5z"/>
                                                                    <path d="M552,846.5c-1,0.2-1.8,0.7-2.5,1.6c-0.2,0.3-0.6,0.3-0.9,0.1c-0.2-0.1-0.3-0.4-0.2-0.6l0.2-0.6
		c0.1-0.4,0.4-0.7,0.8-0.9c2.2-0.7,4.3-0.8,6.5-0.2c0.3,0.1,0.5,0.4,0.5,0.7l0.2,6c0,0.3-0.2,0.6-0.5,0.6l-2.7,0.5
		c-0.2,0-0.5-0.1-0.5-0.4c0-0.2,0-0.3,0.2-0.4c1.4-1.1,2-2.6,1.6-4.6C554.5,847.1,553.3,846.3,552,846.5z"/>
                                                                    <path d="M558.2,850.5v-3.3c0-0.3,0.3-0.6,0.6-0.6c0,0,0.1,0,0.1,0c14.1,3,28.3,6,42.4,9.1c1.3,0.3,2.2,0.8,2.6,1.5
		c0.5,0.9,0.3,2-0.5,3.2c-0.2,0.3-0.4,0.3-0.7,0.3l-44-9.5C558.4,851.1,558.2,850.9,558.2,850.5z"/>
                                                                    <path d="M915.7,848.8c6.8-2.9,9.8,5.8,2,7.4c-0.3,0.1-0.5-0.1-0.5-0.4c0.2-2.4-0.4-4.5-1.7-6.4
		C915.4,849,915.4,848.9,915.7,848.8z"/>
                                                                    <path d="M552.1,851.4c-0.6,0.1-0.9-0.1-0.8-0.7l0-1c0-0.6,0.5-1,1.1-0.9c0.5,0,0.9,0.4,0.9,0.9
		C553.4,850.6,553,851.2,552.1,851.4z"/>

                                                                    <ellipse transform="matrix(0.9767 -0.2147 0.2147 0.9767 -162.017 215.8287)" cx="912.4" cy="853.6" rx="3.1" ry="4.5"/>
                                                                    <path d="M933.2,851.8c4.3-1.9,8.2,1.1,5.9,5.8c-0.1,0.3-0.4,0.5-0.7,0.6l-3.2,1.3c-0.3,0.1-0.5,0-0.4-0.3
		c0.2-2.5-0.3-4.7-1.7-6.8C932.9,852.1,933,852,933.2,851.8z"/>
                                                                    <path d="M551.3,853.2c0.3-0.1,0.4,0.1,0.5,0.4c0.1,0.4-0.1,0.6-0.5,0.7l-1.9,0.3c-0.5,0.1-0.7-0.1-0.7-0.6v-1.7
		c0-0.2,0.2-0.4,0.4-0.4c0.1,0,0.2,0,0.3,0.1l1,1C550.7,853.3,551,853.3,551.3,853.2z"/>
                                                                    <path d="M534.8,852.5L534.8,852.5c0.3,0.2,0.3,0.5,0.1,0.7l-57,68c-0.2,0.2-0.4,0.2-0.6,0.1l-0.1-0.1
		c-0.2-0.2-0.2-0.4-0.1-0.6l57-68C534.4,852.4,534.6,852.3,534.8,852.5z"/>

                                                                    <ellipse transform="matrix(0.9715 -0.2368 0.2368 0.9715 -176.4919 244.6203)" cx="929.9" cy="856.9" rx="3.2" ry="4.6"/>
                                                                    <path d="M570.2,880.4c-0.1-0.1-0.2-0.2-0.3-0.3c-2.7-8.8-6.4-17.1-11.1-25c-0.2-0.3-0.1-0.8,0.3-1
		c0.1,0,0.2-0.1,0.2-0.1c1.5-0.3,3-0.2,4.6,0.1c7.2,1.5,14.3,3,21.4,4.6c0.4,0.1,0.6,0.4,0.6,0.8V871c0,0.4-0.1,0.7-0.3,1
		C581.6,877.2,578.4,884.2,570.2,880.4z"/>
                                                                    <path d="M552.8,860l-0.4-0.3c-0.4-0.3-0.4-0.6-0.1-1l2.6-3.2c0.5-0.6,1.4-0.7,2.1-0.2c0.2,0.1,0.3,0.3,0.4,0.5l6.7,13
		c0.4,0.7,0.4,1.4,0.1,2.2l-4.3,11.3c-0.1,0.3-0.4,0.6-0.7,0.6l-14.4,3.4c-0.2,0.1-0.4-0.1-0.5-0.3c0-0.1,0-0.2,0-0.2l8.8-24.9
		C553.2,860.5,553.1,860.2,552.8,860z"/>
                                                                    <path d="M594.5,861.2l-6.1,7.5c-0.1,0.1-0.3,0.2-0.5,0c-0.1-0.1-0.1-0.2-0.1-0.3l-0.1-8.9c0-0.2,0.1-0.4,0.3-0.4
		c0,0,0.1,0,0.1,0l6.3,1.4c0.2,0,0.3,0.2,0.3,0.4C594.5,861.1,594.5,861.2,594.5,861.2z"/>
                                                                    <path d="M550.8,860.2l0.5,0.2c0.1,0,0.2,0.2,0.2,0.3l-9.1,26.1c0,0.1-0.2,0.2-0.3,0.2l-0.5-0.2c-0.1,0-0.2-0.2-0.2-0.3
		l9.1-26.1C550.5,860.2,550.7,860.2,550.8,860.2z"/>
                                                                    <path d="M854.5,862.1c-0.4,0-0.7-0.1-0.9-0.2c-0.2-0.2-0.3-0.5-0.1-0.7c0.1-0.2,0.3-0.2,0.5-0.2
		c44.8,8.7,89.6,17.2,134.3,25.9c0.5,0.1,0.8,0.3,1,0.6c0.1,0.2,0.1,0.4-0.1,0.5c-0.1,0-0.2,0.1-0.3,0.1
		c-39-7.4-77.8-14.9-116.4-22.4c-8.5-1.6-14.2-2.8-17.1-3.5C855.2,862.1,854.9,862.1,854.5,862.1z"/>
                                                                    <path d="M600.8,862.1c0.3,0.1,0.5,0.4,0.4,0.6c0,0.1-0.1,0.2-0.1,0.2c-3.6,4.2-7.1,8.6-10.7,13.2
		c-2.3,2.9-4.7,3.5-8.2,3.8c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0-0.1,0.1-0.2l14.5-17.9c0.2-0.3,0.5-0.4,0.9-0.3L600.8,862.1z"/>
                                                                    <path d="M600.8,876.2c-3,0.1-5.9,0.6-8.8,1.6c-0.2,0.1-0.4,0-0.5-0.2c0-0.1,0-0.3,0.1-0.4l11.2-14
		c0.2-0.2,0.5-0.3,0.7-0.1c0.1,0.1,0.2,0.3,0.2,0.4l-1.1,12.4c0,0,0,0,0,0.1c-0.3,0.5-0.7,0.7-1.1,0.4
		C601.3,876.3,601.1,876.2,600.8,876.2z"/>
                                                                    <path d="M798.4,867.2l-22,5.7c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.3l6.4-8.8c0.1-0.1,0.2-0.2,0.4-0.1l15.6,3.1
		c0.2,0,0.3,0.2,0.3,0.4C798.6,867.1,798.5,867.2,798.4,867.2z"/>
                                                                    <path d="M968.8,881.7c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.1,0.2-0.2c0.7-0.2,1.4-0.2,2.2-0.2c5.2,0.3,9.7-0.4,15.1-2.1
		c10.9-3.2,21.7-6.6,32.6-10c0.8-0.3,1.5-1.1,2.1-2.5c0.1-0.3,0.4-0.4,0.7-0.4l22.6,4.1c0.2,0,0.3,0.2,0.3,0.5
		c0,0.1-0.1,0.2-0.3,0.3c-16,5-32,9.9-48,14.7c-1.4,0.4-2.9,0.5-4.3,0.2C984.4,884.6,976.7,883.2,968.8,881.7z"/>
                                                                    <path d="M770.8,879.7l49.4-13.2c0.2,0,0.4,0.1,0.4,0.2c0,0.1,0,0.2-0.1,0.3l-7.4,10.2c0,0.1-0.1,0.1-0.2,0.1
		l-49.7,13.4c-0.2,0-0.4-0.1-0.4-0.2c0-0.1,0-0.2,0.1-0.3l7.7-10.4C770.6,879.7,770.7,879.7,770.8,879.7z"/>
                                                                    <path d="M773.8,875.3l27.9-7.3c0.4-0.1,0.8,0.1,0.9,0.5l0,0c0.1,0.4-0.1,0.8-0.5,0.9l-27.9,7.3
		c-0.4,0.1-0.8-0.1-0.9-0.5l0,0C773.2,875.8,773.4,875.4,773.8,875.3z"/>

                                                                    <ellipse transform="matrix(0.1994 -0.9799 0.9799 0.1994 -182.1195 1517.8368)" cx="837.8" cy="870.4" rx="1.4" ry="0.9"/>
                                                                    <path d="M581.8,883.4c-0.2,0-0.3-0.2-0.3-0.4c0-0.1,0.1-0.3,0.3-0.3l18.2-4.3c0.3-0.1,0.6,0.1,0.7,0.5c0,0,0,0.1,0,0.1
		v0.9c0,0.5-0.2,0.7-0.7,0.5l-2.2-0.6c-0.3-0.1-0.6,0-0.9,0.1c-2.2,1.4-2.8,3.2-1.6,5.6c0.1,0.3,0,0.6-0.3,0.7c-0.1,0-0.2,0.1-0.3,0
		L581.8,883.4z"/>

                                                                    <ellipse transform="matrix(0.5534 -0.8329 0.8329 0.5534 -468.604 892.6172)" cx="598.1" cy="883.3" rx="1.4" ry="0.9"/>
                                                                    <path d="M506.6,898.4c-0.3-0.1-0.5-0.4-0.5-0.7c0-0.2,0.1-0.3,0.3-0.4c0.6-0.4,1.3-0.7,2.1-0.9
		c19.9-4.5,39.5-9.1,59-13.8c1.2-0.3,2.5-0.3,3.7,0c9.9,2.2,20.1,4.6,30.4,7c3.8,0.9,3.1,2.7,4.9,5.2c8.3,11.3,16.6,22.6,24.8,33.8
		c0.1,0.2,0.1,0.5-0.1,0.6c-0.1,0.1-0.2,0.1-0.4,0.1L506.6,898.4z"/>
                                                                    <path d="M599,886.2l1.2-1.3c0.4-0.4,0.6-0.3,0.6,0.2c0,1-0.5,1.6-1.5,1.7c-0.2,0-0.4-0.1-0.4-0.3
		C598.9,886.3,598.9,886.2,599,886.2z"/>
                                                                    <path d="M603,888c-0.5,0.2-0.8,0-1-0.6c-0.1-0.3,0-0.6,0.3-0.7s0.6,0,0.7,0.3l0.2,0.5C603.2,887.7,603.2,887.9,603,888
		C603,888,603,888,603,888z"/>
                                                                    <path d="M751.7,892l0.2,0c0.5,0.1,0.8,0.6,0.6,1.1l-0.3,1.1c-0.1,0.5-0.6,0.8-1.1,0.6l-0.2,0c-0.5-0.1-0.8-0.6-0.6-1.1
		l0.3-1.1C750.7,892.2,751.2,891.9,751.7,892z"/>
                                                                    <path d="M734.7,897c0.4-0.5,1.2-0.7,1.7-0.3c0,0,0,0,0,0c0.1,0.1,0.1,0.3-0.2,0.6c-19.1,25.9-38.3,51.9-57.6,77.9
		c-0.1,0.2-0.4,0.2-0.6,0.1c-0.1-0.1-0.2-0.2-0.2-0.3v-1.2c0-0.3,0.1-0.6,0.3-0.9L734.7,897z"/>

                                                                    <ellipse transform="matrix(0.9998 -1.919744e-02 1.919744e-02 0.9998 -17.0931 16.9315)" cx="873.3" cy="898.8" rx="5.9" ry="1"/>
                                                                    <path d="M807.4,903.8c-0.3-0.1-0.5-0.3-0.4-0.6c0-0.2,0.2-0.4,0.4-0.4c4.5-1.1,12.7-4.5,17.1-3.7
		c4.9,0.9,9.5,2.2,14.4,2.2c7.1-0.1,14.2-0.3,21.4-0.5c1.3-0.1,2.5-0.5,3.7-0.5c0.4,0,0.8,0.3,0.8,0.8c0,0.2-0.1,0.4-0.2,0.6
		c-0.5,0.5-1.3,0.7-2.3,0.5c-0.9-0.2-2.9-0.2-5.8,0.1c-12.4,1.1-24.8,2.1-37.2,3C815,905.4,811.4,904.6,807.4,903.8z"/>
                                                                    <path d="M504.9,902.1l-1.8,1.8c-0.1,0.1-0.3,0.1-0.5,0c-0.1-0.1-0.1-0.1-0.1-0.2v-1.5c0-0.5,0.2-0.8,0.6-0.9
		c2.3-0.8,4.6-0.9,7-0.3c0.3,0.1,0.5,0.3,0.5,0.6l0.5,5.9c0,0.3-0.1,0.5-0.4,0.5l-7.2,2c-0.4,0.1-0.8-0.1-0.9-0.5c0,0,0-0.1,0-0.1
		l-0.1-1.3c0-0.6,0.2-0.8,0.8-0.5c3.2,1.9,7.4-1.3,5.6-4.3c-0.8-1.4-2.4-2.1-3.6-1.6C505.2,901.9,505,902,504.9,902.1z"/>
                                                                    <path d="M866.7,917.4l0.1-16c0-0.4,0.2-0.6,0.6-0.5c4,0.8,8.1,0.7,12-0.3c0.3-0.1,0.6,0.1,0.7,0.4c0,0.1,0,0.1,0,0.2
		l-0.5,16.1c0,0.5-0.3,0.9-0.8,1c-3.9,1-7.7,1-11.6-0.1C866.9,918.1,866.7,917.8,866.7,917.4z"/>
                                                                    <path d="M512.5,905.4v-3.1c0-0.5,0.2-0.6,0.7-0.5c39.1,9.7,78.2,19.5,117.4,29.3c3.5,0.9,5.1,3.1,6.8,6
		c0.2,0.4,0.1,0.5-0.3,0.4l-123.9-31.2C512.7,906.1,512.5,905.9,512.5,905.4z"/>
                                                                    <path d="M803.5,946.6l-0.3-41.5c0-0.3,0.3-0.6,0.6-0.6c0,0,0.1,0,0.1,0c5.5,1.4,10.5,2.7,16.3,2.2
		c14.6-1.3,29.3-2.4,44.2-3.5c0.3,0,0.5,0.2,0.6,0.5c0,0,0,0,0,0v6.2c0,0.5-0.3,0.9-0.8,1.1c-3.4,1.2-6.8,1.8-9.9,3.6
		c-2.3,1.4-4.6,2.7-6.9,4.1c-0.8,0.5-1,0.7-1.2,1.6c-0.1,0.3-0.3,0.6-0.5,0.8c-5.9,4.6-10.8,10-14.7,16.3
		c-5.4,8.7-11.4,15.3-15.3,25c-0.2,0.5-0.3,0.5-0.4,0c-0.5-3.6,1-11.8-3.2-13c-2.8-0.8-5.4-1.4-7.8-2
		C803.8,947.3,803.5,947,803.5,946.6z"/>

                                                                    <ellipse transform="matrix(0.5255 -0.8508 0.8508 0.5255 -530.0556 860.3506)" cx="506.3" cy="905.4" rx="1.5" ry="1.1"/>
                                                                    <path d="M525.3,935.8c-0.5-0.2-0.9-0.2-1.4-0.1l-44.4,11.4c-0.2,0.1-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.3
		c8.3-10,16.6-19.9,24.9-30c1.7-2,2.6-3.4,5.3-4.1c4-0.9,10-3.1,14-2.1c8.4,2.1,16.8,4.2,25.2,6.4c0.3,0.1,0.4,0.3,0.3,0.6
		c0,0.1,0,0.1-0.1,0.2l-13.2,16.1c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.4-0.4,0.6
		C531.7,936.8,528.5,937,525.3,935.8z"/>
                                                                    <path d="M800.7,910.4c0-0.2,0.2-0.4,0.5-0.5c0.2-0.1,0.5,0.1,0.6,0.3c0,0,0,0.1,0,0.1v19.9c0,0.4-0.1,0.7-0.3,1.1
		L789,949c-0.2,0.3-0.5,0.5-0.8,0.6l-18.7,5.7c-0.2,0.1-0.4-0.1-0.4-0.2c0-0.1,0-0.2,0-0.3C779.6,940,790.1,925.2,800.7,910.4z
		 M795.3,932.2c1.5,0.3,3-0.9,3.5-2.9c0.5-1.9-0.4-3.8-1.8-4.1c-1.5-0.3-3,0.9-3.5,2.9C793,930,793.8,931.8,795.3,932.2z"/>
                                                                    <path d="M856.8,918.3c0,0.1-0.1,0.3-0.3,0.3l-7.8,2.9c-0.3,0.1-0.5,0-0.6-0.3c-0.1-0.2,0-0.5,0.2-0.6l9.8-5.7
		c0.1,0,0.2,0,0.2,0.1c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.1-0.1,0.2C857.5,916.1,857,917.1,856.8,918.3z"/>
                                                                    <path d="M538.5,934.4c-0.3,0-0.5-0.2-0.6-0.5c0-0.1,0-0.3,0.1-0.4l12.5-15.5c0.3-0.3,0.6-0.4,1-0.3l3.6,0.9
		c0.4,0.1,0.5,0.3,0.2,0.7c-3.1,3.8-6.2,7.7-9.3,11.5C543.9,933.4,541.7,934.1,538.5,934.4z"/>
                                                                    <path d="M852.6,926.3c-0.9,0.3-0.8,0.7,0.2,1.2c0,0,0.1,0,0.1,0.1c2.9,0.8,5.8,1.4,8.7,1.9c0.4,0.1,0.7,0.3,1,0.5
		c0.3,0.3,0.3,0.7,0,1c-0.2,0.2-0.4,0.3-0.7,0.2c-2.5-0.5-4.9-0.8-7.2-0.9c-2.1-0.1-4.7,0.6-7.3,0.3c-0.3,0-0.5,0-0.8,0.2l-1,0.6
		c-0.2,0.1-0.5,0.1-0.6-0.1c-0.1-0.1-0.1-0.2-0.1-0.4c0.6-3,1.9-7.1,4.6-8c4.7-1.4,9.4-2.9,14.1-4.5c0.3-0.1,0.7-0.1,0.9,0.2
		c1.2,0.9,1.8,2.2,1.7,3.7c0,0.3,0.1,0.7,0.4,0.8c3.2,2,9.5,1.4,12.6,0.1c0.8-0.3,1.2-1.2,1-2c-0.2-0.6-0.1-1.1,0.3-1.5
		c0.2-0.2,0.5-0.3,0.8-0.2c4.1,1.3,8.2,2.3,12.3,3c4.4,0.8,3.9,3.9,2.8,7.4c-6.4,19.9-12.7,39.7-19,59.6c-0.9,2.9-4.2,7.6-7.7,7.2
		c-6.6-0.7-3.6-12.3-2.4-16.2c4.4-15.4,9-30.7,13.6-46.1c0.2-0.8,0.1-1.3-0.4-1.5c-0.3-0.1-0.7,0-0.9,0.3c0,0,0,0.1,0,0.1
		c-4.9,16.6-9.8,33.1-14.7,49.6c-1.1,3.6-1.1,7.3-0.2,11c0.1,0.2-0.1,0.5-0.3,0.5c-0.1,0-0.1,0-0.2,0c-0.5-0.1-0.8-0.4-1-1
		c-1-3.5-1-6.9-0.1-10.2c4.5-15.3,8.9-30.7,13.4-46c1.3-4.4-1.5-6.2-5.3-7.2c-5.9-1.5-11.9-2.8-18-3.8
		C853.2,926.2,852.9,926.2,852.6,926.3z M871.7,991.6c2,0.5,4.2-1,4.9-3.4c0,0,0,0,0,0c0.7-2.4-0.5-4.9-2.5-5.4l0,0
		c-2-0.5-4.2,1-4.9,3.4c0,0,0,0,0,0C868.6,988.6,869.7,991,871.7,991.6L871.7,991.6z"/>
                                                                    <path d="M557,930.2c-3.1,0.3-6.2,1-9.2,2.1c-0.2,0.1-0.4,0-0.5-0.2c0-0.1,0-0.3,0.1-0.4l9.8-12
		c0.2-0.3,0.6-0.4,0.9-0.3l3.6,0.9c0.3,0.1,0.6,0.3,0.8,0.6c1.4,2.7,3.6,3.8,6.7,3.3c0.2,0,0.4,0.1,0.4,0.3c0,0.1,0,0.2-0.1,0.3
		l-10,12.6c-0.2,0.2-0.4,0.2-0.6,0c-0.1-0.1-0.1-0.2-0.1-0.3v-5.3c0-0.9-0.7-1.6-1.6-1.6C557.1,930.2,557.1,930.2,557,930.2z"/>
                                                                    <path d="M878.1,922c-3.3,1.1-6,0.9-9.4,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2,0-0.3c0-0.2,0.1-0.3,0.3-0.3
		c3,0.4,6.1,0.3,9.1-0.4c0.3-0.1,0.4,0,0.4,0.3c0,0.1,0,0.2,0,0.3C878.2,921.9,878.2,922,878.1,922z"/>
                                                                    <path d="M755.7,925.3c0.5,0.1,0.6,0.4,0.1,0.7c-0.1,0.1-0.3,0.2-0.7,0.3c-7.7,2.3-15.4,4.5-23,6.7
		c-0.2,0.1-0.4,0-0.4-0.2c0-0.1,0-0.2,0.1-0.3l7.4-10c0.4-0.5,1-0.7,1.5-0.6L755.7,925.3z"/>
                                                                    <path d="M833.9,936c3.5-4.8,7.6-10,12.5-13.3c0.5-0.3,0.5-0.2,0.2,0.2c-1.6,2.3-2.6,4.9-3.1,7.7c-0.2,1-0.9,1.7-1.8,2
		c-2.6,0.9-5,2.2-7.2,3.9c-0.2,0.2-0.5,0.1-0.6-0.1C833.8,936.4,833.8,936.1,833.9,936z"/>
                                                                    <path d="M429.7,962.2c-0.6-0.2-1-0.7-1-1.4l-0.4-26.9c0-0.9,0.6-1.7,1.5-2l35.7-8.8c0.3-0.1,0.6,0,0.9,0.2
		c0.6,0.4,0.7,0.8,0.3,1.3c-9.6,11.5-19.3,23-29.1,34.7c-0.2,0.2-0.2,0.5,0,0.8l3.4,3.9c0.3,0.3,0.2,0.8-0.1,1
		c-0.2,0.2-0.4,0.2-0.7,0.1L429.7,962.2z"/>
                                                                    <path d="M586.1,926.4c0.4,0.1,0.5,0.3,0.3,0.6c0,0.1-0.1,0.2-0.2,0.2l-19.3,5c-0.3,0.1-0.5-0.1-0.6-0.4
		c0-0.1,0-0.3,0.1-0.4l6.2-7.8c0.2-0.3,0.6-0.4,1-0.3C577.7,924.2,581.9,925.3,586.1,926.4z"/>
                                                                    <path d="M718.3,950.8c1.8-2.5,6.8-10.4,9.6-11.3c16.8-5,33.5-9.8,50.2-14.6c0.2-0.1,0.4,0,0.4,0.2c0,0.1,0,0.2-0.1,0.3
		l-7.1,9.7c-0.4,0.6-1,1-1.8,1.2l-50.9,15.1c-0.2,0.1-0.5-0.1-0.6-0.3C718.2,951.1,718.2,950.9,718.3,950.8z"/>
                                                                    <path d="M440.8,959.5c-0.6-0.2-0.7-0.5-0.3-1l27.2-32.3c0.3-0.3,0.7-0.3,1-0.1c0,0,0.1,0.1,0.1,0.1
		c0.8,0.9,1.7,1.3,3,1c0.2,0,0.5,0.1,0.5,0.3c0,0.1,0,0.3-0.1,0.4L445,960.4c-0.2,0.2-0.5,0.3-0.8,0.2L440.8,959.5z"/>
                                                                    <path d="M729.3,937c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0-0.3c0.4-0.6,0.9-0.9,1.7-1.2c9.4-2.8,18.8-5.6,28.4-8.3
		c0.4-0.1,0.7,0.1,0.9,0.5c0.1,0.2,0,0.5-0.1,0.7c-0.2,0.2-0.5,0.3-1,0.5C749.2,931.5,739.3,934.3,729.3,937z"/>

                                                                    <ellipse transform="matrix(0.2487 -0.9686 0.9686 0.2487 -301.2271 1469.0803)" cx="796.3" cy="928.7" rx="1.5" ry="0.9"/>
                                                                    <path d="M864.9,931.5c-0.2,0-0.4-0.2-0.4-0.5v-0.4c0-0.4,0.2-0.6,0.6-0.5l8.6,2c0.8,0.2,1.4,1,1.2,1.9c0,0,0,0,0,0
		c0,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1C871.2,933,868.1,932.1,864.9,931.5z"/>
                                                                    <path d="M536,938.6c-0.2-0.1-0.3-0.2-0.2-0.4c0-0.1,0.1-0.2,0.2-0.2l20.1-5.3c0.4-0.1,0.8,0.1,0.9,0.5
		c0,0.1,0,0.1,0,0.2l0,1.2c0,0.3-0.3,0.6-0.6,0.5c-0.1,0-0.2,0-0.2-0.1c-5.5-2.8-7.6,5.3-2.3,5.9c0.4,0,0.7,0,1-0.2l1.8-1.4
		c0.2-0.1,0.4-0.1,0.5,0.1c0.1,0.1,0.1,0.2,0.1,0.3c-0.1,0.6-0.5,1-1.2,1.3C549.3,943.5,542.8,940.6,536,938.6z"/>

                                                                    <ellipse transform="matrix(0.2957 -0.9553 0.9553 0.2957 -505.4258 1189.9772)" cx="554.3" cy="937.8" rx="1.3" ry="1"/>
                                                                    <path d="M459.4,956.2c-0.2-0.1-0.4-0.1-0.5-0.2c-0.5-0.2-0.7-0.7-0.6-1.2c0.1-0.3,0.4-0.5,0.7-0.6l64.5-16.7
		c0.5-0.1,1.1-0.1,1.6,0l139.8,37.3c1.3,0.3,2.1,1.7,1.7,3c-0.1,0.3-0.2,0.6-0.4,0.9c-2,2.6-4.1,5.5-6.4,8.5
		c-2.1,2.9-6.3,3.7-9.5,4.5c-1.5,0.4-2.6,1.7-2.6,3.2c0,1.9,0,4,0,6.2c0,2-2.8,8.2-5.6,7.4C581.2,991.2,520.3,973.7,459.4,956.2z"/>
                                                                    <path d="M721.9,953.4c-0.5-0.1-0.5-0.4-0.2-0.9c0.1-0.1,0.2-0.2,0.3-0.2l46.1-13.7c0.2-0.1,0.4,0,0.5,0.2
		c0,0.1,0,0.2-0.1,0.3l-14.3,19.9c-0.8,1.1-2.2,1.6-3.5,1.3C741.2,958.1,731.6,955.8,721.9,953.4z"/>

                                                                    <ellipse transform="matrix(0.4274 -0.9041 0.9041 0.4274 -580.4683 968.9615)" cx="474.7" cy="942.7" rx="2.8" ry="2.2"/>
                                                                    <path d="M801.4,943.5c0.1,0,0.2,0.1,0.3,0.3c0.3,0.9,0,1.5-0.8,1.8c-2.8,1-5.7,1.9-8.6,2.6c-0.6,0.2-0.8,0-0.4-0.6
		l3.7-5.1c0.2-0.2,0.5-0.3,0.7-0.3L801.4,943.5z"/>
                                                                    <path d="M471.1,945.7c0.3,0.1,0.6,0.3,0.8,0.6c0.2,0.2,0.2,0.5,0,0.7c-0.2,0.1-0.4,0.2-0.6,0.1c-0.6-0.3-0.9-0.8-1-1.5
		c0-0.1,0.1-0.2,0.2-0.2c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.4,0.3C471,945.6,471,945.6,471.1,945.7z"/>
                                                                    <path d="M650.7,1027.4c-0.6-0.2-1-0.7-1-1.4l-0.4-30.6c0-0.9,0.6-1.7,1.5-2l150.2-46c0.3-0.1,0.6,0,0.8,0.3
		c1.4,2,6.8,2.5,9.3,3.1c1.3,0.3,2.2,1.5,2.2,2.8c0,6.5,0.4,12.8-0.9,19.2c-0.6,3.3-1.3,6.5-2,9.8c-0.1,0.4-0.3,0.6-0.6,0.7
		l-145.2,48c-0.4,0.1-0.7,0.1-1.1,0L650.7,1027.4z"/>

                                                                    <ellipse transform="matrix(0.284 -0.9588 0.9588 0.284 -411.152 1360.6206)" cx="705.5" cy="955.6" rx="1.7" ry="1.1"/>
                                                                    <path d="M461,961.7c-0.6-3.5-4.3-2.5-5.9-0.4c-0.2,0.2-0.5,0.2-0.7,0.1c0,0-0.1-0.1-0.1-0.1c-0.3-0.5-0.4-1-0.2-1.5
		c0.1-0.3,0.5-0.6,1-0.8c2.5-1,4.9-1,7.4-0.2c0.2,0.1,0.4,0.3,0.4,0.5l0.5,6c0,0.3-0.1,0.5-0.4,0.5l-7.9,2.1
		c-0.3,0.1-0.7-0.1-0.8-0.5c0-0.1,0-0.1,0-0.2v-2.5c0-0.6,0.2-0.7,0.5-0.1C456.7,967.9,461.6,965.6,461,961.7z"/>
                                                                    <path d="M464.7,963.3l-0.1-3.2c0-0.3,0.2-0.5,0.5-0.6c0.1,0,0.1,0,0.2,0l176.6,50.6c0.3,0.1,0.5,0.4,0.4,0.7
		c0,0.1-0.1,0.1-0.1,0.2l-2.4,3.1c-0.1,0.2-0.4,0.2-0.6,0.2l-174.1-50.5C464.9,963.8,464.7,963.6,464.7,963.3z"/>
                                                                    <path d="M451.9,971.9c-2.9,0.7-7.4-6-8.9-8.1c-0.2-0.2-0.4-0.5-0.7-0.6c-0.5-0.3-0.8-0.7-0.9-1.2
		c-0.1-0.4,0.1-0.5,0.4-0.4c3.9,0.8,7.5,6.7,10.2,9.6c0.2,0.2,0.1,0.4,0,0.6C452.1,971.9,452,971.9,451.9,971.9z"/>

                                                                    <ellipse transform="matrix(0.4352 -0.9003 0.9003 0.4352 -608.2322 956.3748)" cx="458.2" cy="963" rx="1.6" ry="1.1"/>
                                                                    <path d="M456.4,971.4c-0.1,0.2-0.3,0.5-0.6,0.7c-0.4,0.3-0.7,0.3-1-0.1l-1-1c-0.2-0.2-0.2-0.6,0-0.8
		c0.1-0.1,0.1-0.1,0.2-0.1l3.2-1c0.3-0.1,0.6,0.1,0.7,0.3c0.1,0.2,0,0.4-0.1,0.5l-1,1.1C456.6,971,456.5,971.2,456.4,971.4z"/>
                                                                    <path d="M671.3,981.3l2.1-0.3c0.3,0,0.5,0.1,0.6,0.4c0,0.1,0,0.3-0.1,0.4c-3.5,4.6-6.4,4.9-11.8,6.4
		c-0.2,0-0.4-0.1-0.4-0.3c0-0.1,0-0.2,0.1-0.3l6-8c0.2-0.3,0.5-0.3,0.8-0.1l1.8,1.5C670.6,981.2,671,981.3,671.3,981.3z"/>
                                                                    <path d="M640.5,1018c-0.4-0.1-0.7-0.5-0.6-0.9c0-0.1,0.1-0.2,0.1-0.3l7-9.7c0.1-0.2,0.3-0.2,0.5-0.1
		c0.1,0.1,0.1,0.2,0.1,0.3c0,3.2,1.1,9.5-1.9,11.6c-0.3,0.2-0.6,0.3-0.9,0.2L640.5,1018z"/>
                                                                    <path d="M648.1,1029.6c-2.5-2.9-4.9-6-7.1-9.1c-0.1-0.1,0-0.3,0.1-0.3c0,0,0.1,0,0.1,0c8.8,0.1,4.5,8,10.3,9.5
		c0.3,0.1,0.6,0.3,0.9,0.5l0.8,0.8c0.2,0.2,0.2,0.6,0,0.8c-0.1,0.1-0.3,0.2-0.5,0.2C650.8,1031.4,649.4,1031.2,648.1,1029.6z"/>
                                                                </g>
                                                            </g>
                                                            <g onClick={()=>{
                                                                setObjects({
                                                                    ...objects,
                                                                    2: {
                                                                        ...objects[2],
                                                                        active: 1
                                                                    }
                                                                });
                                                            }} id="E1" style={{color: objects[2].elements[0]}} className={'action-group' + (objects[2].active == 1 ? ' active' : '')}>
                                                                <g>
                                                                    <path d="M1205.6,121.8c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1c-1.3,4.2-1.9,8-2,12.6c-0.1,34.5-0.4,69-0.8,103.4
                                                                    c0,0.3-0.2,0.5-0.5,0.5c-0.2,0-0.4-0.1-0.5-0.3l-28.7-75.2c-0.2-0.5-0.1-1,0.2-1.4L1205.6,121.8z"/>
                                                                    <path d="M1026.2,134.7c0.2-0.3,0.6-0.3,0.9,0c0.1,0.1,0.1,0.2,0.2,0.3c1.4,3.7,2,7.6,2,11.9c-0.1,31-0.2,62-0.3,92.9
                                                                    c0,8.5-5.9,13.6-11.1,19.6c-0.2,0.2-0.5,0.2-0.7,0c-0.1,0-0.1-0.1-0.1-0.2l-33.7-76.1c-0.2-0.5-0.1-1.1,0.2-1.5L1026.2,134.7z"/>
                                                                    <path d="M1189.9,266.2c0.3,0,0.6,0.3,0.6,0.6l0.1,14.8c0,0.2-0.1,0.5-0.2,0.7L1009.7,525c-0.2,0.2-0.5,0.3-0.7,0.1
                                                                    c-0.1,0-0.1-0.1-0.2-0.2l-48.2-98.2c-0.3-0.6-0.2-1.2,0.2-1.7l204.1-253.2c1.1-1.3,1.9-1.2,2.5,0.4l25.3,66.1
                                                                    c0.1,0.3,0,0.6-0.3,0.7c-0.1,0-0.1,0-0.2,0l-3.1,0.2c-0.3,0-0.6,0.2-0.8,0.5c-1.7,3.2-3.5,5.9-3.5,9.7c0,5.2,0,10.3-0.2,15.5
                                                                    c0,0.3,0.2,0.6,0.6,0.7L1189.9,266.2z"/>
                                                                    <path d="M1015.2,277.8L813.7,517.5c-0.2,0.2-0.6,0.3-0.8,0.1c-0.1,0-0.1-0.1-0.1-0.2l-50.6-90.2
                                                                    c-0.2-0.4-0.2-0.9,0.2-1.3c70.4-78,140.9-155.9,211.5-233.8c0.6-0.7,1.5-1.2,2.1-1.9c0.2-0.2,0.5-0.2,0.6-0.1
                                                                    c0.1,0,0.1,0.1,0.1,0.2l38.7,86.6C1015.5,277.2,1015.4,277.5,1015.2,277.8z M940.9,326.3c1.5,1,2.7,0.9,3.6-0.1
                                                                    c3.6-4,7-7.9,10.2-11.7c0.9-1.2,1.1-2.7,0.5-4.1l-5.3-11.3c-0.6-1.4-2.3-2-3.7-1.3c-0.4,0.2-0.7,0.4-0.9,0.7l-9.8,11.4
                                                                    c-1.1,1.3-1.3,3.1-0.6,4.6l5.2,10.9C940.2,325.6,940.5,326,940.9,326.3z M879.3,398c1.9,0.9,3,0.8,4.4-0.9c3.7-4.2,7.1-8.2,10.2-12
                                                                    c1-1.2,1.2-3,0.5-4.4l-5.8-11.4c-0.7-1.4-2.4-1.9-3.8-1.2c-0.3,0.2-0.6,0.4-0.8,0.6c-3.3,3.7-6.7,7.6-10.4,11.6
                                                                    c-1.6,1.7-1.9,3.4-1,5.2c2.1,4.1,4.1,8.2,6.2,12.1C879.1,397.7,879.2,397.9,879.3,398z M813.7,475.3c1.3,0.2,2.6,0.6,3.5-0.6
                                                                    c3.8-4.4,7.7-9.1,11.9-13.9c0.9-1.1,1.1-2.6,0.4-3.9l-6.4-11.9c-0.8-1.5-2.6-2-4.1-1.2c-0.3,0.2-0.6,0.4-0.9,0.7l-11.7,13.9
                                                                    c-1,1.2-1.2,2.8-0.5,4.1c2.1,4,4.3,8,6.6,12C812.9,475,813.2,475.2,813.7,475.3z"/>
                                                                    <path d="M752.6,436.2c0.9-1,1.7-0.9,2.4,0.3l50.6,89.7c0.2,0.4,0.2,0.8-0.1,1.2L542.6,840.1c-0.4,0.4-0.7,0.4-1.1,0
                                                                    l-69.4-92.8c-0.2-0.3-0.2-0.7,0-1L752.6,436.2z M740.9,557.7c2.1,1.7,3.5,1.8,5.3-0.3c4.2-4.8,8.3-9.5,12.1-14
                                                                    c1.2-1.4,1.3-3.4,0.4-5l-6.4-11c-1-1.6-3.1-2.2-4.7-1.2c-0.3,0.2-0.6,0.4-0.8,0.7l-12.4,14.3c-1.2,1.3-1.3,3.3-0.4,4.8l6.8,11.4
                                                                    C740.7,557.5,740.8,557.6,740.9,557.7z M656,637c1.9,3.2,8.1,16.1,12.3,11.3c4.8-5.5,9.3-10.8,13.5-15.8c1.3-1.5,1.5-3.7,0.4-5.5
                                                                    c-2-3.2-4.2-6.8-6.8-10.6c-2.5-3.8-5.1-1.7-7.4,0.9c-3.8,4.3-7.5,8.6-11.2,13C654.8,632.8,654.3,634.2,656,637z M577.5,745.4
                                                                    c2.2,3.1,4.8,3,7.2,0.3c4.9-5.6,9.5-11.1,13.9-16.3c1.4-1.7,1.5-4.1,0.2-5.9l-7.5-10.8c-1.2-1.7-3.6-2.1-5.3-1
                                                                    c-0.3,0.2-0.5,0.4-0.7,0.6l-15,17.2c-1.2,1.4-1.3,3.4-0.3,4.9C572.4,738,574.9,741.6,577.5,745.4z"/>
                                                                    <path d="M949.9,438.1c0.7-1,1.6-1.7,2.5-2.2c0.2-0.1,0.5,0,0.7,0.2c0,0,0,0,0,0l48.6,98.5c0.2,0.4,0.2,0.9-0.1,1.3
                                                                    L743.3,882.8c-0.2,0.3-0.6,0.3-0.9,0.1c-0.1,0-0.1-0.1-0.1-0.2l-68.5-101.2c-0.2-0.3-0.2-0.6,0-0.9
                                                                    C761.8,671.3,850,562.1,938.3,453C942.2,448.1,946.1,443.2,949.9,438.1z"/>
                                                                    <path d="M460.9,758.1c0.8-0.9,1.6-0.9,2.4,0.1l69.6,92.3c0.3,0.3,0.3,0.8,0,1.2l-56.3,67c-0.3,0.3-0.8,0.4-1.1,0.1
                                                                    c0,0-0.1-0.1-0.1-0.1c-20.2-25.2-40.4-50.4-60.6-75.7c-2.5-3.1-3.1-6-2.8-10.2c0.5-7.2,1.1-14.2,1.7-21c0.1-0.6,0.3-1.3,0.8-1.7
                                                                    L460.9,758.1z M477,842.1c2.2,3,10,17.3,14.9,11.8c5.6-6.4,11-12.7,16.4-19c1.3-1.6,1.4-3.9,0.1-5.6c-2.3-2.9-4.7-6-7.2-9.4
                                                                    c-2-2.8-4.8-4.9-7.6-1.7c-5.5,6.2-11,12.5-16.4,18.8C476,838.5,475.9,840.6,477,842.1z"/>
                                                                    <path d="M663.1,793.7c0.5-0.6,1-0.6,1.5,0.1l68.9,100.9c0.3,0.4,0.3,0.9,0,1.2L677,971.8c-0.3,0.4-0.6,0.4-0.9,0
                                                                    c-20-27.4-40.2-54.9-60.4-82.5c-1.8-2.4-2.6-5.3-2.5-8.5c0.4-8.6,0.9-17.2,1.6-26c0.1-0.9,0.4-1.8,1-2.5L663.1,793.7z"/>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {currentSlide === 3 && (
                                            <div className="obj__it obj__it--3">
                                                <div className="obj__it-img">
                                                    <img src={require("../assets/img/objects/baggagebelt.svg").default}/>
                                                    <div className="obj__el">
                                                        <svg viewBox="0 0 1931 1086">
                                                            <g
                                                                onClick={()=>{
                                                                    setObjects({
                                                                        ...objects,
                                                                        3: {
                                                                            ...objects[3],
                                                                            active: 2
                                                                        }
                                                                    });
                                                                }}
                                                                style={{color: objects[3].elements[1]}}
                                                                id="E2"
                                                                className={'action-group' + (objects[3].active == 2 ? ' active' : '')}>
                                                                <g>
                                                                    <path d="M1635.2,592.7L1683.8,94c0-0.2,0.2-0.4,0.4-0.4l88.6-1.1c0.2,0,0.4,0.2,0.4,0.4c0,0,0,0,0,0l-55.8,508.3
			c0,0.2-0.2,0.4-0.4,0.4l-81.4-8.5C1635.3,593.1,1635.2,592.9,1635.2,592.7z"/>
                                                                    <path d="M1474.7,413.4c-1.2-0.1-2.7,0-4.4,0.2c-0.3,0-0.5-0.1-0.5-0.4l22.6-316.5c0-0.1,0.1-0.2,0.2-0.2l165.8-2.4
			c0.6,0,0.9,0.3,0.8,0.9l-46.7,495c0,0.5-0.3,0.7-0.8,0.7l-5.5-0.5c-0.3,0-0.5-0.2-0.4-0.5l15.5-166.5c0-0.1-0.1-0.2-0.2-0.2
			C1572.3,419.7,1523.5,416.5,1474.7,413.4z"/>
                                                                    <path d="M1298.6,483l18.2-383.3c0-0.6,0.4-0.9,1-0.9l150-2.2c0.4,0,0.8,0.3,0.8,0.8c0,0,0,0,0,0.1l-27,397.8
			c0,0.4-0.4,0.7-0.8,0.7c0,0,0,0,0,0l-141.4-12.1C1298.9,483.8,1298.5,483.4,1298.6,483z"/>
                                                                    <path d="M953.2,440.8l0.1,12.6c0,0.4-0.3,0.8-0.8,0.8c0,0-0.1,0-0.1,0l-4.5-0.4c-0.6-0.1-1-0.4-1-1l-1.1-348
			c0-0.5,0.4-0.9,0.9-0.9l347.5-4.8c0.4,0,0.6,0.2,0.5,0.5l-17,381.8c0,0.4-0.2,0.5-0.6,0.5l-133.9-11.4c-0.4,0-0.7-0.4-0.6-0.7
			l5.4-214.2c0-0.3-0.3-0.6-0.6-0.6c0,0,0,0,0,0c-35.9-0.1-71.2-1.9-93.9-2.4c-1.7,0-3.7,0.2-6.1,0.8c-0.3,0.1-0.5,0.3-0.5,0.6
			l-2.4,207.4c0,0.4-0.3,0.6-0.7,0.6c0,0,0,0,0,0l-29.1-2.5c-0.4,0-0.7-0.4-0.7-0.7l0.3-34.4c0-0.2-0.2-0.5-0.4-0.5
			c-4.1-0.2-10.7-1.4-14.5,0c-15.3,5.5-30.5,10.9-45.8,16.4C953.3,440.4,953.2,440.5,953.2,440.8z"/>
                                                                    <path d="M814.5,105.7l111.7-1.5c0.3,0,0.5,0.2,0.5,0.5l1.9,346.7c0,0.3-0.2,0.5-0.5,0.5c0,0,0,0-0.1,0l-106.5-9.1
			c-0.3,0-0.5-0.3-0.5-0.5l-7.2-336.2C813.9,106,814.2,105.7,814.5,105.7C814.5,105.7,814.5,105.7,814.5,105.7z"/>
                                                                    <path d="M703.4,432.3L691,107.9c0-0.3,0.2-0.6,0.6-0.6c0,0,0,0,0,0l102.8-1.3c0.3,0,0.6,0.3,0.6,0.6l7.9,334.1
			c0,0.3-0.3,0.6-0.6,0.6c0,0,0,0-0.1,0l-98.3-8.4C703.6,432.8,703.4,432.6,703.4,432.3z"/>
                                                                    <path d="M576.8,108.9l95.3-1.3c0.3,0,0.6,0.3,0.6,0.6l13,322.3c0,0.4-0.3,0.7-0.6,0.7c0,0-0.1,0-0.1,0l-91.5-7.8
			c-0.3,0-0.6-0.3-0.6-0.6l-16.8-313.3C576.2,109.2,576.4,108.9,576.8,108.9C576.8,108.9,576.8,108.9,576.8,108.9z"/>
                                                                    <path d="M485.9,349.6l-16.3-238.5c0-0.3,0.2-0.7,0.6-0.7c0,0,0,0,0,0l88.5-1.2c0.3,0,0.5,0.2,0.5,0.4l13.7,244.9
			c0,0.4-0.2,0.6-0.6,0.6c-27.8-1.8-55.2-3.6-82.2-5.2c-1.1-0.1-2.4,0.3-3.7,0.1C486,350,485.9,349.8,485.9,349.6z"/>
                                                                    <path d="M440.8,468c-0.3,0-0.5-0.3-0.6-0.6l-27-355.3c0-0.5,0.3-0.9,0.8-1c0,0,0,0,0.1,0l38.4-0.4
			c0.3,0,0.6,0.2,0.6,0.5l22.6,320.4c0,0.5-0.3,0.9-0.8,1c-7,1.7-13.6,4.5-19.8,8.4c-2.6,1.6-5.3,6.6-5,9.6
			c0.4,5.9,0.9,11.7,1.3,17.6c0,0.4-0.3,0.8-0.7,0.8c0,0-0.1,0-0.1,0L440.8,468z"/>
                                                                </g>
                                                            </g>
                                                            <g
                                                                onClick={()=>{
                                                                    setObjects({
                                                                        ...objects,
                                                                        3: {
                                                                            ...objects[3],
                                                                            active: 1
                                                                        }
                                                                    });
                                                                }}
                                                                style={{color: objects[3].elements[0]}}
                                                                id="E1"
                                                                className={'action-group' + (objects[3].active == 1 ? ' active' : '')}>
                                                                <g>
                                                                    <path d="M1615,592.7c-0.1,0-0.2-0.1-0.2-0.2c-0.1-1.2-0.1-2.5,0-3.8c15.6-164.7,31.2-329.5,46.8-494.4
			c0-0.3,0.2-0.5,0.5-0.5l18-0.5c0.3,0,0.5,0.2,0.5,0.5l-48.5,499.6c-0.1,0.5-0.5,0.9-1.1,0.9c0,0,0,0,0,0L1615,592.7z"/>
                                                                    <path d="M1457.6,498.9l-13.7-1.2c-0.2,0-0.4-0.2-0.4-0.5c0-1.3,0-2.6,0.1-3.9c8.8-132.1,17.8-264.1,27-396.1
			c0-0.4,0.3-0.6,0.7-0.6l16-0.4c0.3,0,0.6,0.2,0.6,0.6c0,0,0,0,0,0.1l-28.4,400.5C1459.4,498.4,1458.8,499,1457.6,498.9z"/>
                                                                    <path d="M1476.9,294.8c-3.2,39.4-5.7,79-8.5,118.5c-0.1,2-0.9,4.3-1.1,6.6c-1.2,17.7-2.4,35.4-3.8,53.1
			c-0.5,6.2-1,16.4-1.8,24.6c0,0.3-0.2,0.4-0.5,0.4c0,0,0,0,0,0c-0.2,0-0.3-0.2-0.3-0.4c9.4-133.7,18.8-267.2,28.3-400.8
			c0-0.3,0.3-0.5,0.6-0.5c0.1,0,0.2,0,0.2,0.1l0.5,0.3c0.3,0.1,0.4,0.4,0.3,0.7c-4.1,57.9-8.2,115.8-12.2,173.6
			C1478.1,278.9,1477.5,286.9,1476.9,294.8z"/>
                                                                    <path d="M1279.1,482.7l17.1-383.2c0-0.4,0.3-0.6,0.7-0.6l14.3-0.4c0.4,0,0.7,0.3,0.7,0.7c0,0,0,0,0,0l-18,384.8
			c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0l-13.3-1.2C1279.3,483.4,1279.1,483.1,1279.1,482.7z"/>
                                                                    <path d="M1313.8,98.8l0.8,0c0.3,0,0.6,0.3,0.6,0.6l-18.1,383.6c0,0.3-0.3,0.6-0.6,0.6l-0.8,0c-0.3,0-0.6-0.3-0.6-0.6
			l18.1-383.6C1313.2,99.1,1313.5,98.8,1313.8,98.8z"/>
                                                                    <path d="M930.1,452.9l-2-348.1c0-0.4,0.3-0.7,0.7-0.7l11.1-0.5c0.4,0,0.7,0.3,0.7,0.7c0,0,0,0,0,0l1.4,349.4
			c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0-0.1,0l-10.5-0.8C930.4,453.5,930.1,453.2,930.1,452.9z"/>
                                                                    <path d="M942.8,103.9l0.9,0c0.4,0,0.6,0.3,0.6,0.6l1.2,348.7c0,0.4-0.3,0.6-0.6,0.6l-0.9,0c-0.4,0-0.6-0.3-0.6-0.6
			l-1.2-348.7C942.1,104.2,942.4,103.9,942.8,103.9z"/>
                                                                    <path d="M804.4,442.2l-7.9-335.9c0-0.3,0.2-0.5,0.5-0.5c0,0,0,0,0,0l10.5-0.1c0.3,0,0.5,0.2,0.5,0.5c0,0,0,0,0,0
			l7.4,336.8c0,0.3-0.2,0.5-0.5,0.5c0,0,0,0-0.1,0l-9.9-0.8C804.6,442.7,804.4,442.5,804.4,442.2z"/>
                                                                    <path d="M809.9,105.7l2.3,0c0.2,0,0.4,0.2,0.4,0.4l7.1,336.6c0,0.2-0.2,0.4-0.4,0.4l-2.3,0c-0.2,0-0.4-0.2-0.4-0.4
			l-7.1-336.6C809.5,105.9,809.7,105.7,809.9,105.7z"/>
                                                                    <path d="M687.3,432l-13.1-323.8c0-0.3,0.2-0.6,0.5-0.6l9.4-0.5c0.3,0,0.6,0.2,0.6,0.5c0,0,0,0,0,0l12.6,325.1
			c0,0.3-0.2,0.6-0.5,0.6c0,0-0.1,0-0.1,0l-8.9-0.8C687.5,432.5,687.3,432.3,687.3,432z"/>
                                                                    <path d="M686.7,107.4l2.3-0.1c0.3,0,0.5,0.2,0.5,0.4L702,432.4c0,0.3-0.2,0.5-0.4,0.5l-2.3,0.1
			c-0.3,0-0.5-0.2-0.5-0.4l-12.5-324.7C686.3,107.6,686.5,107.4,686.7,107.4z"/>
                                                                    <path d="M578,422.7l-17.4-312.8c0-0.4,0.3-0.7,0.6-0.7c0,0,0,0,0,0l8.3-0.3c0.4,0,0.7,0.3,0.7,0.6l17.1,313.6
			c0,0.4-0.3,0.7-0.6,0.7c0,0-0.1,0-0.1,0l-8-0.5C578.3,423.3,578.1,423,578,422.7z"/>
                                                                    <path d="M572.5,109l1.3-0.1c0.4,0,0.8,0.3,0.8,0.7l17,313.1c0,0.4-0.3,0.8-0.7,0.8l-1.3,0.1c-0.4,0-0.8-0.3-0.8-0.7
			l-17-313.1C571.8,109.4,572.1,109,572.5,109z"/>
                                                                    <path d="M455,110.4h8.1c0.2,0,0.5,0.2,0.5,0.4l16.6,239.9c0,0.2-0.2,0.5-0.4,0.5l-1.1,0.1c-0.2,0-0.4,0.3-0.4,0.5
			l5.3,77.8c0,0.2-0.1,0.4-0.4,0.5l-5.5,1.4c-0.3,0.1-0.5-0.1-0.6-0.3c0,0,0-0.1,0-0.1l-22.6-320.3
			C454.5,110.6,454.7,110.4,455,110.4C455,110.4,455,110.4,455,110.4z"/>
                                                                    <path d="M465.4,110.5l2.3-0.2c0.2,0,0.4,0.1,0.4,0.3l16.3,239.7c0,0.2-0.1,0.4-0.3,0.4l-2.3,0.2
			c-0.2,0-0.4-0.1-0.4-0.3l-16.3-239.7C465,110.7,465.2,110.5,465.4,110.5z"/>
                                                                    <path d="M1049,255l7.3,0.1c0.3,0,0.6,0.3,0.6,0.6l-2.6,209.7c0,0.3-0.3,0.6-0.6,0.6l-7.3-0.1c-0.3,0-0.6-0.3-0.6-0.6
			l2.6-209.7C1048.4,255.3,1048.7,255,1049,255z"/>
                                                                    <path d="M1056.6,466.7c-0.5,0-0.8-0.3-0.8-0.9l0-6.2c0-0.4,0.3-0.7,0.7-0.7c0,0,0,0,0.1,0l67.6,5.7
			c0.3,0,0.5-0.2,0.5-0.5c0,0,0,0,0,0l4.4-198.5c0-0.5-0.4-0.9-0.8-0.9l-69.5-2.2c-0.3,0-0.5-0.2-0.5-0.5V256c0-0.4,0.4-0.8,0.8-0.8
			c0,0,0,0,0,0l72.8,2.1c0.2,0,0.4,0.1,0.4,0.4l-5,213.8c0,0.3-0.2,0.5-0.5,0.6c-2.1,0.3-3.5,0.4-4.2,0.3
			C1101.2,470.6,1079.2,468.7,1056.6,466.7z"/>
                                                                    <path d="M1129.6,473c-0.5-0.1-0.8-0.5-0.8-1l4.9-213.8c0-0.3,0.3-0.6,0.6-0.6l11.8-0.6c0.2,0,0.3,0.1,0.3,0.3
			c0,0,0,0,0,0l-5.3,212.8c0,0.3-0.1,0.5-0.4,0.7C1137.5,473.1,1133.7,473.8,1129.6,473z"/>
                                                                    <path d="M487.7,352c-0.5,0-0.6-0.2-0.1-0.5c0.4-0.3,0.9-0.4,1.6-0.4c25.6,1.6,51.2,3.2,76.9,4.8
			c0.2,0,0.3,0.2,0.3,0.3c0,0.1-0.1,0.3-0.2,0.3c-1,0.4-2.1,0.6-3.4,0.5C537.8,355.5,512.8,353.8,487.7,352z"/>
                                                                    <path d="M485.6,361.2l4.5,66.8c0,0.2-0.2,0.5-0.4,0.5l-4.1,1c-0.3,0.1-0.5-0.1-0.6-0.4c0,0,0,0,0-0.1l-5.3-75.7
			c0-0.3,0.2-0.5,0.5-0.5c0,0,0.1,0,0.1,0l82.9,5.4c0.3,0,0.5,0.2,0.5,0.5l3.7,65.1c0,0.3-0.2,0.5-0.5,0.5c0,0-0.1,0-0.1,0l-4.6-0.5
			c-0.2,0-0.4-0.2-0.4-0.5l-3.4-57.3c0-0.3-0.2-0.5-0.5-0.5l-71.8-4.9C485.9,360.7,485.6,360.9,485.6,361.2
			C485.6,361.2,485.6,361.2,485.6,361.2z"/>
                                                                    <path d="M569.8,423.9c-0.2,0.2-0.4,0.2-0.6,0.2c-0.2-0.1-0.3-0.2-0.3-0.4l-3.8-64.9c0-0.4,0.2-0.6,0.5-0.7l6.7-1.1
			c0.3-0.1,0.6,0.2,0.7,0.5c0,0,0,0,0,0.1l3.6,64.1c0,0.3-0.1,0.5-0.4,0.6C574,423.1,571.9,423.6,569.8,423.9z"/>
                                                                    <path d="M487.7,362.3l4.2,0.3c0.3,0,0.5,0.2,0.5,0.5c0.6,12.2,1.5,24.5,2.7,36.7c0.1,1.4,0.1,3.4-0.1,5.8
			c-0.2,2.4,0.7,4,0.8,6.3c0.2,4.5,0.7,9.1,1.3,13.7c0.1,0.7-0.4,1.4-1.1,1.6l-3.8,0.8c-0.3,0.1-0.5-0.1-0.6-0.4l-4.4-64.8
			C487.1,362.5,487.3,362.3,487.7,362.3z"/>
                                                                    <path d="M1470.9,416.1c-0.4,0-0.5-0.2-0.3-0.5c0.3-0.7,1.3-1,2.1-0.9c48.6,3.1,97.3,6.3,146.2,9.5
			c0.3,0,0.5,0.3,0.5,0.6c0,0.1-0.1,0.2-0.1,0.3c-1.3,1.4-3.2,0.6-4.6,0.5C1566.8,422.5,1518.9,419.4,1470.9,416.1z"/>
                                                                    <path d="M539.3,425.8c-7.9-4.8,18-10.3,19.7-10.6c0.2,0,0.4,0.1,0.5,0.3c0,0.2-0.1,0.4-0.2,0.4
			c-3.2,1.3-13.9,3.3-13.3,8c0,0.3,0.1,0.5,0.3,0.7c1.9,2.1,4.1,3.4,6.6,3.9c10.1,1.9,20.3,3.2,30.6,3.8c0.2,0,0.4,0.2,0.4,0.5
			c0,0.1,0,0.2-0.1,0.2c-0.2,0.3-0.6,0.4-1,0.4c-13.3-1.2-26.4-2.2-39.4-5.7C542.3,427.4,540.9,426.8,539.3,425.8z"/>
                                                                    <path d="M559.5,424.2c-3.9,0.8-8.3,3.9-11.6,0c-0.6-0.6-0.5-1.6,0.2-2.2c0.1-0.1,0.1-0.1,0.2-0.2
			c3.5-2,7.3-3.5,11.3-4.3c0.2-0.1,0.4,0.1,0.5,0.3c0,0,0,0,0,0.1l0.3,5.2C560.3,423.6,560,424.1,559.5,424.2z"/>
                                                                    <path d="M1480,428.5c-0.9-0.1-1.4,0.4-1.5,1.3l-4.6,62.5c-0.1,1-0.7,1.9-1.7,2.2l-8.5,3c-0.4,0.1-0.6,0-0.6-0.4
			l5.6-78.9c0-0.4,0.4-0.7,0.8-0.7c0,0,0,0,0,0l148.2,9.7c0.2,0,0.4,0.2,0.4,0.5c0,0,0,0,0,0L1602.8,594c0,0.3-0.3,0.6-0.7,0.6
			c0,0,0,0,0,0l-9.6-1c-0.4,0-0.5-0.3-0.5-0.6l13.8-155.2c0-0.3-0.2-0.6-0.6-0.7c0,0,0,0,0,0L1480,428.5z"/>
                                                                    <path d="M958.3,440.6c-0.1,0-0.1-0.1-0.2-0.2c0,0,0.1-0.1,0.2-0.2c13.1-4.8,26.2-9.6,39.3-14.3
			c4.1-1.5,7.5-1.5,11.5-0.8c0.6,0.1,0.6,0.3,0,0.5c-13.4,5-26.7,9.9-40.1,14.7C965.5,441.7,962,441.1,958.3,440.6z"/>
                                                                    <path d="M583.8,455.9c-6.6-0.5-44.6-2.6-45-11.1c-0.3-5.8-0.6-11.7-1-17.6c-0.1-0.8,0.2-0.9,0.7-0.3l0.7,0.7
			c0,0,0.1,0.1,0.1,0.1c3.8,1.6,6.8,2.6,8.9,3c11.2,1.8,22.5,3.1,33.8,4c0.6,0,1,0.5,1.1,1.1l1.1,19.8
			C584.3,455.8,584.1,455.9,583.8,455.9z"/>
                                                                    <path d="M500.6,430.1c0,0.1-0.1,0.2-0.2,0.3c0,0-0.1,0.1-0.2,0.1c-13.6,2.7-27.8,6.4-39.6,13.8
			c-0.2,0.1-0.4,0-0.5-0.1c0-0.1-0.1-0.1,0-0.2c0.1-0.4,0.2-0.7,0.5-0.9c11.1-7.8,26.5-11.5,39.7-14.1c0.3-0.1,0.6,0.1,0.7,0.5
			c0,0,0,0.1,0,0.1c0,0.3-0.1,0.4-0.2,0.4C500.7,430,500.6,430,500.6,430.1z"/>
                                                                    <path d="M1480.4,434c-1.5,18.6-2.3,37.3-4.3,55.8c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.1-0.3,0-0.3-0.2
			c1.4-19.6,2.9-39.2,4.3-58.8c0-0.3,0.1-0.5,0.3-0.6c0.2-0.2,0.3-0.2,0.3,0.1C1480.5,432.4,1480.5,433.5,1480.4,434z"/>
                                                                    <path d="M493.8,464.1c-0.5,0.2-1.2,0.2-1.9,0.1c-11.7-2.5-22.5-4.1-34.1-9.5c-2.6-1.2-3.9-3.3-5.8-5.3
			c-0.2-0.2-0.3-0.5-0.1-0.8l2.6-5.2c0.1-0.3,0.3-0.4,0.6-0.6c3.8-1.9,7.4-4.4,11.2-5.8c4.2-1.6,8.1-2.8,12-3.9
			c1.3-0.4,1.4-0.3,0.1,0.3c-3,1.3-6.6,1.9-9.5,3.5c-3.1,1.6-6.1,3.3-9,5.1c-0.2,0.1-0.4,0.3-0.5,0.5l-2.8,5.5
			c-0.3,0.5-0.2,1.2,0.2,1.6c2.1,2.3,3.9,4.8,6.9,5.9c4.8,1.9,9.2,3.9,14.3,4.9c5.3,1.1,10.6,2.2,15.8,3.2c0.1,0,0.2,0.2,0.2,0.3
			C493.9,464,493.9,464,493.8,464.1z"/>
                                                                    <path d="M694.4,442.2c39.8,3.5,79.5,6.8,119.3,10.6c0.2,0,0.3,0.2,0.3,0.3c0,0.1-0.1,0.2-0.2,0.3
			c-0.3,0.1-0.7,0.2-1.1,0.1c-70.7-6.1-141.5-12.3-212.2-18.5c-4.1-0.4-7.4-0.7-9.7-1.1c-0.2,0-0.3-0.2-0.2-0.4c0-0.1,0-0.1,0.1-0.2
			c0.2-0.2,0.6-0.3,1-0.2c2.8,0.3,5.3,0.8,8,0.8c3.8,0,7.3,0.8,11.2,0.9c2.9,0.1,5.6,1,8.4,0.8c3.6-0.2,7.2,0.7,10.5,0.9
			c3.4,0.2,6.4,0.9,9.7,0.9c1.6,0,3.2,0.1,4.8,0.4c6.2,1.1,12.8,0.5,18.9,1.6c3.3,0.6,6.7,0.4,10,0.9c2.7,0.4,5.5,0.3,8,0.6
			C684.2,441.3,688.6,441.7,694.4,442.2z"/>
                                                                    <path d="M586.4,455.6l-1.1-19.9c0-0.4,0.3-0.7,0.7-0.8c0,0,0.1,0,0.1,0l225.8,20c0.4,0,0.7,0.3,0.7,0.7l0.6,21.4
			c0,0.4-0.3,0.7-0.7,0.8c0,0-0.1,0-0.1,0l-225.3-21.5C586.7,456.3,586.4,456,586.4,455.6z"/>
                                                                    <path d="M954.8,529.7v-87.3c0-0.3,0.3-0.6,0.6-0.6c0,0,0,0,0.1,0l9.9,0.9c0.3,0,0.6,0.3,0.6,0.6l0.3,87.4
			c0,0.3-0.3,0.6-0.6,0.6c0,0,0,0-0.1,0l-10.1-1C955,530.3,954.8,530,954.8,529.7z"/>
                                                                    <path d="M456.6,474.3c-1.8-1.9-3.5-3.6-3.7-6.3c-0.5-5.3-0.9-10.7-1.3-15.9c0-0.2,0.2-0.4,0.4-0.4
			c0.1,0,0.2,0,0.3,0.1c3.6,4.4,7,5.1,12,7.1c6.3,2.5,13.2,3.5,19.8,4.9c10.3,2.1,20.9,3.2,31.5,4.5c0.3,0,0.5,0.2,0.5,0.6l1.4,20
			c0,0.2-0.2,0.5-0.4,0.5c0,0-0.1,0-0.1,0c-14.4-1.3-28.6-3.7-42.6-7.2c-4.8-1.2-9.3-3.3-14-5.2C459,476.3,457.6,475.4,456.6,474.3z
			"/>
                                                                    <path d="M819.9,454.4c-0.4,0-0.6-0.2-0.7-0.4c-0.1-0.2,0-0.5,0.2-0.5c0.1,0,0.1,0,0.2,0c27.4,2.3,55.3,5.9,81.9,13.1
			c9.1,2.5,18.9,6.3,25.9,12.9c14.4,13.4-27.4,29.2-34.8,32.1c-3.3,1.3-6.4,1.3-9.7,0.6c-1-0.2-1-0.5-0.1-0.8
			c7.8-2.9,47.1-17.6,35.2-31.4c-5.9-6.9-17.2-11.2-25.8-13.7C869.2,459.3,843.9,456.9,819.9,454.4z"/>
                                                                    <path d="M815.7,478.1c-0.4,0-0.6-0.3-0.6-0.7l-0.5-21.4c0-0.5,0.4-0.8,0.8-0.8c0,0,0.1,0,0.1,0
			c17.4,1.5,34.5,3.7,51.4,6.5c10,1.7,52.5,10.2,51.9,24c-0.2,5-6.6,9.8-10.7,11.9c-0.3,0.1-0.5,0.1-0.8,0
			c-9.5-4.6-18.5-7.9-27.2-9.7C859.1,483.6,837.6,480.3,815.7,478.1z"/>
                                                                    <path d="M519.7,489.1l-1.4-19.6c0-0.4,0.3-0.8,0.7-0.8c0,0,0.1,0,0.1,0l233.2,23.8c0.4,0,0.7,0.4,0.7,0.7l0.7,21.2
			c0,0.4-0.3,0.8-0.8,0.8c0,0-0.1,0-0.1,0l-232.5-25.4C520,489.7,519.7,489.4,519.7,489.1z"/>
                                                                    <path d="M967.5,491.4c0-0.1,0.1-0.2,0.2-0.3l44.2-19c0.3-0.1,0.4,0,0.5,0.2c0.6,1.8,0.2,2.9-1.6,3.7
			c-14.3,6.2-28.6,12.4-43,18.7c-0.2,0.1-0.4,0-0.5-0.2c0,0,0-0.1,0-0.2L967.5,491.4z"/>
                                                                    <path d="M458.1,499.5c-1.6-1.9-3.3-3.4-3.5-6.1c-0.4-6.1-0.9-12.3-1.3-18.4c0-0.2,0.1-0.5,0.4-0.5
			c0.2,0,0.3,0.1,0.4,0.2c3.1,4.5,7.5,5.4,12.3,7.5c6.2,2.7,13.1,3.7,19.7,5.2c10.3,2.3,20.8,3.4,31.3,4.6c0.2,0,0.4,0.2,0.4,0.4
			l1.5,23.1c0,0.4-0.1,0.5-0.5,0.5c-9.7-1.4-19.6-2.4-29-4.5c-4.6-1-9.1-2.1-13.7-3.2C470.9,507,461.7,503.6,458.1,499.5z"/>
                                                                    <path d="M1001.2,506.6l0.3-24.7c0-0.2,0.1-0.3,0.3-0.4l9.7-4.1c0.2-0.1,0.5,0,0.6,0.2c0,0.1,0,0.1,0,0.2l-0.2,29.8
			c0,0.2-0.2,0.4-0.5,0.4c0,0,0,0,0,0l-9.8-1C1001.3,507.1,1001.2,506.9,1001.2,506.6z"/>
                                                                    <path d="M929.8,491.3c0.1-0.1,0.3-0.2,0.4-0.1c0.1,0,0.1,0.1,0.1,0.2c1,4.6-0.2,8.6-3.7,11.8
			c-9.6,9-23.7,15.1-35.9,19.7c-0.2,0.1-0.4,0-0.5-0.2c0,0,0-0.1,0-0.1V515c0-0.6,0.4-1.2,1-1.4c11.9-4.5,24.2-9.9,34.4-17.7
			C927,494.9,928.4,493.3,929.8,491.3z"/>
                                                                    <path d="M452.3,754.8c-15.2-3-47.6-11.4-47.6-32c0-0.9,0.6-2.6,1.9-5c3.2-5.9,9.7-11.2,15.3-14.7
			c14.8-9.2,31.3-16,47.5-22.6c39.3-16,78.6-32.1,117.9-48.2c0.3-0.1,0.6,0,0.7,0.3c0,0.1,0,0.1,0,0.2l0,4.8c0,0.4-0.2,0.6-0.5,0.8
			c-36.5,15.2-72.9,30.3-109.4,45.3c-10.9,4.5-21.5,9.5-31.9,15c-7.7,4.1-15,9-19.6,16c-8.5,12.8,7.3,23.8,17.4,28
			c7.1,2.9,14.4,5.1,21.9,6.6c19.6,3.9,39.5,5.6,59.6,5c25-0.7,50.1-3.3,74.8-7.8c27.4-5.1,50.3-15.9,75.5-29.1
			c122.6-64,245.1-128,367.7-192c23-12,43.5-17.8,70.2-22.2c60.5-10,126-10.7,188.6-6.8c42.2,2.7,99.3,11.5,149.1,5
			c8-1,20.4-3.2,25.3-9.1c0.2-0.2,0.4-0.3,0.6-0.3l9,0.7c0.3,0,0.6,0.3,0.5,0.6c0,0.2-0.1,0.4-0.3,0.5c-1,0.6-1.6,1.6-2.6,2.1
			c-8.2,3.4-17,5.6-26.3,6.7c-33.4,4-63.4,2.9-98.3-0.3c-30.3-2.8-53.1-4.6-68.4-5.3c-39.1-2-76.7-2-114.4,0.4
			c-23.9,1.5-47.6,4.7-71,9.5c-18.5,3.8-36.1,10.3-52.6,19.3c-5.4,2.9-8.1,4.4-8.1,4.4c-121,64.5-242,128.9-363,193.4
			c-27.9,14.9-54.2,24.3-85.2,29.1C547.3,760.8,500.4,764.1,452.3,754.8z"/>
                                                                    <path d="M521.5,515.9L520,493c0-0.3,0.2-0.6,0.6-0.7c0,0,0.1,0,0.1,0L753.3,518c0.3,0,0.6,0.3,0.6,0.6l0.5,18.5
			c0,0.3-0.2,0.5-0.4,0.6l-12,4.6c-0.1,0-0.2,0.1-0.3,0L522,516.5C521.7,516.5,521.5,516.2,521.5,515.9z"/>
                                                                    <path d="M756.6,515.4c-0.4-0.1-0.6-0.3-0.6-0.7l-0.7-21.4c0-0.3,0.2-0.5,0.5-0.5c0,0,0,0,0.1,0
			c16.8,1.5,33.8,3,50.1,7.9c3.2,1,13.8,5.7,10.1,10.1c-4.4,5.1-11.9,7.9-18,10c-0.6,0.2-1.3,0.3-1.9,0.2c-11.3-2.1-22.6-3.7-34-4.7
			C760.5,516,758.7,515.7,756.6,515.4z"/>
                                                                    <path d="M779,493.8c-0.3-0.1-0.5-0.1-0.8-0.1c-0.2,0-0.3-0.1-0.3-0.4c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.1
			c4.9,0.8,10,1.2,14.9,2.4c1.3,0.3,2.6,0.1,3.8,0.4c4.8,1.1,9.7,2.3,14.4,3.7c3.5,1,13.3,5.3,12.7,9.6
			c-0.9,6.1-19.1,12.5-24.3,14.2c-0.7,0.2-1.6,0.3-2.3,0.2c-0.3,0-0.4-0.3-0.4-0.5c0-0.2,0.1-0.3,0.3-0.4c5.2-1.9,10.2-4.1,14.9-6.6
			c2.3-1.2,3.7-3.1,5.6-4.9c0.2-0.2,0.3-0.4,0.3-0.7c0.1-1.9,0.1-3.5-1.3-4.8C807.2,496.4,791.4,496.2,779,493.8z"/>
                                                                    <path d="M422.7,788.6c-6-3.9-13.3-10.5-14-18.2c-1.1-12.5-2.2-25-3.2-37.4c0-0.2,0.1-0.3,0.3-0.4
			c0.1,0,0.3,0.1,0.3,0.2c3.1,6.7,13.5,12.9,19.7,15.5c9.2,3.8,19.4,6.7,30.6,8.7c38.5,6.9,76,5.7,115.4,0.9
			c17.8-2.2,33.4-4.8,46.8-7.9c24.5-5.7,46.7-15.7,68.8-27.5c124.1-66.1,248.2-132.3,372.4-198.4c18.1-9.7,38-14.9,59.8-18.6
			c47.3-8,96.4-10,144.3-8.2c7.6,0.3,15.5,0.3,22.8,0.8c25.7,1.6,51.4,3.7,77.1,6.1c33.5,3.2,68.3,3.7,100.3-1
			c6-0.9,12.7-2.8,20.2-5.9c0.3-0.1,0.5-0.3,0.8-0.5l1.8-1.5c0.2-0.2,0.5-0.1,0.6,0.1c0.1,0.1,0.1,0.2,0.1,0.3l-1.9,23.6
			c0,0.3-0.1,0.5-0.3,0.7c-5,6.7-21,9.6-29.1,10.6c-27,3.5-52.4,3.5-80.6,1.1c-22.4-1.9-44.7-3.8-67.1-5.7
			c-43.4-3.7-86.9-4-130.4-1.1c-32.1,2.2-63.3,6-92.2,15.3c-11.7,3.8-22.1,9.2-34.1,16.1C930,626.6,807.9,696.8,685.7,767
			c-11.9,6.9-26.7,14.7-40.1,19.8c-13.7,5.3-28.7,9.4-45.1,12.3c-27.4,4.9-52.8,7.6-76.2,8.2c-15.8,0.4-31.6-0.4-47.3-2.5
			C459.1,802.4,438.4,798.6,422.7,788.6z"/>
                                                                    <path d="M1008.2,509.1c0.4,0.1,0.6,0.2,0.7,0.3c0.2,0.3,0.2,0.5-0.1,0.7l-40.4,19.4c-0.3,0.2-0.5,0.1-0.5-0.3v-4.6
			c0-0.4,0.2-0.6,0.5-0.8c9.9-4.7,19.9-9.3,29.8-14C1001.4,508.2,1004.7,508.6,1008.2,509.1z"/>
                                                                    <path d="M824.3,513.1c-2.1,2.4-3.3,4.1-6.2,5.7c-5.6,3-11.5,5.6-17.5,7.7c-0.3,0.1-0.4,0-0.5-0.2
			c-0.4-0.9-0.1-1.6,0.9-1.9c6.3-2.2,12.4-4.8,18.3-8c1.8-1,3-2.4,4.4-3.8c0.2-0.2,0.5-0.2,0.7,0
			C824.5,512.6,824.5,512.9,824.3,513.1L824.3,513.1z"/>
                                                                    <path d="M884.6,514c0.3,0,0.4,0.1,0.5,0.2c0.1,0.2,0,0.5-0.2,0.6c0,0,0,0-0.1,0l-182,75.6c-0.3,0.1-0.5,0-0.5-0.3
			v-4.2c0-0.4,0.2-0.6,0.5-0.7c57.7-23.6,115.4-47.2,173.1-70.8C878.8,513.1,881.6,513.6,884.6,514z"/>
                                                                    <path d="M702.9,602.7l-0.5-10.3c0-0.2,0.1-0.4,0.3-0.5L887.8,515c0.3-0.1,0.5,0,0.7,0.3c0,0.1,0,0.1,0,0.2v8.2
			c0,0.2-0.1,0.4-0.3,0.5l-184.6,79c-0.3,0.1-0.5,0-0.7-0.3C702.9,602.8,702.9,602.8,702.9,602.7z"/>
                                                                    <path d="M501.3,515.7c-0.1,0-0.2-0.2-0.2-0.3c0-0.1,0.2-0.2,0.3-0.2l26.9,3.5c0.3,0,0.4,0.3,0.4,0.5c0,0,0,0,0,0
			c-0.1,0.4-0.3,0.5-0.8,0.5C519.1,518.9,510.2,517.5,501.3,515.7z"/>
                                                                    <path d="M790.5,522.6c0.3,0.1,0.6,0.2,0.8,0.3c0.1,0.1,0.1,0.3,0,0.4c0,0-0.1,0.1-0.1,0.1l-34.1,13.1
			c-0.2,0.1-0.5,0-0.5-0.2c0,0,0-0.1,0-0.1l-0.5-17c0-0.4,0.3-0.7,0.6-0.7c0,0,0,0,0.1,0C768,519.4,779.3,520.8,790.5,522.6z"/>
                                                                    <path d="M531.2,520.1c-0.2,0-0.4-0.3-0.4-0.5c0,0,0,0,0,0c0.1-0.4,0.3-0.5,0.7-0.5c54.4,6.4,108.9,12.7,163.3,19.1
			c0.1,0,0.2,0.1,0.2,0.2l0.2,0.4c0,0.1,0,0.1-0.1,0.1c-4.3-0.3-7.9-0.9-12.8-1c-1.9,0-4.1-0.2-6.8-0.5
			c-45.8-5.4-91.6-10.8-137.3-16.2C536,520.9,533.6,520.5,531.2,520.1z"/>
                                                                    <path d="M701.2,560.4c0.2-0.6,0.3-0.9,0.5-1c29.6-11.4,59.3-22.9,88.9-34.3c1.3-0.5,2.6-0.4,3.9-0.3
			c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.2-0.2,0.3c-30.9,12-61.6,24-92.2,35.8c-0.6,0.2-1,0.1-1.2-0.5
			C701.1,560.6,701.1,560.5,701.2,560.4z"/>
                                                                    <path d="M701.5,563.4c0.1-0.3,0.2-0.5,0.5-0.6l95.4-37.1c0.2-0.1,0.4,0,0.5,0.2c0.5,0.9,0.3,1.5-0.4,1.8
			c-31.8,12.5-63.6,25-95.5,37.5c-0.3,0.1-0.6,0-0.7-0.3c0-0.1-0.1-0.2,0-0.3L701.5,563.4z"/>
                                                                    <path d="M1365,552.8c-0.6,0-1.2-0.1-1.7-0.1c-0.3,0-0.4-0.2-0.2-0.4c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1
			c9,0.3,17.9,1.5,26.9,1.7c4,0.1,8.1,0.6,11.7,0.7c9.9,0.2,19.8,0.2,29.7,0.7c10.1,0.5,22.1-0.3,30.1-0.4
			c21.1-0.2,36.8-1.5,58.8-3.8c10.2-1.1,20.2-3.1,30.2-4.9c8.9-1.6,17.3-5.1,25.8-8.1c3.7-1.3,12.2-7.6,13.6-11.3
			c0.1-0.3,0.3-0.4,0.6-0.4l4.4,0.4c0.7,0.1,1.2,0.7,1.1,1.3c0,0.3-0.2,0.6-0.4,0.8c-3.7,3.3-7.7,7.5-12.4,9.2
			c-8.4,3-16.7,6.5-25.4,8.2c-11,2.1-21.9,4.4-33,5.6c-21.3,2.3-42,4.1-63.9,4.2c-2.1,0-5.6,0.1-10.3,0.4
			c-5.2,0.3-10.3,0.1-15.7,0.1c-2.4,0-5-0.5-7.1-0.5c-7.8,0-15.5-0.1-23.3-0.3c-1.6,0-3.4,0.3-4.8,0.2
			C1388.4,554.6,1376.7,553.6,1365,552.8z"/>
                                                                    <path d="M1286.7,573.6c-22.7-2.2-45.5-1.9-68,0.1c-14.5,1.3-28.5,4-41.3,9.2c-2.3,0.9-4.4,2.2-6.5,3.7
			c-42.4,31.3-84.7,62.7-127,94.2c-0.2,0.1-0.4,0.1-0.5-0.1c0-0.1-0.1-0.1-0.1-0.2l0.7-32c0-0.2,0.1-0.4,0.3-0.6
			c43-29.9,85.7-60.3,129.2-89.4c3.8-2.6,10.9-5,13.9-5.8c9.5-2.5,18.8-4.2,27.9-5.2c24.4-2.6,49.2-2.6,74-0.4
			c17.5,1.6,35,3.3,52.4,5c16,1.6,32.1,2.5,48.1,3.6c5.3,0.4,10.9,0.6,16.9,0.7c12.5,0.1,24.9,0.7,37.5,0.8
			c9.8,0.1,20.2-0.7,30.3-0.8c8.9-0.1,17.4-0.3,26-1.2c13.3-1.4,26.8-2.2,39.9-4.6c9.8-1.8,19.9-3.1,29.3-6.4
			c5.4-1.9,10.7-3.8,16-5.8c3.6-1.3,6.9-5,9.8-7.4c0.2-0.1,0.4-0.1,0.5,0c0.1,0.1,0.1,0.2,0.1,0.3l-2.1,23.2c0,0.3-0.1,0.5-0.3,0.7
			c-2,2.3-4.2,4.3-6.5,6.3c-1.8,1.5-4,2.8-6.6,3.8c-9.1,3.7-17.7,7.2-27.6,9c-9.6,1.8-19.1,4-28.6,5.1c-14.2,1.6-28.4,3.4-42.8,3.8
			c-11.6,0.3-23.2,0.7-34.9,1.2c-9.7,0.4-21.3-0.5-30.1-0.6C1373.1,583.4,1329.8,577.7,1286.7,573.6z"/>
                                                                    <path d="M698,539.3c-0.2,0-0.4-0.1-0.4-0.3c-0.1-0.3,0-0.4,0.4-0.4c13.2,1.6,26.6,3.1,40,4.7c0.2,0,0.3,0.2,0.2,0.3
			c0,0.1-0.1,0.2-0.1,0.2c-0.8,0.6-1.8,0.8-3,0.6c-11.1-1.3-22.2-2.7-33.3-4c-0.3,0-0.5-0.1-0.8-0.3
			C700.2,539.5,699.2,539.3,698,539.3z"/>
                                                                    <path d="M590.2,573.8c-0.4,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.2-0.3-0.1-0.5c0-0.1,0.1-0.1,0.2-0.1
			c30.8-11.1,61.7-22.2,92.5-33.2c3.7-1.3,9.3,0,13.2,0.6c0.2,0,0.3,0.2,0.3,0.4c0,0.1-0.1,0.2-0.2,0.3
			c-30.3,11.1-60.7,22.3-91.2,33.5C600.6,575.8,593.9,574.2,590.2,573.8z"/>
                                                                    <path d="M1037.5,646.6c-0.3,0-0.5-0.3-0.5-0.6c0-0.2,0.1-0.3,0.2-0.4c42.3-29,84.2-58.1,126.3-86.5
			c4.9-3.3,11.6-6.1,16.4-7.4c15.5-4.2,31.3-6.7,47.4-7.3c4.1-0.2,8.3-0.7,12.2-0.8c10.8,0,21.6,0,32.4,0c0.1,0,0.2,0,0.3,0.1
			c0.2,0.1,0.3,0.1,0.3,0.1c0.2,0.1,0.3,0.3,0.2,0.5c-0.1,0.2-0.2,0.3-0.4,0.3c-27.2-0.4-54.3-0.5-81.2,5.7
			c-5.6,1.3-13.1,3.6-18,6.8c-4.2,2.8-8.4,5.6-12.6,8.5c-38.4,26.7-76.8,53.3-115.1,80C1042.3,647.7,1040.8,646.8,1037.5,646.6z"/>
                                                                    <path d="M1280.3,544.5c5.2,0.2,10.4,0.6,15.6,1.1c20.3,1.9,40.6,4.6,60.9,5.7c1.7,0.1,3.4,0.4,5.1,0.9
			c0.2,0.1,0.2,0.2,0.1,0.3c-0.1,0.2-0.3,0.2-0.6,0.2c-4.2-0.6-8.9-0.6-12.1-0.9c-21.4-2.3-42.9-4.3-64.3-6.2
			c-2.4-0.2-4.8-0.4-7.2-0.5c-0.2,0-0.3-0.2-0.3-0.4c0-0.1,0.1-0.2,0.2-0.3C1278.6,544.1,1279.7,544.5,1280.3,544.5z"/>
                                                                    <path d="M1583.5,594.3c-1.1,1.1-2.5,2-3.9,2.7c-8.2,3.5-17.6,8-26.7,10c-6,1.3-12,2.5-17.9,3.8
			c-12.1,2.5-24.4,3.5-36.6,4.9c-5.7,0.7-11.3,1.1-16.8,1.3c-16.5,0.6-33.1,1.7-49.5,1.2c-16.9-0.4-33.9-0.5-50.8-2
			c-15.3-1.3-30.7-2.3-45.9-4.1c-27.2-3.3-45.1-5.2-53.6-5.9c-20.1-1.7-40.1-1.6-60.2,0.1c-15.1,1.3-31.1,4-44.5,10.2
			c-6.6,3-13.8,9.3-18.3,12.9c-38.4,30.8-76.8,61.5-115.2,92.1c-0.1,0.1-0.3,0.1-0.4,0c0-0.1-0.1-0.1-0.1-0.2l0.8-36.1
			c0-0.3,0.1-0.5,0.4-0.7c42.6-31.8,84.9-63.9,127.9-95.2c1.6-1.2,3.2-2.1,4.9-2.8c16.7-7.4,35.5-9.5,53.9-10.7
			c20.1-1.3,40.5-0.9,61.2,1.3c10.1,1.1,31.6,3.2,64.4,6.3c21.9,2.1,47.4,3.4,76.3,4c16.7,0.3,32.1-0.4,48.1-0.9
			c14-0.4,27.6-2,41.4-3.6c11.3-1.3,22.4-3.7,33.6-5.9c9.2-1.8,18.1-5.9,26.8-9.2c4-1.5,7.3-5.4,10.4-8.3c0.1-0.1,0.4-0.1,0.5,0
			c0.1,0.1,0.1,0.2,0.1,0.3l-2.4,26.3c0,0.5-0.3,0.9-0.6,1.3L1583.5,594.3z"/>
                                                                    <path d="M593.2,700.4l-7-125.2c0-0.3,0.2-0.6,0.5-0.6c0,0,0.1,0,0.1,0l14.2,1.8c0.3,0,0.5,0.3,0.5,0.5l6.8,125.9
			c0,0.3-0.2,0.6-0.5,0.6c0,0-0.1,0-0.1,0l-13.9-2.5C593.4,700.9,593.2,700.7,593.2,700.4z"/>
                                                                    <path d="M364.4,690.7h-1.5c-0.2,0-0.4-0.2-0.3-0.4c0-0.1,0.1-0.3,0.2-0.3l223.1-86c0.2-0.1,0.5,0,0.6,0.3
			c0,0,0,0.1,0,0.2v1.2c0,0.4-0.2,0.6-0.5,0.7c-72.2,28.2-144.4,56.3-216.6,84.5c-1,0.4-2.4,0.4-4.1-0.1
			C365,690.7,364.7,690.7,364.4,690.7z"/>
                                                                    <path d="M606.9,651.3c-0.2-1-0.3-1.9-0.1-2.9c0-0.1,0-0.3,0-0.4c-0.4-1.2,0.2-2,1.3-2.5c31-13.3,62-26.6,93-39.9
			c0.2-0.1,0.4,0,0.5,0.2c0,0.1,0,0.1,0,0.2v4.4c0,0.3-0.2,0.6-0.5,0.7l-93.4,40.7C607.3,651.9,607,651.8,606.9,651.3z"/>
                                                                    <path d="M1289.9,610.2c-30.3-3.2-62.2-3.2-92.4,1.5c-0.8,0.1-0.9,0-0.1-0.3c0.8-0.4,1.9-0.7,3.1-0.9
			c28.1-5,57.3-5.4,85.6-2.5c17.4,1.8,34.8,3.7,52.2,5.8c24.7,2.9,49.7,4.6,74.6,5.4c9.4,0.3,18.7,0.5,28.1,0.4
			c13.2,0,27.3-0.9,40.9-1.4c11.4-0.4,22.8-2,34.2-3.1c1-0.1,1.9-0.1,2.8-0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
			c-9.9,1.3-19.8,2.5-29.7,3.6c-15.1,1.6-30.5,1.7-45.7,2.3c-5.7,0.2-11.3,0.2-16.9,0.1c-15.2-0.4-30.5-0.6-45.7-1.8
			c-14.9-1.2-29.7-2.3-44.5-4C1320.9,613.6,1305.4,611.9,1289.9,610.2z"/>
                                                                    <path d="M368.6,695.5l-0.1-2.6c0-0.2,0.1-0.3,0.3-0.4l217.4-84.7c0.2-0.1,0.4,0,0.5,0.2c0,0,0,0.1,0,0.1v2
			c0,0.2-0.1,0.3-0.3,0.4l-217.3,85.3c-0.2,0.1-0.4,0-0.5-0.2C368.6,695.6,368.6,695.6,368.6,695.5z"/>
                                                                    <path d="M688.4,653.9l-1.5-34.7c0-0.2,0.1-0.4,0.3-0.5l12.9-5.6c0.3-0.1,0.6,0,0.7,0.3c0,0.1,0,0.1,0,0.2l1.6,42.4
			c0,0.3-0.2,0.6-0.5,0.6c0,0-0.1,0-0.1,0l-13.1-2C688.6,654.3,688.4,654.1,688.4,653.9z"/>
                                                                    <path d="M428.2,729.2c-14.8-17.6,33.1-36.8,42.9-40.9c38.6-16.1,77.5-32.3,116.4-48.4c0.5-0.2,0.8,0,0.8,0.5l0.7,10.8
			c0,0.7-0.2,1.1-0.9,1.4c-38.2,16.3-76.5,32.7-114.7,49.2c-14.3,6.2-35.1,14.7-44.6,27.5C428.8,729.3,428.5,729.4,428.2,729.2
			C428.3,729.2,428.3,729.2,428.2,729.2z"/>
                                                                    <path d="M844.1,777.8L1033.2,648c0.1,0,0.1-0.1,0.2-0.1l6.5,0.7c0.2,0,0.3,0.2,0.3,0.4c0,0.1-0.1,0.2-0.1,0.2
			L852.3,779.8c-0.1,0-0.2,0.1-0.2,0.1l-7.8-1.5c-0.2,0-0.3-0.2-0.3-0.4C844,777.9,844,777.8,844.1,777.8z"/>
                                                                    <path d="M1041.5,682.5L853.9,821.9c-0.1,0.1-0.3,0.1-0.4,0c0,0-0.1-0.1-0.1-0.2l-0.5-40.6c0-0.1,0-0.2,0.1-0.2
			L1041.4,650c0.1-0.1,0.3-0.1,0.4,0.1c0,0,0.1,0.1,0,0.2l-0.2,32C1041.6,682.4,1041.6,682.4,1041.5,682.5z"/>
                                                                    <path d="M699.8,657.5c0.3,0.1,0.4,0.2,0.5,0.4c0,0.1,0,0.2-0.1,0.2l-88.7,42.5c-0.3,0.2-0.7,0-0.8-0.3
			c0-0.1-0.1-0.2-0.1-0.2l-0.3-7.2c0-0.4,0.2-0.7,0.5-0.8c25.4-11.9,50.7-23.9,76.1-35.9C688.9,655.2,697.6,657.1,699.8,657.5z"/>
                                                                    <path d="M854,826.1l187-139.4c0.1-0.1,0.3-0.1,0.4,0.1c0,0.1,0.1,0.1,0.1,0.2l-0.5,36.3c0,0.1,0,0.2-0.1,0.2
			l-186,148.7c-0.1,0.1-0.3,0.1-0.4-0.1c0-0.1-0.1-0.1-0.1-0.2l-0.5-45.5C853.8,826.3,853.9,826.2,854,826.1z"/>
                                                                    <path d="M254.4,749.4c-6.9,8-13.8,16.1-20.7,24.1c-0.2,0.2-0.3,0.5-0.4,0.8l-4.8,24.6c-0.1,0.4,0,0.9,0.2,1.3
			c4.9,8.3,9.9,16.7,14.9,25.1c0.6,1.1,1.5,1.9,2.5,2.5c12.1,7.3,24.2,14.5,36.4,21.8c0.7,0.4,1.5,0.8,2.4,1
			c17.6,5,35.1,9.9,52.7,14.9c3.7,1.1,7.4,1.8,11.2,2.3c20.5,2.7,41,5.2,61.5,7.7c2.4,0.3,4.7,0.5,6.9,0.5c26,0.6,52,0.4,78,0.8
			c5.5,0.1,11-0.1,16.4-0.6c22.9-2,45.8-4,68.6-6.2c5.6-0.5,11.1-1.3,16.7-2.3c23.1-4.3,46.2-8.7,69.3-13c5.1-1,10.1-2.2,15-3.7
			c20.3-6.2,40.6-12.4,60.9-18.6c3.8-1.2,7.5-2.6,11.1-4.3c15.5-7.3,30.9-14.6,46.4-21.9c2.2-1,4.2-2.2,6.2-3.5
			c10.9-7.2,22.1-14.6,33.6-22.3c0.6-0.4,1.3-0.6,2.1-0.4l7.3,1.2c0.2,0,0.3,0.2,0.3,0.4c0,0.1-0.1,0.2-0.2,0.3
			c-11.1,7.5-22.2,14.9-33.1,22.3c-1.7,1.1-3.4,2.1-5.2,3c-16.6,8-33.3,16-50,23.8c-3.6,1.7-7.4,3.1-11.3,4.3
			c-20.9,6.4-41.8,12.8-62.7,19.2c-4.8,1.5-9.7,2.7-14.7,3.7c-24.8,4.7-49.7,9.4-74.6,14.1c-5.5,1-11.1,1.8-16.7,2.3
			c-23.2,2.1-46.3,4.2-69.5,6.3c-5.6,0.5-11.2,0.7-16.9,0.7c-26.2-0.1-52.5-0.3-78.7-0.7c-5.4-0.1-10.9-0.4-16.3-1.1
			c-20.4-2.5-40.8-5.1-61.2-7.7c-3.7-0.5-7.4-1.2-11-2.3c-17.8-5.1-35.5-10.2-53.3-15.3c-2.1-0.6-4-1.4-5.8-2.6
			c-11.9-7.3-23.8-14.5-35.7-21.7c-1-0.6-1.7-1.4-2.3-2.4c-4.9-8.7-9.8-17.3-14.6-25.8c-0.2-0.4-0.3-0.9-0.2-1.3l5.3-24.4
			c0.1-0.6,0.4-1.1,0.8-1.5c7.8-8.9,15.7-17.9,23.7-26.9c0.5-0.5,1.4-1.2,2.8-2.1c9.9-6.4,19.8-12.7,29.7-19.1
			c3.1-2,6.3-3.7,9.6-5.2c22.8-10.1,43.3-19.4,68.9-29c2.6-1,4.4-0.9,6.8,0c0.2,0.1,0.2,0.2,0.2,0.4c0,0.1-0.1,0.2-0.2,0.2
			c-10.6,4.1-21.1,8.1-31.5,12.8c-11.1,4.9-22.2,9.9-33.3,14.9c-3.8,1.7-7.5,3.7-10.9,5.8c-9.4,6-18.8,12.1-28,18.4
			C257.5,746.3,255.9,747.7,254.4,749.4z"/>
                                                                    <path d="M257.3,748.3c1.6-1.8,4.8-3.5,6.4-4.6c8.4-5.6,16.9-11.2,25.4-16.6c3.2-2,6.6-3.8,10.3-5.5
			c21.9-9.7,41.1-18.7,66.1-27.9c0.4-0.1,0.6,0,0.6,0.4l0.2,2.3c0,0.4-0.1,0.6-0.5,0.7c-25.9,9.9-45.7,19-68.3,29.3
			c-3.5,1.6-6.8,3.4-10,5.5c-9.2,6-18.3,12-27.3,18.1c-1.8,1.2-3.4,2.6-4.8,4.2c-7,8.2-14,16.4-21,24.6c-0.2,0.2-0.3,0.2-0.3-0.1
			c-0.2-2.1,0-3.6,0.6-4.3C246.2,761.1,253.7,752.3,257.3,748.3z"/>
                                                                    <path d="M863.2,817.6c-3.3,2.4-6.6,4.9-9.9,7.3c-0.3,0.2-0.4,0.1-0.5-0.2c0-0.2,0-0.3,0.2-0.4
			c39.4-29.4,78.9-58.7,118.4-88c0.4-0.3,0.5-0.2,0.3,0.2c-0.5,1-1.8,1.5-2.5,2c-35,26-70,52.1-104.9,78.3c0,0-0.2,0.2-0.4,0.4
			c-0.1,0.1-0.2,0.2-0.3,0.2C863.4,817.5,863.3,817.6,863.2,817.6z"/>
                                                                    <path d="M403.5,928.8c-20.2-2.6-40.4-5.2-60.5-8.1c-3.7-0.5-7.3-1.4-10.9-2.5c-17.7-5.4-35.4-10.8-53-16.3
			c-2-0.6-3.9-1.5-5.6-2.6c-11.9-7.7-23.8-15.4-35.7-23.1c-1-0.7-1.8-1.5-2.4-2.6c-4.6-8.6-9.2-17.2-13.8-25.7
			c-0.7-1.4-1.2-2.9-1.4-4.5c-1.6-12.3-3-24.6-4.3-36.8c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2,0.1,0.3,0.2c4.2,7.5,8.5,15,12.7,22.5
			c0.6,1,1.3,1.8,2.3,2.4c12.2,7.5,24.4,14.9,36.7,22.3c1.8,1.1,3.8,2,5.9,2.5c17.4,5,34.9,10,52.3,15c3.8,1.1,7.5,1.9,11.4,2.4
			c20.4,2.7,40.7,5.3,61.1,7.8c5.6,0.7,11.2,1.1,16.8,1.1c26.2,0.4,52.5,0.7,78.7,0.8c5.6,0,11.2-0.2,16.9-0.7
			c23.2-2.1,46.5-4.2,69.7-6.3c5.6-0.5,11.2-1.3,16.7-2.3c26.5-5,53-10,79.5-15c2-0.4,3.9-0.9,5.9-1.5c23.3-7.1,46.5-14.3,69.7-21.7
			c3.7-1.2,7.3-2.6,10.8-4.3c14.6-7.1,29.2-14,43.8-21c3.6-1.7,7-3.6,10.2-5.7c11-7.3,21.9-14.7,32.8-22.1c0.1-0.1,0.3-0.1,0.4,0.1
			c0,0.1,0.1,0.1,0.1,0.2l0.5,40.2c0,0.3-0.1,0.5-0.3,0.7c-10.7,7.8-21.5,15.6-32.2,23.4c-3,2.2-6.3,4.1-9.6,5.9
			c-17.1,8.7-32.9,16.8-47.3,24.2c-2.1,1.1-4.2,1.9-6.4,2.7c-23.1,7.7-46.2,15.3-69.4,22.8c-4.7,1.5-9.6,2.8-14.5,3.8
			c-25.7,5.1-51.4,10.3-77.1,15.4c-1.7,0.3-3.4,0.6-5.1,0.8c-24.9,2.4-49.8,4.8-74.7,7.2c-5.6,0.5-11.2,0.8-16.8,0.8
			c-26.4-0.2-52.8-0.5-79.2-0.8C413.7,929.8,408.6,929.4,403.5,928.8z"/>
                                                                    <path d="M819.9,848.2c9.7-7.2,19.6-14.5,29.6-21.2c0.1-0.1,0.3-0.1,0.4,0.1c0,0.1,0.1,0.2,0,0.2
			c-0.1,0.2-0.2,0.3-0.4,0.5c-10.2,7.3-20.3,14.6-30.3,22c-2.9,2.1-5.9,4-9,5.6c-15.7,8.1-31.4,16.1-47.2,24.1
			c-3.5,1.8-7,3.3-10.7,4.5c-21.7,7.3-43.5,14.5-65.3,21.6c-4.6,1.5-9.3,2.7-14.1,3.7c-26.4,5.3-52.7,10.6-79.1,15.9
			c-2.2,0.5-4.4,0.8-6.7,1c-23.8,2.2-47.6,4.5-71.5,6.8c-5.6,0.6-11.3,0.8-16.8,0.8c-26.4-0.1-52.8-0.6-79.3-0.8
			c-4.4,0-8.8-0.3-13-0.9c-21.2-2.8-42.3-5.7-63.5-8.6c-3.4-0.5-6.8-1.2-10-2.2c-24.3-7.5-40.9-12.2-54-16.7
			c-2.1-0.7-4.3-1.8-6.5-3.3c-11.8-7.7-23.7-15.4-35.5-23.1c-0.3-0.2-0.5-0.4-0.5-0.7c0-0.4,0.1-0.5,0.5-0.3
			c12.1,7.9,24.2,15.8,36.4,23.6c1.7,1.1,3.6,2,5.6,2.6c18.1,5.5,36.2,11,54.2,16.5c3.3,1,6.7,1.8,10.2,2.2
			c20.9,2.8,41.8,5.6,62.7,8.4c18.5,2.5,37.8,1,56.5,1.6c15.4,0.4,30.8,0.5,46.1,0.1c0.8,0,1.6-0.1,2.4-0.1c24.3-2.3,48.7-4.6,73-7
			c5.6-0.5,11.2-1.4,16.7-2.5c24.1-4.8,48.2-9.7,72.2-14.6c4.8-1,9.6-2.2,14.3-3.8c22-7.2,43.9-14.4,65.8-21.7
			c2.4-0.8,4.8-1.8,7-2.9c17-8.6,34-17.3,51-26C814.2,852.1,817.1,850.3,819.9,848.2z"/>
                                                                    <path d="M410.3,986.3c-20.6-2.9-41.2-5.8-61.8-8.8c-3.3-0.5-6.5-1.3-9.6-2.3c-17.9-5.9-35.8-11.8-53.8-17.6
			c-1.9-0.6-3.6-1.5-5.3-2.6c-12-8.3-24-16.6-36-24.9c-1-0.7-1.7-1.6-2.3-2.6c-4.7-9.3-9.5-18.6-14.2-27.8c-0.7-1.5-1.2-3-1.4-4.6
			c-1.6-13.9-3.2-27.8-4.8-41.7c0-0.2,0.1-0.3,0.3-0.4c0.1,0,0.3,0.1,0.3,0.2c4.2,8.1,8.6,16.2,13,24.3c0.6,1,1.4,1.9,2.4,2.5
			c11.9,7.7,23.7,15.4,35.5,23.1c1.7,1.1,3.6,2,5.6,2.6c18.5,5.8,37.1,11.5,55.8,17.1c3.4,1,7,1.8,10.6,2.3
			c20.5,2.7,41,5.5,61.5,8.2c4.6,0.6,9.3,1,13.9,1c25.9,0.3,51.9,0.5,77.8,0.7c5.6,0,11.2-0.2,16.8-0.7c22.6-2.1,45.1-4.3,67.7-6.4
			c5.6-0.5,11.1-1.3,16.5-2.4c25.3-5,50.6-10.1,75.9-15.3c4.9-1,9.6-2.3,14.3-3.8c21.6-7.1,43.1-14.2,64.6-21.4
			c3.6-1.2,7-2.7,10.4-4.4c16.4-8.4,32.8-16.8,49.3-25.2c1.1-0.5,2.3-1.3,3.7-2.4c11.3-8.2,22.6-16.4,34-24.6
			c0.2-0.2,0.6-0.1,0.7,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0.2,14.3,0.4,28.6,0.6,43c0,2.1-0.9,2.7-2.4,3.9c-10.1,8-20.2,15.9-30.4,23.9
			c-2.7,2.2-5.6,4.1-8.7,5.8c-15.5,8.6-31.1,17.1-46.7,25.6c-3.2,1.7-6.4,3.2-9.8,4.4c-21.9,7.8-43.7,15.5-65.6,23.2
			c-4.2,1.5-8.4,2.7-12.8,3.6c-26.3,5.6-52.6,11.3-78.9,16.9c-1.6,0.3-3.1,0.6-4.7,0.7c-23.9,2.5-47.8,4.9-71.6,7.4
			c-5.8,0.6-11.4,0.9-16.8,1c-26.7,0.2-53.5-0.5-80.2-0.8C419.3,987.3,414.8,987,410.3,986.3z"/>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {currentSlide === 4 && (
                                            <div className="obj__it obj__it--4">
                                                <div className="obj__it-img">
                                                    <img src={require("../assets/img/objects/fueler.svg").default}/>
                                                    <div className="obj__el">
                                                        <svg viewBox="0 0 1931 1086">
                                                            <g
                                                                onClick={()=>{
                                                                    setObjects({
                                                                        ...objects,
                                                                        4: {
                                                                            ...objects[4],
                                                                            active: 1
                                                                        }
                                                                    });
                                                                }}
                                                                style={{color: objects[4].elements[0]}}
                                                                className={'action-group' + (objects[4].active == 1 ? ' active' : '')}
                                                                id="E1">
                                                                <g>
                                                                    <path d="M101.5,476.1l-43.3-9.5c-1.7-0.4-2.5,0.3-2.3,2l1.5,22.4c0,0.5-0.2,0.7-0.7,0.6l-1.7-0.4
			c-0.4-0.1-0.6-0.4-0.6-0.8c0.3-15.1,0-30.5,1-45.4c0.8-12.3,1.6-24.5,2.5-36.7c0-0.3,0.3-0.5,0.6-0.5c0,0,0,0,0,0l70.8,10.8
			c0.2,0,0.5,0.2,0.7,0.4c0.1,0.1,0.1,0.3,0.1,0.5c-0.1,0.2-0.1,0.4,0.1,0.6l13.4,18.8c0.4,0.6,1,1,1.8,1l7.8,0.9
			c0.3,0,0.6-0.1,0.8-0.3l1.4-1.2c0.2-0.2,0.5-0.3,0.8-0.3l3.3,0.2c0.3,0,0.6-0.2,0.8-0.4c1.4-2.7,3.5-12.1-1.7-11.7
			c-0.3,0-0.5-0.1-0.6-0.4l-1.3-3.5c-0.1-0.2,0-0.5,0.3-0.6c0.1,0,0.1,0,0.2,0c32.7,5,65.3,10,98,14.9c0.6,0.1,0.9,0.5,0.7,1.1
			c-0.1,0.3,0,0.7,0.2,1l17.2,21.3c0.7,0.9,1.6,1.4,2.8,1.5c3.3,0.3,8.1,2,10.6-1c0.2-0.3,0.5-0.4,0.9-0.3c8,2,7.9-12.8,2.5-13.4
			c-0.4,0-0.7-0.3-0.7-0.7c-0.2-1.3-0.7-2.4-1.5-3.5c-0.7-0.8-0.5-1.1,0.6-1l51,7.8c0.6,0.1,1.1-0.3,1.2-0.9c0-0.1,0-0.1,0-0.2
			c0-0.8,0-1.7,0.2-2.7c11-62.8,22-125.6,33.1-188.4c1.2-6.6,2.6-13.1,4.4-19.5c5.4-19.6,10.7-39.2,16.1-58.8c2-7,4.7-14.1,6-21.6
			c0.1-0.3,0-0.6-0.2-0.8c-2.2-2.3-4.8-3.4-7.8-3.1c-94.4,9.1-188.8,18.1-283.1,27.2c-0.2,0-0.4-0.2-0.5-0.4c0-0.1,0-0.2,0-0.2
			c19.2-37.4,50.4-56.8,91.6-63.1c94.6-14.4,189.3-28.8,284-43.2c13.4-2,26.2-3.8,40.2-4.7c12.3-0.9,24.6-1.8,36.9-2.7
			c17.5-1.3,35.1-0.9,52.6-0.9c5.7,0,11.4,0.4,17.1,1c52.9,6.4,105.9,12.7,158.8,19.1c5,0.6,11,2.3,14.9,5.9
			c9.3,8.6,10.3,19.1,10.5,31.4c2.3,149,4.7,298.1,6.7,447.1c0,1-0.1,2-0.2,3c-0.1,0.3-0.3,0.6-0.6,0.7c-4.4,1.9-9.2,3.2-14.6,3.9
			c-12.4,1.5-25,4.2-37.3,8c-29,8.9-54.9,20.6-78.8,39.6c-22.1,17.6-42.5,41.2-56.8,66.7c-0.2,0.3-0.4,0.5-0.8,0.6l-19.3,3.6
			c-0.2,0-0.4-0.1-0.5-0.3c0-0.1,0-0.2,0.1-0.3c12.7-18.3,25.4-36.6,38.2-54.8c21.2-30.2,46-54.3,80.4-68.8c2.5-1,5.2-1.8,8.1-2.1
			c14.8-2,29.5-4,44.3-6c1.9-0.3,3.6-0.3,5.3-0.3c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0c-1.8-136.2-3.7-272.1-5.6-407.8
			c-0.1-8.9,0-18.6-11.2-19.3c-67.5-4.2-135-8.7-202.5-11.1c-7.1-0.2-20-1.6-28,0c-16.3,3.3-30.2,9.6-41.7,18.8
			c-14.5,11.6-25.5,34.1-30.4,51.8c-5.5,20.1-10.9,40.2-16.1,60.4c-1.7,6.4-3.1,12.9-4.2,19.5c-10.1,57.9-20.2,115.7-30.3,173.5
			c-3.3,18.8-3.9,38.3-5.3,57.4c-1.2,17.4,0.4,35.3,1.1,52.8c0,0.7-0.3,1.1-1,1.2c-23.1,2.6-47.1,3.5-70.4,1.5
			c-4.7-0.4-9.9-1.3-15.5-2.7c-74.1-18.4-148.9-37.1-224.4-56c-0.6-0.1-0.9-0.5-0.9-1.1l-1.7-25.9
			C101.9,476.3,101.8,476.1,101.5,476.1z M357.4,425.7c-0.6,0.4-0.6,0.9-0.2,1.5c0.2,0.3,0.6,0.3,0.9,0.1c0.2-0.1,0.2-0.3,0.3-0.5
			l0-0.6c0-0.4-0.3-0.7-0.6-0.7C357.7,425.6,357.6,425.6,357.4,425.7z M92.6,448c10.2,2.1,20.5,3.9,30.9,5.4
			c3.5,0.5,8.9,1.6,13.6,1.9c0.4,0,0.7-0.2,0.8-0.5c1.1-3.2,1.3-6.4,0.6-9.7c-0.1-0.3-0.3-0.6-0.6-0.6c-8-1.4-16.1-2.6-24.3-3.8
			c-2-0.3-3.7-1.3-5.7-1.7c-4.5-0.8-9.1-1.4-13.6-1.6c-0.4,0-0.7,0.3-0.7,0.6l-1.2,9.5C92.2,447.8,92.3,448,92.6,448z M254.8,478.1
			c1.9,0.2,4.9,1,7.4,1c0.3,0,0.7-0.2,0.8-0.5c1.1-2.3,3.2-11.3-1-11.9c-10.8-1.6-21.6-3.3-32.3-5.1c-1.1-0.2-1.6-1.1-2.8-1.3
			c-5.2-0.9-10.5-1.5-15.8-1.9c-0.4,0-0.6,0.1-0.6,0.5l-1.5,10.5c-0.1,0.4,0.2,0.7,0.6,0.8C224.4,473.4,239.5,476,254.8,478.1z"/>
                                                                    <path d="M742.9,561.8c-37.3,4.8-69.5,33.1-90.5,62.1c-15.5,21.4-30.9,42.8-46.3,64.3c-0.2,0.3-0.5,0.5-0.8,0.5
			l-169.5,31.7c-0.3,0.1-0.6-0.1-0.6-0.4c0,0,0,0,0-0.1c-2.8-66.5-5.6-132.9-8.2-199.5c-0.2-4.3,0-8.6,0.5-12.9
			c2.4-21.1,4.8-42,8.2-63.1c0-0.3,0.3-0.4,0.5-0.4c0.1,0,0.3,0.1,0.3,0.2c3.3,5.4,8.1,7.3,14.5,5.6c17.4-4.7,34.8-9.4,52.2-14.2
			c0.3-0.1,0.5,0.1,0.6,0.3c0,0.1,0,0.2,0,0.3l-5.7,19.7c-0.1,0.4-0.4,0.6-0.8,0.6c-3-0.1-5.8-0.4-8.6-1c-3.3-0.7-5.3,4.9-2.5,7.1
			c2,1.6,8.6,1.9,11.2,2.3c3.8,0.6,6.6-0.2,7.8-3.9c2.8-9,5.5-18.2,8.2-27.5c0.1-0.4,0.4-0.7,0.8-0.8
			c73.6-20.2,147.2-40.5,220.9-60.8c15.8-4.4,24.6-18.6,24.4-34.6c-0.8-52.9-1.7-105.8-2.4-158.7c0-1.5-0.6-3.9-1.8-7
			c-3.5-9.4-9.7-11.3-19.6-11.8c-65.1-3.2-130.2-6.8-195.3-9.5c-20.5-0.9-38.1,7.9-50.3,24.5c-0.3,0.4-0.4,0.3-0.5-0.1
			c-0.1-0.6,0.1-1.1,0.4-1.6c6.2-9.3,12.9-19,22.5-25.5c14.1-9.5,28.8-14.3,46.3-13.3c70.2,4,140.4,8,210.6,12.1
			c4.7,0.3,7.1,3.4,7.1,8c0.1,17.1,0.3,34.1,0.6,51.2c1.8,116.9,3.3,233,5.1,350.8c0,0.3-0.2,0.5-0.5,0.6
			C768.8,558.5,755.9,560.2,742.9,561.8z M696,503.5c0.2,5.8-1.5,13.1,7.5,12.1c9.2-1,18.4-2,27.7-3c5.3-0.6,8-3.2,7.3-8.6
			c0-0.3,0.2-0.6,0.5-0.6c0,0,0,0,0.1,0c2,0,4,0.6,5.9,0.4c8.5-0.8,16.9-1.7,25.2-2.6c0.5-0.1,0.8-0.4,0.8-0.9l-0.4-16.7
			c0-0.4-0.2-0.6-0.6-0.6c-3.3-0.4-6.3-0.5-8.8-0.3c-7.4,0.7-14.7,1.4-21.7,2.1c-0.6,0.1-0.9-0.2-1-0.8c-0.5-6.8-3.3-10-10.2-9.5
			c-8,0.6-16,1.3-24,2.1c-10.5,1-8.8,9.1-8.4,16.9c0,0.5-0.3,0.9-0.8,1l-11.2,1c-0.4,0-0.6,0.2-0.6,0.6l0.1,5.8
			c0,0.3,0.2,0.6,0.6,0.7c3.7,0.9,7.5,1,11.4,0.4C695.6,502.8,695.9,503,696,503.5C696,503.4,696,503.4,696,503.5z"/>
                                                                </g>
                                                            </g>
                                                            <g
                                                                onClick={()=>{
                                                                    setObjects({
                                                                        ...objects,
                                                                        4: {
                                                                            ...objects[4],
                                                                            active: 3
                                                                        }
                                                                    });
                                                                }}
                                                                style={{color: objects[4].elements[2]}}
                                                                className={'action-group' + (objects[4].active == 3 ? ' active' : '')}
                                                                id="E3">
                                                                <g>
                                                                    <path d="M1673.6,233.9c1.6-0.6,2.3-0.9,4-0.5c16.9,4.1,34.2,7.9,50.5,14.2c9,3.5,18.9,6.9,26.9,10.8
			c19.9,9.7,50.2,27.8,52.3,52.8c1,11.8,0.3,19.9-0.2,30c-0.2,4.2-0.7,8.1-1.4,11.9c-1,5.5-1.6,11.1-4,15.9c-2.4,4.9-5.6,9.2-9.6,13
			c-0.2,0.2-0.5,0.4-0.9,0.4c-2.1,0.4-4.2,0.6-6.3,0.8c-115.5,10.9-231,21.8-346.4,32.6c-1.9,0.2-3.7,0.4-5.6,0.7
			c-0.3,0.1-0.6,0.2-0.8,0.4c-2.7,2.7-9.3,7.7-10.2,11.3c-1.8,7-0.3,13.3,4.5,18.8c0.1,0.2,0.1,0.4,0,0.5c-0.1,0-0.1,0.1-0.2,0.1
			c-12,1.4-23.9,2.7-35.6,3.9c-2.2,0.2-4.3,0.8-6.3,1.6c-7.3,3.2-14.3,6.2-21.9,8.7c-0.3,0.1-0.5,0.4-0.5,0.7l-0.1,5.1
			c0,0.5-0.2,0.7-0.7,0.8l-130.3,15.9c-0.5,0.1-0.8-0.2-0.8-0.7l6.2-308.1c0-0.5,0.2-0.7,0.7-0.7c133,7.7,265.9,15,398.9,22.4
			c8.7,0.5,17.3,1.2,25.7,2.1c0.6,0.1,1,0.4,1.2,1l10.2,33.1C1672.9,233.8,1673.2,234,1673.6,233.9
			C1673.5,233.9,1673.6,233.9,1673.6,233.9z M1303.1,315.4c-10.4,16.8-20.9,33.7-31.2,50.5c-0.1,0.2-0.1,0.4,0,0.6L1301,414
			c0.5,0.7,1.4,1,2.1,0.5c0.2-0.1,0.4-0.3,0.5-0.5l30.3-50.6c0.2-0.3,0.2-0.6,0-0.9l-27.8-47.6c-0.5-0.9-1.2-1-2-0.5
			C1303.7,314.6,1303.3,315,1303.1,315.4z"/>
                                                                    <path d="M1583.9,436c-1-0.1-1.8-0.2-2.6-0.3c-0.2,0-0.3-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2l139.8-16.2
			c0.2,0,0.3,0.1,0.4,0.3c0,0.2-0.1,0.3-0.2,0.4c-13.9,3.3-27.5,7.2-41.4,9.3c-7.8,1.2-15.5,2.4-23.3,3.5c-0.4,0.1-0.6-0.1-0.6-0.5
			V430c0-0.4-0.2-0.6-0.6-0.6c-4.7,0.5-9.4,1.1-14,1.7c-8.3,1.1-17.1,3.9-25.8,5.5c-0.3,0.1-0.6,0.2-0.9,0.4
			c-0.8,0.5-1.9,0.7-3.3,0.6C1602.1,437,1593,436.4,1583.9,436z"/>
                                                                    <path d="M61.2,540.3c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0,0,0h0c-0.6,0.1-1.3-2.8-1.6-6.3c0,0,0,0,0,0l0-0.3
			c-0.3-3.5,0-6.4,0.6-6.4l0,0c0.1,0,0.2,0.1,0.2,0.1c0,0,0,0,0,0L61.2,540.3z"/>
                                                                    <path 
                                                                          d="M1581.2,686.8c1.8,0.4,2.9,1.7,3.5,4L1581.2,686.8z"/>
                                                                </g>
                                                            </g>
                                                            <g
                                                                onClick={()=>{
                                                                    setObjects({
                                                                        ...objects,
                                                                        4: {
                                                                            ...objects[4],
                                                                            active: 2
                                                                        }
                                                                    });
                                                                }}
                                                                style={{color: objects[4].elements[1]}}
                                                                className={'action-group' + (objects[4].active == 2 ? ' active' : '')}
                                                                id="E2">
                                                                <g>
                                                                    <path className="st4" d="M883.6,570.8l-53.5-9.4c-0.3-0.1-0.5-0.2-0.5-0.6l-1.2-108.7c0-0.8-0.4-1.3-1.3-1.4l-5.3-0.4
			c-0.1,0-0.2-0.1-0.2-0.2l-5-334.4c0-0.3,0.2-0.5,0.5-0.5c36.9-3.7,73.8-7.2,110.7-10.6c4.2-0.4,8.4-1.4,10.8,3.1
			c1.2,2.2,1.8,4.4,1.8,6.6c0.2,188.9,0.1,325.9,0.8,431.7c0.1,19.7,0.1,39.3-0.1,58.6c0,0.6-0.3,0.9-0.9,0.8l-47.4-9
			c-0.4-0.1-0.6-0.3-0.7-0.7l-5.8-22.6C886,572,885,571.1,883.6,570.8z"/>
                                                                    <path className="st4" d="M1232.6,262.6c-2.5,129.6-5.1,259.3-7.8,389.1c0,0.4-0.3,0.7-0.7,0.8l-225.7,49.1c-0.5,0.1-1-0.1-1.3-0.6
			c-17.6-30.7-35.3-61.5-53.1-92.2c-0.7-1.2-1.1-2.5-1.1-3.9c-1-160.4-0.8-320.9-1.1-481.3c0-6,0.9-12.6-2.7-17.9
			c-0.2-0.2-0.1-0.6,0.1-0.7c0.1-0.1,0.2-0.1,0.3-0.1c95.5,7.9,191.1,15.8,286.8,23.7c5.1,0.4,8.6,2.8,8.5,8.1
			C1234.8,169.1,1233.5,214.1,1232.6,262.6z M1224.4,267.7l-273.3-0.5c-0.3,0-0.5,0.2-0.5,0.5l0,0c0.4,111.4,0.8,222.8,1.2,334.3
			c0,1.4,0.3,2.7,1,3.8c16,28.5,31.9,56.9,47.8,85.4c0.2,0.3,0.4,0.4,0.7,0.3l215.9-45.8c0.7-0.1,1.2-0.7,1.2-1.4l6.5-376.2
			C1224.8,267.8,1224.7,267.7,1224.4,267.7z"/>
                                                                    <path className="st4" d="M1096.2,645.2c0-1.6-0.8-2.2-2.3-1.9l-46.5,9.2c-0.3,0.1-0.5,0.3-0.5,0.6l0.4,18.5c0,0.6-0.3,1-0.9,1.1
			l-48.2,10c-0.3,0.1-0.5,0-0.7-0.3c-14.3-25.7-28.6-51.3-43.1-76.9c-0.7-1.2-1-2.5-1-3.8c-0.4-109.8-0.8-220.3-1.2-331.6
			c0-0.5,0.3-0.8,0.8-0.8h5.6c0.4,0,0.6,0.2,0.6,0.6v300.9c0,0.3-0.1,0.6-0.4,0.8c-10.6,8.2-0.3,22.8,11.5,12.7
			c4.1-3.5,5-9.6,5-14.8c0.2-100,0.3-199.9,0.4-299.8c0-0.2,0.2-0.5,0.4-0.5c0,0,0,0,0,0l228.1,0.4c0.4,0,0.6,0.3,0.6,0.6
			c0,0,0,0,0,0l-4.9,305c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.8-10.8-5.6-23.3-17.3-27
			c-37.8-11.8-52.9,64-17,72.8c20,4.9,31.7-16.5,33.8-33.2c0-0.3,0.2-0.6,0.4-0.8c0.2-0.2,0.3-0.1,0.3,0.2l-0.9,52.8
			c0,0.4-0.3,0.7-0.7,0.8c-32.1,6.7-64.3,13.3-96.5,19.9c-1.7,0.3-3.4,0.3-5-0.1c-0.3-0.1-0.5-0.4-0.5-0.7V645.2z M985.8,563.5
			c0.1,0,0.3,0,0.5,0c58.4-8.7,116.8-17.2,175.2-25.7c0.6-0.1,0.9-0.5,0.9-1.1l3.9-259.1c0-0.4-0.2-0.6-0.6-0.6l-180.7,0.5
			c-0.1,0-0.2,0.1-0.2,0.2c-0.2,94.7-0.4,189.5-0.5,284.3c0,0.3,0.1,0.5,0.2,0.7c0.2,0.2,0.4,0.4,0.6,0.5
			C985.5,563.4,985.8,563.5,985.8,563.5z"/>

                                                                    <ellipse
                                                                        transform="matrix(6.801531e-02 -0.9977 0.9977 6.801531e-02 653.1894 1468.1355)"
                                                                        className="st4" cx="1112.4" cy="384.5" rx="16"
                                                                        ry="9.6"/>

                                                                    <ellipse
                                                                        transform="matrix(6.801531e-02 -0.9977 0.9977 6.801531e-02 621.3364 1437.9371)"
                                                                        className="st4" cx="1080.3" cy="386.4" rx="16.3"
                                                                        ry="10.1"/>

                                                                    <ellipse
                                                                        transform="matrix(6.627395e-02 -0.9978 0.9978 6.627395e-02 590.8334 1408.0983)"
                                                                        className="st4" cx="1047.8" cy="388.4" rx="16.6"
                                                                        ry="10.5"/>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                        </div>
                        {mainStatus === 'intro' && (
                            <div className="sc__action fadeIn animated">
                                <button onClick={()=>{
                                    setMainStatus('start');
                                    setCountDownDate(Date.now() + 60000);
                                }} type="button" className="btn d-flex align-items-center justify-content-between">
                                    <span>Начать</span>
                                    <i className="icon__right">
                                        <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="white"/>
                                        </svg>
                                    </i>
                                </button>
                            </div>
                        )}

                        {mainStatus === 'start' && (
                            <div className="sc__action fadeIn animated">
                                <button onClick={()=>{
                                    setMainStatus('draw');
                                }} type="button" className="btn d-flex align-items-center justify-content-between">
                                    <span>Вперед</span>
                                    <i className="icon__right">
                                        <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928932C18.9526 0.538408 18.3195 0.538408 17.9289 0.928932C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM0 9H25V7H0V9Z" fill="white"/>
                                        </svg>
                                    </i>
                                </button>
                            </div>
                        )}

                        {mainStatus === 'draw' && (
                            <div className="colors__box fadeIn animated">
                                <div ref={sliderRef2} className="keen-slider">
                                    <div className="keen-slider__slide">
                                        <div className="colors__list">
                                            {colors1.map((color, i) => (
                                                <div onClick={()=>{
                                                    let _elements = objects[currentSlide].elements;
                                                    _elements[objects[currentSlide].active - 1] = color;
                                                    setObjects({
                                                        ...objects,
                                                        [currentSlide]: {
                                                            ...objects[currentSlide],
                                                            elements: _elements
                                                        }
                                                    });
                                                }} style={{backgroundColor: color}} className="colors__it d-flex align-items-center justify-content-center">
                                                    {objects[currentSlide].elements[objects[currentSlide].active - 1] == color && (
                                                        <>
                                                            <div className="colors__it-border" style={{borderColor: color}}/>
                                                            <i className="icon__checked fadeIn animated">
                                                                <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1 4.3619L3.6381 7L9.6381 1" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                                                </svg>
                                                            </i>
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="keen-slider__slide">
                                        <div className="colors__list">
                                            {colors2.map((color, i) => (
                                                <div onClick={()=>{
                                                    let _elements = objects[currentSlide].elements;
                                                    _elements[objects[currentSlide].active - 1] = color;
                                                    setObjects({
                                                        ...objects,
                                                        [currentSlide]: {
                                                            ...objects[currentSlide],
                                                            elements: _elements
                                                        }
                                                    });
                                                }} style={{backgroundColor: color}} className="colors__it d-flex align-items-center justify-content-center">
                                                    {objects[currentSlide].elements[objects[currentSlide].active - 1] == color && (
                                                        <>
                                                            <div className="colors__it-border" style={{borderColor: color}}/>
                                                            <i className="icon__checked fadeIn animated">
                                                                <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1 4.3619L3.6381 7L9.6381 1" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                                                </svg>
                                                            </i>
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {slLoaded2 && instanceRef2.current && (
                                    <div className="dots">
                                        {[
                                            ...Array(instanceRef2.current.track.details.slides.length).keys(),
                                        ].map((idx) => {
                                            return (
                                                <button
                                                    key={idx}
                                                    onClick={() => {
                                                        instanceRef2.current?.moveToIdx(idx)
                                                    }}
                                                    className={"dot" + (currentSlide2 === idx ? " active" : "")}
                                                ></button>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        )}

                        {mainStatus === 'draw' && (
                            <div className="sc__action fadeIn animated">
                                <button onClick={()=>{
                                    setWsStatus('finish');
                                }} disabled={!drawFinished} type="button" className="btn d-flex align-items-center justify-content-between">
                                    <span>Готово</span>
                                    {drawFinished && (
                                        <i className="icon__done fadeIn animated">
                                            <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.723877 7.72381L6.00007 13L18.0001 1" stroke="white" strokeWidth="2"/>
                                            </svg>
                                        </i>
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {wsStatus === 'success' && (
                <div className="sc__main sc__main--success h-full fadeIn animated">
                    <div className="logo">
                        <img src={require("../assets/img/logo.png")}/>
                    </div>
                    <div className="container h100 d-flex align-items-center">
                        <div className="success__box">
                            <div className="success__bg">
                                <img src={require("../assets/img/success.svg").default}/>
                            </div>
                            <div className="success__box-ct">
                                <div className="success__box-title text-center">
                                    Отлично!
                                </div>
                                <div className="success__box-descr text-center">
                                    Теперь ты увидишь свою <br/>
                                    раскраску на стенде <br/>
                                    и узнаешь, что такое ИС АСП
                                </div>
                            </div>
                            <div className="succes__after text-center">
                                Узнай больше на <a href="#" target="_blank">сайте ЗИТ</a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="desk__lock d-flex align-items-center justify-content-center">
                <div className="desk__lock-ct">
                    <div className="desk__lock-img">
                        <img src={require("../assets/img/lock-img.png")}/>
                    </div>
                    <div className="desk__lock-info">
                        <div className="desk__lock-info-icon">
                            <img src={require("../assets/img/lock-icon.svg").default}/>
                        </div>
                        <div className="desk__lock-info-descr">
                            Открой <br/>
                            эту страницу <br/>
                            на своём смартфоне
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;